import React from 'react'

import { Typography, Box, FormHelperText } from '@mui/material'
import { PropTypes } from 'prop-types'

import { DEFAULT_LABEL_WIDTH } from '~/common/constants'

const LabelValue = ({
  label,
  value,
  children,
  sx,
  labelWidth,
  labelColor,
  required,
  labelSx = {},
  valueSx = {},
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        wordBreak: 'break-word',
        ...sx,
      }}
      {...props}
    >
      <Box
        sx={{
          flex:
            typeof labelWidth === 'string'
              ? `0 0 ${labelWidth}`
              : `0 0 ${labelWidth}px`,
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          ...labelSx,
        }}
      >
        {typeof label === 'string' ? (
          <Typography variant="body2" sx={{ color: labelColor }}>
            {label}
          </Typography>
        ) : (
          label
        )}
        {required && <FormHelperText error>*</FormHelperText>}
      </Box>
      <Box sx={{ flex: 1, minWidth: 0, ...valueSx }}>
        {children ? (
          children
        ) : typeof value === 'string' ? (
          <Typography sx={{ ...valueSx }}>{value}</Typography>
        ) : (
          value
        )}
      </Box>
    </Box>
  )
}

LabelValue.defaultProps = {
  label: null,
  value: null,
  children: null,
  sx: {},
  labelWidth: DEFAULT_LABEL_WIDTH,
}

LabelValue.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
  children: PropTypes.node,
  sx: PropTypes.shape(),
  labelWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default LabelValue
