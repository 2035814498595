import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateSetting = (body) => {
  const uri = `/cron-setting`
  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetSetting = (params) => {
  const uri = `/cron-setting`
  return api.get(uri, params)
}
