import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListOrgStructure } from '~/modules/master/redux/apis/org-structure'
import useFactory from '~/modules/master/redux/hooks/useFactory'
import { convertFilterParams } from '~/utils'

const ChooseOrg = ({
  t,
  onSubmit = () => {},
  onClose = () => {},
  open,
  data = {},
}) => {
  const {
    data: {
      detailFactory: { data: detailFactory },
    },
    actions: { actGetDetailFactory },
  } = useFactory()

  useEffect(() => {
    if (data) {
      actGetDetailFactory(data.id)
    }
  }, [data])

  const initialValues = useMemo(() => {
    if (detailFactory) {
      return { orgStructure: detailFactory.orgStructures }
    }
    return { orgStructure: [] }
  }, [detailFactory])

  return (
    <Dialog
      open={open}
      title={t('factory.chooseOrg')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ handleSubmit, resetForm }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
            >
              <Grid item xs={12}>
                <LV label={t('factory.name')} value={data?.name} />
              </Grid>
              <Grid item xs={12}>
                <LV label={t('factory.code')} value={data?.code} />
              </Grid>

              <Grid item xs={12}>
                <LV label={t('factory.location')} value={data?.location} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field.Autocomplete
                  name="orgStructure"
                  label={t('factory.orgStructure')}
                  placeholder={t('factory.orgStructure')}
                  asyncRequest={(s) =>
                    apiGetListOrgStructure({
                      keyword: s,
                      limit: ASYNC_SEARCH_LIMIT,
                      filter: convertFilterParams({
                        isActive: STATUS_VALUE.active,
                      }),
                    })
                  }
                  asyncRequestHelper={(res) => res?.data?.items}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                  multiple
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    // color="grayF4"
                    onClick={() => handleSubmit()}
                  >
                    {t('actionBar.save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default ChooseOrg
