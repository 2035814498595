const style = (theme) => ({
  root: {
    display: 'flex',
    marginLeft: 'auto',
    gap: theme.spacing(1),
  },
  btn: {
    width: 40,
    minWidth: 40,
    height: 40,
  },
})

export default style
