import { all } from 'redux-saga/effects'

import watchAttendance from './attendance'
import watchBusinessTravel from './business-travel'
import watchCatShift from './cat-shift'
import watchEmail from './email'
import watchFactory from './factory'
import watchOrgStructure from './org-structure'
import watchOvertime from './overtime'
import watchPickupPoint from './pickup-point'
import watchPosition from './position'
import watchProfileCustom from './profile-custom'
import watchReportAll from './report-all'
import watchReportBr11 from './report-br11'
import watchReportFee from './report-fee'
import watchReportOt from './report-ot'
import watchRosterCalendar from './roster-calendar'
import watchRoute from './route'
import watchSetting from './setting'
import watchVehicleCategories from './vehicle-categories'
import watchWorkSchedule from './work-schedule'

/**
 * Root saga
 */
export default function* sagas() {
  yield all([
    watchOrgStructure(),
    watchProfileCustom(),
    watchOvertime(),
    watchPickupPoint(),
    watchRoute(),
    watchWorkSchedule(),
    watchCatShift(),
    watchBusinessTravel(),
    watchFactory(),
    watchAttendance(),
    watchVehicleCategories(),
    watchReportBr11(),
    watchPosition(),
    watchRosterCalendar(),
    watchReportAll(),
    watchReportOt(),
    watchSetting(),
    watchEmail(),
    watchReportFee(),
  ])
}
