import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actCreateFactoryFailed,
  actCreateFactorySuccess,
  MASTER_CREATE_FACTORY,
} from '../../actions/factory'
import { apiCreateFactory } from '../../apis/factory'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doCreate(action) {
  try {
    const response = yield call(apiCreateFactory, action?.payload)

    if (response?.statusCode === 200) {
      // Call callback action if provided
      yield put(actCreateFactorySuccess(response))

      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'factory.notification.createSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actCreateFactoryFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchCreate() {
  yield takeLatest(MASTER_CREATE_FACTORY, doCreate)
}
