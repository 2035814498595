import React from 'react'

import { useTranslation } from 'react-i18next'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { Field } from '~/components/Formik'
import { apiGetListFactoryList } from '~/modules/master/redux/apis/factory'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Field.Autocomplete
        name="factory"
        placeholder={t('orgStructure.filter.code')}
        asyncRequest={(s) =>
          apiGetListFactoryList({
            keyword: s,
            limit: ASYNC_SEARCH_LIMIT,
          })
        }
        asyncRequestHelper={(res) => res?.data?.items}
        getOptionLabel={(option) => option.name}
      />
    </>
  )
}

export default FilterForm
