import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetDetailPickupPointFailed,
  actGetDetailPickupPointSuccess,
  MASTER_GET_DETAIL_PICKUP_POINT,
} from '../../actions/pickup-point'
import { apiGetDetailPickupPoint } from '../../apis/pickup-point'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDetail(action) {
  try {
    const response = yield call(apiGetDetailPickupPoint, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actGetDetailPickupPointSuccess(response.data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetDetailPickupPointFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetDetail() {
  yield takeLatest(MASTER_GET_DETAIL_PICKUP_POINT, doGetDetail)
}
