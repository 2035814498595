import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import Button from '~/components/Button'
import DateTimePicker from '~/components/DateTimePicker'
import Dialog from '~/components/Dialog'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'

const DEFAULT_DATA = {
  dateStart: new Date(),
  dateEnd: new Date(),
}

const PopupSync = ({
  t,
  onClose = () => {},
  open,
  showDetailId,
  onSubmit = () => {},
}) => {
  const [formData, setFormData] = useState(DEFAULT_DATA)
  const {
    actions: { actGetDetailProfileCustom },
  } = useProfileCustom()

  useEffect(() => {
    if (showDetailId) {
      actGetDetailProfileCustom(showDetailId)
    }
  }, [showDetailId])

  const handleChange = (name, value) => {
    setFormData((val) => ({ ...val, [name]: value }))
  }

  const resetForm = () => {
    setFormData(DEFAULT_DATA)
  }

  return (
    <Dialog
      open={open}
      title={`${t('attendance.sync.title')}`}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
        justifyContent={'space-between'}
      >
        <Grid item xs={5.5}>
          <DateTimePicker
            label={t('attendance.sync.start')}
            value={formData.dateStart}
            onChange={(val) => handleChange('dateStart', val)}
          />
        </Grid>
        <Grid item xs={5.5}>
          <DateTimePicker
            label={t('attendance.sync.end')}
            value={formData.dateEnd}
            onChange={(val) => handleChange('dateEnd', val)}
          />
        </Grid>
      </Grid>
      <Box sx={{ padding: 3 }}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={resetForm}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              onClick={() => onSubmit(formData)}
            >
              {t('attendance.sync.syncButton')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default PopupSync
