// Action: sync
export const MASTER_SYNC_ROSTER_CALENDAR = 'MASTER_SYNC_ROSTER_CALENDAR'
export const MASTER_SYNC_ROSTER_CALENDAR_SUCCESS =
  'MASTER_SYNC_ROSTER_CALENDAR_SUCCESS'
export const MASTER_SYNC_ROSTER_CALENDAR_FAILED =
  'MASTER_SYNC_ROSTER_CALENDAR_FAILED'
export const MASTER_SYNC_ROSTER_CALENDAR_RESET =
  'MASTER_SYNC_ROSTER_CALENDAR_RESET'

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncRosterCalendar(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_ROSTER_CALENDAR,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncRosterCalendarSuccess(payload) {
  return {
    type: MASTER_SYNC_ROSTER_CALENDAR_SUCCESS,
    payload: payload,
  }
}

export function actSyncRosterCalendarFailed() {
  return {
    type: MASTER_SYNC_ROSTER_CALENDAR_FAILED,
  }
}

export function actSyncRosterCalendarReset() {
  return {
    type: MASTER_SYNC_ROSTER_CALENDAR_RESET,
  }
}

export default {
  actSyncRosterCalendar,
  actSyncRosterCalendarSuccess,
  actSyncRosterCalendarFailed,
  actSyncRosterCalendarReset,
}
