// Action: send email
export const MASTER_SEND_EMAIL = 'MASTER_SEND_EMAIL'
export const MASTER_SEND_EMAIL_SUCCESS = 'MASTER_SEND_EMAIL_SUCCESS'
export const MASTER_SEND_EMAIL_FAILED = 'MASTER_SEND_EMAIL_FAILED'
export const MASTER_SEND_EMAIL_RESET = 'MASTER_SEND_EMAIL_RESET'

/** get data
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSendEmail(payload, onSuccess, onError) {
  return {
    type: MASTER_SEND_EMAIL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSendEmailSuccess(payload) {
  return {
    type: MASTER_SEND_EMAIL_SUCCESS,
    payload: payload,
  }
}

export function actSendEmailFailed() {
  return {
    type: MASTER_SEND_EMAIL_FAILED,
  }
}

export function actSendEmailReset() {
  return {
    type: MASTER_SEND_EMAIL_RESET,
  }
}

export default {
  actSendEmail,
  actSendEmailSuccess,
  actSendEmailFailed,
}
