import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Tabs from '~/components/Tabs'
import {
  EXPORT_TYPE,
  IMPORT_TYPE,
  SEND_MAIL_TYPE,
  TYPE_OT_AND_BT,
} from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import useAttendance from '~/modules/master/redux/hooks/useAttendance'
import useBusinessTravel from '~/modules/master/redux/hooks/useBusinessTravel'
import useOvertime from '~/modules/master/redux/hooks/useOvertime'
import useRosterCalendar from '~/modules/master/redux/hooks/useRosterCalendar'
import { ROUTE } from '~/modules/master/routes/config'
import { convertFilterParams, convertSortParams } from '~/utils'

import {
  columnBusinessTravel,
  columnOvertime,
  columnRosterCalendar,
} from '../columns'
import {
  ATTENDANCE_EXPORT_PERMISSION,
  ATTENDANCE_IMPORT_PERMISSION,
  ATTENDANCE_SEARCH_PERMISSION,
  ATTENDANCE_SYNC_PERMISSION,
  TAB_VALUE,
  tabList,
} from '../constants'
import PopupEmail from '../popup-email'
import PopupSync from '../popup-sync'
import RegisterVehicle from '../register-vehicle'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    route: ROUTE.ATTENDANCE.LIST.PATH,
    title: ROUTE.ATTENDANCE.LIST.TITLE,
  },
]

const ListAttendance = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { canAccess } = useApp()

  const [selectedRows, setSelectedRows] = useState([])
  const [registerVehicle, setRegisterVehicle] = useState(null)
  const [isSync, setIsSync] = useState(false)
  const [syncQuery, setSyncQuery] = useState(null)
  const [sendEmail, setSendEmail] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState({
    tab: 0,
  })

  const {
    data: {
      listAttendance: {
        data,
        isLoading,
        totalBussinessTravel,
        totalOvertimePlan,
        totalRosterCalendar,
      },
      detailRegisterVehicleOt: { data: detailRegisterVehicleOt },
      detailRegisterVehicleBuTravel: { data: detailRegisterVehicleBuTravel },
      registerVehicleOt: { isLoading: registerVehicleOtLoading },
      registerVehicleBuTravel: { isLoading: registerVehicleBuTravelLoading },
    },
    actions: {
      actGetListAttendance,
      actRegisterVehicleOt,
      actRegisterVehicleBuTravel,
      actDetailRegisterVehicleOt,
      actDetailRegisterVehicleOtReset,
      actDetailRegisterVehicleBuTravel,
      actDetailRegisterVehicleBuTravelReset,
    },
  } = useAttendance()

  const {
    data: {
      syncRosterCalendar: { isLoading: isLoadingRosterCalendar },
    },
    actions: { actSyncRosterCalendar },
  } = useRosterCalendar()

  const {
    data: {
      syncOvertime: { isLoading: isLoadingOvertime },
    },
    actions: { actSyncOvertime },
  } = useOvertime()

  const {
    data: {
      syncBusinessTravel: { isLoading: isLoadingBusinessTravel },
    },
    actions: { actSyncBusinessTravel },
  } = useBusinessTravel()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const openRegister = (val) => {
    setRegisterVehicle(val)

    if (tab === TAB_VALUE.overtime) {
      actDetailRegisterVehicleOt(val.id)
    }
    if (tab === TAB_VALUE.businessTravel) {
      actDetailRegisterVehicleBuTravel(val.id)
    }
  }

  const openSendEmail = (val) => {
    setSendEmail(val)
  }

  const closeSendEmail = () => {
    setSendEmail(null)
    refreshData()
  }

  const tableConfig = useMemo(() => {
    switch (tab) {
      case TAB_VALUE.overtime:
        return {
          columns: columnOvertime({ t, openRegister, openSendEmail }),
          total: totalOvertimePlan,
        }
      case TAB_VALUE.businessTravel:
        return {
          columns: columnBusinessTravel({ t, openRegister, openSendEmail }),
          total: totalBussinessTravel,
        }
      default:
        return {
          columns: columnRosterCalendar({ t }),
          total: totalRosterCalendar,
        }
    }
  }, [tab, t, totalOvertimePlan, totalBussinessTravel, totalRosterCalendar])

  const refreshData = () => {
    const { keyword = '', orgStructure, ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      type: tab || TAB_VALUE.rosterCalendar,
      filter: convertFilterParams(
        {
          ...dataFilter,
          orgStructureIds: orgStructure?.map((item) => item.id),
        },
        [
          ...tableConfig.columns,
          { field: 'otDate', filterFormat: 'date' },
          { field: 'businessTravelDay', filterFormat: 'date' },
          { field: 'dateWork', filterFormat: 'date' },
          { field: 'orgStructureIds', filterFormat: 'multiple' },
        ],
      ),
      sort: convertSortParams(sort),
    }
    actGetListAttendance(params)
    closeConfirmSync()
    closeSyncPopup()
    handleCloseRegister()
  }

  const openSyncPopup = () => {
    setIsSync(true)
  }

  const closeSyncPopup = () => {
    setIsSync(false)
  }

  const openConfirmSync = (values) => {
    setSyncQuery(values)
  }

  const closeConfirmSync = () => {
    setSyncQuery(null)
  }

  const handleSyncFailed = () => {
    closeConfirmSync()
  }

  const submitSyncData = () => {
    if (!syncQuery) return
    const query = {
      dateStart: syncQuery.dateStart.toISOString(),
      dateEnd: syncQuery.dateEnd.toISOString(),
    }

    switch (tab) {
      case TAB_VALUE.overtime:
        actSyncOvertime({ query }, refreshData, handleSyncFailed)
        break
      case TAB_VALUE.businessTravel:
        actSyncBusinessTravel({ query }, refreshData, handleSyncFailed)
        break
      default:
        actSyncRosterCalendar({ query }, refreshData, handleSyncFailed)
        break
    }
  }

  const typeExport = () => {
    switch (tab) {
      case TAB_VALUE.overtime:
        return {
          type: EXPORT_TYPE.OVERTIME_PLAN,
          tabName: t('attendance.overtime.title'),
          fileName: t('attendance.overtime.fileNameExport'),
        }
      case TAB_VALUE.businessTravel:
        return {
          type: EXPORT_TYPE.BUSSINESS_TRAVEL,
          tabName: t('attendance.businessTravel.title'),
          fileName: t('attendance.businessTravel.fileNameExport'),
        }
      default:
        return {
          type: EXPORT_TYPE.ROSTER_CALENDAR,
          tabName: t('attendance.rosterCalendar.title'),
          fileName: t('attendance.rosterCalendar.fileNameExport'),
        }
    }
  }

  const { type, tabName, fileName } = typeExport()

  const beforeTopbar = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {[TAB_VALUE.overtime, TAB_VALUE.businessTravel].includes(tab) &&
        !isEmpty(selectedRows) && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              onClick={() => openSendEmail(selectedRows)}
            >
              <Icon
                name="sendEmail"
                // fill="#FF9054"
                // stroke="#FF9054"
                sx={{ marginRight: '5px' }}
              />
              {t('common.email')}
            </Box>
            <Box sx={{ margin: '0 5px' }}>|</Box>
          </>
        )}
      <Guard code={ATTENDANCE_SYNC_PERMISSION[tab]}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          onClick={openSyncPopup}
        >
          <Icon
            name="assign"
            fill="#FF9054"
            stroke="#FF9054"
            sx={{ marginRight: '5px' }}
          />
          {t('common.sync')}
        </Box>
        <Box sx={{ margin: '0 5px' }}>|</Box>
      </Guard>
      <ImportExport
        name={fileName}
        onImport={
          tab === TAB_VALUE.rosterCalendar &&
          canAccess(ATTENDANCE_IMPORT_PERMISSION[tab])
            ? (body) => apiImport({ body, type: IMPORT_TYPE.ROSTER_CALENDAR })
            : null
        }
        onExport={
          canAccess(ATTENDANCE_EXPORT_PERMISSION[tab])
            ? () => {
                const params = {
                  filter: convertFilterParams(filters, [
                    ...tableConfig.columns,
                    { field: 'otDate', filterFormat: 'date' },
                    { field: 'businessTravelDay', filterFormat: 'date' },
                    { field: 'dateWork', filterFormat: 'date' },
                  ]),
                  sort: convertSortParams(sort),
                  type,
                }
                if (!isEmpty(selectedRows)) {
                  params.ids = JSON.stringify(
                    selectedRows?.map((x) => ({ id: x?.id })),
                  )
                }

                return apiExport(params)
              }
            : null
        }
        onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.ROSTER_CALENDAR)}
        onRefresh={refreshData}
      />
    </Box>
  )

  const tabElement = (
    <Tabs
      list={tabList(t, {
        totalBussinessTravel,
        totalOvertimePlan,
        totalRosterCalendar,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  const handleCloseRegister = () => {
    setRegisterVehicle(null)
    actDetailRegisterVehicleOtReset()
    actDetailRegisterVehicleBuTravelReset()
  }

  const handleSubmitRegister = (values) => {
    const body = {
      descriptionRegisterVehicle: values.descriptionRegisterVehicle,
      jobDetails: values.jobDetails?.map((item) => ({
        id: item.id,
        code: item.code,
        vehicleType: item.vehicleType,
        createdBy: item.createdBy?.id,
      })),
      type: SEND_MAIL_TYPE.ATTENDANCE,
    }

    if (tab === TAB_VALUE.overtime) {
      actRegisterVehicleOt({ id: values.id, body }, refreshData)
    } else {
      actRegisterVehicleBuTravel({ id: values.id, body }, refreshData)
    }
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.attendance')}
      loading={
        isLoading ||
        isLoadingRosterCalendar ||
        isLoadingOvertime ||
        isLoadingBusinessTravel
      }
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <Guard code={ATTENDANCE_SEARCH_PERMISSION[tab]}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm tab={tab} />,
            gridSpace: 3,
            customWidth: {},
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={tableConfig.columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={tableConfig.total}
        title={t('master.title')}
        sort={sort}
        beforeTopbar={beforeTopbar}
        tabs={tabElement}
      />
      <PopupSync
        t={t}
        open={isSync}
        onSubmit={openConfirmSync}
        onClose={closeSyncPopup}
        tabName={tabName}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!syncQuery}
        onConfirm={submitSyncData}
        onCancel={closeConfirmSync}
      />
      <PopupEmail
        t={t}
        open={!!sendEmail}
        data={sendEmail}
        onClose={closeSendEmail}
        screen={SEND_MAIL_TYPE.ATTENDANCE}
        type={
          tab === TAB_VALUE.overtime
            ? TYPE_OT_AND_BT.Overtime
            : TYPE_OT_AND_BT.BussinessTravel
        }
      />
      <RegisterVehicle
        t={t}
        registerVehicle={registerVehicle}
        open={!!registerVehicle}
        registerData={
          tab === TAB_VALUE.businessTravel
            ? detailRegisterVehicleBuTravel
            : detailRegisterVehicleOt
        }
        onClose={handleCloseRegister}
        onSubmit={handleSubmitRegister}
        tab={tab}
        refreshData={refreshData}
        isLoading={registerVehicleOtLoading || registerVehicleBuTravelLoading}
      />
    </Page>
  )
}

export default ListAttendance
