// Action: Get list
export const MASTER_GET_REPORT_ALL = 'MASTER_GET_REPORT_ALL'
export const MASTER_GET_REPORT_ALL_SUCCESS = 'MASTER_GET_REPORT_ALL_SUCCESS'
export const MASTER_GET_REPORT_ALL_FAILED = 'MASTER_GET_REPORT_ALL_FAILED'
export const MASTER_GET_REPORT_ALL_RESET = 'MASTER_GET_REPORT_ALL_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetReportAll(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_REPORT_ALL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetReportAllSuccess(payload) {
  return {
    type: MASTER_GET_REPORT_ALL_SUCCESS,
    payload: payload,
  }
}

export function actGetReportAllFailed() {
  return {
    type: MASTER_GET_REPORT_ALL_FAILED,
  }
}

export function actGetReportAllReset() {
  return {
    type: MASTER_GET_REPORT_ALL_RESET,
  }
}

export default {
  actGetReportAll,
  actGetReportAllSuccess,
  actGetReportAllFailed,
  actGetReportAllReset,
}
