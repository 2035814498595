// Action: Get list
export const MASTER_GET_LIST_CAT_SHIFT = 'MASTER_GET_LIST_CAT_SHIFT'
export const MASTER_GET_LIST_CAT_SHIFT_SUCCESS =
  'MASTER_GET_LIST_CAT_SHIFT_SUCCESS'
export const MASTER_GET_LIST_CAT_SHIFT_FAILED =
  'MASTER_GET_LIST_CAT_SHIFT_FAILED'
export const MASTER_GET_LIST_CAT_SHIFT_RESET = 'MASTER_GET_LIST_CAT_SHIFT_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_CAT_SHIFT = 'MASTER_GET_DETAIL_CAT_SHIFT'
export const MASTER_GET_DETAIL_CAT_SHIFT_SUCCESS =
  'MASTER_GET_DETAIL_CAT_SHIFT_SUCCESS'
export const MASTER_GET_DETAIL_CAT_SHIFT_FAILED =
  'MASTER_GET_DETAIL_CAT_SHIFT_FAILED'
export const MASTER_GET_DETAIL_CAT_SHIFT_RESET =
  'MASTER_GET_DETAIL_CAT_SHIFT_RESET'

// Action: sync
export const MASTER_SYNC_CAT_SHIFT = 'MASTER_SYNC_CAT_SHIFT'
export const MASTER_SYNC_CAT_SHIFT_SUCCESS = 'MASTER_SYNC_CAT_SHIFT_SUCCESS'
export const MASTER_SYNC_CAT_SHIFT_FAILED = 'MASTER_SYNC_CAT_SHIFT_FAILED'
export const MASTER_SYNC_CAT_SHIFT_RESET = 'MASTER_SYNC_CAT_SHIFT_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListCatShift(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_CAT_SHIFT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListCatShiftSuccess(payload) {
  return {
    type: MASTER_GET_LIST_CAT_SHIFT_SUCCESS,
    payload: payload,
  }
}

export function actGetListCatShiftFailed() {
  return {
    type: MASTER_GET_LIST_CAT_SHIFT_FAILED,
  }
}

export function actGetListCatShiftReset() {
  return {
    type: MASTER_GET_LIST_CAT_SHIFT_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailCatShift(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_CAT_SHIFT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailCatShiftSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_CAT_SHIFT_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailCatShiftFailed() {
  return {
    type: MASTER_GET_DETAIL_CAT_SHIFT_FAILED,
  }
}

export function actGetDetailCatShiftReset() {
  return {
    type: MASTER_GET_DETAIL_CAT_SHIFT_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncCatShift(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_CAT_SHIFT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncCatShiftSuccess(payload) {
  return {
    type: MASTER_SYNC_CAT_SHIFT_SUCCESS,
    payload: payload,
  }
}

export function actSyncCatShiftFailed() {
  return {
    type: MASTER_SYNC_CAT_SHIFT_FAILED,
  }
}

export function actSyncCatShiftReset() {
  return {
    type: MASTER_SYNC_CAT_SHIFT_RESET,
  }
}

export default {
  actGetListCatShift,
  actGetListCatShiftSuccess,
  actGetListCatShiftFailed,
  actGetListCatShiftReset,
  actGetDetailCatShift,
  actGetDetailCatShiftSuccess,
  actGetDetailCatShiftFailed,
  actGetDetailCatShiftReset,
  actSyncCatShift,
  actSyncCatShiftSuccess,
  actSyncCatShiftFailed,
  actSyncCatShiftReset,
}
