import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import {
  EXPORT_TYPE,
  IMPORT_TYPE,
  STATUS_OPTIONS,
} from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import useFactory from '~/modules/master/redux/hooks/useFactory'
import { ROUTE } from '~/modules/master/routes/config'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateToLocalTz,
} from '~/utils'

import ChooseOrg from '../chosse-org'
import { tabList } from '../constants'
import FormUpdate from '../form-update'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.EMPLOYEE.TITLE,
  },
  {
    route: ROUTE.EMPLOYEE.FACTORY.LIST.PATH,
    title: ROUTE.EMPLOYEE.FACTORY.LIST.TITLE,
  },
]

const ListFactory = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { canAccess } = useApp()

  const DEFAULT_FILTERS = {}

  const [selectedRows, setSelectedRows] = useState([])
  const [formData, setFormData] = useState(null)
  const [deleteItems, setDeleteItems] = useState(null)
  const [changeStatus, setChangeStatus] = useState(null)
  const [chooseOrg, setChooseOrg] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState()

  const {
    data: {
      listFactory: { data, total, isLoading },
      detailFactory: { data: detailFactory, isLoading: isLoadingDetail },
    },
    actions: {
      actGetListFactory,
      actUpdateFactory,
      actGetDetailFactory,
      actGetDetailFactoryReset,
      actDeleteFactory,
      actChangeStatusFactory,
    },
  } = useFactory()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  useEffect(() => {
    if (detailFactory && !isEmpty(detailFactory) && !chooseOrg) {
      setFormData(detailFactory)
    }
  }, [detailFactory])

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('factory.code'),
      width: 150,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('factory.name'),
      width: 150,
      sortable: true,
    },
    {
      field: 'location',
      headerName: t('factory.location'),
      width: 350,
      sortable: true,
    },
    {
      field: 'isActive',
      headerName: t('factory.status'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'updatedAt',
      headerName: t('factory.dateUpdate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { updatedAt } = params?.row
        return convertUtcDateToLocalTz(updatedAt)
      },
    },
    {
      field: 'orgStructure',
      headerName: t('factory.orgStructure'),
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <IconButton
            icon="clone"
            onClick={() => setChooseOrg(params?.row)}
            tooltipText={t('common.clone')}
          />
        )
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 150,
      renderCell: (params) => {
        const { isActive, id } = params?.row

        return (
          <Box sx={{ display: 'flex' }}>
            <Guard code={FUNCTION_CODE.USER_UPDATE_FACTORY}>
              <IconButton
                icon="edit"
                tooltipText={t('common.update')}
                onClick={() => openForm(params?.row)}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CREATE_FACTORY}>
              <IconButton
                icon="clone"
                onClick={() => handleClickClone(params.row)}
                tooltipText={t('common.clone')}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_FACTORY_PERMISSION}>
              <IconButton
                icon={isActive ? 'locked' : 'unlock'}
                tooltipText={t('common.activePending')}
                onClick={() => openChangeStatus({ isActive, id })}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_DELETE_FACTORY}>
              <IconButton
                icon="delete"
                tooltipText={t('common.delete')}
                onClick={() => openConfirmDelete([params.row])}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(dataFilter, columns),
      sort: convertSortParams(sort),
    }
    actGetListFactory(params)
    CloseForm()
    handleCloseChooseOrg()
    closeChangeStatus()
    closeConfirmDelete()
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        working: 1,
        unregistered: 22,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  const handleClickClone = (data) => {
    const formData = { ...data, id: undefined, code: '' }
    setFormData(formData)
  }

  const CloseForm = () => {
    setFormData(null)
    actGetDetailFactoryReset()
  }

  const openForm = (data) => {
    if (data) {
      actGetDetailFactory(data.id)
    } else {
      setFormData({})
    }
  }

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const handleDelete = () => {
    if (!deleteItems) return
    actDeleteFactory(
      { codes: deleteItems.map((item) => item.code) },
      refreshData,
    )
  }

  const openChangeStatus = (val) => {
    if (Array.isArray(val) && val.length > 0) {
      const status = val[0].isActive
      const checkUpdate = val.some((item) => item.isActive !== status)
      if (checkUpdate) return
    }
    setChangeStatus(val)
  }

  const closeChangeStatus = () => {
    setChangeStatus(null)
  }

  const handlChangeStatus = () => {
    if (Array.isArray(changeStatus) && changeStatus.length > 0) {
      const status = changeStatus[0].isActive
      const codes = changeStatus.map((item) => item.code)
      actChangeStatusFactory(
        { params: { codes }, isActive: status },
        refreshData,
      )
    } else {
      actChangeStatusFactory(
        { params: changeStatus.id, isActive: changeStatus.isActive },
        refreshData,
      )
    }
  }

  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_CREATE_FACTORY}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={() => openForm()}
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_FACTORY_PERMISSION}>
        <IconButton
          icon="locked"
          title={`${t('common.active')}/ ${t('common.pending')}`}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openChangeStatus(selectedRows)}
        />
      </Guard>,
      // <IconButton
      //   icon="delete"
      //   title={t('common.delete')}
      //   sx={{ marginLeft: '10px', marginRight: '10px' }}
      //   onClick={() => openConfirmDelete(selectedRows)}
      // />,
    ]
  }

  const handleCloseChooseOrg = () => {
    setChooseOrg(null)
  }

  const onSubmitChooseOrg = (val) => {
    const body = {
      id: chooseOrg?.id,
      orgStructureIds: val.orgStructure?.map((item) => item.id),
    }

    actUpdateFactory(body, refreshData)
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      onBack={() => history.goBack()}
      title={t('menu.factory')}
      loading={isLoading || isLoadingDetail}
    >
      <HotKeys handlers={{}} />
      <TaskBar
        left={actionHeader}
        right={[
          <ImportExport
            name={t('factory.fileNameExport')}
            onImport={
              canAccess(FUNCTION_CODE.USER_IMPORT_FACTORY)
                ? (body) => apiImport({ body, type: IMPORT_TYPE.FACTORY })
                : null
            }
            importMessage={{
              success: t('factory.notification.importSuccess'),
            }}
            onExport={
              canAccess(FUNCTION_CODE.USER_EXPORT_FACTORY)
                ? () => {
                    const params = {
                      filter: convertFilterParams(filters, [
                        { field: 'createdAt', filterFormat: 'date' },
                      ]),
                      sort: convertSortParams(sort),
                      type: EXPORT_TYPE.FACTORY,
                    }
                    if (!isEmpty(selectedRows)) {
                      params.ids = JSON.stringify(
                        selectedRows?.map((x) => ({ id: x?.id })),
                      )
                    }

                    return apiExport(params)
                  }
                : null
            }
            onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.FACTORY)}
            onRefresh={refreshData}
          />,
        ]}
      />
      <Guard code={FUNCTION_CODE.USER_SEARCH_FACTORY}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
          }}
        />
      </Guard>

      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        tabs={tabElement}
      />

      <FormUpdate
        t={t}
        open={!!formData}
        data={formData}
        refreshData={refreshData}
        onClose={CloseForm}
      />
      <PopupConfirm
        t={t}
        title={t('factory.modalChangeStatus.title')}
        content={t('factory.modalChangeStatus.content')}
        open={!!changeStatus}
        onConfirm={handlChangeStatus}
        onCancel={closeChangeStatus}
      />
      <ChooseOrg
        t={t}
        open={!!chooseOrg}
        data={chooseOrg}
        onSubmit={onSubmitChooseOrg}
        onClose={handleCloseChooseOrg}
      />
      <PopupConfirm
        t={t}
        title={t('factory.modalDelete.title')}
        content={t('factory.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default ListFactory
