import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'

import { ASYNC_SEARCH_LIMIT, NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Loading from '~/components/Loading'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiUpdateProfileCustom } from '~/modules/master/redux/apis/profile-custom'
import {
  apiGetListRouteList,
} from '~/modules/master/redux/apis/route'
import usePickupPoint from '~/modules/master/redux/hooks/usePickupPoint'
import useRoute from '~/modules/master/redux/hooks/useRoute'
import { convertFilterParams } from '~/utils'
import addNotification from '~/utils/toast'

import { validationSchema } from './schema'

const DEFAULT_DATA = {
  route: null,
  pickupPoint: null,
}

const RegisterRoute = ({
  t,
  open,
  data = {},
  onClose = () => {},
  handleRegisterSuccess = () => {},
}) => {
  const {
    data: {
      detailRoute: { data: detailRoute },
    },
    actions: { actGetDetailRoute, actGetDetailRouteReset },
  } = useRoute()

  const {
    data: {
      detailPickupPoint: { data: detailPickupPoint },
    },
    actions: { actGetDetailPickupPoint, actGetDetailPickupPointReset },
  } = usePickupPoint()

  useEffect(() => {
    if (data?.routeId) {
      actGetDetailRoute(data.routeId)
    }

    return () => {
      actGetDetailRouteReset()
    }
  }, [data?.routeId])

  useEffect(() => {
    if (data?.pickupPointId) {
      actGetDetailPickupPoint(data.pickupPointId)
    }

    return () => {
      actGetDetailPickupPointReset()
    }
  }, [data?.pickupPointId])

  const initialValues = useMemo(() => {
    const initData = {
      ...DEFAULT_DATA,
      profileCustomId: data?.id,
    }
    if (!isEmpty(detailRoute)) {
      initData.route = {
        ...detailRoute,
        pickupPoints: detailRoute.pickupPoints?.map((item) => item.pickupPoint),
      }
    }

    if (!isEmpty(detailPickupPoint)) {
      initData.pickupPoint = detailPickupPoint
    }
    return initData
  }, [data?.id, detailRoute, detailPickupPoint])

  const handleChangeRoute = (setFieldValue) => {
    setFieldValue('pickupPoint', undefined)
  }

  const handleSubmitRegister = async (val, { setFieldValue }) => {
    setFieldValue('isLoading', true)
    const res = await apiUpdateProfileCustom({
      id: val.profileCustomId,
      routeCode: val.route?.code,
      pickupPointCode: val.pickupPoint?.code || 18,
    })

    if (res.statusCode === 200) {
      addNotification(
        t('profileCustom.notification.updateSuccess'),
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(res.message, NOTIFICATION_TYPE.ERROR)
      setFieldValue('isLoading', false)
    }

    handleRegisterSuccess()
  }

  return (
    <Dialog
      open={open}
      title={t('profileCustom.registerRoute.title')}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmitRegister}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              dateSyn
            >
              <Grid item lg={6} xs={12}>
                <Field.Autocomplete
                  name="route"
                  label={t('profileCustom.registerRoute.route')}
                  placeholder={t('profileCustom.registerRoute.routeHolder')}
                  asyncRequest={(s) =>
                    apiGetListRouteList({
                      keyword: s,
                      limit: ASYNC_SEARCH_LIMIT,
                      filter: convertFilterParams({
                        isActive: STATUS_VALUE.active,
                      }),
                    })
                  }
                  asyncRequestHelper={(res) => res?.data?.items}
                  getOptionLabel={(option) => option.name}
                  onChange={() => {
                    handleChangeRoute(setFieldValue)
                  }}
                  isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                  required
                />
              </Grid>
              <Grid item lg={6} xs={12}></Grid>
              <Grid item lg={6} xs={12}>
                <Field.Autocomplete
                  name="pickupPoint"
                  label={t('profileCustom.registerRoute.pickUpPoint')}
                  placeholder={t(
                    'profileCustom.registerRoute.pickUpPointHolder',
                  )}
                  options={values.route?.pickupPoints || []}
                  getOptionLabel={(opt) => opt?.name}
                  getOptionValue={(opt) => opt}
                  isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
                  required
                />
              </Grid>
              <Grid item lg={6} xs={12}></Grid>
              <Grid item lg={3} xs={12}>
                <LV
                  label={t('profileCustom.registerRoute.timeDeparture')}
                  value={values.pickupPoint?.timeDeparture}
                  labelColor="#000000"
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                <LV
                  label={t('profileCustom.registerRoute.timeArrival')}
                  value={values.pickupPoint?.timeArrival}
                  labelColor="#000000"
                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t('actionBar.save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default RegisterRoute
