import { api } from '~/services/api'


/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListWorkSchedule = (params) => {
  const uri = ``
  return api.get(uri, params)
}

/**
 *
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailWorkSchedule = (id) => {
  const uri = `${id}`
  return api.get(uri)
}

/**
 *
 * @returns {Promise}
 */
export const apiSyncWorkSchedule = () => {
  const uri = ``
  return api.get(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateWorkSchedule = ({ id, body }) => {
  const uri = `${id}`
  return api.put(uri, body)
}
