import * as Yup from 'yup'

import { REGEX_MAIL } from '~/modules/master/constants'

export const validationSchema = (t) =>
  Yup.object().shape({
    subject: Yup.string().required(t('form.required')),
    cc: Yup.string().test(_, (value, context) => {
      if (value && !REGEX_MAIL.test(value)) {
        return context.createError({
          path: context.path,
          message: t('form.validEmail'),
        })
      }

      return true
    }),
    bcc: Yup.string().test(_, (value, context) => {
      if (value && !REGEX_MAIL.test(value)) {
        return context.createError({
          path: context.path,
          message: t('form.validEmail'),
        })
      }

      return true
    }),
    detail: Yup.array()
      .min(1, t('form.required'))
      .of(
        Yup.object().shape({
          email: Yup.string().test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            if (value && !REGEX_MAIL.test(value)) {
              return context.createError({
                path: context.path,
                message: t('form.validEmail'),
              })
            }

            return true
          }),
        }),
      ),
  })
