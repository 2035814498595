import * as Yup from 'yup'

export const validationSchema = (t, initialValues) => {
  return Yup.object().shape({
    ...genValidate(t, Object.keys(initialValues)),
  })
}

const genValidate = (t, keys = []) => {
  return keys.reduce((acc, current) => {
    acc[current] = Yup.object().shape({
      initDay: Yup.date()
        .nullable()
        .when('isActive', {
          is: true,
          then: Yup.date().nullable().required(t('form.required')),
        }),
      initHour: Yup.number()
        .nullable()
        .when('isActive', {
          is: true,
          then: Yup.number()
            .required(t('form.required'))
            .min(0, t('form.validTime'))
            .max(24, t('form.validTime')),
        }),
      beginHour: Yup.number()
        .required(t('form.required'))
        .nullable()
        .min(0, t('form.validTime'))
        .max(24, t('form.validTime'))
        .test(_, (value, context) => {
          if (
            value &&
            context.parent.beginHour &&
            value > context.parent.endHour
          ) {
            return context.createError({
              path: context.path,
              message: t('form.invalidTimeStart'),
            })
          }
          return true
        }),
      endHour: Yup.number()
        .nullable()
        .min(0, t('form.validTime'))
        .max(24, t('form.validTime'))
        .test(_, (value, context) => {
          if (
            value &&
            context.parent.beginHour &&
            value < context.parent.beginHour
          ) {
            return context.createError({
              path: context.path,
              message: t('form.invalidTimeRange'),
            })
          }
          return true
        }),
      hour: Yup.number()
        .nullable()
        .min(0, t('form.validTime'))
        .max(24, t('form.validTime')),
      minute: Yup.number()
        .nullable()
        .min(0, t('form.validTime'))
        .max(60, t('form.validTime')),
    })

    return acc
  }, {})
}
