import React, { useContext } from 'react'

import KeyIcon from '@mui/icons-material/Key'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { TypeEnumLogin } from '~/common/constants'
import Avatar from '~/components/Avatar'
import Dropdown from '~/components/Dropdown'
import Icon from '~/components/Icon'
import AzureContext from '~/contexts/AzureContext'
import { useAuth } from '~/modules/auth/redux/hooks/useAuth'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { ROUTE } from '~/modules/configuration/routes/config'
import { useClasses } from '~/themes'


import PageSetting from './PageSetting'
import style from './style'

const Toolbar = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { actions } = useAuth()
  const classes = useClasses(style)
  const azure = useContext(AzureContext);
  const {
    data: { userInfo },
  } = useUserInfo()

  const options = [
    {
      label: t('page.userInfo'),
      icon: <Icon name="user" size={18} />,
      onClick: () => history.push(ROUTE.ACCOUNT.DETAIL.PATH),
    }
  ]

  if (userInfo.type !== 1) {
    options.push({
      label: t('page.changePassword'),
      icon: <KeyIcon fontSize="small" />,
      onClick: () => history.push(ROUTE.ACCOUNT.CHANGE_PASSWORD.PATH),
    });
  }

  options.push(
    {
      label: t('page.logout'),
      icon: <LogoutIcon fontSize="small" />,
      onClick: () => {
        if (userInfo.type === TypeEnumLogin.SYSTEM) actions.logout();
        else {
          azure.onLogout();
          actions.logout();
        }
      },
    }
  )

  const renderButtonDropdown = (even) => {
    return (
      <Tooltip title={userInfo?.fullName || userInfo?.username || ''}>
        <IconButton
          sx={{
            width: 40,
            minWidth: 40,
            height: 40,
            borderRadius: '3px',
          }}
          color="grayEE"
          onClick={even.onClick}
        >
          <Avatar
            src=""
            name={userInfo?.fullName || userInfo?.username}
            variant="square"
            sx={{ borderRadius: '3px' }}
          />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Box className={classes.root}>
      <PageSetting />
      {/* <Notification />
      <LanguageSwitcher /> */}
      <Dropdown
        options={options}
        color="grayEE"
        icon="user"
        handleMenuItemClick={(opt) => opt.onClick()}
        sx={{
          width: 40,
          minWidth: 40,
          height: 40,
        }}
        renderButton={(even) => renderButtonDropdown(even)}
      />
    </Box>
  )
}

export default Toolbar
