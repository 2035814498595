import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actDeleteVehicleCategoriesFailed,
  actDeleteVehicleCategoriesSuccess,
  MASTER_DELETE_VEHICLE_CATEGORIES,
} from '../../actions/vehicle-categories'
import { apiDeleteVehicles } from '../../apis/vehicle-categories'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doDelete(action) {
  try {
    const response = yield call(apiDeleteVehicles, action.payload)

    if (response?.statusCode === 200) {
      yield put(actDeleteVehicleCategoriesSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification('toast.deleteSuccess', NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || 'toast.deleteSuccess',
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actDeleteVehicleCategoriesFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchDelete() {
  yield takeLatest(MASTER_DELETE_VEHICLE_CATEGORIES, doDelete)
}
