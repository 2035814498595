import React, { useEffect, useMemo, useState } from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import TextLink from '~/components/TextLink'
import { STATUS_OPTIONS, STATUS_SYN_OPTION } from '~/modules/master/constants'
import { apiGetDetailOrgStructure } from '~/modules/master/redux/apis/org-structure'
import useOrgStructure from '~/modules/master/redux/hooks/useOrgStructure'
import { ROUTE } from '~/modules/master/routes/config'
import theme from '~/themes'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import CustomizedTreeView from '../components/customized-tree-view'
import FormData from '../form'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.EMPLOYEE.TITLE,
  },
  {
    route: ROUTE.EMPLOYEE.ORG_STRUCTURE.LIST.PATH,
    title: ROUTE.EMPLOYEE.ORG_STRUCTURE.LIST.TITLE,
  },
]
const ListOrgStructure = () => {
  const { t } = useTranslation(['buseye'])
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [confirmSync, setConfirmSync] = useState(false)

  const history = useHistory()

  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    costCenterIds: [],
    status: null,
  }

  const { page, pageSize, sort, filters, tab, setFilters } = useQueryState({
    filters: DEFAULT_FILTERS,
    tab: '',
  })

  const {
    data: {
      listOrgStructure: { data, isLoading },
      syncOrgStructure: { isLoading: isLoadingSync },
    },
    actions: { actGetListOrgStructure, actSyncOrgStructure },
  } = useOrgStructure()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('orgStructure.code'),
      width: 80,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { code, id } = params?.row
        return <TextLink text={code} onClick={() => handleShowDetail(id)} />
      },
    },
    {
      field: 'name',
      headerName: t('orgStructure.name'),
      width: 150,
      sortable: true,
    },
    {
      field: 'parentCode',
      headerName: t('orgStructure.parentCode'),
      width: 150,
      sortable: true,
    },
    {
      field: 'isActive',
      headerName: t('orgStructure.status'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'dateUpdate',
      headerName: t('orgStructure.dateUpdate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateUpdate } = params?.row
        return convertUtcDateTimeToLocalTz(dateUpdate)
      },
    },
    {
      field: 'isSyncSuccess',
      headerName: t('orgStructure.statusSyn'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { isSyncSuccess } = params.row
        return (
          <Status
            options={STATUS_SYN_OPTION}
            value={isSyncSuccess}
            variant="text"
          />
        )
      },
    },
  ])

  const handleShowDetail = () => {}

  const refreshData = () => {
    const { keyword = '', factory, ...dataFilter } = filters

    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...dataFilter,
          factory: factory?.id,
        },
        columns,
      ),
      sort: convertSortParams(sort),
    }
    actGetListOrgStructure(params)
    closeConfirmSync()
    // actions.searchProducingSteps(params)
  }

  const handleSyncData = () => {
    actSyncOrgStructure({}, refreshData)
  }

  const handleClick = async (data) => {
    setLoading(true)
    const res = await apiGetDetailOrgStructure(data?.id)
    setLoading(false)
    if (res?.data) {
      setFormData(res.data)
    }
  }

  const openConfirm = () => {
    setConfirmSync(true)
  }

  const closeConfirmSync = () => {
    setConfirmSync(false)
  }
  return (
    <Page
      breadcrumbs={breadcrumbs}
      onBack={() => history.goBack()}
      title={t('menu.orgStructure')}
      loading={isLoading || isLoadingSync || loading}
    >
      <HotKeys handlers={{}} />
      <FilterArea
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: <FilterForm />,
          defaultValues: DEFAULT_FILTERS,
          gridSpace: 3,
        }}
      />
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h4"
          component="span"
          borderBottom={`2px solid ${theme.palette.primary.main}`}
          padding="0 15px 5px 15px"
          color={theme.palette.primary.main}
        >
          {t('orgStructure.title')}
        </Typography>
        <Guard code={FUNCTION_CODE.USER_SYNCHRONIZE_ORGANIZATION_STRUCTURE}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={openConfirm}
          >
            <Icon
              name="assign"
              fill="#FF9054"
              stroke="#FF9054"
              sx={{ marginRight: '5px' }}
            />
            {t('common.sync')}
          </Box>
        </Guard>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '40%',
            paddingRight: '50px',
            marginRight: '50px',
            borderRight: '2px solid #F5F5F5',
          }}
        >
          <CustomizedTreeView t={t} onClick={handleClick} data={data || []} />
        </Box>
        <Box sx={{ width: '60%', padding: '0 0 0 0' }}>
          <Guard code={FUNCTION_CODE.USER_DETAIL_ORGANIZATION_STRUCTURE}>
            <FormData
              t={t}
              data={formData}
              setLoading={setLoading}
              refreshData={refreshData}
            />
          </Guard>
        </Box>
      </Box>
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!confirmSync}
        onConfirm={handleSyncData}
        onCancel={closeConfirmSync}
      />
    </Page>
  )
}

export default ListOrgStructure
