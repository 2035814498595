import { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { Form, Formik } from 'formik'

import { MODAL_MODE, NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import Loading from '~/components/Loading'
import {
  apiGetFeeShiftRouteUnitDetail,
  apiUpsertFeeShiftRouteUnitDetail,
} from '~/modules/master/redux/apis/setting-fee'
import addNotification from '~/utils/toast'

import FormTable from './form-table'
import { validationSchema } from './schema'

const DEFAULT_DATA = {
  items: [],
}

const LABEL_WIDTH = '200px'

const FormManual = ({ t, open, dataEdit, onClose = () => { }, mode }) => {
  const [initialValues, setInitialValues] = useState(DEFAULT_DATA)
  const [isLoading, setIsLoading] = useState(DEFAULT_DATA)

  useEffect(() => {
    if (open && dataEdit) {
      refreshData()
    }
  }, [open])

  const refreshData = async (date, setFieldValue) => {
    if (setFieldValue) {
      setFieldValue('isLoading', true)
    } else {
      setIsLoading(true)
    }

    const res = await apiGetFeeShiftRouteUnitDetail(dataEdit)

    if (setFieldValue) {
      setFieldValue('isLoading', false)
    } else {
      setIsLoading(false)
    }

    setInitialValues({
      ...dataEdit,
      items: res?.data || [],
    })
  }

  const handleSubmit = async (val, { setFieldValue }) => {
    if (!val && !setFieldValue) return

    const body = {
      feeShiftRouteUnits: val?.items,
      id: dataEdit?.id,
    }
    setFieldValue('isLoading', true)
    let res = await apiUpsertFeeShiftRouteUnitDetail(body)
    setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `settingFee.notification.manualSuccess`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      onClose()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
  }

  return (
    <Dialog
      open={open}
      title={t(
        `settingFee.${mode === MODAL_MODE.UPDATE ? 'editManualTitle' : 'detailManualTitle'
        }`,
      )}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ handleReset, values }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <LV
                  label={t('settingFee.dateFee')}
                  labelWidth={LABEL_WIDTH}
                  value={`${values.month}/${values.year}`}
                />
              </Grid>
              <Grid item xs={12}>
                <LV
                  label={t('settingFee.catShift')}
                  labelWidth={LABEL_WIDTH}
                  value={values.shiftCode}
                />
              </Grid>
              <Grid item xs={12}>
                <LV
                  label={t('settingFee.route')}
                  labelWidth={LABEL_WIDTH}
                  value={values.routeCode}
                />
              </Grid>
              <Box sx={{ mt: 3 }}>
                <FormTable t={t} data={initialValues.items} mode={mode} />
              </Box>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {mode === MODAL_MODE.UPDATE ? <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={handleReset}
                  >
                    {t('actionBar.cancel')}
                  </Button> : <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={onClose}
                  >
                    {t('actionBar.close')}
                  </Button>}
                  {mode === MODAL_MODE.UPDATE && (
                    <Button type="submit" sx={{ marginRight: '10px' }}>
                      {t(`actionBar.save`)}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Box>
            <Loading open={isLoading || values?.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default FormManual
