import { combineReducers } from 'redux'

import {
  MASTER_GET_REPORT_BR11,
  MASTER_GET_REPORT_BR11_FAILED,
  MASTER_GET_REPORT_BR11_RESET,
  MASTER_GET_REPORT_BR11_SUCCESS,
  MASTER_GET_REPORT_BR11_SUMMARY,
  MASTER_GET_REPORT_BR11_SUMMARY_FAILED,
  MASTER_GET_REPORT_BR11_SUMMARY_RESET,
  MASTER_GET_REPORT_BR11_SUMMARY_SUCCESS,
  MASTER_SYNC_REPORT_BR11,
  MASTER_SYNC_REPORT_BR11_FAILED,
  MASTER_SYNC_REPORT_BR11_RESET,
  MASTER_SYNC_REPORT_BR11_SUCCESS,
} from '../actions/report-br11'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportBr11(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_REPORT_BR11:
      return { ...state, isLoading: true }
    case MASTER_GET_REPORT_BR11_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_REPORT_BR11_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_REPORT_BR11_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function listReportBr11Summary(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_REPORT_BR11_SUMMARY:
      return { ...state, isLoading: true }
    case MASTER_GET_REPORT_BR11_SUMMARY_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_REPORT_BR11_SUMMARY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_REPORT_BR11_SUMMARY_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function syncReportBr11(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_REPORT_BR11:
      return { ...state, isLoading: true }
    case MASTER_SYNC_REPORT_BR11_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_REPORT_BR11_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_REPORT_BR11_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportBr11,
  listReportBr11Summary,
  syncReportBr11,
})
