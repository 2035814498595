import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enAuth from '~/assets/locales/en/auth.json'
import enConfiguration from '~/assets/locales/en/configuration.json'
import enGeneral from '~/assets/locales/en/general.json'
import enMaster from '~/assets/locales/en/master.json'
import jpAuth from '~/assets/locales/jp/auth.json'
import jpConfiguration from '~/assets/locales/jp/configuration.json'
import jpGeneral from '~/assets/locales/jp/general.json'
import jpMaster from '~/assets/locales/jp/master.json'
import viAuth from '~/assets/locales/vi/auth.json'
import viConfiguration from '~/assets/locales/vi/configuration.json'
import viGeneral from '~/assets/locales/vi/general.json'
import viMaster from '~/assets/locales/vi/master.json'
import viMenu from '~/assets/locales/vi/menu.json'
import { DEFAULT_LANG, LANG_OPTIONS } from '~/common/constants'

const resources = {
  [LANG_OPTIONS.VI]: {
    buseye: {
      ...viMenu,
      ...viMaster,
      ...viAuth,
      ...viConfiguration,
      ...viGeneral,
    },
  },
  [LANG_OPTIONS.EN]: {
    buseye: {
      ...enMaster,
      ...enAuth,
      ...enConfiguration,
      ...enGeneral,
    },
  },
  [LANG_OPTIONS.JP]: {
    buseye: {
      ...jpMaster,
      ...jpAuth,
      ...jpConfiguration,
      ...jpGeneral,
    },
  },
}

const getCurrentLang = () => {
  let language = window.localStorage.getItem('language')

  if (Object.values(LANG_OPTIONS).every((lang) => lang !== language)) {
    window.localStorage.setItem('language', DEFAULT_LANG)
    language = DEFAULT_LANG
  }

  return language
}

i18n.use(initReactI18next).init({
  resources: resources,
  lng: getCurrentLang(),
  fallbackLng: DEFAULT_LANG,
  ns: ['buseye'],
  defaultNS: ['buseye'],
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  react: {
    useSuspense: true,
    wait: true,
  },
})

export default i18n
