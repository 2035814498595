import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ACTIVE_STATUS,
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_ALLOW,
} from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'
import Guard from '~/components/Guard'
import IconButton from '~/components/IconButton'
import { apiGetListVehicleCategoriesList } from '~/modules/master/redux/apis/vehicle-categories'
import { convertFilterParams } from '~/utils'

import { DEFAULT_TABLE } from '..'
import { formatCatshift } from '../../constants'

const FormTable = ({ mode, values, arrayHelpers, setFieldValue }) => {
  const { t } = useTranslation(['buseye'])

  const handleChangeRoute = (val, idx) => {
    if (!val?.catShifts) return

    setFieldValue(
      `items[${idx}].catShiftOptions`,
      formatCatshift(val.catShifts),
    )
  }

  const columns = useMemo(
    () => [
      {
        field: '#',
        headerName: '#',
        width: 40,
        minWidth: 40,
        align: 'center',
        renderCell: (_, idx) => {
          return idx + 1
        },
      },
      {
        field: 'route',
        headerName: t('settingFee.route'),
        minWidth: 150,
        required: true,
        renderCell: (_, idx) => {
          const id = `setting-fee-route-${idx}`
          return (
            <Field.Autocomplete
              id={id}
              name={`items[${idx}].route`}
              placeholder={t('settingFee.route')}
              options={values?.listRouteAll || []}
              getOptionLabel={(opt) => opt?.name}
              getOptionValue={(opt) => opt}
              labelWidth={100}
              isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
              onChange={(val) => {
                handleChangeRoute(val, idx)
              }}
            />
          )
        },
      },
      {
        field: 'catShifts',
        headerName: t('settingFee.catShift'),
        minWidth: 150,
        required: true,
        renderCell: (params, idx) => {
          const { catShiftOptions = [] } = params?.row
          return (
            <Field.Autocomplete
              name={`items[${idx}].catShifts`}
              placeholder={t('settingFee.catShift')}
              options={catShiftOptions}
              getOptionLabel={(opt) => opt?.name}
              getOptionValue={(opt) => opt}
              labelWidth={100}
              isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
            />
          )
        },
      },
      {
        field: 'vehicleType',
        headerName: t('settingFee.vehicleType'),
        minWidth: 150,
        required: true,
        renderCell: (_, idx) => {
          return (
            <Field.Autocomplete
              name={`items[${idx}].vehicleType`}
              placeholder={t('settingFee.vehicleType')}
              asyncRequest={(s) =>
                apiGetListVehicleCategoriesList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                  filter: convertFilterParams({
                    isActive: ACTIVE_STATUS.ACTIVE,
                  }),
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.code}
              isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
            />
          )
        },
      },
      {
        field: 'fee',
        width: 150,
        headerName: t('settingFee.fee'),
        required: true,
        renderCell: (params, idx) => {
          const { fee } = params.row
          return mode !== MODAL_MODE.DETAIL ? (
            <Field.TextField
              name={`items[${idx}].fee`}
              placeholder={t('settingFee.fee')}
              type="number"
              // numberProps={{
              //   decimalScale: 2,
              // }}
              allow={TEXTFIELD_ALLOW.POSITIVE_DECIMAL}
            />
          ) : (
            fee
          )
        },
      },

      {
        field: 'action',
        headerName: t('common.action'),
        width: 80,
        align: 'center',
        renderCell: (_, idx) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
                <IconButton
                  icon="delete"
                  tooltipText={t('common.update')}
                  onClick={() => {
                    arrayHelpers?.remove(idx)
                  }}
                />
              </Guard>
            </Box>
          )
        },
      },
    ],
    [t, values],
  )

  const focusNewInput = () => {
    setTimeout(() => {
      const inputs = document.querySelectorAll(
        'input[id^="setting-fee-route-"]',
      );

      const firstInput = inputs[0];
      if (firstInput) {
        firstInput.focus();
      }
    }, 0);
  };

  const handleAddNew = () => {
    arrayHelpers.unshift({
      ...DEFAULT_TABLE,
      id: new Date().getTime(),
    })

    focusNewInput()
  }

  const beforeTopbar = (
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      variant="outlined"
      iconColor="primary"
      icon="add"
      onClick={handleAddNew}
    >
      {t('common.create')}
    </Button>
  )

  return (
    <DataTable
      rows={values?.items || []}
      columns={columns}
      maxHeight="310px"
      total={0}
      beforeTopbar={beforeTopbar}
      // tabs={<Tabs list={tabFormList(t)} value={tab} onChange={setTab} />}
      hideFooter
      hideSetting
    />
  )
}

export default FormTable
