import {
  SEARCH_ROLE_LIST_START,
  SEARCH_ROLE_LIST_SUCCESS,
  SEARCH_ROLE_LIST_FAILED,
  UPDATE_ROLE_ASSIGN_FAILED,
  UPDATE_ROLE_ASSIGN_START,
  UPDATE_ROLE_ASSIGN_SUCCESS,
  GET_ROLE_ASSIGN_DETAILS_FAILED,
  GET_ROLE_ASSIGN_DETAILS_START,
  GET_ROLE_ASSIGN_DETAILS_SUCCESS,
  RESET_ROLE_ASSIGN_DETAILS_STATE,
  CREATE_ROLE_ASSIGN_START,
  DELETE_ROLE_ASSIGN_START,
  CHANGE_STATUS_ROLE,
  CREATE_ROLE_ASSIGN_FAILED,
  CREATE_ROLE_ASSIGN_SUCCESS,
  CHANGE_STATUS_ROLE_FAILED,
  CHANGE_STATUS_ROLE_SUCCESS,
  DELETE_ROLE_ASSIGN_FAILED,
  DELETE_ROLE_ASSIGN_SUCCESS,
} from '~/modules/configuration/redux/actions/role-list'

const initialState = {
  isLoading: false,
  roleList: [],
  total: null,
  roleDetail: {},
}

export default function roleManagement(state = initialState, action) {
  switch (action.type) {
    case SEARCH_ROLE_LIST_START:
    case CREATE_ROLE_ASSIGN_START:
    case UPDATE_ROLE_ASSIGN_START:
    case DELETE_ROLE_ASSIGN_START:
    case CHANGE_STATUS_ROLE:
    case GET_ROLE_ASSIGN_DETAILS_START:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleList: action.payload.list,
        isLoading: false,
        total: action.payload.total,
        ...action.payload,
      }
    case GET_ROLE_ASSIGN_DETAILS_SUCCESS:
      return {
        ...state,
        roleDetail: action.payload,
        isLoading: false,
      }
    case GET_ROLE_ASSIGN_DETAILS_FAILED:
    case UPDATE_ROLE_ASSIGN_FAILED:
    case UPDATE_ROLE_ASSIGN_SUCCESS:
    case CREATE_ROLE_ASSIGN_FAILED:
    case CREATE_ROLE_ASSIGN_SUCCESS:
    case CHANGE_STATUS_ROLE_FAILED:
    case CHANGE_STATUS_ROLE_SUCCESS:
    case DELETE_ROLE_ASSIGN_FAILED:
    case DELETE_ROLE_ASSIGN_SUCCESS:
    case SEARCH_ROLE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_ROLE_ASSIGN_DETAILS_STATE:
      return {
        ...state,
        roleDetail: {},
      }
    default:
      return state
  }
}
