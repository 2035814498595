import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    rangeDate: Yup.array()
      .nullable()
      .required(t('form.required'))
      .test('processedDate', t('form.required'), function (value, context) {
        // Use function
        const result = value?.filter((i) => i === null)
        if (value?.length < 2 || result?.length)
          return context?.createError({ message: t('form.required') })
        else return true
      }),
  })
