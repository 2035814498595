import React from 'react'

import { Box, Tooltip } from '@mui/material'
import IconButtonHover from '@mui/material/IconButton'
import { PropTypes } from 'prop-types'

import theme from '~/themes'
import palette from '~/themes/palette'

import Icon from '../Icon'

const config = {
  add: {
    fill: palette.primary.main,
  },
  tick: {
    fill: palette.success.main,
  },
  remove: {
    fill: palette.error.main,
  },
  delete: {
    fill: palette.error.main,
  },
}

const IconButton = ({
  title = '',
  children,
  type,
  icon = '',
  onClick = () => {},
  sx,
  fill,
  stroke,
  tooltipText,
  ...props
}) => {
  return (
    <Tooltip
      {...(type === 'squareIcon'
        ? {
            sx: {
              width: 40,
              height: 40,
              borderRadius: 0,
              backgroundColor: theme.palette.primary.contrastText,
              ml: 1,
            },
          }
        : {})}
      title={title && <div style={{ fontSize: '14px' }}>{title}</div>}
    >
      {children ? (
        <IconButtonHover onClick={onClick} {...props}>
          {children}
        </IconButtonHover>
      ) : (
        <Box
          sx={{
            ...sx,
          }}
        >
          <Box
            sx={{
              cursor: 'pointer',
              width: 'auto',
            }}
            onClick={onClick}
          >
            <IconButtonHover {...props} title={tooltipText || title}>
              <Icon
                name={icon}
                fill={fill || config[icon]?.fill}
                stroke={stroke || config[icon]?.stroke}
              />
            </IconButtonHover>
            {title}
          </Box>
        </Box>
      )}
    </Tooltip>
  )
}
IconButton.defaultProps = {
  title: '',
  onClick: null,
  type: '',
}

IconButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
}

export default IconButton
