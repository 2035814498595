import { useEffect } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import Status from '~/components/Status'
import {
  STATUS_PROFILE_CUSTOM_OPTIONS,
  STATUS_SYN_OPTION,
} from '~/modules/master/constants'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'
import { convertUtcDateToLocalTz } from '~/utils'

const DetailProfile = ({ t, onClose = () => {}, open, showDetailId }) => {
  const {
    data: {
      detailProfileCustom: { data },
    },
    actions: { actGetDetailProfileCustom },
  } = useProfileCustom()

  useEffect(() => {
    if (showDetailId) {
      actGetDetailProfileCustom(showDetailId)
    }
  }, [showDetailId])

  return (
    <Dialog
      open={open}
      title={t('profileCustom.detail')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
      >
        <Grid item xs={12}>
          <LV
            label={t('profileCustom.status')}
            value={
              <Status
                options={STATUS_PROFILE_CUSTOM_OPTIONS}
                value={data?.statusSyn}
              />
            }
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV label={t('profileCustom.code')} value={data?.code} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV label={t('profileCustom.name')} value={data?.name} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.codeAttendance')}
            value={data?.codeAttendance}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV label={t('profileCustom.position')} value={data?.position} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.orgStructure')}
            value={data?.orgStructure}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.dateQuit')}
            value={convertUtcDateToLocalTz(data?.dateQuit)}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.dateCreate')}
            value={convertUtcDateToLocalTz(data?.dateCreate)}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.dateUpdate')}
            value={convertUtcDateToLocalTz(data?.dateUpdate)}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('profileCustom.statusSyn')}
            value={
              <Status
                options={STATUS_SYN_OPTION}
                value={data?.isSyncSuccess}
                variant="text"
              />
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ padding: 3 }}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={onClose}
            >
              {t('actionBar.close')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DetailProfile
