export const SEARCH_AZURE_USERS_START = 'BUSEYE_SEARCH_AZURE_USERS_START'
export const SEARCH_AZURE_USERS_SUCCESS = 'BUSEYE_SEARCH_AZURE_USERS_SUCCESS'
export const SEARCH_AZURE_USERS_FAILED = 'BUSEYE_SEARCH_AZURE_USERS_FAILED'
/**
 * Search user
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 * @returns {object}
 */
export function searchAzureUsers(payload, onSuccess, onError) {
  return {
    type: SEARCH_AZURE_USERS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Search user success action
 * @param {*} payload
 * @returns {object}
 */
export function searchAzureUsersSuccess(payload) {
  return {
    type: SEARCH_AZURE_USERS_SUCCESS,
    payload: payload,
  }
}

/**
 * Search user failed action
 * @returns {object}
 */
export function searchAzureUsersFailed() {
  return {
    type: SEARCH_AZURE_USERS_FAILED,
  }
}

export default {
  searchAzureUsers,
  searchAzureUsersSuccess,
  searchAzureUsersFailed
}
