import React from 'react'

import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '~/components/Button'

const TopActionBar = ({
  onEdit,
  onDelete,
  sx,
  children,
  elBefore,
  elAfter,
}) => {
  const { t } = useTranslation(['buseye'])

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        pb: 1,
        mb: 1,
        gap: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        ...sx,

        '&:empty': {
          display: 'none',
        },
      })}
    >
      {children ? (
        children
      ) : (
        <>
          {typeof elBefore === 'function' ? elBefore() : elBefore}

          {typeof onEdit === 'function' && (
            <Button
              variant="outlined"
              color="success"
              icon="editOutline"
              onClick={onEdit}
            >
              {t('actionBar.edit')}
            </Button>
          )}

          {typeof onDelete === 'function' && (
            <Button
              variant="outlined"
              color="error"
              icon="deleteOutline"
              onClick={onDelete}
            >
              {t('actionBar.delete')}
            </Button>
          )}

          {typeof elAfter === 'function' ? elAfter() : elAfter}
        </>
      )}
    </Box>
  )
}

TopActionBar.defaultProps = {
  children: null,
  elBefore: null,
  elAfter: null,
  sx: {},
}

TopActionBar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  onEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.shape]),
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.shape]),
  elBefore: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  elAfter: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default TopActionBar
