import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetListWorkScheduleFailed,
  actGetListWorkScheduleSuccess,
  MASTER_GET_LIST_WORK_SCHEDULE,
} from '../../actions/work-schedule'
import { apiGetListWorkSchedule } from '../../apis/work-schedule'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(apiGetListWorkSchedule, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.items,
        total: response.data?.meta?.total,
      }
      yield put(actGetListWorkScheduleSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetListWorkScheduleFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_LIST_WORK_SCHEDULE, doGetList)
}
