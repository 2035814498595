import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actSyncRosterCalendarFailed,
  actSyncRosterCalendarSuccess,
  MASTER_SYNC_ROSTER_CALENDAR,
} from '../../actions/roster-calendar'
import { apiSyncRosterCalendar } from '../../apis/roster-calendar'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doSync(action) {
  try {
    const response = yield call(apiSyncRosterCalendar, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.items,
        total: response.data?.meta?.total,
      }
      yield put(actSyncRosterCalendarSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'attendance.notification.syncRosterCalendarSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actSyncRosterCalendarFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchSync() {
  yield takeLatest(MASTER_SYNC_ROSTER_CALENDAR, doSync)
}
