import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListReportFee = (params) => {
  const uri = `/reports/fee-distribution`

  return api.get(uri, params)
}
