// Action: Get list
export const MASTER_GET_REPORT_FEE = 'MASTER_GET_REPORT_FEE'
export const MASTER_GET_REPORT_FEE_SUCCESS = 'MASTER_GET_REPORT_FEE_SUCCESS'
export const MASTER_GET_REPORT_FEE_FAILED = 'MASTER_GET_REPORT_FEE_FAILED'
export const MASTER_GET_REPORT_FEE_RESET = 'MASTER_GET_REPORT_FEE_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetReportFee(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_REPORT_FEE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetReportFeeSuccess(payload) {
  return {
    type: MASTER_GET_REPORT_FEE_SUCCESS,
    payload: payload,
  }
}

export function actGetReportFeeFailed() {
  return {
    type: MASTER_GET_REPORT_FEE_FAILED,
  }
}

export function actGetReportFeeReset() {
  return {
    type: MASTER_GET_REPORT_FEE_RESET,
  }
}

export default {
  actGetReportFee,
  actGetReportFeeSuccess,
  actGetReportFeeFailed,
  actGetReportFeeReset,
}
