import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { MODAL_MODE, TEXTFIELD_ALLOW } from '~/common/constants'
import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'

const FormTable = ({ mode, data = [] }) => {
  const { t } = useTranslation(['buseye'])

  const columns = useMemo(
    () => [
      {
        field: '#',
        headerName: '#',
        width: 40,
        minWidth: 40,
        align: 'center',
        renderCell: (_, idx) => {
          return idx + 1
        },
      },
      {
        field: 'unitCode',
        headerName: t('settingFee.unitCode'),
        minWidth: 150,
      },
      {
        field: 'unitName',
        headerName: t('settingFee.unitName'),
        minWidth: 250,
      },
      {
        field: 'amount',
        width: 150,
        headerName: t('settingFee.amount'),
        required: mode !== MODAL_MODE.DETAIL,
        renderCell: (params, idx) => {
          const { amount } = params.row
          return mode !== MODAL_MODE.DETAIL ? (
            <Field.TextField
              name={`items[${idx}].amount`}
              placeholder={t('settingFee.amount')}
              type="number"
              // numberProps={{
              //   decimalScale: 2,
              // }}
              allow={TEXTFIELD_ALLOW.NUMERIC}
            />
          ) : (
            amount
          )
        },
      },
    ],
    [t],
  )

  return (
    <DataTable
      rows={data || []}
      columns={columns}
      maxHeight="310px"
      total={0}
      // tabs={<Tabs list={tabFormList(t)} value={tab} onChange={setTab} />}
      hideFooter
      hideSetting
    />
  )
}

export default FormTable
