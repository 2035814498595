import { useEffect } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'

import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'

import { validationSchema } from './schema'

const initialValues = {
  dateStart: new Date(),
  dateEnd: new Date(),
}

const PopupSync = ({
  t,
  onClose = () => {},
  open,
  showDetailId,
  onSubmit = () => {},
  tabName,
}) => {
  const {
    actions: { actGetDetailProfileCustom },
  } = useProfileCustom()

  useEffect(() => {
    if (showDetailId) {
      actGetDetailProfileCustom(showDetailId)
    }
  }, [showDetailId])

  return (
    <Dialog
      open={open}
      title={`${t('attendance.sync.title')} ${tabName}`}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Formik
        onSubmit={(val, { setFieldValue }) => onSubmit(val, setFieldValue)}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ resetForm }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              justifyContent={'space-between'}
            >
              <Grid item xs={5.5}>
                <Field.DateTimePicker
                  name="dateStart"
                  label={t('attendance.sync.start')}
                  placeholder={t('attendance.sync.start')}
                  // value={formData.dateStart}
                  // onChange={(val) => handleChange('dateStart', val)}
                  required
                />
              </Grid>
              <Grid item xs={5.5}>
                <Field.DateTimePicker
                  name="dateEnd"
                  label={t('attendance.sync.end')}
                  placeholder={t('attendance.sync.end')}
                  // value={formData.dateEnd}
                  // onChange={(val) => handleChange('dateEnd', val)}
                  required
                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    sx={{ marginRight: '10px' }}
                    // onClick={() => onSubmit(formData)}
                  >
                    {t('attendance.sync.syncButton')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default PopupSync
