import { api } from '~/services/api'

export const apiImport = ({ body, type }) => {
  const uri = `/import/${type}`
  const formData = new FormData()
  formData.append('type', type)
  formData.append('files', body)
  return api.post(uri, formData)
}

export const apiExport = (params) => {
  const uri = `/export`
  return api.get(uri, params)
}

export const apiGetTemplate = (type) => {
  const uri = `/export/template`
  return api.get(uri, { type })
}

export const apiExportReport = (params) => {
  const uri = `/export-reports`
  return api.get(uri, params)
}
