export const ROUTE = {
  CONFIGURATION: {
    PATH: '/configuration',
    TITLE: 'configuration',
  },
  DECENTRALIZATION: {
    PATH: '/decentralization',
    TITLE: 'decentralization',
  },
  USER_MANAGEMENT: {
    LIST: {
      PATH: '/user-management',
      TITLE: 'userManagement',
    },
    CREATE: {
      PATH: '/user-management/create',
      TITLE: 'userManagementCreate',
    },
    CREATE_AZURE: {
      PATH: '/user-management/user-azure',
      TITLE: 'userManagementCreateUserAzure',
    },
    DETAIL: {
      PATH: '/user-management/:id/detail',
      TITLE: 'userManagementDetail',
    },
    EDIT: {
      PATH: '/user-management/:id/edit',
      TITLE: 'userManagementEdit',
    },
    USER_AZURE_FORM: {
      PATH: '/user-management/user-azure',
      TITLE: 'userAzureForm',
    },
  },
  USER_PERMISSION: {
    PATH: '/user-permission',
    TITLE: 'userPermission',
  },

  ROLE_LIST: {
    LIST: {
      PATH: '/role-list',
      TITLE: 'defineRole',
    },
    CREATE: {
      PATH: '/role-list/create',
      TITLE: 'defineRoleCreate',
    },
    EDIT: {
      PATH: '/role-list/:id/edit',
      TITLE: 'defineRoleUpdate',
    },
    DETAIL: {
      PATH: '/role-list/:id/detail',
      TITLE: 'defineRoleDetail',
    },
  },
  ACCOUNT: {
    DETAIL: {
      PATH: '/account',
      TITLE: 'userInfoDetail',
    },
    EDIT: {
      PATH: '/account/edit',
      TITLE: 'userInfoEdit',
    },
    CHANGE_PASSWORD: {
      PATH: '/account/change-password',
      TITLE: 'changePassword',
    },
  },
}
