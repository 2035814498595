import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListFactoryList } from '~/modules/master/redux/apis/factory'
import {
  apiGetListOrgStructureList,
} from '~/modules/master/redux/apis/org-structure'
import { convertFilterParams } from '~/utils'
const FilterForm = () => {
  const { t } = useTranslation('buseye')

  return (
    <>
      <Grid item xs={2}>
        <Field.TextField
          name="keyword"
          placeholder={t('userManagement.keyword')}
          inputProps={{
            maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
          }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Field.TextField
          name="fullName"
          placeholder={t('userManagement.fullName')}
          inputProps={{
            maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Field.TextField
          name="email"
          placeholder={t('userManagement.email')}
          inputProps={{
            maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Field.Autocomplete
          name="factory"
          placeholder={t('profileCustom.factory')}
          asyncRequest={(s) =>
            apiGetListFactoryList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
              filter: convertFilterParams({
                isActive: STATUS_VALUE.active,
              }),
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
        />
      </Grid>

      <Grid item xs={2}>
        <Field.Autocomplete
          name="orgStructureIds"
          placeholder={t('profileCustom.orgStructure')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListOrgStructureList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
              filter: convertFilterParams({
                isActive: STATUS_VALUE.active,
              }),
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </Grid>
    </>
  )
}

export default FilterForm
