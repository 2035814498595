import React from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { Field } from '~/components/Formik'
import {
  apiGetListCatShiftList,
} from '~/modules/master/redux/apis/cat-shift'
import {
  apiGetListRouteList,
} from '~/modules/master/redux/apis/route'
import { convertFilterParams } from '~/utils'

import { CAT_SHIFT_LTF_CODE } from '../../constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="codeRoute"
              placeholder={t('reportBr11.filter.codeRoute')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListRouteList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="codeShift"
              placeholder={t('reportBr11.filter.codeShift')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListCatShiftList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                  filter: convertFilterParams({
                    codes: CAT_SHIFT_LTF_CODE.join(','),
                  }),
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.DateRangePicker
              name="rangeDate"
              placeholder={t('reportBr11.filter.rangeDate')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterForm
