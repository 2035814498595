const TAB_VALUE = {
  all: 0,
  active: 1,
  pending: 2,
  synError: 3,
}

export const tabList = (t, totals) => {
  const { all } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
  ]
}
