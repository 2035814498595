import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListOrgStructure = (params) => {
  const uri = `/human-resource/org-structure`
  return api.get(uri, params)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListOrgStructureList = (params) => {
  const uri = `/human-resource/org-structure-list`
  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailOrgStructure = (id) => {
  const uri = `/human-resource/org-structure/${id}`
  return api.get(uri)
}

/**
 * @returns {Promise}
 */
export const apiSyncOrgStructure = (body = {}) => {
  const uri = `/sync/org-structure`
  return api.post(uri, body)
}

export const apiGetOrgStructureTree = (params) => {
  const uri = `/human-resource/org-structure-tree`
  return api.get(uri, params)
}

export const apiUpdateOrgStructure = (body) => {
  const uri = `/human-resource/org-structure`
  return api.patch(uri, body)
}
