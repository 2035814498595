import moment from 'moment'
const TAB_VALUE = {
  all: 0,
  active: 1,
  pending: 2,
  synError: 3,
}

export const tabList = (t, totals) => {
  const { all } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
  ]
}

export const dateFilterDefault = (data) => {
  const currentDate = data ? moment(data) : moment()
  const date = moment(currentDate).date()

  if (date > 20) {
    return {
      start: currentDate,
      end: moment(currentDate).add(1, 'months').subtract(1, 'days'),
    }
  }
  return {
    start: moment(currentDate).subtract(1, 'months'),
    end: moment(currentDate).subtract(1, 'days'),
  }
}

export const enumerateDaysBetweenDates = function (startDate, endDate) {
  const dates = {}

  const currDate = moment(startDate).startOf('day')
  const lastDate = moment(endDate).startOf('day')

  do {
    const key = currDate.format('MMM YYYY')

    const weekday = currDate.weekday()
    if (!dates[key]) {
      dates[key] = {
        title: key,
        month: currDate.month(),
        year: currDate.year(),
        days: [],
      }
    }
    dates[key].days.push({
      key: currDate.format('YYYY_M_D'),
      title: currDate.date(),
      isWeekend: weekday === 0 || weekday === 6 ? true : false,
    })
  } while (currDate.add(1, 'days').diff(lastDate) <= 0)

  return dates
}
