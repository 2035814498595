const style = () => ({
  tableContainer: {
    '& .MuiTableCell-body': {
      padding: 0,
      '& td': {},
    },
    '& thead': {
      'tr:nth-child(1)': {
        '& th:nth-child(1)': {
          background: '#0761AD80',
        },
        '& th:nth-child(2)': {
          background: '#0761AD80',
        },
        '& th:nth-child(3)': {
          background: '#0761AD80',
        },
      },
      'tr:nth-child(2)': {
        '& th h5': {
          whiteSpace: 'wrap',
        },
      },
    },
  },
})
export default style
