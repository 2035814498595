import { PROFILE_CUSTOM_FLAG } from '../../constants'

export const TAB_VALUE = {
  all: '',
  working: PROFILE_CUSTOM_FLAG.WORKING,
  unregistered: PROFILE_CUSTOM_FLAG.UNREGISTER_ROUT,
  // maternityLeave: '2',
  // quit: '3',
  // synError: '4',
}

export const tabList = (t, totals) => {
  const { all, working, unregistered } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
    {
      label: `${t('common.working')} (${working})`,

      value: TAB_VALUE.working,
    },
    {
      label: `${t('common.unregistered')} (${unregistered})`,

      value: TAB_VALUE.unregistered,
    },
  ]
}
