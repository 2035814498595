import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetListProfileCustomFailed,
  actGetListProfileCustomSuccess,
  MASTER_GET_LIST_PROFILE_CUSTOM,
} from '../../actions/profile-custom'
import { apiGetListProfileCustom } from '../../apis/profile-custom'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(apiGetListProfileCustom, action?.payload)

    const { total, totalUnRegisterRoute, totalWorking, count } =
      response.data?.meta

    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.items,
        total,
        totalUnRegisterRoute,
        totalWorking,
        count,
      }
      yield put(actGetListProfileCustomSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetListProfileCustomFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_LIST_PROFILE_CUSTOM, doGetList)
}
