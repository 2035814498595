import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_BUSINESS_TRAVEL,
  MASTER_GET_DETAIL_BUSINESS_TRAVEL_FAILED,
  MASTER_GET_DETAIL_BUSINESS_TRAVEL_RESET,
  MASTER_GET_DETAIL_BUSINESS_TRAVEL_SUCCESS,
  MASTER_GET_LIST_BUSINESS_TRAVEL,
  MASTER_GET_LIST_BUSINESS_TRAVEL_FAILED,
  MASTER_GET_LIST_BUSINESS_TRAVEL_RESET,
  MASTER_GET_LIST_BUSINESS_TRAVEL_SUCCESS,
  MASTER_SYNC_BUSINESS_TRAVEL,
  MASTER_SYNC_BUSINESS_TRAVEL_FAILED,
  MASTER_SYNC_BUSINESS_TRAVEL_RESET,
  MASTER_SYNC_BUSINESS_TRAVEL_SUCCESS,
} from '../actions/business-travel'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listBusinessTravel(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_BUSINESS_TRAVEL:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_BUSINESS_TRAVEL_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_BUSINESS_TRAVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_BUSINESS_TRAVEL_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailBusinessTravel(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_BUSINESS_TRAVEL:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_BUSINESS_TRAVEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_BUSINESS_TRAVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_BUSINESS_TRAVEL_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncBusinessTravel(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_BUSINESS_TRAVEL:
      return { ...state, isLoading: true }
    case MASTER_SYNC_BUSINESS_TRAVEL_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_BUSINESS_TRAVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_BUSINESS_TRAVEL_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listBusinessTravel,
  detailBusinessTravel,
  syncBusinessTravel,
})
