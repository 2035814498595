import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListOvertime = (params) => {
  const uri = `/human-resource/overtime`

  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailOvertime = ({ id }) => {
  const uri = `${id}`

  return api.get(uri)
}

/**
 * @returns {Promise}
 */
export const apiSyncOvertime = ({ body = {}, query = {} }) => {
  const params = new URLSearchParams(query)
  const uri = `/sync/overtime-plan?${params.toString()}`

  return api.post(uri, body)
}
