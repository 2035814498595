import { combineReducers } from 'redux'

import {
  MASTER_CHANGE_STATUS_ROUTE,
  MASTER_CHANGE_STATUS_ROUTE_FAILED,
  MASTER_CHANGE_STATUS_ROUTE_SUCCESS,
  MASTER_CREATE_ROUTE,
  MASTER_CREATE_ROUTE_FAILED,
  MASTER_CREATE_ROUTE_SUCCESS,
  MASTER_DELETE_ROUTE,
  MASTER_DELETE_ROUTE_FAILED,
  MASTER_DELETE_ROUTE_SUCCESS,
  MASTER_GET_DETAIL_ROUTE,
  MASTER_GET_DETAIL_ROUTE_FAILED,
  MASTER_GET_DETAIL_ROUTE_RESET,
  MASTER_GET_DETAIL_ROUTE_SUCCESS,
  MASTER_GET_LIST_ROUTE,
  MASTER_GET_LIST_ROUTE_FAILED,
  MASTER_GET_LIST_ROUTE_RESET,
  MASTER_GET_LIST_ROUTE_SUCCESS,
  MASTER_UPDATE_ROUTE,
  MASTER_UPDATE_ROUTE_FAILED,
  MASTER_UPDATE_ROUTE_SUCCESS,
} from '../actions/route'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listRoute(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_ROUTE:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_ROUTE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_ROUTE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function createRoute(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_CREATE_ROUTE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_CREATE_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function detailRoute(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_ROUTE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_ROUTE_SUCCESS:
      const {
        routeToPickupPoints,
        routeToVehicleCategories,
        routeToCatShifts,
        ...detail
      } = action.payload
      return {
        ...state,
        isLoading: false,
        data: {
          ...detail,
          pickupPoints: routeToPickupPoints,
          vehicleCategories: routeToVehicleCategories,
          catShifts: routeToCatShifts,
        },
      }
    case MASTER_GET_DETAIL_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_ROUTE_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function updateRoute(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_UPDATE_ROUTE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function deleteRoute(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_DELETE_ROUTE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DELETE_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function changeStatusRoute(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_CHANGE_STATUS_ROUTE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CHANGE_STATUS_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_CHANGE_STATUS_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listRoute,
  createRoute,
  detailRoute,
  updateRoute,
  deleteRoute,
  changeStatusRoute,
})
