import { FUNCTION_CODE } from '~/common/constants/functionCode'

import RoleDetail from '../feature/role-list/detail'
import DefineRoleForm from '../feature/role-list/form'
import RoleList from '../feature/role-list/list'
import ChangePassword from '../feature/user-info/change-password'
import UserInfoDetail from '../feature/user-info/user-detail'
import UserInfoForm from '../feature/user-info/user-form'
import UserManagement from '../feature/user-management'
import UserAzureForm from '../feature/user-management/user-azure/user-form'
import UserManagementDetail from '../feature/user-management/user-detail'
import UserManagementForm from '../feature/user-management/user-form'
import UserPermission from '../feature/user-permission'
import { ROUTE } from './config'

const routes = [
  {
    name: ROUTE.CONFIGURATION.TITLE,
    icon: 'configuration',
    path: ROUTE.CONFIGURATION.PATH,
    component: UserManagement,
    isInSidebar: false,
  },
  {
    name: ROUTE.DECENTRALIZATION.TITLE,
    icon: 'plan',
    isInSidebar: true,
    subMenu: [
      {
        name: ROUTE.ROLE_LIST.LIST.TITLE,
        path: ROUTE.ROLE_LIST.LIST.PATH,
        component: RoleList,
        isInSidebar: true,
        code: 'USER_LIST_USER_ROLE_SETTING',
        subMenu: [
          {
            name: ROUTE.ROLE_LIST.CREATE.TITLE,
            path: ROUTE.ROLE_LIST.CREATE.PATH,
            // code: FUNCTION_CODE.USER_CREATE_USER_ROLE_SETTING,
            component: DefineRoleForm,
            isInSidebar: false,
            code: 'USER_CREATE_USER_ROLE_SETTING',
          },
          {
            name: ROUTE.ROLE_LIST.EDIT.TITLE,
            path: ROUTE.ROLE_LIST.EDIT.PATH,
            // code: FUNCTION_CODE.USER_UPDATE_USER_ROLE_SETTING,
            component: DefineRoleForm,
            isInSidebar: false,
            code: 'USER_UPDATE_USER_ROLE_SETTING',
          },
          {
            name: ROUTE.ROLE_LIST.DETAIL.TITLE,
            path: ROUTE.ROLE_LIST.DETAIL.PATH,
            // code: FUNCTION_CODE.USER_DETAIL_USER_ROLE_SETTING,
            component: RoleDetail,
            isInSidebar: false,
            code: 'USER_DETAIL_USER_ROLE_SETTING',
          },
        ],
      },
      {
        name: ROUTE.USER_PERMISSION.TITLE,
        path: ROUTE.USER_PERMISSION.PATH,
        component: UserPermission,
        code: FUNCTION_CODE.USER_LIST_PERMISSION_USER,
        isInSidebar: true,
      },
      {
        name: ROUTE.USER_MANAGEMENT.LIST.TITLE,
        path: ROUTE.USER_MANAGEMENT.LIST.PATH,
        component: UserManagement,
        isInSidebar: true,
        code: 'USER_LIST_USER',
        subMenu: [
          {
            name: ROUTE.USER_MANAGEMENT.CREATE.TITLE,
            path: ROUTE.USER_MANAGEMENT.CREATE.PATH,
            component: UserManagementForm,
            code: 'USER_CREATE_USER',
            isInSidebar: false,
          },
          {
            name: ROUTE.USER_MANAGEMENT.DETAIL.TITLE,
            path: ROUTE.USER_MANAGEMENT.DETAIL.PATH,
            component: UserManagementDetail,
            code: 'USER_DETAIL_USER',
            isInSidebar: false,
          },
          {
            name: ROUTE.USER_MANAGEMENT.EDIT.TITLE,
            path: ROUTE.USER_MANAGEMENT.EDIT.PATH,
            component: UserManagementForm,
            code: 'USER_UPDATE_USER',
            isInSidebar: false,
          },
          {
            name: ROUTE.USER_MANAGEMENT.USER_AZURE_FORM.TITLE,
            path: ROUTE.USER_MANAGEMENT.USER_AZURE_FORM.PATH,
            component: UserAzureForm,
            code: 'USER_UPDATE_USER',
            isInSidebar: false,
          },
        ],
      },
    ],
  },
  {
    name: ROUTE.ACCOUNT.DETAIL.TITLE,
    path: ROUTE.ACCOUNT.DETAIL.PATH,
    component: UserInfoDetail,
  },
  {
    name: ROUTE.ACCOUNT.EDIT.TITLE,
    path: ROUTE.ACCOUNT.EDIT.PATH,
    component: UserInfoForm,
  },
  {
    name: ROUTE.ACCOUNT.CHANGE_PASSWORD.TITLE,
    path: ROUTE.ACCOUNT.CHANGE_PASSWORD.PATH,
    component: ChangePassword,
  },
]

export default routes
