import React, { useEffect, useState, useMemo } from 'react'

import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import { USER_MANAGEMENT_STATUS_OPTIONS } from '~/modules/configuration/constants'
import { ROUTE } from '~/modules/configuration/routes/config'
import { EXPORT_TYPE, IMPORT_TYPE } from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import { convertFilterParams, convertSortParams } from '~/utils'
import qs from '~/utils/qs'

import useUserManagement from '../../redux/hooks/useUserManagement'
import { tabList } from './constants'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: 'decentralization',
  },
  {
    route: ROUTE.USER_MANAGEMENT.LIST.PATH,
    title: ROUTE.USER_MANAGEMENT.LIST.TITLE,
  },
]

function UserManagement() {
  const { t } = useTranslation('buseye')
  const history = useHistory()
  const location = useLocation()
  const { canAccess } = useApp()
  const { factoryId } = qs.parse(location.search)

  const DEFAULT_FILTERS = {
    username: '',
    fullName: '',
    department: '',
    status: '',
    createTime: [],
    factoryId: factoryId,
  }

  const {
    page,
    pageSize,
    sort,
    filters,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    withSearch,
    tab,
    setTab,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      userList = [],
      total,
      isLoading,
      totalActive,
      totalInActive,
      totalAzure,
      count,
    },
    actions: { searchUsers, deleteUser },
  } = useUserManagement()

  const [selectedRows, setSelectedRows] = useState([])
  const [deleteItems, setDeleteItems] = useState(null)

  const columns = useMemo(
    () => [
      {
        field: 'code',
        headerName: t('userManagement.code'),
        width: 100,
        sortable: true,
        visible: 'always',
      },
      {
        field: 'username',
        headerName: t('userManagement.username'),
        width: 100,
        sortable: true,
        visible: 'always',
      },
      {
        field: 'fullName',
        headerName: t('userManagement.fullName'),
        width: 150,
        sortable: true,
        visible: 'always',
      },
      {
        field: 'email',
        headerName: t('userManagement.email'),
        width: 150,
        sortable: false,
      },
      {
        field: 'departmentName',
        headerName: t('reportBr11.bu'),
        width: 150,
        sortable: false,
        renderCell: (params) => {
          const deparmentName = params.row?.orgStructures
            ?.map((org) => org?.name)
            .join('; ')
          return deparmentName
        },
      },
      {
        field: 'roleName',
        headerName: t('userManagement.role'),
        width: 100,
        sortable: false,
        renderCell: (params) => {
          const roleName = params.row?.userRoles
            ?.map((role) => role?.userRoleName)
            .join('; ')
          return roleName
        },
      },
      {
        field: 'factory',
        headerName: t('userManagement.factory'),
        width: 100,
        sortable: false,
        renderCell: (params) => {
          return params.row?.factory?.name
        },
      },
      {
        field: 'status',
        headerName: t('userManagement.status'),
        width: 80,
        sortable: true,
        renderCell: (params) => {
          const { status } = params.row
          return (
            <Status
              options={USER_MANAGEMENT_STATUS_OPTIONS}
              value={status}
              variant="text"
            />
          )
        },
      },
      {
        field: 'action',
        headerName: t('userManagement.action'),
        width: 170,
        sortable: false,
        align: 'center',
        visible: 'always',

        sticky: 'right',
        renderCell: (params) => {
          const { id } = params?.row
          return (
            <div>
              <Guard code={FUNCTION_CODE.USER_DETAIL_USER}>
                <IconButton
                  onClick={() =>
                    history.push(
                      withSearch(
                        ROUTE.USER_MANAGEMENT.DETAIL.PATH.replace(
                          ':id',
                          `${id}`,
                        ),
                      ),
                    )
                  }
                >
                  <Icon name="show" />
                </IconButton>
              </Guard>
              <Guard code={FUNCTION_CODE.USER_IMPORT_USER}>
                <IconButton
                  onClick={() =>
                    history.push(
                      withSearch(
                        ROUTE.USER_MANAGEMENT.EDIT.PATH.replace(':id', `${id}`),
                      ),
                    )
                  }
                >
                  <Icon name="edit" />
                </IconButton>
              </Guard>
              <Guard code={FUNCTION_CODE.USER_DELETE_USER}>
                <IconButton onClick={() => openConfirmDelete(params.row)}>
                  <Icon name="delete" />
                </IconButton>
              </Guard>
              <Guard code={FUNCTION_CODE.USER_CREATE_USER}>
                <IconButton
                  onClick={() =>
                    history.push(
                      withSearch(
                        `${ROUTE.USER_MANAGEMENT.CREATE.PATH}?cloneId=${id}`,
                      ),
                    )
                  }
                >
                  <Icon name="clone" />
                </IconButton>
              </Guard>
            </div>
          )
        },
      },
    ],
    [userList],
  )

  const refreshData = () => {
    const { keyword = '', factory, email, fullName } = filters
    let typeFilter = { type: 0 };
    if (tab === 2) {
      typeFilter = { type: 1 };
    }
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          email: email,
          fullName: fullName,
          factoryId: factory?.id,
          orgStructureIds: filters.orgStructureIds?.id,
          status: tab === 2 ? '' : tab,
          ...typeFilter
        },
        [{ field: 'createdAt', filterFormat: 'date' }],
      ),
      sort: convertSortParams(sort),
    }
    // setSelectedRows([])
    searchUsers(params)
    setDeleteItems(null)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_CREATE_USER}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={() =>
          history.push(withSearch(ROUTE.USER_MANAGEMENT.CREATE.PATH))
        }
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
    <Guard code={FUNCTION_CODE.USER_CREATE_USER}>
      <IconButton
        icon="add"
        title={t('userManagement.azureUser')}
        onClick={() =>
          history.push(withSearch(ROUTE.USER_MANAGEMENT.USER_AZURE_FORM.PATH))
        }
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <Guard code={FUNCTION_CODE.USER_DELETE_USER}>
        <IconButton
          icon="delete"
          title={t('common.delete')}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openConfirmDelete(selectedRows)}
        />
      </Guard>,
    ]
  }

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const handleDelete = () => {
    if (!deleteItems) return
    const params = Array.isArray(deleteItems)
      ? { ids: deleteItems.map((item) => item.id).join() }
      : deleteItems.id
    deleteUser(params, () => {
      refreshData()
    })
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        totalActive,
        totalInActive,
        totalAzure
      })}
      value={tab}
      onChange={setTab}
    />
  )

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.userManagement')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <TaskBar
        left={actionHeader}
        right={[
          <ImportExport
            name={'ds_nguoidung'}
            onImport={
              canAccess(FUNCTION_CODE.USER_IMPORT_USER)
                ? (body) =>
                    apiImport({ body, type: IMPORT_TYPE.USER_MANAGEMENT })
                : null
            }
            importMessage={{
              success: t('userManagement.notification.importSuccess'),
            }}
            onExport={
              canAccess(FUNCTION_CODE.USER_EXPORT_USER)
                ? () => {
                    const params = {
                      filter: convertFilterParams(filters, [
                        { field: 'createdAt', filterFormat: 'date' },
                      ]),
                      sort: convertSortParams(sort),
                      type: EXPORT_TYPE.USER_MANAGEMENT,
                    }
                    if (!isEmpty(selectedRows)) {
                      params.ids = JSON.stringify(
                        selectedRows?.map((x) => ({ id: x?.id })),
                      )
                    }

                    return apiExport(params)
                  }
                : null
            }
            onDownloadTemplate={() =>
              apiGetTemplate(EXPORT_TYPE.USER_MANAGEMENT)
            }
            onRefresh={refreshData}
          />,
        ]}
      />
      <Guard code={FUNCTION_CODE.USER_SEARCH_USER}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            customWidth: true,
            gridSpace: 4,
          }}
        />
      </Guard>
      <DataTable
        title={t('userManagement.title')}
        rows={userList}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={count}
        sort={sort}
        filters={{
          form: <FilterForm />,
          values: filters,
          defaultValue: DEFAULT_FILTERS,
          onApply: setFilters,
          // validationSchema: filterSchema(t),
        }}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        title={t('userManagement.userManagementDelete')}
        content={t('route.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default UserManagement
