import { combineReducers } from 'redux'

import {
  MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES,
  MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_FAILED,
  MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_SUCCESS,
  MASTER_CREATE_VEHICLE_CATEGORIES,
  MASTER_CREATE_VEHICLE_CATEGORIES_FAILED,
  MASTER_CREATE_VEHICLE_CATEGORIES_SUCCESS,
  MASTER_DELETE_VEHICLE_CATEGORIES,
  MASTER_DELETE_VEHICLE_CATEGORIES_FAILED,
  MASTER_DELETE_VEHICLE_CATEGORIES_SUCCESS,
  MASTER_GET_DETAIL_VEHICLE_CATEGORIES,
  MASTER_GET_DETAIL_VEHICLE_CATEGORIES_FAILED,
  MASTER_GET_DETAIL_VEHICLE_CATEGORIES_RESET,
  MASTER_GET_DETAIL_VEHICLE_CATEGORIES_SUCCESS,
  MASTER_GET_LIST_VEHICLE_CATEGORIES,
  MASTER_GET_LIST_VEHICLE_CATEGORIES_FAILED,
  MASTER_GET_LIST_VEHICLE_CATEGORIES_RESET,
  MASTER_GET_LIST_VEHICLE_CATEGORIES_SUCCESS,
  MASTER_UPDATE_VEHICLE_CATEGORIES,
  MASTER_UPDATE_VEHICLE_CATEGORIES_FAILED,
  MASTER_UPDATE_VEHICLE_CATEGORIES_SUCCESS,
} from '../actions/vehicle-categories'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listVehicleCategories(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_VEHICLE_CATEGORIES:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_VEHICLE_CATEGORIES_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_VEHICLE_CATEGORIES_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function createVehicleCategories(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_CREATE_VEHICLE_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CREATE_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_CREATE_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function detailVehicleCategories(
  state = { isLoading: false, data: {} },
  action,
) {
  switch (action.type) {
    case MASTER_GET_DETAIL_VEHICLE_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_VEHICLE_CATEGORIES_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function updateVehicleCategories(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_UPDATE_VEHICLE_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function deleteVehicleCategories(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_DELETE_VEHICLE_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_DELETE_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DELETE_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function changeStatusVehicleCategories(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listVehicleCategories,
  createVehicleCategories,
  detailVehicleCategories,
  updateVehicleCategories,
  deleteVehicleCategories,
  changeStatusVehicleCategories,
})
