import * as React from 'react'

import { isNil, isNumber } from 'lodash'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const config = {
  thousandSeparator: ',',
  decimalSeparator: '.',
  allowNegative: false,
  decimalScale: 5,
}

export const NumberFormatInput = React.forwardRef(function NumberFormatInput(
  props,
  ref,
) {
  const { onChange, numberProps, ...other } = props

  const validMaxLength = (val) => {
    if (!val) return true

    const maxLength = isNumber(numberProps?.maxLengthNumber)
      ? numberProps?.maxLengthNumber
      : 18
    return val.toString().length <= maxLength
  }

  const validMaxValue = (val) => {
    if (!val) return true
    if (!isNumber(numberProps?.maxValue)) return true
    const valNumber = val.replace(/,/g, '').replace(/[^0-9.]/g, '')
    const maxValue = numberProps?.maxValue

    return Number(valNumber) <= maxValue
  }

  return (
    <NumberFormat
      {...other}
      type="text"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props?.name,
            value: values?.floatValue ?? '',
          },
        })
      }}
      {...config}
      {...numberProps}
      isAllowed={(val) => {
        if (typeof numberProps?.isAllowed === 'function') {
          return validMaxLength(val.value) && numberProps?.isAllowed(val)
        }
        return validMaxLength(val.value) && validMaxValue(val.value)
      }}
    />
  )
})

NumberFormatInput.defaultProps = {
  onChange: () => {},
  numberProps: {},
}

NumberFormatInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  numberProps: PropTypes.shape(),
}

export const NumberFormatText = ({ value, numberProps }) => {
  if (isNil(value)) return ''

  const convertedValue = (+Number(value || 0).toFixed(
    config?.decimalScale || 0,
  )).toString()

  return (
    <NumberFormat
      value={convertedValue}
      displayType="text"
      isNumericString
      {...config}
      {...numberProps}
    />
  )
}

NumberFormatText.defaultProps = {
  value: '',
  numberProps: {},
}

NumberFormatText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberProps: PropTypes.shape(),
}

export default NumberFormatText
