import React from 'react'

import { InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { STATUS_OPTIONS } from '~/modules/master/constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Field.TextField
        name="keyword"
        placeholder={t('common.inputKeyWord')}
        inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            <Icon name="search" />
          </InputAdornment>
        }
      />

      <div style={{ marginLeft: '20px' }}>
        <Field.Autocomplete
          label={t('pickupPoint.isActive')}
          placeholder={t('pickupPoint.isActive')}
          name="isActive"
          options={STATUS_OPTIONS}
          getOptionLabel={(opt) => t(opt?.text) || ''}
          getOptionValue={(opt) => opt?.id}
          isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
        />
      </div>
    </>
  )
}

export default FilterForm
