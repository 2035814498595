const TAB_VALUE = {
  all: 0,
  working: 1,
  unregistered: 2,
  maternityLeave: 3,
  quit: 4,
  synError: 5,
}

export const tabList = (t, totals) => {
  const { all } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
  ]
}
