import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    code: Yup.string().required(t('form.required')),
    name: Yup.string().required(t('form.required')),
    timeRun: Yup.object().test(_, (value, context) => {
      if (!value || !Object.keys(value).some((key) => value[key])) {
        return context.createError({
          path: context.path,
          message: t('form.required'),
        })
      }
      return true
    }),
    pickupPoints: Yup.array()
      .min(1, t('form.required'))
      .of(
        Yup.object().shape({
          pickupPoint: Yup.object().nullable().required(t('form.required')),
        }),
      ),
    catShifts: Yup.array()
      .min(1, t('form.required'))
      .of(
        Yup.object().shape({
          catShift: Yup.object()
            .nullable()
            .test(_, (value, context) => {
              if (!value) {
                return context.createError({
                  path: context.path,
                  message: t('form.required'),
                })
              }

              const currentDatas =
                context.from[context.from.length - 1]?.value?.catShifts || []
              const formVal = context?.parent || {}

              if (
                checkDupicate({
                  items: currentDatas,
                  catShift: value,
                  vehicleType: formVal.vehicleType,
                })
              ) {
                return context.createError({
                  path: context.path,
                  message: t('form.codeDupicate'),
                })
              }

              return true
            }),
          vehicleType: Yup.object()
            .nullable()
            .test(_, (value, context) => {
              if (!value) {
                return context.createError({
                  path: context.path,
                  message: t('form.required'),
                })
              }

              const currentDatas =
                context.from[context.from.length - 1]?.value?.catShifts || []
              const formVal = context?.parent || {}

              if (
                checkDupicate({
                  items: currentDatas,
                  catShift: formVal.catShift,
                  vehicleType: value,
                })
              ) {
                return context.createError({
                  path: context.path,
                  message: t('form.codeDupicate'),
                })
              }

              return true
            }),
          totalVehicle: Yup.number()
            .nullable()
            .required(t('form.required'))
            .min(
              1,
              t('form.minNumber', {
                min: 1,
              }),
            ),
        }),
      ),
  })

const checkDupicate = ({ items, catShift, vehicleType }) => {
  if (!catShift || !vehicleType) return
  const listDupicate = items?.filter(
    (item) =>
      item?.catShift?.code === catShift.code &&
      item?.vehicleType?.code === vehicleType.code,
  )

  return listDupicate.length > 1 ? true : false
}
