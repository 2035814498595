import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetReportFeeFailed,
  actGetReportFeeSuccess,
  MASTER_GET_REPORT_FEE,
} from '../../actions/report-fee'
import { apiGetListReportFee } from '../../apis/report-fee'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(apiGetListReportFee, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.data,
        header: response.data?.header,
        feeSetting: response.data?.feeSetting,
      }

      yield put(actGetReportFeeSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetReportFeeFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_REPORT_FEE, doGetList)
}
