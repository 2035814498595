export const SETTING_CODE = {
  CODE_PROFILE_CUSTOM: 'profileCustom',
  CODE_ORGANIZATION_STRUCTURE: 'orgStructure',
  CODE_POSITION: 'position',
  CODE_CATEGORY_SHIFT: 'catShift',
}

export const FREQUENCY = {
  HOUR: 'hour',
  MINUTE: 'minute',
}

export const TIME_RUN_CHECKBOXS = [
  {
    name: 'runMon',
    text: 'setting.runMon',
  },
  {
    name: 'runTue',
    text: 'setting.runTue',
  },
  {
    name: 'runWed',
    text: 'setting.runWed',
  },
  {
    name: 'runThu',
    text: 'setting.runThu',
  },
  {
    name: 'runFri',
    text: 'setting.runFri',
  },
  {
    name: 'runSat',
    text: 'setting.runSat',
  },
  {
    name: 'runSun',
    text: 'setting.runSun',
  },
]
