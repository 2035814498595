import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListProfileCustom = (params) => {
  const uri = `/human-resource/profile-custom`

  return api.get(uri, params)
}

/**
 *
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailProfileCustom = (id) => {
  const uri = `/human-resource/profile-custom/${id}`
  return api.get(uri)
}

/**
 *
 * @returns {Promise}
 */
export const apiSyncProfileCustom = (body = {}) => {
  const uri = `/sync/profile-custom`
  return api.post(uri, body)
}

/**
 *
 * @param { body: {
 * id: string,
 * routeCode: number,
 * pickupPointCode: number
 * }} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateProfileCustom = (body) => {
  const uri = `/human-resource/profile-custom/route-pickup-point`
  return api.patch(uri, body)
}

/**
 *
 * @param { body: {
 * id: string,
 * routeCode: number,
 * pickupPointCode: number
 * }} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateAddSite = (body) => {
  const uri = `/human-resource/profile-custom/factory`
  return api.patch(uri, body)
}
