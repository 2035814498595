import { useEffect } from 'react'

import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'

import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import Status from '~/components/Status'
import TaskBar from '~/components/TaskBar'
import { STATUS_OPTIONS } from '~/modules/master/constants'
import usePickupPoint from '~/modules/master/redux/hooks/usePickupPoint'

const DetailProfile = ({
  t,
  onClose = () => {},
  open,
  showDetailId,
  onUpdate = () => {},
  openConfirmDelete = () => {},
}) => {
  const {
    data: {
      detailPickupPoint: { data },
    },
    actions: { actGetDetailPickupPoint },
  } = usePickupPoint()

  useEffect(() => {
    if (showDetailId) {
      actGetDetailPickupPoint(showDetailId)
    }
  }, [showDetailId])

  const actionHeader = [
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      color="success"
      variant="outlined"
      icon="edit"
      iconColor="success"
      onClick={() => {
        onUpdate(data)
        // history.push(ROUTE.ROUTE.UPDATE.PATH.replace(':id', `${id}`))
      }}
    >
      {t('actionBar.editInfo')}
    </Button>,
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      color="error"
      variant="outlined"
      icon={'deleteOutline'}
      onClick={() => {
        if (data.id) {
          openConfirmDelete([data])
        }
      }}
    >
      {t('common.delete')}
    </Button>,
  ]

  return (
    <Dialog
      open={open}
      title={t('pickupPoint.form.detail')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <TaskBar sx={{ background: 'white' }} left={[]} right={actionHeader} />
      <Divider sx={{ borderColor: '#CBCBCB', margin: '0 0 20px 0' }} />
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
      >
        <Grid item xs={12}>
          <LV
            label={t('pickupPoint.isActive')}
            labelColor="#222222"
            value={<Status options={STATUS_OPTIONS} value={data?.isActive} />}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('pickupPoint.form.code')}
            labelColor="#222222"
            value={data?.code}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('pickupPoint.form.name')}
            labelColor="#222222"
            value={data?.name}
          />
        </Grid>

        <Grid item lg={6} xs={12}>
          <LV
            label={t('pickupPoint.timeDeparture')}
            labelColor="#222222"
            value={data?.timeDeparture}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('pickupPoint.timeArrival')}
            labelColor="#222222"
            value={data?.timeArrival}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('pickupPoint.location')}
            labelColor="#222222"
            value={data?.location}
          />
        </Grid>
      </Grid>
      <Box sx={{ padding: 3 }}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={onClose}
            >
              {t('actionBar.close')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DetailProfile
