import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actCreatePickupPointFailed,
  actCreatePickupPointSuccess,
  MASTER_CREATE_PICKUP_POINT,
} from '../../actions/pickup-point'
import { apiCreatePickupPoint } from '../../apis/pickup-point'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doCreate(action) {
  try {
    const response = yield call(apiCreatePickupPoint, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actCreatePickupPointSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'pickupPoint.notification.createSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(
        response?.message || 'pickupPoint.notification.createError',
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actCreatePickupPointFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchCreate() {
  yield takeLatest(MASTER_CREATE_PICKUP_POINT, doCreate)
}
