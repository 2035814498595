import React, { useEffect } from 'react'

import Box from '@mui/material/Box'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { useApp } from '~/common/hooks/useApp'
import HotKeys from '~/components/HotKeys'
import Sidebar from '~/components/Sidebar'
import { SidebarProvider } from '~/contexts/SidebarContext'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { useNotification } from '~/modules/shared/redux/hooks/useNotification'
import { privateRoutesFlatten } from '~/routes'
import { isAuth } from '~/utils'
import { getCurrentModule } from '~/utils/menu'

const PrivateLayout = ({ children }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { canAccess } = useApp()
  const { path } = useRouteMatch()
  const {
    data: { userInfo },
  } = useUserInfo()
  const {
    data: { totalUnRead },
  } = useNotification()
  const currentModule = getCurrentModule(pathname) || 'general'

  const { t } = useTranslation('buseye')

  const title = 'Smart Buseye'

  if (!isAuth()) {
    return <Redirect to="/login" />
  }

  const hotKeysHandlers = {
    goToHomePage: () => {
      if (['buseye'].includes(currentModule)) {
        history.push(`/${currentModule}`)
      } else {
        history.push('/')
      }
    },
    toggleNotification: () => {
      document.querySelector('[hotkey="global_toggleNotification"]')?.click()
    },
    toggleFilter: () => {
      document.querySelector('[hotkey="global_toggleFilter"]')?.click()
    },
  }

  const code = privateRoutesFlatten.find((r) => r?.path === path)?.code

  const name = privateRoutesFlatten.find((r) => r?.path === path)?.name

  useEffect(() => {
    if (name) {
      document.title = totalUnRead
        ? `(${totalUnRead < 0 ? 0 : totalUnRead}) ${title} - ${t(
            `menu.${name}`,
          )}`
        : `${title} - ${t(`menu.${name}`)}`
    } else {
      document.title = title
    }
    return () => {
      document.title = title
    }
  }, [path, totalUnRead])

  const routeAccess = privateRoutesFlatten?.reduce((acc, current) => {
    if (current.code && canAccess(current.code)) {
      acc.push(current)
    }

    if (current.codes && current.codes.some((code) => canAccess(code))) {
      acc.push(current)
    }

    return acc
  }, [])

  if (!canAccess(code) && !!userInfo?.userPermissions) {
    let linkRedicect = '/'

    if (!isEmpty(routeAccess)) {
      linkRedicect = routeAccess[0]?.path
    }

    // if (userInfo?.userRoles && userInfo?.userRoles[0]?.userRoleId === 4) {
    //   linkRedicect = '/report-ot-business-travel'
    // }

    return <Redirect to={linkRedicect} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <HotKeys handlers={hotKeysHandlers} />
      <SidebarProvider>
        <Sidebar />
        <Box sx={{ flex: 1, overflow: 'hidden' }}>{children}</Box>\
      </SidebarProvider>
    </Box>
  )
}

PrivateLayout.defaultProps = {
  children: null,
}

PrivateLayout.propTypes = {
  children: PropTypes.node,
}

export default PrivateLayout
