import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListCatShift = (params) => {
  const uri = `/human-resource/cat-shift`
  return api.get(uri, params)
}

export const apiGetListCatShiftList = (params) => {
  const uri = `/human-resource/cat-shift-list`
  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailCatShift = (id) => {
  const uri = `${id}`
  return api.get(uri)
}

/**
 * @returns {Promise}
 */
export const apiSyncCatShift = (body = {}) => {
  const uri = `/sync/cat-shift`
  return api.post(uri, body)
}
