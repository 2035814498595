import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiCreatePickupPoint = ({ body }) => {
  const uri = `/pickup-point`

  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListPickupPoint = (params) => {
  const uri = `/pickup-point`

  return api.get(uri, params)
}

export const apiGetListPickupPointList = (params) => {
  const uri = `/pickup-point/list`

  return api.get(uri, params)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetUnregisterPickupPoint = (params) => {
  const uri = `/pickup-point/unregister`

  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailPickupPoint = (id) => {
  const uri = `/pickup-point/${id}`

  return api.get(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdatePickupPoint = ({ body }) => {
  const uri = `/pickup-point/${body?.id}`

  return api.patch(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiDeletePickupPoint = (params) => {
  const uri = `/pickup-point`

  return api.delete(uri, params)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActivePickupPoint = (id) => {
  const uri = `/pickup-point/${id}/active`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActivePickupPoints = (body) => {
  const uri = `/pickup-point/active/multiple`

  return api.post(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActivePickupPoint = (id) => {
  const uri = `/pickup-point/${id}/inactive`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActivePickupPoints = (body) => {
  const uri = `/pickup-point/inactive/multiple`

  return api.post(uri, body)
}
