import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import { STATUS_SYN_OPTION } from '~/modules/master/constants'
import useCatShift from '~/modules/master/redux/hooks/useCatShift'
import { ROUTE } from '~/modules/master/routes/config'
import {
  convertFilterParams,
  convertNumberThousandComma,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
  convertUtcDateToLocalTz,
} from '~/utils'

import { tabList } from '../constants'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.EMPLOYEE.TITLE,
  },
  {
    route: ROUTE.EMPLOYEE.CAT_SHIFT.LIST.PATH,
    title: ROUTE.EMPLOYEE.CAT_SHIFT.LIST.TITLE,
  },
]

const ListCatShift = () => {
  const { t } = useTranslation(['buseye'])
  const [confirmSync, setConfirmSync] = useState(false)
  const history = useHistory()

  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    costCenterIds: [],
    status: null,
  }

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setTab,
  } = useQueryState()

  const {
    data: {
      listCatShift: { data, total, isLoading },
      syncCatShift: { isLoading: isLoadingSync },
    },
    actions: { actGetListCatShift, actSyncCatShift },
  } = useCatShift()

  const columns = useMemo(() => [
    {
      field: '#',
      headerName: 'STT',
      width: 40,
      minWidth: 40,
      align: 'center',
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'code',
      headerName: t('catShift.code'),
      width: 150,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('catShift.name'),
      width: 200,
      sortable: true,
    },
    {
      field: 'inTime',
      headerName: t('catShift.inTime'),
      width: 150,
      align: 'center',
      sortable: true,
      renderCell: (params) => {
        const { inTime } = params?.row
        return convertUtcDateTimeToLocalTz(inTime, 'HH:mm')
      },
    },
    {
      field: 'outTime',
      headerName: t('catShift.outTime'),
      width: 150,
      sortable: true,
      align: 'center',
      renderCell: (params) => {
        const { outTime } = params?.row
        return convertUtcDateTimeToLocalTz(outTime, 'HH:mm')
      },
    },
    {
      field: 'coOut',
      headerName: t('catShift.coOut'),
      width: 150,
      align: 'center',
      renderCell: (params) => {
        const { coOut } = params.row
        return convertNumberThousandComma(coOut)
      },
    },

    {
      field: 'updatedAt',
      headerName: t('catShift.dateUpdate'),
      width: 150,
      sortable: true,
      align: 'center',
      renderCell: (params) => {
        const { updatedAt } = params?.row
        return convertUtcDateToLocalTz(updatedAt)
      },
    },
    {
      field: 'isSyncSuccess',
      headerName: t('catShift.statusSyn'),
      width: 150,
      renderCell: (params) => {
        const { isSyncSuccess } = params.row
        return (
          <Status
            options={STATUS_SYN_OPTION}
            value={isSyncSuccess}
            variant="text"
          />
        )
      },
    },
  ])

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...dataFilter,
          // departmentIds: filters?.departmentIds
          //   ?.map((item) => item?.id)
          //   ?.join(','),
          // costCenterIds: filters?.costCenterIds
          //   ?.map((item) => item?.id)
          //   ?.join(','),
        },
        columns,
      ),
      sort: convertSortParams(sort),
    }
    actGetListCatShift(params)
    closeConfirmSync()
    // actions.searchProducingSteps(params)
  }

  const openConfirm = () => {
    setConfirmSync(true)
  }

  const closeConfirmSync = () => {
    setConfirmSync(false)
  }

  const handleSync = () => {
    actSyncCatShift({}, refreshData)
  }

  const syncButton = (
    <Guard code={FUNCTION_CODE.USER_SYNCHRONIZE_CATEGORY_SHIFT}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={openConfirm}
      >
        <Icon
          name="assign"
          fill="#FF9054"
          stroke="#FF9054"
          sx={{ marginRight: '5px' }}
        />
        {t('common.sync')}
      </Box>
    </Guard>
  )

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        active: 1,
        pending: 2,
        synError: 4,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.catShift')}
      onBack={() => history.goBack()}
      loading={isLoading || isLoadingSync}
    >
      <HotKeys handlers={{}} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_CATEGORY_SHIFT}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        total={total}
        title={t('master.title')}
        sort={sort}
        beforeTopbar={syncButton}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!confirmSync}
        onConfirm={handleSync}
        onCancel={closeConfirmSync}
      />
    </Page>
  )
}

export default ListCatShift
