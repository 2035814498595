// Action: Get list
export const MASTER_GET_LIST_ORG_STRUCTURE = 'MASTER_GET_LIST_ORG_STRUCTURE'
export const MASTER_GET_LIST_ORG_STRUCTURE_SUCCESS =
  'MASTER_GET_LIST_ORG_STRUCTURE_SUCCESS'
export const MASTER_GET_LIST_ORG_STRUCTURE_FAILED =
  'MASTER_GET_LIST_ORG_STRUCTURE_FAILED'
export const MASTER_GET_LIST_ORG_STRUCTURE_RESET =
  'MASTER_GET_LIST_ORG_STRUCTURE_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_ORG_STRUCTURE = 'MASTER_GET_DETAIL_ORG_STRUCTURE'
export const MASTER_GET_DETAIL_ORG_STRUCTURE_SUCCESS =
  'MASTER_GET_DETAIL_ORG_STRUCTURE_SUCCESS'
export const MASTER_GET_DETAIL_ORG_STRUCTURE_FAILED =
  'MASTER_GET_DETAIL_ORG_STRUCTURE_FAILED'
export const MASTER_GET_DETAIL_ORG_STRUCTURE_RESET =
  'MASTER_GET_DETAIL_ORG_STRUCTURE_RESET'

// Action: sync
export const MASTER_SYNC_ORG_STRUCTURE = 'MASTER_SYNC_ORG_STRUCTURE'
export const MASTER_SYNC_ORG_STRUCTURE_SUCCESS =
  'MASTER_SYNC_ORG_STRUCTURE_SUCCESS'
export const MASTER_SYNC_ORG_STRUCTURE_FAILED =
  'MASTER_SYNC_ORG_STRUCTURE_FAILED'
export const MASTER_SYNC_ORG_STRUCTURE_RESET = 'MASTER_SYNC_ORG_STRUCTURE_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListOrgStructure(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_ORG_STRUCTURE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListOrgStructureSuccess(payload) {
  return {
    type: MASTER_GET_LIST_ORG_STRUCTURE_SUCCESS,
    payload: payload,
  }
}

export function actGetListOrgStructureFailed() {
  return {
    type: MASTER_GET_LIST_ORG_STRUCTURE_FAILED,
  }
}

export function actGetListOrgStructureReset() {
  return {
    type: MASTER_GET_LIST_ORG_STRUCTURE_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailOrgStructure(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_ORG_STRUCTURE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailOrgStructureSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_ORG_STRUCTURE_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailOrgStructureFailed() {
  return {
    type: MASTER_GET_DETAIL_ORG_STRUCTURE_FAILED,
  }
}

export function actGetDetailOrgStructureReset() {
  return {
    type: MASTER_GET_DETAIL_ORG_STRUCTURE_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncOrgStructure(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_ORG_STRUCTURE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncOrgStructureSuccess(payload) {
  return {
    type: MASTER_SYNC_ORG_STRUCTURE_SUCCESS,
    payload: payload,
  }
}

export function actSyncOrgStructureFailed() {
  return {
    type: MASTER_SYNC_ORG_STRUCTURE_FAILED,
  }
}

export function actSyncOrgStructureReset() {
  return {
    type: MASTER_SYNC_ORG_STRUCTURE_RESET,
  }
}

export default {
  actGetListOrgStructure,
  actGetListOrgStructureSuccess,
  actGetListOrgStructureFailed,
  actGetListOrgStructureReset,
  actGetDetailOrgStructure,
  actGetDetailOrgStructureSuccess,
  actGetDetailOrgStructureFailed,
  actGetDetailOrgStructureReset,
  actSyncOrgStructure,
  actSyncOrgStructureSuccess,
  actSyncOrgStructureFailed,
  actSyncOrgStructureReset,
}
