import * as Yup from 'yup'


export const validationSchema = (t) =>
  Yup.object().shape({
    jobDetails: Yup.array().of(
      Yup.object().shape({
        code: Yup.string().test(_, (value, context) => {
          if (!value) return true
          const currentDatas = context.from[1]?.value?.jobDetails || []

          const listDupicate = currentDatas?.filter(
            (item) => item.code === value,
          )

          if (listDupicate?.length > 1) {
            return context.createError({
              path: context.path,
              message: t('form.codeDupicate'),
            })
          }

          return true
        }),
      }),
    ),
  })
