import React, { useEffect, useLayoutEffect, useMemo } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import { EXPORT_REPORT_TYPE } from '~/modules/master/constants'
import { apiExportReport } from '~/modules/master/redux/apis/import-export'
import useReportFee from '~/modules/master/redux/hooks/useReportFee'
import { ROUTE } from '~/modules/master/routes/config'
import { convertNumberThousandComma } from '~/utils'

import { SHIFT_TYPE } from '../constants'
import FilterForm from './filter-form'
import { validationSchema } from './filter-form/schema'

import './style.css'

const breadcrumbs = [
  {
    title: ROUTE.REPORT.TITLE,
  },
  {
    route: ROUTE.REPORT.REPORT_FEE.LIST.PATH,
    title: ROUTE.REPORT.REPORT_FEE.LIST.TITLE,
  },
]
const now = new Date()
const ReportFee = () => {
  const { t } = useTranslation(['buseye'])

  // const history = useHistory()

  const DEFAULT_FILTERS = {
    date: now,
  }

  const { page, pageSize, sort, filters, setFilters } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listReportFee: { data, header, total, isLoading, feeSetting },
    },
    actions: { actGetReportFee, actGetReportFeeReset },
  } = useReportFee()

  useEffect(() => {
    refreshData()
  }, [filters])

  useLayoutEffect(() => {
    return () => actGetReportFeeReset()
  }, [])

  let headerAmountArr = {}
  let headerExArr = []
  let flexColumn = []
  if (header && header.length > 0) {
    headerAmountArr = header[header.length - 1]
      .filter((item) => item.key.endsWith('_Amount'))
      .reduce((a, b) => {
        a[b.key] = 2
        return a
      }, {})
    headerExArr = header[header.length - 1]
      .filter((item) => item.key.endsWith('_Expenses'))
      .map((item) => item.key)

    flexColumn = header[header.length - 1]
      .filter((item) => item.key.startsWith('2_'))
      .map((item) => item.key)
  }

  const filterByKey = (datas, key) => {
    return datas.filter((item) => item.key?.includes(key))
  }

  const genColumns = (colDatas, parenKey) => {
    if (colDatas.length === 0) return undefined
    const currentData = [...colDatas]
    const currentHeader = currentData.shift()
    if (!parenKey && currentHeader?.length > 0) {
      return currentHeader.map((item) => {
        return {
          field: item.key,
          headerName: item.title,
          width:
            filterByKey(currentData[currentData.length - 1], item.key).length *
            110,
          visible: 'always',
          align: 'center',
          columns: genColumns(currentData, item.key),
        }
      })
    }

    const filterSubCol = currentHeader.filter((item) =>
      item.key?.startsWith(parenKey),
    )

    return filterSubCol.length > 0
      ? filterSubCol.map((item) => {
        const columns = genColumns(currentData, item.key)
        return {
          field: item.key,
          headerName: item.title,
          width: 110,
          visible: 'always',
          align: 'center',
          columns,
          renderCell: (params) => {
            return (
              <Box>{convertNumberThousandComma(params.row[item.key])}</Box>
            )
          },
        }
      })
      : undefined
  }

  const colDynamic = genColumns(header || [])

  const columns = useMemo(() => {
    if (colDynamic) {
      return [
        {
          field: 'order',
          headerName: t('reportFee.order'),
          width: 40,
          sticky: 'left',
          visible: 'always',
          align: 'center',
          resizable: false
        },
        {
          field: 'department',
          headerName: t('reportFee.department'),
          width: 200,
          sticky: 'left',
          visible: 'always',
          align: 'center',
          resizable: false
        },
        {
          field: 'section',
          headerName: t('reportFee.section'),
          width: 200,
          sticky: 'left',
          visible: 'always',
          align: 'center',
          resizable: false
        },
        {
          field: 'unit',
          headerName: t('reportFee.unit'),
          width: 200,
          sticky: 'left',
          visible: 'always',
          align: 'center',
          resizable: false
        },
        ...colDynamic,
      ]
    }
    return []
  }, [colDynamic])

  const getFilterParams = () => {
    const { date } = filters
    return {
      month: moment(date).format('M'),
      year: moment(date).format('YYYY'),
    }
  }

  const refreshData = () => {
    const params = {
      type: SHIFT_TYPE.MANUAL,
      ...getFilterParams(),
    }
    actGetReportFee(params)
  }

  const beforeTopbar = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Guard code={FUNCTION_CODE.USER_EXPORT_REPORT_FEE_DISTRIBUTION}>
        <ImportExport
          name={t('reportFee.fileNameExport')}
          // buttonImportText="profileCustom.registerRoute.title"
          onExport={() => {
            const params = {
              ...getFilterParams(),
              type: EXPORT_REPORT_TYPE.FEE_DISTRIBUTION,
            }

            return apiExportReport(params)
          }}
          onRefresh={refreshData}
        />
      </Guard>
    </Box>
  )

  const getRowSpanMatrix = () => {
    let tmp = [],
      m0 = [],
      m1 = [],
      m2 = [],
      m = []
    const data1 = data
      .filter((item) => item.orgStructureCode1)
      .map((item) => item.orgStructureCode1)
    const data2 = data
      .filter((item) => item.orgStructureCode2)
      .map((item) => item.orgStructureCode2)
    data1.forEach((ele) => {
      if (tmp.indexOf(ele) < 0) {
        m1.push(data1.filter((d) => d === ele).length)
        tmp.push(ele)
      } else {
        m1.push(-1)
      }
    })

    tmp = []
    data2.forEach((ele) => {
      if (tmp.indexOf(ele) < 0) {
        m0.push(data2.filter((d) => d === ele).length)
        m2.push(data2.filter((d) => d === ele).length)
        tmp.push(ele)
      } else {
        m0.push(-1)
        m2.push(-1)
      }
    })

    const data0 = data
      .filter((item) => !item.orgStructureCode2)
      .map((item) => item.order)
    tmp = []
    data0.forEach((ele) => {
      if (tmp.indexOf(ele) < 0) {
        m0.push(data0.filter((d) => d === ele).length)
        tmp.push(ele)
      } else {
        m0.push(-1)
      }
    })

    data.forEach((val, ind) => {
      m.push([m0[ind], m2[ind], m1[ind]])
    })

    return m
  }

  const rowClass = []
  data.forEach((item) => {
    rowClass.push(item.key ? 'tr-' + item.key : '')
  })

  const rowSpanMatrix = getRowSpanMatrix()

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.reportFee')}
      loading={isLoading}
    >
      <HotKeys handlers={{}} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_REPORT_FEE_DISTRIBUTION}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            customWidth: {},
            gridSpace: 4,
            validationSchema: validationSchema(t),
          }}
        />
      </Guard>

      {header && isEmpty(header[0]) ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
          }}
        >
          {t('dataTable.noData')}
        </Box>
      ) : (
        <DataTable
          rows={data || []}
          pageSize={pageSize}
          page={page}
          columns={columns}
          total={total}
          sort={sort}
          beforeTopbar={beforeTopbar}
          disableColumnResize={true}
          variantTitle="h5"
          title={t('reportFee.tableTitle', {
            ca: feeSetting?.fixedNum,
            hc: feeSetting?.flexibledNum,
          })}
          hideFooter
          rowClass={rowClass}
          rowSpanMatrix={rowSpanMatrix}
          hideSetting
          mergeSetting={{
            rowSpanKey: 'key',
            colSpan: {
              ACC: { order: 4 },
              TotalAll: { order: 4, ...headerAmountArr },
              TotalAllIncludeVAT: { order: 3, ...headerAmountArr },
              AmountRouteMonth: { order: 4 },
              TotalFixed: { order: 4, ...headerAmountArr },
              VehicleFlex: { order: 2, section: 2 },
              TotalFlexibled: { order: 4, ...headerAmountArr },
              AverageMonth: { order: 4 },
            },
            removeKeys: {
              ACC: ['department', 'section', 'unit'],
              TotalAll: [
                'department',
                'section',
                'unit',
                ...headerExArr,
                ...flexColumn,
              ],
              TotalAllIncludeVAT: [
                'department',
                'section',
                ...headerExArr,
                ...flexColumn,
              ],
              AmountRouteMonth: [
                'department',
                'section',
                'unit',
                ...flexColumn,
              ],
              TotalFixed: [
                'department',
                'section',
                'unit',
                ...headerExArr,
                ...flexColumn,
              ],
              VehicleFlex: ['department', 'unit', ...flexColumn],
              TotalFlexibled: [
                'department',
                'section',
                'unit',
                ...headerExArr,
                ...flexColumn,
              ],
              AverageMonth: ['department', 'section', 'unit', ...flexColumn],
            },
          }}
        />
      )}
    </Page>
  )
}

export default ReportFee
