import * as Yup from 'yup'

import { TEXTFIELD_REQUIRED_LENGTH, MODAL_MODE } from '~/common/constants'
import { phoneSchema, passwordSchema } from '~/common/schemas'

export const validationSchema = (t, mode) =>
  Yup.object().shape({
    code: Yup.string().required(t('form.required')),
    fullName: Yup.string().required(t('form.required')),
    username: Yup.string().required(t('form.required')),
    status: Yup.number().nullable().required(t('form.required')),
    ...(mode === MODAL_MODE.CREATE
      ? {
          password: passwordSchema(t).required(t('form.required')),
        }
      : {}),
    email: Yup.string()
      .required(t('form.required'))
      .email(t('form.validEmail'))
      .min(
        TEXTFIELD_REQUIRED_LENGTH.EMAIL.MIN,
        t('form.minLength', {
          min: TEXTFIELD_REQUIRED_LENGTH.EMAIL.MIN,
        }),
      ),
    phone: phoneSchema(t),
    dateOfBirth: Yup.date().nullable().max(new Date(), t('date.maxToday')),
    userRoleSettings: Yup.number().nullable().required(t('form.required')),
  })
