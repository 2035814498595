import React from 'react'

import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'

import ajinomoto_logo from '~/assets/images/AGB-logo-2017-WhiteWindow.jpg'
import { useSidebar } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import Icon from '~/components/Icon'
import { appRoutesObj } from '~/routes'
import { getCurrentModule } from '~/utils/menu'

import MainMenu from './MainMenu'

export default function Sidebar() {
  const { pathname } = useLocation()
  const theme = useTheme()
  const currentModule = getCurrentModule(pathname)
  const { isMinimal, setIsMinimal, setIsOpenModuleList } = useSidebar()
  const { canAccess } = useApp()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: theme.palette.sidebar.bg,
        zIndex: 1000,
        transition: 'all .2s ease',
        width: isMinimal ? 64 : 250,
        boxShadow: !currentModule
          ? '0px 5px 30px 2px rgba(0, 0, 0, 0.10)'
          : 'none',
      }}
    >
      <Typography
        noWrap
        variant="h3"
        sx={{
          color: '#fff',
          pl: 1,
          display: 'flex',
          alignItems: 'center',
          background: '#ffffff',
        }}
      >
        <img
          src={ajinomoto_logo}
          alt=""
          width={isMinimal ? 60 : 90}
          height={isMinimal ? 60 : 90}
          style={{margin: 'auto'}}
        />
      </Typography>
      <IconButton
        sx={{
          position: 'absolute',
          p: 0,
          top: 70,
          right: -10,
          zIndex: 3,
          '>*': { pointerEvents: 'none' },
        }}
        onClick={() => {
          setIsMinimal(!isMinimal)
          setIsOpenModuleList(false)
        }}
      >
        <Icon
          name="drawer"
          size={24}
          sx={isMinimal ? {} : { transform: 'rotate(-180deg)' }}
        />
      </IconButton>
      {/* <ModuleList />
       */}
      {/* {currentModule && (
        <MainMenu
          routes={appRoutesObj[currentModule]?.filter(
            (item) => item?.isInSidebar && canAccess(item?.code),
          )}
          currentModule={currentModule}
        />
      )} */}

      <MainMenu
        routes={Object.keys(appRoutesObj)
          .reduce((acc, current) => {
            return [...acc, ...appRoutesObj[current]]
          }, [])
          ?.filter((item) => item?.isInSidebar && canAccess(item?.code))}
      />
    </Box>
  )
}
