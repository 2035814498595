const style = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxSizing: 'border-box',
    background: theme.palette.bg.main,
    height: '100%',
    overflow: 'auto',
  },
})

export default style
