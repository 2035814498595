import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListReportBr11 = (params) => {
  const uri = `/reports/bus-roster-calendar`

  return api.get(uri, params)
}

export const apiGetReportBr11Summary = (params) => {
  const uri = `/reports/bus-roster-calendar/summary`

  return api.get(uri, params)
}

/**
 * @returns {Promise}
 */
export const apiSyncReportBr11 = (body = {}) => {
  const uri = `/sync/report-roster-calendar`

  return api.post(uri, body)
}
