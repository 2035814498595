import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { TYPE_OT_AND_BT_OPTIONS } from '~/modules/master/constants'
import { apiGetListOrgStructureList } from '~/modules/master/redux/apis/org-structure'

import { LGA_DISTRIBUTE_VEHICLE_OPTIONS } from '../../../attendance/constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
    <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field.TextField
              name="employee"
              placeholder={t('reportOt.filter.employee')}
              inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX }}
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon name="search" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              placeholder={t('reportOt.filter.orgStructure')}
              name="orgStructure"
              asyncRequest={(s) =>
                apiGetListOrgStructureList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => `[${option.code}] ${option.name}`}
              multiple
            />
          </Grid>

          <Grid item xs={4}>
            <Field.Autocomplete
              placeholder={t('reportOt.filter.purpose')}
              name="type"
              options={TYPE_OT_AND_BT_OPTIONS}
              getOptionLabel={(opt) => t(opt?.text) || ''}
              getOptionValue={(opt) => opt?.id}
              labelWidth={100}
              isOptionEqualToValue={(opt, val) => opt?.id === val}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.DateRangePicker
              name="rangeDate"
              placeholder={t('reportOt.filter.rangeDate')}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              placeholder={t('attendance.overtime.lgaVehicle')}
              name="lgaVehicle"
              options={LGA_DISTRIBUTE_VEHICLE_OPTIONS}
              getOptionLabel={(opt) => t(opt?.text) || ''}
              getOptionValue={(opt) => opt?.id}
              isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterForm
