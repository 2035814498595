import { api } from '~/services/api'

/**
 * @returns {Promise}
 */
export const apiSyncRosterCalendar = ({ body = {}, query = {} }) => {
  const params = new URLSearchParams(query)
  const uri = `/sync/roster-calendar?${params.toString()}`
  return api.post(uri, body)
}
