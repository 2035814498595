const style = () => ({
  tableContainer: {
    '& .MuiTableCell-body': {
      padding: 0,
      '& td': {},
    },
    '& thead > tr:nth-child(1) > th:nth-child(1)': {
      // background: '#0761AD80',
    },
  },
})
export default style
