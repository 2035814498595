import React, { useState, useEffect } from 'react'

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import { findIndex, isArray, isUndefined } from 'lodash'
import PropTypes from 'prop-types'

const Tabs = ({
  list,
  children,
  sx,
  onChange,
  value: externalValue,
  divider,
  color,
}) => {
  const [value, setValue] = useState(0)
  const theme = useTheme()

  useEffect(() => {
    if (isUndefined(externalValue)) return

    if (!list?.[0]?.hasOwnProperty('value')) {
      setValue(externalValue)
      return
    }

    if (list?.every((item) => item?.value !== externalValue)) {
      setValue(0)
      onChange(list?.[0]?.value)
      return
    }

    let idx = findIndex(list, (item) => item?.value === externalValue)
    if (idx === -1) idx = 0
    setValue(idx)
  }, [externalValue, list])

  const getLabel = (item) => {
    const label = item?.label ?? item
    const isRequired = item?.required

    // return (
    //   <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
    //     {label}
    //     {isRequired && (
    //       <Typography color="error" component="span" ml="3px">
    //         *
    //       </Typography>
    //     )}
    //   </Box>
    // )

    // To work around for bold label text on selected (CSS)
    const Label = ({ style = {} }) => (
      <Box component="span" sx={{ whiteSpace: 'nowrap', ...style }}>
        {label}
        {isRequired && (
          <Typography color="error" component="span" ml="3px">
            *
          </Typography>
        )}
      </Box>
    )
    return (
      <Box sx={{ position: 'relative' }}>
        <Label style={{ fontWeight: 700, opacity: 0, pointerEvents: 'none' }} />
        <Label
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      </Box>
    )
  }

  const renderContent = () => {
    if (isArray(children)) return children?.[value] ?? null

    return children
  }

  return (
    <>
      <Box
        sx={{
          mb: children ? 2 : 0,
          ...(divider
            ? {
                borderBottom: 2,
                borderColor: 'divider',
              }
            : {}),
        }}
      >
        <MuiTabs
          value={value ?? 0}
          onChange={(_, newValue) => {
            setValue(newValue)
            onChange(
              list?.[newValue]?.hasOwnProperty('value')
                ? list?.[newValue]?.value
                : newValue,
            )
          }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          selectionFollowsFocus
          sx={{
            minHeight: 32,
            ...sx,
          }}
        >
          {list.map((item, index) => {
            return (
              <Tab
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  minHeight: 32,
                  p: theme.spacing(1),
                  minWidth: 0,
                  maxWidth: 'none',
                  '&.Mui-selected': {
                    fontWeight: 700,
                    color,
                  },
                }}
                key={`Tab-${index}`}
                label={getLabel(item)}
                wrapped
                {...(item.error
                  ? {
                      icon: (
                        <WarningAmberRoundedIcon
                          fontSize="small"
                          sx={{
                            mr: '4px !important',
                            color: theme.palette.error.main,
                          }}
                        />
                      ),
                      iconPosition: 'start',
                      sx: {
                        minHeight: '48px',
                      },
                    }
                  : {})}
              />
            )
          })}
        </MuiTabs>
      </Box>
      {renderContent()}
    </>
  )
}

Tabs.defaultProps = {
  list: [],
  children: null,
  sx: {},
  onChange: () => {},
  divider: false,
}

Tabs.propTypes = {
  children: PropTypes.node,
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.shape(), PropTypes.string]),
  ).isRequired,
  sx: PropTypes.shape(),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  divider: PropTypes.bool,
}

export default Tabs
