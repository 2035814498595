import React from 'react'

import { useTranslation } from 'react-i18next'

import { Field } from '~/components/Formik'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <Field.DatePicker
      name="date"
      placeholder={t('settingFee.filter.date')}
      views={['month', 'year']}
      inputFormat="MM/yyyy"
      required
    />
  )
}

export default FilterForm
