import { api } from '~/services/api'

/**
 * @param { year: string, month: string, params: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetFeeSettingList = (params) => {
  const uri = `/fee-setting/list`

  return api.get(uri, params)
}

/**
 * @param { year: string, month: string, params: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetFeeSetting = ({ year, month, ...params }) => {
  const uri = `/fee-setting/${year}/${month}`

  return api.get(uri, params)
}

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpsertFeeSetting = (body = {}) => {
  const uri = `/fee-setting`

  return api.post(uri, body)
}

/**
 * @param { year: string, month: string, params: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetFeeShiftRouteUnitDetail = (params) => {
  const uri = `/fee-setting/getFeeShiftRouteUnitDetail`

  return api.get(uri, params)
}

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpsertFeeShiftRouteUnitDetail = (body = {}) => {
  const uri = `/fee-setting/upsertFeeShiftRouteUnitDetail`

  return api.post(uri, body)
}

/**
 * @param { year: string, month: string, params: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetFeeSettingRoute = (params) => {
  const uri = `/fee-setting/route/list`

  return api.get(uri, params)
}

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpsertFeeSettingRoute = (body = {}) => {
  const uri = `/fee-setting/route`

  return api.patch(uri, body)
}
