import { combineReducers } from 'redux'

import authReducers from '~/modules/auth/redux/reducers'
import configurationReducers from '~/modules/configuration/redux/reducers'
import masterReducers from '~/modules/master/redux/reducers'
import sharedReducers from '~/modules/shared/redux/reducers'

export default combineReducers({
  ...authReducers,
  shared: sharedReducers,
  configuration: configurationReducers,
  master: masterReducers,
})
