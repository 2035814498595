// Action: Get data
export const MASTER_GET_SETTING = 'MASTER_GET_SETTING'
export const MASTER_GET_SETTING_SUCCESS = 'MASTER_GET_SETTING_SUCCESS'
export const MASTER_GET_SETTING_FAILED = 'MASTER_GET_SETTING_FAILED'
export const MASTER_GET_SETTING_RESET = 'MASTER_GET_SETTING_RESET'

// Action: update
export const MASTER_UPDATE_SETTING = 'MASTER_UPDATE_SETTING'
export const MASTER_UPDATE_SETTING_SUCCESS = 'MASTER_UPDATE_SETTING_SUCCESS'
export const MASTER_UPDATE_SETTING_FAILED = 'MASTER_UPDATE_SETTING_FAILED'

/** get data
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetSetting(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_SETTING,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetSettingSuccess(payload) {
  return {
    type: MASTER_GET_SETTING_SUCCESS,
    payload: payload,
  }
}

export function actGetSettingFailed() {
  return {
    type: MASTER_GET_SETTING_FAILED,
  }
}

export function actGetSettingReset() {
  return {
    type: MASTER_GET_SETTING_RESET,
  }
}

/** update
 * @param {
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateSetting(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_SETTING,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateSettingSuccess(payload) {
  return {
    type: MASTER_UPDATE_SETTING_SUCCESS,
    payload: payload,
  }
}

export function actUpdateSettingFailed() {
  return {
    type: MASTER_UPDATE_SETTING_FAILED,
  }
}

export default {
  actGetSetting,
  actGetSettingSuccess,
  actGetSettingFailed,
  actGetSettingReset,
  actUpdateSetting,
  actUpdateSettingSuccess,
  actUpdateSettingFailed,
}
