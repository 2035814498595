import Icons from '../features/icons'
import RedirectPage from '../features/redirect'
import { ROUTE } from './config'

const routes = [
  {
    path: ROUTE.REDIRECT.PATH,
    component: RedirectPage,
  },
  {
    path: ROUTE.ICONS.PATH,
    component: Icons,
  },
]

export default routes
