export const TAB_VALUE = {
  FIXED_D: 'D',
  FIXED_OTHER: '1_2_3',
  FLEXIBLE_MANUAL: 'MANUAL',
}

export const tabList = (t) => {
  return [
    {
      label: t('reportFee.fixedD'),
      value: TAB_VALUE.FIXED_D,
    },
    {
      label: t('reportFee.fixedOther'),
      value: TAB_VALUE.FIXED_OTHER,
    },
    {
      label: t('reportFee.flexibleManual'),
      value: TAB_VALUE.FLEXIBLE_MANUAL,
    },
  ]
}

export const SHIFT_TYPE = {
  AUTO: 0,
  MANUAL: 1,
}
