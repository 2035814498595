import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_ORG_STRUCTURE,
  MASTER_GET_DETAIL_ORG_STRUCTURE_FAILED,
  MASTER_GET_DETAIL_ORG_STRUCTURE_RESET,
  MASTER_GET_DETAIL_ORG_STRUCTURE_SUCCESS,
  MASTER_GET_LIST_ORG_STRUCTURE,
  MASTER_GET_LIST_ORG_STRUCTURE_FAILED,
  MASTER_GET_LIST_ORG_STRUCTURE_RESET,
  MASTER_GET_LIST_ORG_STRUCTURE_SUCCESS,
  MASTER_SYNC_ORG_STRUCTURE,
  MASTER_SYNC_ORG_STRUCTURE_FAILED,
  MASTER_SYNC_ORG_STRUCTURE_RESET,
  MASTER_SYNC_ORG_STRUCTURE_SUCCESS,
} from '../actions/org-structure'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listOrgStructure(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_ORG_STRUCTURE:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_ORG_STRUCTURE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_ORG_STRUCTURE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_ORG_STRUCTURE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailOrgStructure(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_ORG_STRUCTURE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_ORG_STRUCTURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_ORG_STRUCTURE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_ORG_STRUCTURE_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncOrgStructure(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_ORG_STRUCTURE:
      return { ...state, isLoading: true }
    case MASTER_SYNC_ORG_STRUCTURE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_ORG_STRUCTURE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_ORG_STRUCTURE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listOrgStructure,
  detailOrgStructure,
  syncOrgStructure,
})
