export const TAB_VALUE = {
  all: '',
  active: 1,
  inActive: 0,
  azure: 2,
}

export const USER_TYPE = {
  SYSTEM: 0,
  AZURE: 1
}

export const tabList = (t, totals) => {
  const { all, totalActive, totalInActive, totalAzure } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
    {
      label: `${t('userManagement.azureUser')} (${totalAzure})`,
      value: TAB_VALUE.azure,
    },
    {
      label: `${t('common.active')} (${totalActive})`,
      value: TAB_VALUE.active,
    },
    {
      label: `${t('common.inActive')} (${totalInActive})`,

      value: TAB_VALUE.inActive,
    },
  ]
}
