import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import actionOvertime from '~/modules/master/redux/actions/overtime'

const useOvertime = () => {
  const data = useSelector((state) => get(state, 'master.overtime'))

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(actionOvertime, dispatch),
    [dispatch],
  )
  return {
    actions,
    data,
  }
}

export default useOvertime
