import { all } from 'redux-saga/effects'

import watchGetDetail from './get-detail'
import watchGetList from './get-list'
import watchSync from './sync'
import watchUpdate from './update'

export default function* sagas() {
  yield all([watchGetList(), watchSync(), watchGetDetail(), watchUpdate()])
}
