import { combineReducers } from 'redux'

import {
  MASTER_SEND_EMAIL,
  MASTER_SEND_EMAIL_FAILED,
  MASTER_SEND_EMAIL_SUCCESS,
} from '../actions/email'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function sendEmail(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_SEND_EMAIL:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_SEND_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  sendEmail,
})
