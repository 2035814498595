import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiCreateVehicleCategories = ({ body }) => {
  const uri = `/vehicle-categories`

  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListVehicleCategories = (params) => {
  const uri = `/vehicle-categories`

  return api.get(uri, params)
}

export const apiGetListVehicleCategoriesList = (params) => {
  const uri = `/vehicle-categories/list`

  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailVehicleCategories = (id) => {
  const uri = `/vehicle-categories/${id}`

  return api.get(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateVehicleCategories = ({ body }) => {
  const uri = `/vehicle-categories/${body?.id}`

  return api.patch(uri, body)
}

/**
 * @param {codes: sring[]} params Params will be sent to server
 * @returns {Promise}
 */
export const apiDeleteVehicles = (params) => {
  const uri = `/vehicle-categories`

  return api.delete(uri, params)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveVehicle = (id) => {
  const uri = `/vehicle-categories/${id}/active`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveVehicles = (body) => {
  const uri = `/vehicle-categories/active/multiple`

  return api.post(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveVehicle = (id) => {
  const uri = `/vehicle-categories/${id}/inactive`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveVehicles = (body) => {
  const uri = `/vehicle-categories/inactive/multiple`

  return api.post(uri, body)
}
