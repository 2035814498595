/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import Tabs from '~/components/Tabs'

const TotaList = ({ data, total }) => {
  const { t } = useTranslation(['buseye'])

  // const history = useHistory()

  const { page, pageSize, sort, filters, tab, setPage, setPageSize, setSort } =
    useQueryState({
      filters: {},
    })

  const getColum = (data) => {
    const totalRow = data.find((item) => item.key)
    if (totalRow && !isEmpty(totalRow)) {
      const columnData = Object.keys(totalRow).reduce((acc, current) => {
        if (current === 'key' || current === 'total') return acc
        const [key, subKey] = current.split('_')
        if (!acc[key]) {
          acc[key] = {
            key,
            subColumns: [],
          }
        }
        acc[key].subColumns.push({ key: current, title: subKey })

        return acc
      }, {})

      return Object.values(columnData)
    }

    return []
  }

  const getRowSpanMatrix = () => {
    let tmp = [],
      m1 = [],
      m2 = [],
      m = []
    const data1 = data.map((item) => item.orgStructureCode1)
    const data2 = data.map((item) => item.orgStructureCode2)
    data1.forEach((ele) => {
      if (tmp.indexOf(ele) < 0) {
        m1.push(data1.filter((d) => d === ele).length)
        tmp.push(ele)
      } else {
        m1.push(-1)
      }
    })

    tmp = []
    data2.forEach((ele) => {
      if (tmp.indexOf(ele) < 0) {
        m2.push(data2.filter((d) => d === ele).length)
        tmp.push(ele)
      } else {
        m2.push(-1)
      }
    })

    data.forEach((val, ind) => {
      m.push([m2[ind], m1[ind]])
    })

    return m
  }

  const rowSpanMatrix = getRowSpanMatrix()

  const columns = useMemo(() => {
    const newColumns = getColum(data).reduce(
      (acc, current) => {
        const column = {
          field: current.key,
          headerName: t(`reportBr11.${current.key}`),
          align: 'center',
          visible: 'always',
          noCache: true,
        }
        if (current.subColumns.length > 0) {
          column.columns = current.subColumns.map((subColumn) => ({
            field: subColumn.key,
            headerName: subColumn.title,
            width: 80,
            align: 'center',
            visible: 'always',
            noCache: true,
          }))
        }
        column.width = column.columns.length * 80
        acc.push(column)
        return acc
      },
      [
        {
          field: 'orgStructureCode2',
          headerName: 'Phòng ban',
          width: '250px',
          renderCell: (params) => {
            const { orgStructureName2, key } = params.row
            return key ? (
              <Box
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {key}
              </Box>
            ) : (
              orgStructureName2
            )
          },
        },
        {
          field: 'orgStructureCode1',
          headerName: 'Bộ Phận',
          width: '250px',
          renderCell: (params) => {
            const { orgStructureName1, key } = params.row
            return key ? (
              <Box
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {key}
              </Box>
            ) : (
              orgStructureName1
            )
          },
        },
        {
          field: 'orgStructureCode',
          headerName: 'Đơn vị',
          width: '80px',
          renderCell: (params) => {
            const { orgStructureCode, key } = params.row
            return key ? (
              <Box
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {key}
              </Box>
            ) : (
              orgStructureCode
            )
          },
        },
        {
          field: 'total',
          headerName: 'Tổng',
          width: '80px',
          renderCell: (params) => {
            const { total, key } = params.row
            return key ? (
              <Box
                sx={{
                  fontWeight: 'bold',
                  // display: 'flex',
                  // justifyContent: 'center',
                }}
              >
                {total}
              </Box>
            ) : (
              total
            )
          },
        },
      ],
    )
    return newColumns
  }, [filters, data])

  const tabElement = (
    <Tabs
      list={[
        {
          label: t('reportBr11.catShiftInfo', { number: total }),
          value: '',
        },
      ]}
      value={tab}
    />
  )

  return (
    <DataTable
      rows={data || []}
      pageSize={pageSize}
      page={page}
      columns={columns}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onSortChange={setSort}
      total={total}
      sort={sort}
      hideSetting
      tabs={tabElement}
      uniqKey={'2'}
      hideFooter
      rowSpanMatrix={rowSpanMatrix}
      mergeSetting={{
        rowSpanKey: 'key',
        colSpan: {ACC: { orgStructureCode2: 3 }},
        removeKeys: {ACC: ['orgStructureCode1', 'orgStructureCode']},
      }}
    />
  )
}

export default TotaList
