export const ROUTE = {
  REDIRECT: {
    PATH: '/redirect',
    TITLE: '',
  },
  ICONS: {
    PATH: '/icons',
    TITLE: '',
  },
}
