import { all } from 'redux-saga/effects'

import watchChangeStatus from './change-status'
import watchCreate from './create'
import watchDelete from './delete'
import watchGetDetail from './get-detail'
import watchGetList from './get-list'
import watchUpdate from './update'

export default function* sagas() {
  yield all([
    watchCreate(),
    watchGetList(),
    watchGetDetail(),
    watchUpdate(),
    watchChangeStatus(),
    watchDelete(),
  ])
}
