import { useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'

import {
  NOTIFICATION_TYPE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Loading from '~/components/Loading'
import PopupConfirm from '~/components/PopupConfirm'
import {
  apiDeleteReportAll,
  apiUpdateReportAll,
} from '~/modules/master/redux/apis/report-all'
import { convertUtcDateToLocalTz } from '~/utils'
import addNotification from '~/utils/toast'

import { validationSchema } from './schema'

const UpdateVehicle = ({
  t,
  open,
  data = {},
  onClose = () => {},
  refreshData,
}) => {
  const initialValues = useMemo(() => {
    if (data) {
      return {
        ...data,
        workDate: convertUtcDateToLocalTz(data?.workDate, 'yyyy-MM-dd'),
      }
    }
    return {}
  }, [data])

  const handleClose = () => {
    onClose()
  }

  const handleUpdateSite = async (val = {}, setFieldValue) => {
    const body = {
      routeCode: val.routeCode,
      shiftCode: val.shiftCode,
      workDate: val.workDate,
      vehicleTypeCode: val.vehicleTypeCode,
      value: val.value || 0,
      note: val.note,
    }

    setFieldValue('isLoading', true)
    const res = await apiUpdateReportAll(body)
    if (res.statusCode === 200) {
      addNotification(
        'reportAll.notification.updateVehicleSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
      setFieldValue('isLoading', true)
      refreshData()
    } else {
      addNotification(res.message, NOTIFICATION_TYPE.ERROR)
      setFieldValue('isLoading', false)
    }
  }

  const handleDelete = async (setFieldValue) => {
    if (!data) return
    setFieldValue('isLoading', true)
    setFieldValue('isDelete', false)

    const res = await apiDeleteReportAll({
      routeCode: data.routeCode,
      shiftCode: data.shiftCode,
      workDate: initialValues.workDate,
      vehicleTypeCode: data.vehicleTypeCode,
    })

    if (res.statusCode === 200) {
      addNotification(
        'reportAll.notification.deleteVehicleSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
      setFieldValue('isLoading', false)
      refreshData()
    } else {
      addNotification(res.message, NOTIFICATION_TYPE.ERROR)
      setFieldValue('isLoading', false)
    }
  }

  return (
    <Dialog
      open={open}
      title={t('reportAll.updateVehicle.title')}
      onCancel={handleClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={(val, { setFieldValue }) =>
          handleUpdateSite(val, setFieldValue)
        }
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ resetForm, values, setFieldValue }) => (
          <Form>
            <Typography
              variant="body2"
              sx={{ color: '#222222', fontWeight: 'bold' }}
            >
              {t('reportAll.updateVehicle.vehicleInfo')}
            </Typography>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB', mt: 1 }}
              dateSyn
            >
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.vehicle')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.routeCode}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.catShift')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.shiftCode}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.dateUpdate')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={initialValues?.workDate}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.fixedVehicle')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.fixedVehicle}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.vehicleType')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.totalSeat + ' (chỗ)'}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('reportAll.updateVehicle.guest')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.guest}
                />
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              sx={{ color: '#222222', mt: 1, fontWeight: 'bold' }}
            >
              {t('reportAll.updateVehicle.updateInfo')}
            </Typography>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB', mt: 1 }}
              dateSyn
            >
              <Grid item lg={5.5} xs={12}>
                <Field.TextField
                  name="value"
                  placeholder={t('reportAll.updateVehicle.vehicleQuantity')}
                  label={t('reportAll.updateVehicle.vehicleQuantity')}
                  type="number"
                  allow={TEXTFIELD_ALLOW.NUMERIC}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field.TextField
                  name="note"
                  placeholder={t('reportAll.updateVehicle.note')}
                  label={t('reportAll.updateVehicle.note')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{ marginRight: '10px' }}
                    onClick={() => setFieldValue('isDelete', true)}
                  >
                    {t('actionBar.delete')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t('actionBar.save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <PopupConfirm
              t={t}
              title={t('reportAll.modalDelete.title')}
              content={t('reportAll.modalDelete.content')}
              open={values.isDelete}
              onConfirm={() => {
                handleDelete(setFieldValue)
              }}
              onCancel={() => {
                setFieldValue('isDelete', false)
              }}
            />
            <Loading open={values?.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateVehicle
