import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import {
  CHANGE_STATUS_ROLE,
  actChangeStatusRoleSuccess,
  actChangeStatusRoleFailed,
} from '~/modules/configuration/redux/actions/role-list'
import { api } from '~/services/api'
import addNotification from '~/utils/toast'

const confirmRoleAssignApi = (params) => {
  const uri = `/users/user-role-settings/${params}/unlock`
  return api.put(uri)
}

const rejectRoleAssignApi = (params) => {
  const uri = `/users/user-role-settings/${params}/lock`
  return api.put(uri)
}

const confirmRoleAssignApis = (params) => {
  const uri = `/users/user-role-settings/unlock`
  return api.put(uri, params)
}

const rejectRoleAssignApis = (params) => {
  const uri = `/users/user-role-settings/lock/multiple`
  return api.put(uri, params)
}

const getApi = ({ params, status }) => {
  if (params?.ids) {
    return status ? rejectRoleAssignApis : confirmRoleAssignApis
  } else {
    return status ? rejectRoleAssignApi : confirmRoleAssignApi
  }
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doChangeStatus(action) {
  try {
    const response = yield call(
      getApi(action?.payload),
      action?.payload?.params,
    )

    if (response?.statusCode === 200) {
      yield put(actChangeStatusRoleSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification('toast.changeStatusSuccess', NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification('toast.changeStatusError', NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actChangeStatusRoleFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchChangeStatus() {
  yield takeLatest(CHANGE_STATUS_ROLE, doChangeStatus)
}
