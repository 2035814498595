import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actChangeStatusVehicleCategoriesFailed,
  actChangeStatusVehicleCategoriesSuccess,
  MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES,
} from '../../actions/vehicle-categories'
import {
  apiActiveVehicle,
  apiActiveVehicles,
  apiInActiveVehicle,
  apiInActiveVehicles,
} from '../../apis/vehicle-categories'

const getApi = ({ params, isActive }) => {
  if (params?.codes) {
    return isActive ? apiInActiveVehicles : apiActiveVehicles
  } else {
    return isActive ? apiInActiveVehicle : apiActiveVehicle
  }
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doChangeStatus(action) {
  try {
    const response = yield call(
      getApi(action?.payload),
      action?.payload?.params,
    )

    if (response?.statusCode === 200) {
      yield put(
        actChangeStatusVehicleCategoriesSuccess({ data: response.data }),
      )
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification('toast.changeStatusSuccess', NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification('toast.changeStatusError', NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actChangeStatusVehicleCategoriesFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchChangeStatus() {
  yield takeLatest(MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES, doChangeStatus)
}
