import { FUNCTION_CODE } from '~/common/constants/functionCode'

import ListAttendance from '../features/attendance/list'
import ListCatShift from '../features/cat-shift/list'
import ListFactory from '../features/factory/list'
import ListOrgStructure from '../features/org-structure/list'
import ListPickupPoint from '../features/pickup-point/list'
import ListPosition from '../features/position/list'
import ListProfileCustom from '../features/profile-custom/list'
import ReportAll from '../features/report-all/list'
import ReportBr11 from '../features/report-br11/list'
import ReportFee from '../features/report-fee/list'
import ReportOt from '../features/report-ot/list'
import DetailRoute from '../features/route/detail'
import RouteForm from '../features/route/form'
import ListRoute from '../features/route/list'
import SettingFee from '../features/setting-fee/list'
import SettingSync from '../features/setting-sync'
import ListVehicleCategories from '../features/vehicle-categories/list'
import { ROUTE } from './config'

const routes = [
  {
    name: ROUTE.EMPLOYEE.TITLE,
    icon: 'home',
    isInSidebar: true,
    subMenu: [
      {
        name: ROUTE.EMPLOYEE.PROFILE_CUSTOM.LIST.TITLE,
        path: ROUTE.EMPLOYEE.PROFILE_CUSTOM.LIST.PATH,
        component: ListProfileCustom,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_PROFILE_CUSTOM,
      },
      {
        name: ROUTE.EMPLOYEE.CAT_SHIFT.LIST.TITLE,
        path: ROUTE.EMPLOYEE.CAT_SHIFT.LIST.PATH,
        component: ListCatShift,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_CATEGORY_SHIFT,
      },
      {
        name: ROUTE.EMPLOYEE.ORG_STRUCTURE.LIST.TITLE,
        path: ROUTE.EMPLOYEE.ORG_STRUCTURE.LIST.PATH,
        component: ListOrgStructure,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_ORGANIZATION_STRUCTURE,
      },
      {
        name: ROUTE.EMPLOYEE.FACTORY.LIST.TITLE,
        path: ROUTE.EMPLOYEE.FACTORY.LIST.PATH,
        component: ListFactory,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_FACTORY,
      },
      {
        name: ROUTE.EMPLOYEE.POSITION.LIST.TITLE,
        path: ROUTE.EMPLOYEE.POSITION.LIST.PATH,
        component: ListPosition,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_POSITION,
      },
    ],
  },
  {
    name: ROUTE.VEHICLE_CATEGORIES.LIST.TITLE,
    path: ROUTE.VEHICLE_CATEGORIES.LIST.PATH,
    component: ListVehicleCategories,
    isInSidebar: true,
    code: FUNCTION_CODE.USER_LIST_VEHICLE_CATEGORY,
  },
  {
    name: ROUTE.ATTENDANCE.LIST.TITLE,
    path: ROUTE.ATTENDANCE.LIST.PATH,
    icon: 'barChart',
    component: ListAttendance,
    isInSidebar: true,
    codes: [
      FUNCTION_CODE.USER_LIST_BUSSINESS_TRAVEL,
      FUNCTION_CODE.USER_LIST_OVERTIME_PLAN,
      FUNCTION_CODE.USER_LIST_ROSTER_CALENDAR,
    ],
  },
  {
    name: ROUTE.ROUTE.LIST.TITLE,
    path: ROUTE.ROUTE.LIST.PATH,
    icon: 'tracking',
    component: ListRoute,
    isInSidebar: true,
    code: FUNCTION_CODE.USER_LIST_ROUTE,
    subMenu: [
      {
        name: ROUTE.ROUTE.CREATE.TITLE,
        path: ROUTE.ROUTE.CREATE.PATH,
        component: RouteForm,
        isInSidebar: false,
        code: FUNCTION_CODE.USER_CREATE_ROUTE,
      },
      {
        name: ROUTE.ROUTE.UPDATE.TITLE,
        path: ROUTE.ROUTE.UPDATE.PATH,
        component: RouteForm,
        isInSidebar: false,
        code: FUNCTION_CODE.USER_UPDATE_ROUTE,
      },
      {
        name: ROUTE.ROUTE.DETAIL.TITLE,
        path: ROUTE.ROUTE.DETAIL.PATH,
        component: DetailRoute,
        isInSidebar: false,
        code: FUNCTION_CODE.USER_LIST_ROUTE,
      },
    ],
  },
  {
    name: ROUTE.PICKUP_POINT.LIST.TITLE,
    path: ROUTE.PICKUP_POINT.LIST.PATH,
    icon: 'map',
    component: ListPickupPoint,
    isInSidebar: true,
    code: FUNCTION_CODE.USER_LIST_PICKUP_POINT,
  },
  {
    name: ROUTE.REPORT.TITLE,
    icon: 'result',
    isInSidebar: true,
    subMenu: [
      {
        name: ROUTE.REPORT.REPORT_BR11.LIST.TITLE,
        path: ROUTE.REPORT.REPORT_BR11.LIST.PATH,
        icon: 'map',
        component: ReportBr11,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_REPORT_ROSTER_CALENDAR,
      },
      {
        name: ROUTE.REPORT.REPORT_ALL.LIST.TITLE,
        path: ROUTE.REPORT.REPORT_ALL.LIST.PATH,
        icon: 'map',
        component: ReportAll,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_SEARCH_REPORT_OVERALL,
      },
      {
        name: ROUTE.REPORT.REPORT_OT.LIST.TITLE,
        path: ROUTE.REPORT.REPORT_OT.LIST.PATH,
        icon: 'map',
        component: ReportOt,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL,
      },
      {
        name: ROUTE.REPORT.REPORT_FEE.LIST.TITLE,
        path: ROUTE.REPORT.REPORT_FEE.LIST.PATH,
        icon: 'map',
        component: ReportFee,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_LIST_REPORT_FEE_DISTRIBUTION,
      },
    ],
  },
  {
    name: ROUTE.SETTING.TITLE,
    icon: 'setting',
    isInSidebar: true,
    codes: [
      FUNCTION_CODE.USER_UPDATE_CRON_SETTING,
      FUNCTION_CODE.USER_UPDATE_FEE_SETTING,
      FUNCTION_CODE.USER_DETAIL_FEE_SETTING,
    ],
    subMenu: [
      {
        name: ROUTE.SETTING.SYNC.TITLE,
        path: ROUTE.SETTING.SYNC.PATH,
        component: SettingSync,
        isInSidebar: true,
        code: FUNCTION_CODE.USER_UPDATE_CRON_SETTING,
      },
      {
        name: ROUTE.SETTING.FEE.TITLE,
        path: ROUTE.SETTING.FEE.PATH,
        component: SettingFee,
        isInSidebar: true,
        codes: [
          FUNCTION_CODE.USER_UPDATE_FEE_SETTING,
          FUNCTION_CODE.USER_DETAIL_FEE_SETTING,
        ],
      },
    ],
  },
]

export default routes
