import { combineReducers } from 'redux'

import {
  MASTER_GET_REPORT_FEE,
  MASTER_GET_REPORT_FEE_FAILED,
  MASTER_GET_REPORT_FEE_RESET,
  MASTER_GET_REPORT_FEE_SUCCESS,
} from '../actions/report-fee'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportFee(
  state = {
    isLoading: false,
    data: [],
    feeSetting: {},
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_REPORT_FEE:
      return { ...state, isLoading: true }
    case MASTER_GET_REPORT_FEE_SUCCESS:
      const { data, header, feeSetting } = action.payload
      return {
        ...state,
        isLoading: false,
        header,
        feeSetting,
        data: data.map((item, idx) => ({ id: idx, ...item })),
      }
    case MASTER_GET_REPORT_FEE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_REPORT_FEE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportFee,
})
