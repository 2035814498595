import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListBusinessTravel = (params) => {
  const uri = `/human-resource/bussiness-travel`
  return api.get(uri, params)
}

/**
 *
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailBusinessTravel = (id) => {
  const uri = `${id}`
  return api.get(uri)
}

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiSyncBusinessTravel = ({ body = {}, query = {} }) => {
  const params = new URLSearchParams(query)
  const uri = `/sync/bussiness-travel?${params.toString()}`
  return api.post(uri, body)
}
