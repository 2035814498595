// Action: Get list
export const MASTER_GET_REPORT_OT = 'MASTER_GET_REPORT_OT'
export const MASTER_GET_REPORT_OT_SUCCESS = 'MASTER_GET_REPORT_OT_SUCCESS'
export const MASTER_GET_REPORT_OT_FAILED = 'MASTER_GET_REPORT_OT_FAILED'
export const MASTER_GET_REPORT_OT_RESET = 'MASTER_GET_REPORT_OT_RESET'

// Action: sync
export const MASTER_SYNC_REPORT_OT_BU = 'MASTER_SYNC_REPORT_OT_BU'
export const MASTER_SYNC_REPORT_OT_BU_SUCCESS =
  'MASTER_SYNC_REPORT_OT_BU_SUCCESS'
export const MASTER_SYNC_REPORT_OT_BU_FAILED = 'MASTER_SYNC_REPORT_OT_BU_FAILED'
export const MASTER_SYNC_REPORT_OT_BU_RESET = 'MASTER_SYNC_REPORT_OT_BU_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetReportOt(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_REPORT_OT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetReportOtSuccess(payload) {
  return {
    type: MASTER_GET_REPORT_OT_SUCCESS,
    payload: payload,
  }
}

export function actGetReportOtFailed() {
  return {
    type: MASTER_GET_REPORT_OT_FAILED,
  }
}

export function actGetReportOtReset() {
  return {
    type: MASTER_GET_REPORT_OT_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncReportOtBu(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_REPORT_OT_BU,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncReportOtBuSuccess(payload) {
  return {
    type: MASTER_SYNC_REPORT_OT_BU_SUCCESS,
    payload: payload,
  }
}

export function actSyncReportOtBuFailed() {
  return {
    type: MASTER_SYNC_REPORT_OT_BU_FAILED,
  }
}

export function actSyncReportOtBuReset() {
  return {
    type: MASTER_SYNC_REPORT_OT_BU_RESET,
  }
}

export default {
  actGetReportOt,
  actGetReportOtSuccess,
  actGetReportOtFailed,
  actGetReportOtReset,
  actSyncReportOtBu,
  actSyncReportOtBuSuccess,
  actSyncReportOtBuFailed,
  actSyncReportOtBuReset,
}
