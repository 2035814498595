import React, { useEffect } from 'react'

import { Box, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import { useQueryState } from '~/common/hooks'
import ActionBar from '~/components/ActionBar'
import Button from '~/components/Button'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import { USER_MANAGEMENT_STATUS_OPTIONS } from '~/modules/configuration/constants'
import useUserManagement from '~/modules/configuration/redux/hooks/useUserManagement'
import { ROUTE } from '~/modules/configuration/routes/config'
import { convertUtcDateTimeToLocalTz, convertUtcDateToLocalTz } from '~/utils'
function UserManagementDetail() {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { id } = useParams()
  const { withSearch } = useQueryState()

  const breadcrumbs = [
    {
      title: 'decentralization',
    },
    {
      route: withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH),
      title: ROUTE.USER_MANAGEMENT.LIST.TITLE,
    },
    {
      route: ROUTE.USER_MANAGEMENT.DETAIL.PATH,
      title: ROUTE.USER_MANAGEMENT.DETAIL.TITLE,
    },
  ]

  const {
    data: { isLoading, userDetails },
    actions,
  } = useUserManagement()

  useEffect(() => {
    actions.getUserDetailsById(id)
    return () => {
      actions.resetUserDetailsState()
    }
  }, [id])

  const backToList = () => {
    history.push(withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH))
  }
  const onEdit = () => {
    history.push(
      withSearch(ROUTE.USER_MANAGEMENT.EDIT.PATH.replace(':id', `${id}`)),
    )
  }
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.userManagementDetail')}
      onBack={backToList}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 8, xs: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                {t('userManagement.commonInfo')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LV
                label={t('userManagement.status')}
                value={
                  <Status
                    options={USER_MANAGEMENT_STATUS_OPTIONS}
                    value={userDetails?.status}
                  />
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label={t('userManagement.code')} value={userDetails.code} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label={t('userManagement.email')} value={userDetails.email} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.username')}
                value={userDetails.username}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV label={t('userManagement.phone')} value={userDetails.phone} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.fullName')}
                value={userDetails.fullName}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.dateOfBirth')}
                value={convertUtcDateToLocalTz(userDetails.dateOfBirth)}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.user')}
                value={userDetails.createdBy?.username}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.createTime')}
                value={convertUtcDateTimeToLocalTz(userDetails.createdAt)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" mt={1}>
                {t('userManagement.workInfo')}
              </Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.department')}
                value={userDetails.orgStructures
                  ?.map((org) => org?.name)
                  .join(', ')}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.factoryName')}
                value={userDetails?.factory?.name}
              />
            </Grid>

            <Grid item lg={6} xs={12}>
              <LV
                label={t('userManagement.roleAssign')}
                value={userDetails.userRoles
                  ?.map((role) => role?.userRoleName)
                  .join(', ')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActionBar>
        <Box>
          <Button color="grayF4" onClick={backToList}>
            {t('actionBar.back')}
          </Button>

          <Button
            variant="outlined"
            onClick={onEdit}
            sx={{ marginLeft: '10px' }}
          >
            {t('userManagement.edit')}
          </Button>
        </Box>
      </ActionBar>
    </Page>
  )
}

export default UserManagementDetail
