import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListReportAll = (params) => {
  const uri = `/reports/bus-overall`

  return api.get(uri, params)
}

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateReportAll = (params) => {
  const uri = `/overall-customize/upsert`

  return api.post(uri, params)
}

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiDeleteReportAll = (params) => {
  const uri = `/overall-customize`

  return api.delete(uri, params)
}
