import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import actRosterCalendar from '~/modules/master/redux/actions/roster-calendar'

const useRosterCalendar = () => {
  const data = useSelector((state) => get(state, 'master.rosterCalendar'))

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(actRosterCalendar, dispatch),
    [dispatch],
  )
  return {
    actions,
    data,
  }
}

export default useRosterCalendar
