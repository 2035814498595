import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actDetailRegisterVehicleBuTravelFailed,
  actDetailRegisterVehicleBuTravelSuccess,
  actDetailRegisterVehicleOtFailed,
  actDetailRegisterVehicleOtSuccess,
  actRegisterVehicleBuTravelFailed,
  actRegisterVehicleBuTravelSuccess,
  actRegisterVehicleOtFailed,
  actRegisterVehicleOtSuccess,
  MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL,
  MASTER_DETAIL_REGISTER_VEHICLE_OT,
  MASTER_REGISTER_VEHICLE_BU_TRAVEL,
  MASTER_REGISTER_VEHICLE_OT,
} from '../../actions/attendance'
import {
  apiGetRegisterVehicleBuTravel,
  apiGetRegisterVehicleOt,
  apiRegisterVehicleBuTravel,
  apiRegisterVehicleOt,
} from '../../apis/attendance'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDetailOt(action) {
  try {
    const response = yield call(apiGetRegisterVehicleOt, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actDetailRegisterVehicleOtSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actDetailRegisterVehicleOtFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

function* doRegisterOt(action) {
  try {
    const response = yield call(apiRegisterVehicleOt, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actRegisterVehicleOtSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'attendance.notification.registerVehicleSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actRegisterVehicleOtFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

function* doGetDetailButravel(action) {
  try {
    const response = yield call(apiGetRegisterVehicleBuTravel, action?.payload)

    if (response?.statusCode === 200) {
      yield put(
        actDetailRegisterVehicleBuTravelSuccess({ data: response.data }),
      )
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actDetailRegisterVehicleBuTravelFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

function* doRegisterBuTravel(action) {
  try {
    const response = yield call(apiRegisterVehicleBuTravel, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actRegisterVehicleBuTravelSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'attendance.notification.registerVehicleSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actRegisterVehicleBuTravelFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchRegisterVehicle() {
  yield all([
    takeLatest(MASTER_DETAIL_REGISTER_VEHICLE_OT, doGetDetailOt),
    takeLatest(MASTER_REGISTER_VEHICLE_OT, doRegisterOt),
    takeLatest(MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL, doGetDetailButravel),
    takeLatest(MASTER_REGISTER_VEHICLE_BU_TRAVEL, doRegisterBuTravel),
  ])
}
