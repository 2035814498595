import theme from '~/themes'

const TextLink = ({ text, onClick }) => {
  return (
    <div
      style={{
        color: theme.palette.primary.main,
        cursor: 'pointer',
        // textDecoration: 'underline',
      }}
      onClick={onClick}
    >
      {text}
    </div>
  )
}

export default TextLink
