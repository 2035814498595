import React, { useMemo } from 'react'

import { Box } from '@mui/system'
import { compact } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'
import IconButton from '~/components/IconButton'
import { APPROVED_VALUE } from '~/modules/master/constants'

const EmpTable = ({ arrayHelpers, values }) => {
  const { t } = useTranslation(['buseye'])

  const columns = useMemo(() => {
    return [
      {
        field: 'STT',
        headerName: 'STT',
        width: 80,
        renderCell: (_, idx) => {
          return idx + 1
        },
      },
      {
        field: 'codeEmp',
        headerName: t('attendance.email.codeEmp'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { codeEmp, empCode } = params?.row
          return codeEmp || empCode
        },
      },
      {
        field: 'nameEmp',
        headerName: t('attendance.email.nameEmp'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { nameEmp, empName } = params?.row
          return nameEmp || empName
        },
      },
      {
        field: 'orgStructure',
        headerName: t('attendance.email.orgStructure'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { orgStructure, orgName } = params?.row
          return orgStructure || orgName
        },
      },
      {
        field: 'type',
        headerName: t('attendance.email.purpose'),
        width: 250,
      },
      {
        field: 'dateFrom',
        headerName: t('attendance.email.dateFrom'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { dateFrom, timeFrom } = params?.row
          return moment(dateFrom || timeFrom).format('YYYY-MM-DD')
        },
      },
      {
        field: 'dateTo',
        headerName: t('attendance.email.dateTo'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { dateTo, timeTo } = params?.row
          return moment(dateTo || timeTo).format('YYYY-MM-DD')
        },
      },
      {
        field: 'placeFrom',
        headerName: t('attendance.email.placeFrom'),
        width: 100,
        visible: 'always',
      },
      {
        field: 'placeTo',
        headerName: t('attendance.email.placeTo'),
        width: 100,
        visible: 'always',
      },
      {
        field: 'status',
        headerName: t('attendance.email.status'),
        width: 100,
        visible: 'always',
        renderCell: (params) => {
          const { status, approved } = params?.row
          const emailStatus = approved !== undefined ? approved : status
          return [APPROVED_VALUE.approved, true].includes(emailStatus)
            ? t('attendance.email.approved')
            : t('attendance.email.reject')
        },
      },
      {
        field: 'lgaVehicle',
        headerName: t('attendance.email.lgaVehicle'),
        width: 150,
        visible: 'always',
        renderCell: (params) => {
          const { descriptionRegisterVehicle } = params?.row
          return descriptionRegisterVehicle
        },
      },
      {
        field: 'taxiCode',
        headerName: t('attendance.email.taxiCode'),
        width: 150,
        visible: 'always',
        renderCell: (params) => {
          const { registerVehicles, ticket } = params?.row
          const lga = ticket !== undefined ? ticket : registerVehicles

          return compact(
            lga?.map((item) => (item.code !== undefined ? item.code : item)),
          ).join(', ')
        },
      },
      {
        field: 'email',
        headerName: t('attendance.email.toEmail'),
        width: 150,
        required: true,
        renderCell: (_, idx) => {
          return (
            <Field.TextField
              name={`detail[${idx}].email`}
              placeholder={t('attendance.email.toEmail')}
              inputProps={{
                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
              }}
            />
          )
        },
      },

      {
        field: 'action',
        headerName: t('common.action'),
        width: 80,
        hide: values.detail?.length <= 1,
        renderCell: (_, idx) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                icon="remove"
                onClick={() => {
                  arrayHelpers.remove(idx)
                }}
                tooltipText={t('common.delete')}
              />
            </Box>
          )
        },
      },
    ]
  }, [t, values, arrayHelpers])

  return (
    <DataTable
      rows={values.detail || []}
      columns={columns}
      hideSetting
      hideFooter
    />
  )
}

export default EmpTable
