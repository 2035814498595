import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { NOTIFICATION_TYPE } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TextLink from '~/components/TextLink'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import {
  EXPORT_TYPE,
  IMPORT_TYPE,
  STATUS_PROFILE_CUSTOM_OPTIONS,
  STATUS_SYN_OPTION,
  UNIT_LEVEL_ENUM
} from '~/modules/master/constants'
import {
  apiGetTemplate,
  apiImport,
  apiExport
} from '~/modules/master/redux/apis/import-export'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'
import { ROUTE } from '~/modules/master/routes/config'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateToLocalTz,
} from '~/utils'
import addNotification from '~/utils/toast'

import { tabList } from '../constants'
import DetailProfile from '../detail'
import RegisterRoute from '../register-route'
import UpdateSite from '../update-site'
import { canUpdateRoute } from '../utils'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.EMPLOYEE.TITLE,
  },
  {
    route: ROUTE.EMPLOYEE.PROFILE_CUSTOM.LIST.PATH,
    title: ROUTE.EMPLOYEE.PROFILE_CUSTOM.LIST.TITLE,
  },
]

const ListProfileCustom = () => {
  const { t } = useTranslation(['buseye'])
  const { canAccess } = useApp()
  // const history = useHistory()

  const DEFAULT_FILTERS = {
    keyword: '',
    orgStructure: undefined,
  }

  const [selectedRows, setSelectedRows] = useState([])
  const [showDetailId, setShowDetailId] = useState(null)
  const [registerRoute, setRegisterRoute] = useState(null)
  const [confirmSync, setConfirmSync] = useState(false)
  const [addSite, setAddSite] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
    tab: '',
  })

  const {
    data: {
      listProfileCustom: {
        data,
        total,
        isLoading,
        totalUnRegisterRoute,
        totalWorking,
        count,
      },
      syncProfileCustom: { isLoading: isLoadingSync },
    },
    actions: {
      actGetListProfileCustom,
      actGetListProfileCustomReset,
      actSyncProfileCustom,
      actGetDetailProfileCustomReset,
    },
  } = useProfileCustom()

  useEffect(() => {
    if (canAccess(FUNCTION_CODE.USER_LIST_PROFILE_CUSTOM)) {
      refreshData()
    }
  }, [page, pageSize, sort, filters, tab, canAccess])

  useLayoutEffect(() => {
    return () => actGetListProfileCustomReset()
  }, [])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const {
    data: { userInfo },
  } = useUserInfo()

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('profileCustom.code'),
      width: 150,
      visible: 'always',
      sortable: true,
      sticky: 'left',
      renderCell: (params) => {
        const { code, id } = params?.row
        return <TextLink text={code} onClick={() => handleShowDetail(id)} />
      },
    },
    {
      field: 'name',
      headerName: t('profileCustom.name'),
      width: 150,
      sortable: true,
      sticky: 'left',
    },
    {
      field: 'codeAttendance',
      headerName: t('profileCustom.codeAttendance'),
      width: 150,
      sticky: 'left',
    },
    {
      field: 'position',
      headerName: t('profileCustom.position'),
      width: 150,
      sortable: true,
    },
    {
      field: 'factoryName',
      headerName: t('profileCustom.factory'),
      width: 150,
      sortable: true,
    },
    {
      field: 'nameOrgStructure',
      headerName: t('profileCustom.orgStructure'),
      width: 250,
      sortable: true,
      renderCell: (params) => {
        const { nameOrgStructure, levelOrgStructure, nameParentOrg } = params?.row
        if (levelOrgStructure === UNIT_LEVEL_ENUM.SECTION)
          return nameOrgStructure
        if (levelOrgStructure === UNIT_LEVEL_ENUM.UNIT)
          return nameParentOrg
      },
    },
    {
      field: 'levelOrgStructure',
      headerName: t('profileCustom.unit'),
      width: 250,
      sortable: true,
      renderCell: (params) => {
        const { nameOrgStructure, levelOrgStructure } = params?.row
        return levelOrgStructure === UNIT_LEVEL_ENUM.UNIT ? nameOrgStructure : ''
      },
    },
    {
      field: 'dateQuit',
      headerName: t('profileCustom.dateQuit'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateQuit } = params?.row
        return convertUtcDateToLocalTz(dateQuit)
      },
    },

    {
      field: 'status',
      headerName: t('profileCustom.status'),
      width: 150,
      renderCell: (params) => {
        const { statusSyn } = params.row
        return (
          <Status
            options={STATUS_PROFILE_CUSTOM_OPTIONS}
            value={statusSyn}
            variant="text"
          />
        )
      },
    },

    {
      field: 'route',
      headerName: t('profileCustom.route'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { nameRoute, routeCode } = params.row
        return nameRoute && routeCode ? `${routeCode} - ${nameRoute}` : ''
      },
    },
    {
      field: 'namePickupPoint',
      headerName: t('profileCustom.pickupPoint'),
      width: 150,
      sortable: true,
    },
    {
      field: 'timeDeparture',
      headerName: t('profileCustom.timeDeparture'),
      width: 150,
    },
    {
      field: 'timeArrival',
      headerName: t('profileCustom.timeArrival'),
      width: 150,
      sortable: true,
    },
    {
      field: 'isSyncSuccess',
      headerName: t('profileCustom.statusSyn'),
      width: 150,
      renderCell: (params) => {
        const { isSyncSuccess } = params.row
        return (
          <Status
            options={STATUS_SYN_OPTION}
            value={isSyncSuccess}
            variant="text"
          />
        )
      },
    },
    {
      field: 'dateUpdate',
      headerName: t('profileCustom.dateUpdate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateUpdate } = params?.row
        return convertUtcDateToLocalTz(dateUpdate)
      },
    },
    {
      field: 'dateCreate',
      headerName: t('profileCustom.dateCreate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateCreate } = params?.row
        return convertUtcDateToLocalTz(dateCreate)
      },
    },

    {
      field: 'action',
      headerName: t('common.action'),
      width: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {canUpdateRoute(userInfo) ? <Guard code={FUNCTION_CODE.USER_UPDATE_PROFILE_CUSTOM}>
              <IconButton
                icon="edit"
                tooltipText={t('common.update')}
                onClick={() => {
                  if (!canUpdateRoute(userInfo)) {
                    addNotification(t('profileCustom.notification.registerRouteError'), NOTIFICATION_TYPE.ERROR);
                    return
                  }
                  handleOpenRegister(params?.row)
                }}
              />{' '}
            </Guard> : null}
            <Guard code={FUNCTION_CODE.USER_UPDATE_PROFILE_CUSTOM}>
              <IconButton
                icon="listMoney"
                tooltipText={t('common.update')}
                onClick={() => handleOpenSite(params?.row)}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const handleShowDetail = (id) => {
    setShowDetailId(id)
  }

  const handleCloseDetail = () => {
    setShowDetailId(null)
    actGetDetailProfileCustomReset()
  }

  const refreshData = () => {
    const { keyword = '', factory, orgStructure, ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...dataFilter,
          flag: tab,
          orgStructureIds: orgStructure?.map((item) => item.id),
          factoryId: factory?.id,
        },
        [...columns, { field: 'orgStructureIds', filterFormat: 'multiple' }],
      ),
      sort: convertSortParams(sort),
    }
    actGetListProfileCustom(params)
    closeConfirmSync()
    // actions.searchProducingSteps(params)
  }

  const handleSyncData = () => {
    const current = moment().format('YYYY-MM-DD')
    actSyncProfileCustom({ DateFrom: current, DateTo: current }, refreshData)
  }

  const handleOpenSite = (val) => {
    setAddSite(val)
  }

  const handleCloseSite = () => {
    setAddSite(null)
  }

  const handleOpenRegister = (val) => {
    setRegisterRoute(val)
  }

  const handleCloseRegister = () => {
    setRegisterRoute(null)
  }

  const handleRegisterSuccess = () => {
    refreshData()
    setRegisterRoute(null)
  }

  const openConfirm = () => {
    setConfirmSync(true)
  }

  const closeConfirmSync = () => {
    setConfirmSync(false)
  }

  const syncButton = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={openConfirm}
    >
      <Icon
        name="assign"
        fill="#FF9054"
        stroke="#FF9054"
        sx={{ marginRight: '5px' }}
      />
      {t('common.sync')}
    </Box>
  )

  const registerButton = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClickCapture={(event) => {
        if (!canUpdateRoute(userInfo)) {
          event.stopPropagation()
          addNotification(t('profileCustom.notification.registerRouteError'), NOTIFICATION_TYPE.ERROR);
        }
      }}
    >
      <ImportExport
        name={t('menu.profileCustom')}
        buttonImportText="profileCustom.registerRoute.title"
        onImport={(body) =>
          apiImport({ body, type: IMPORT_TYPE.PROFILE_CUSTOM })
        }
        importMessage={{
          success: t('profileCustom.notification.importSuccess'),
        }}
        onExport={
          canAccess(FUNCTION_CODE.USER_EXPORT_FACTORY)
            ? () => {
                const { keyword = '', factory, orgStructure, ...dataFilter } = filters
                const params = {
                  keyword: keyword.trim(),
                  filter: convertFilterParams(
                    {
                      ...dataFilter,
                      flag: tab,
                      orgStructureIds: orgStructure?.map((item) => item.id),
                      factoryId: factory?.id,
                    },
                    [...columns, { field: 'orgStructureIds', filterFormat: 'multiple' }],
                  ),
                  sort: convertSortParams(sort),
                  type: EXPORT_TYPE.PROFILE_CUSTOM
                }
                if (!isEmpty(selectedRows)) {
                  params.ids = JSON.stringify(
                    selectedRows?.map((x) => ({ id: x?.id })),
                  )
                }

                return apiExport(params)
              }
            : null
        }
        onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.PROFILE_CUSTOM)}
        onRefresh={refreshData}
      />
    </Box>
  )

  const beforeTopbar = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Guard code={FUNCTION_CODE.USER_SYNCHRONIZE_PROFILE_CUSTOM}>
        {syncButton}
      </Guard>
      {canUpdateRoute(userInfo) ? <><Box sx={{ margin: '0 5px' }}>|</Box>
        <Guard code={FUNCTION_CODE.USER_REGISTER_ROUTE_PROFILE_CUSTOM}>
          {registerButton}
        </Guard> </> : null}
    </Box>
  )

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        working: totalWorking,
        unregistered: totalUnRegisterRoute,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.profileCustom')}
      loading={isLoading || isLoadingSync}
    >
      <HotKeys handlers={{}} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_PROFILE_CUSTOM}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            customWidth: {},
            gridSpace: 4,
          }}
        />
      </Guard>
      <DataTable
        rows={data}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={count}
        title={t('master.title')}
        sort={sort}
        beforeTopbar={beforeTopbar}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!confirmSync}
        onConfirm={handleSyncData}
        onCancel={closeConfirmSync}
      />
      <DetailProfile
        open={showDetailId !== null}
        showDetailId={showDetailId}
        t={t}
        onClose={handleCloseDetail}
      />
      <RegisterRoute
        open={!!registerRoute}
        data={registerRoute}
        t={t}
        onClose={handleCloseRegister}
        handleRegisterSuccess={handleRegisterSuccess}
      />
      <UpdateSite
        open={!!addSite}
        data={addSite}
        t={t}
        onClose={handleCloseSite}
      />
    </Page>
  )
}

export default ListProfileCustom
