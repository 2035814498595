// Action: Get list
export const MASTER_GET_LIST_POSITION = 'MASTER_GET_LIST_POSITION'
export const MASTER_GET_LIST_POSITION_SUCCESS =
  'MASTER_GET_LIST_POSITION_SUCCESS'
export const MASTER_GET_LIST_POSITION_FAILED = 'MASTER_GET_LIST_POSITION_FAILED'
export const MASTER_GET_LIST_POSITION_RESET = 'MASTER_GET_LIST_POSITION_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_POSITION = 'MASTER_GET_DETAIL_POSITION'
export const MASTER_GET_DETAIL_POSITION_SUCCESS =
  'MASTER_GET_DETAIL_POSITION_SUCCESS'
export const MASTER_GET_DETAIL_POSITION_FAILED =
  'MASTER_GET_DETAIL_POSITION_FAILED'
export const MASTER_GET_DETAIL_POSITION_RESET =
  'MASTER_GET_DETAIL_POSITION_RESET'

// Action: sync
export const MASTER_SYNC_POSITION = 'MASTER_SYNC_POSITION'
export const MASTER_SYNC_POSITION_SUCCESS = 'MASTER_SYNC_POSITION_SUCCESS'
export const MASTER_SYNC_POSITION_FAILED = 'MASTER_SYNC_POSITION_FAILED'
export const MASTER_SYNC_POSITION_RESET = 'MASTER_SYNC_POSITION_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListPosition(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_POSITION,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListPositionSuccess(payload) {
  return {
    type: MASTER_GET_LIST_POSITION_SUCCESS,
    payload: payload,
  }
}

export function actGetListPositionFailed() {
  return {
    type: MASTER_GET_LIST_POSITION_FAILED,
  }
}

export function actGetListPositionReset() {
  return {
    type: MASTER_GET_LIST_POSITION_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailPosition(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_POSITION,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailPositionSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_POSITION_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailPositionFailed() {
  return {
    type: MASTER_GET_DETAIL_POSITION_FAILED,
  }
}

export function actGetDetailPositionReset() {
  return {
    type: MASTER_GET_DETAIL_POSITION_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncPosition(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_POSITION,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncPositionSuccess(payload) {
  return {
    type: MASTER_SYNC_POSITION_SUCCESS,
    payload: payload,
  }
}

export function actSyncPositionFailed() {
  return {
    type: MASTER_SYNC_POSITION_FAILED,
  }
}

export function actSyncPositionReset() {
  return {
    type: MASTER_SYNC_POSITION_RESET,
  }
}

export default {
  actGetListPosition,
  actGetListPositionSuccess,
  actGetListPositionFailed,
  actGetListPositionReset,
  actGetDetailPosition,
  actGetDetailPositionSuccess,
  actGetDetailPositionFailed,
  actGetDetailPositionReset,
  actSyncPosition,
  actSyncPositionSuccess,
  actSyncPositionFailed,
  actSyncPositionReset,
}
