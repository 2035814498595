import React, { createContext, useState } from 'react'

import * as Msal from 'msal'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { NOTIFICATION_TYPE, TypeEnumLogin } from '~/common/constants'
import { useAuth } from '~/modules/auth/redux/hooks/useAuth'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import qs from '~/utils/qs'
import addNotification from '~/utils/toast'


const clientId = process.env.REACT_APP_AZURE_CLIENT_ID
const authority = process.env.REACT_APP_AZURE_LOGIN_BASE_URI

const msalConfig = {
    auth: {
        clientId: clientId,
        authority:
            authority,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true, // Set this to true for Internet Explorer 11
    },
    system: {
        tokenRenewalOffsetSeconds: 1000, // Adjust this value
    }
}

const msalInstance = new Msal.UserAgentApplication(msalConfig)

export const AzureContext = createContext({})

export const AzureProvider = ({ children }) => {
    const { t } = useTranslation('buseye')
    const [isLoading, setIsLoading] = useState(false)
    const { actions } = useAuth()
    const history = useHistory()
    const location = useLocation()
    const { callbackUrl } = qs.parse(location.search)
    const { actions: userActions } = useUserInfo()

    const handleOnLoginAzureSuccess = (values) => {
        const params = {
            username: values?.account?.userName,
            password: Math.random().toString(36).slice(-8),
            type: TypeEnumLogin.AZURE,
            azureToken: values.idToken.rawIdToken
        }
        actions.login(
            params,
            () => {
                history.push(callbackUrl || '/')
                userActions.getUserInfo()
            },
            (e) => {
                addNotification(e + ', ' + t('toast.roleContact', { role: 'ADMIN' }), NOTIFICATION_TYPE.ERROR)
            },
        )
    }

    const openAzureLogin = async () => {
        try {
            setIsLoading(true)
            const response = await msalInstance.loginPopup()
            handleOnLoginAzureSuccess(response)
        } catch (error) {
            console.log('error___', error);
            addNotification(t('toast.loginAzureError'), NOTIFICATION_TYPE.ERROR)
        }
        setIsLoading(false)
    }

    const onLogout = async () => {
        msalInstance.logout()
    }

    const value = {
        isLoading,
        openAzureLogin,
        onLogout
    }

    return (
        <AzureContext.Provider value={value}>{children}</AzureContext.Provider>
    )
}

export default AzureContext
