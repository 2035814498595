export const ROUTE = {
  EMPLOYEE: {
    TITLE: 'employee',
    PROFILE_CUSTOM: {
      LIST: {
        PATH: '/profile-custom',
        TITLE: 'profileCustom',
      },
      DETAIL: {
        PATH: '/profile-custom/:id/detail',
        TITLE: 'employeeDetail',
      },
      UPDATE: {
        PATH: '/profile-custom/:id/update',
        TITLE: 'employeeUpdate',
      },
    },
    CAT_SHIFT: {
      LIST: {
        PATH: '/cat-shift',
        TITLE: 'catShift',
      },
      DETAIL: {
        PATH: '/cat-shift/:id/detail',
        TITLE: 'workShiftDetail',
      },
    },
    ORG_STRUCTURE: {
      LIST: {
        PATH: '/org-structure',
        TITLE: 'orgStructure',
      },
    },
    FACTORY: {
      LIST: {
        PATH: '/factory',
        TITLE: 'factory',
      },
    },
    POSITION: {
      LIST: {
        PATH: '/position',
        TITLE: 'position',
      },
    },
  },
  PICKUP_POINT: {
    LIST: {
      PATH: '/pickup-point',
      TITLE: 'pickupPoint',
    },
  },
  ROUTE: {
    LIST: {
      PATH: '/route',
      TITLE: 'route',
    },
    CREATE: {
      PATH: '/route/create',
      TITLE: 'routeCreate',
    },
    DETAIL: {
      PATH: '/route/:id/detail',
      TITLE: 'routeDetail',
    },
    UPDATE: {
      PATH: '/route/:id/update',
      TITLE: 'routeUpdate',
    },
  },
  ATTENDANCE: {
    LIST: {
      PATH: '/attendance',
      TITLE: 'attendance',
    },
  },
  VEHICLE_CATEGORIES: {
    LIST: {
      PATH: '/vehicle-categories',
      TITLE: 'vehicleCategories',
    },
  },
  REPORT: {
    TITLE: 'report',
    REPORT_BR11: {
      LIST: {
        PATH: '/report-br11',
        TITLE: 'reportBr11',
      },
    },
    REPORT_ALL: {
      LIST: {
        PATH: '/report-all',
        TITLE: 'reportAll',
      },
    },
    REPORT_OT: {
      LIST: {
        PATH: '/report-ot-business-travel',
        TITLE: 'reportOt',
      },
    },
    REPORT_FEE: {
      LIST: {
        TITLE: 'reportFee',
        PATH: '/report-fee',
      },
    },
  },
  SETTING: {
    TITLE: 'setting',
    SYNC: {
      TITLE: 'settingSync',
      PATH: '/setting-sync',
    },
    FEE: {
      TITLE: 'settingFee',
      PATH: '/setting-fee',
    },
  },
}
