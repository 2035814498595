import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import IconButton from '~/components/IconButton'
import Page from '~/components/Page'
import TaskBar from '~/components/TaskBar'
import { apiGetFeeSettingList } from '~/modules/master/redux/apis/setting-fee'
import { ROUTE } from '~/modules/master/routes/config'
import { convertSortParams } from '~/utils'

import DetailFee from '../detail'
import CreateUpdate from '../form'
import FormCost from '../form-cost'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.SETTING.TITLE,
  },
  {
    route: ROUTE.SETTING.FEE.PATH,
    title: ROUTE.SETTING.FEE.TITLE,
  },
]
const DEFAULT_FILTERS = {}

const SettingFee = () => {
  const { t } = useTranslation(['buseye'])

  const history = useHistory()

  const [createUpdate, setCreateUpdate] = useState(null)
  const [data, setData] = useState({ total: 0, tableRows: [] })
  const [isLoading, setIsLoading] = useState(false)
  const [openDetail, setOpenDetail] = useState(null)
  const [editCost, setEditCost] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setTab,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  const columns = useMemo(() => [
    {
      field: '#',
      headerName: '#',
      width: 40,
      align: 'center',
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'monthYear',
      headerName: t('settingFee.dateFee'),
      width: 300,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { month, year } = params.row
        return `${month}/${year}`
      },
    },
    {
      field: 'flexibledNum',
      headerName: t('settingFee.caNumber'),
      width: 300,
      sortable: true,
    },
    {
      field: 'fixedNum',
      headerName: t('settingFee.hcNumber'),
      width: 300,
      sortable: true,
    },
    {
      field: 'tax',
      headerName: t('settingFee.tax'),
      width: 350,
      sortable: true,
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 100,
      renderCell: (params) => {
        const { month, year } = params.row

        return (
          <Box sx={{ display: 'flex' }}>
            <Guard code={FUNCTION_CODE.USER_DETAIL_FEE_SETTING}>
              <IconButton
                icon="show"
                onClick={() => onOpenDetail({ month, year })}
                tooltipText={t('common.clone')}
              />
            </Guard>

            <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
              <IconButton
                icon="edit"
                onClick={() => handleClickUpdate({ month, year })}
                tooltipText={t('common.update')}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const refreshData = async () => {
    const dateFilter = filters.date
      ? {
        month: moment(filters.date).format('M'),
        year: moment(filters.date).format('YYYY'),
      }
      : {}
    const params = {
      page,
      limit: pageSize,
      sort: convertSortParams(sort),
      ...dateFilter,
    }
    setIsLoading(true)
    const res = await apiGetFeeSettingList(params)
    setData({ total: res.data?.meta?.total, tableRows: res.data?.items })
    setIsLoading(false)
  }

  const handleClickCreateNew = () => {
    setCreateUpdate({})
  }

  const handleClickUpdate = async (data) => {
    setCreateUpdate(data)
  }

  const onOpenDetail = (data) => {
    setOpenDetail(data)
  }

  const onCloseDetail = () => {
    setOpenDetail(null)
  }

  const handleSubmitSuccess = () => {
    // setCreateUpdate(null)
    refreshData()
  }

  const handleCloseCreateUpdate = () => {
    setCreateUpdate(null)
  }

  const handleOpenCost = () => {
    setEditCost({})
  }

  const closeEditCost = () => {
    setEditCost(null)
  }
  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={handleClickCreateNew}
        sx={{ marginLeft: '10px', marginRight: '10px' }}
      />
    </Guard>,
    <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
      <IconButton
        icon="setting"
        title={t('settingFee.costSetting')}
        onClick={handleOpenCost}
        sx={{ marginLeft: '10px', marginRight: '10px' }}
      />
    </Guard>,
  ]

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.settingFee')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <TaskBar left={actionHeader} right={[]} />
      <FilterArea
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: <FilterForm />,
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <DataTable
        rows={data.tableRows || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        total={data.total}
        tab={tab}
        setTab={setTab}
        // title={t('master.title')}
        sort={sort}
      // tabs={tabElement}
      />
      <DetailFee
        open={openDetail !== null}
        openDetailData={openDetail}
        t={t}
        onClose={onCloseDetail}
      />
      <CreateUpdate
        open={!!createUpdate}
        dateUpdate={createUpdate}
        t={t}
        onClose={handleCloseCreateUpdate}
        // onSubmit={handleSubmit}
        handleSubmitSuccess={handleSubmitSuccess}
      />
      <FormCost
        open={!!editCost}
        dataEdit={editCost}
        t={t}
        onClose={closeEditCost}
        handleSubmitSuccess={() => { }}
      />
    </Page>
  )
}

export default SettingFee
