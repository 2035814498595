import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useApp } from '~/common/hooks/useApp'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import {
  apiGetListFactoryList,
} from '~/modules/master/redux/apis/factory'
import {
  apiGetListOrgStructureList,
} from '~/modules/master/redux/apis/org-structure'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  const { canAccess } = useApp()

  return (
    <>
      <Grid item xs={3.5}>
        <Field.TextField
          name="keyword"
          placeholder={t('common.inputKeyWord')}
          inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={3.5}>
        <Field.Autocomplete
          name="factory"
          // label={t('profileCustom.factory')}
          placeholder={t('profileCustom.factory')}
          asyncRequest={(s) =>
            apiGetListFactoryList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
        />
      </Grid>

      {canAccess(FUNCTION_CODE.USER_VIEW_ALL_PROFILE_CUSTOM) ? <Grid item xs={3.5}>
        <Field.Autocomplete
          name="orgStructure"
          // label={t('profileCustom.orgStructure')}
          placeholder={t('profileCustom.orgStructure')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListOrgStructureList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => `[${option.code}] ${option.name}`}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
          multiple
        />
      </Grid> : null}
    </>
  )
}

export default FilterForm
