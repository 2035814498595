import React from 'react'

import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Field } from '~/components/Formik'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={3}>
        <Field.DatePicker
          name="date"
          placeholder={t('settingFee.filter.date')}
          views={['month', 'year']}
          inputFormat="MM/yyyy"
          required
        />
      </Grid>
    </>
  )
}

export default FilterForm
