import { Box } from '@mui/material'
import { compact } from 'lodash'

import Guard from '~/components/Guard'
import IconButton from '~/components/IconButton'
import Status from '~/components/Status'
import { convertUtcDateToLocalTz } from '~/utils'

import {
  APPROVED_OPTIONS,
  SOURCE_TYPE,
  STATUS_SYN_OPTION,
} from '../../constants'
import {
  ATTENDANCE_REGISTER_VEHICLE_PERMISSION,
  ATTENDANCE_SEND_EMAIL_PERMISSION,
  TAB_VALUE,
} from './constants'

export const columnRosterCalendar = ({ t }) => [
  {
    field: 'codeEmp',
    headerName: t('attendance.rosterCalendar.codeEmp'),
    width: 80,
    visible: 'always',
    sortable: true,
  },
  {
    field: 'nameEmp',
    headerName: t('attendance.rosterCalendar.nameEmp'),
    width: 150,
    sortable: true,
  },
  {
    field: 'nameOrgStructure',
    headerName: t('reportBr11.bu'),
    width: 150,
  },
  {
    field: 'nameRoute',
    headerName: t('attendance.rosterCalendar.nameRoute'),
    width: 150,
  },
  {
    field: 'nameShift',
    headerName: t('attendance.rosterCalendar.nameShift'),
    width: 150,
    sortable: true,
  },
  {
    field: 'timeStart',
    headerName: t('attendance.rosterCalendar.timeStart'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { timeStart } = params?.row
      return convertUtcDateToLocalTz(timeStart, 'HH:mm')
    },
  },
  {
    field: 'timeEnd',
    headerName: t('attendance.rosterCalendar.timeEnd'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { timeEnd } = params?.row
      return convertUtcDateToLocalTz(timeEnd, 'HH:mm')
    },
  },
  {
    field: 'dateStart',
    headerName: t('attendance.rosterCalendar.dateStart'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { dateStart } = params?.row
      return convertUtcDateToLocalTz(dateStart)
    },
  },
  {
    field: 'dateEnd',
    headerName: t('attendance.rosterCalendar.dateEnd'),
    width: 150,
    sortable: true,
    align: 'center',
    headerAlign: 'left',
    renderCell: (params) => {
      const { dateEnd } = params?.row
      return dateEnd ? convertUtcDateToLocalTz(dateEnd) : '-'
    },
  },
  {
    field: 'dateUpdate',
    headerName: t('attendance.rosterCalendar.dateUpdate'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { dateUpdate } = params?.row
      return convertUtcDateToLocalTz(dateUpdate)
    },
  },

  {
    field: 'statusSyn',
    headerName: t('attendance.rosterCalendar.statusSyn'),
    width: 150,
    renderCell: (params) => {
      const { isSyncSuccess } = params.row
      return (
        <Status
          options={STATUS_SYN_OPTION}
          value={isSyncSuccess}
          variant="text"
        />
      )
    },
  },
  {
    field: 'sourceType',
    headerName: t('attendance.rosterCalendar.source'),
    width: 150,
    renderCell: (params) => {
      const { sourceType } = params.row
      return SOURCE_TYPE[sourceType]
    },
  },
]

export const columnOvertime = ({ t, openRegister, openSendEmail }) => [
  {
    field: 'codeEmp',
    headerName: t('attendance.overtime.codeEmp'),
    width: 150,
    visible: 'always',
    sortable: true,
  },
  {
    field: 'nameEmp',
    headerName: t('attendance.overtime.nameEmp'),
    width: 150,
    sortable: true,
  },
  {
    field: 'codeAttendance',
    headerName: t('attendance.overtime.codeAttendance'),
    width: 150,
    sortable: true,
  },
  {
    field: 'nameOrgStructure',
    headerName: t('reportBr11.bu'),
    width: 150,
  },
  {
    field: 'otDate',
    headerName: t('attendance.overtime.otDate'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { workDateRoot } = params?.row
      return convertUtcDateToLocalTz(workDateRoot)
    },
  },
  {
    field: 'timeFrom',
    headerName: t('attendance.overtime.timeFrom'),
    width: 150,
    sortable: true,
    align: 'center',
    renderCell: (params) => {
      const { timeFrom } = params?.row
      return convertUtcDateToLocalTz(timeFrom, 'HH:mm')
    },
  },
  {
    field: 'timeTo',
    headerName: t('attendance.overtime.timeTo'),
    width: 150,
    sortable: true,
    align: 'center',
    renderCell: (params) => {
      const { timeTo } = params?.row
      return convertUtcDateToLocalTz(timeTo, 'HH:mm')
    },
  },
  {
    field: 'status',
    headerName: t('attendance.overtime.status'),
    width: 150,
    renderCell: (params) => {
      const { status } = params.row
      return <Status options={APPROVED_OPTIONS} value={status} variant="text" />
    },
  },
  {
    field: 'lgaVehicle',
    headerName: t('attendance.overtime.lgaVehicle'),
    width: 150,
    renderCell: (params) => {
      const { descriptionRegisterVehicle } = params?.row
      return descriptionRegisterVehicle
    },
  },
  {
    field: 'taxiCode',
    headerName: t('attendance.overtime.taxiCode'),
    width: 150,
    renderCell: (params) => {
      const { registerVehicles = [] } = params?.row
      const textArr = compact(registerVehicles?.map((item) => item.code))
      return textArr?.length ? textArr.join(', ') : ''
    },
  },
  {
    field: 'dateUpdate',
    headerName: t('attendance.overtime.dateUpdate'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { updatedAt } = params?.row
      return convertUtcDateToLocalTz(updatedAt)
    },
  },
  {
    field: 'statusSyn',
    headerName: t('common.sync'),
    width: 150,
    renderCell: (params) => {
      const { isSyncSuccess } = params.row
      return (
        <Status
          options={STATUS_SYN_OPTION}
          value={isSyncSuccess}
          variant="text"
        />
      )
    },
  },
  {
    field: 'action',
    headerName: t('common.action'),
    width: 100,
    align: 'center',
    renderCell: (params) => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Guard
            code={ATTENDANCE_REGISTER_VEHICLE_PERMISSION[TAB_VALUE.overtime]}
          >
            <IconButton
              icon="bus"
              onClick={() => openRegister(params.row)}
              tooltipText={t('common.registerRoute')}
            />
          </Guard>
          <Guard code={ATTENDANCE_SEND_EMAIL_PERMISSION[TAB_VALUE.overtime]}>
            <IconButton
              icon="sendEmail"
              onClick={() => openSendEmail([params.row])}
              tooltipText={t('common.email')}
            />
          </Guard>
        </Box>
      )
    },
  },
]

export const columnBusinessTravel = ({ t, openRegister, openSendEmail }) => [
  {
    field: 'codeEmp',
    headerName: t('attendance.businessTravel.codeEmp'),
    width: 150,
    visible: 'always',
    sortable: true,
  },
  {
    field: 'nameEmp',
    headerName: t('attendance.businessTravel.nameEmp'),
    width: 150,
    sortable: true,
  },
  {
    field: 'codeAttendance',
    headerName: t('attendance.businessTravel.codeAttendance'),
    width: 150,
    sortable: true,
  },
  {
    field: 'nameOrgStructure',
    headerName: t('reportBr11.bu'),
    width: 150,
  },
  {
    field: 'dateFrom',
    headerName: t('attendance.businessTravel.dateFrom'),
    width: 150,
    renderCell: (params) => {
      const { dateFrom } = params?.row
      return convertUtcDateToLocalTz(dateFrom)
    },
  },
  {
    field: 'dateTo',
    headerName: t('attendance.businessTravel.dateTo'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { dateTo } = params?.row
      return convertUtcDateToLocalTz(dateTo)
    },
  },
  {
    field: 'durationType',
    headerName: t('attendance.businessTravel.durationType'),
    width: 150,
  },
  {
    field: 'bussinessTravelName',
    headerName: t('attendance.businessTravel.bussinessTravelName'),
    width: 150,
  },
  {
    field: 'placeFrom',
    headerName: t('attendance.businessTravel.placeFrom'),
    width: 150,
  },
  {
    field: 'placeTo',
    headerName: t('attendance.businessTravel.placeTo'),
    width: 150,
  },

  {
    field: 'lgaVehicle',
    headerName: t('attendance.businessTravel.lgaVehicle'),
    width: 150,
    renderCell: (params) => {
      const { descriptionRegisterVehicle } = params?.row
      return descriptionRegisterVehicle
    },
  },
  {
    field: 'taxiCode',
    headerName: t('attendance.businessTravel.taxiCode'),
    width: 150,
    renderCell: (params) => {
      const { registerVehicles = [] } = params?.row
      const textArr = compact(registerVehicles?.map((item) => item.code))
      return textArr?.length ? textArr.join(', ') : ''
    },
  },
  {
    field: 'dateUpdate',
    headerName: t('attendance.businessTravel.dateUpdate'),
    width: 150,
    sortable: true,
    renderCell: (params) => {
      const { updatedAt } = params?.row
      return convertUtcDateToLocalTz(updatedAt)
    },
  },
  {
    field: 'statusSyn',
    headerName: t('common.sync'),
    width: 150,
    renderCell: (params) => {
      const { isSyncSuccess } = params.row
      return (
        <Status
          options={STATUS_SYN_OPTION}
          value={isSyncSuccess}
          variant="text"
        />
      )
    },
  },
  {
    field: 'action',
    headerName: t('common.action'),
    width: 150,
    renderCell: (params) => {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Guard
            code={
              ATTENDANCE_REGISTER_VEHICLE_PERMISSION[TAB_VALUE.businessTravel]
            }
          >
            <IconButton
              icon="bus"
              onClick={() => openRegister(params.row)}
              tooltipText={t('common.registerRoute')}
            />
          </Guard>
          <Guard
            code={ATTENDANCE_SEND_EMAIL_PERMISSION[TAB_VALUE.businessTravel]}
          >
            <IconButton
              icon="sendEmail"
              onClick={() => openSendEmail([params.row])}
              tooltipText={t('common.email')}
            />
          </Guard>
        </Box>
      )
    },
  },
]
