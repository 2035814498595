import React, { useRef } from 'react'

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material'
import { PropTypes } from 'prop-types'

import { useApp } from '~/common/hooks/useApp'
import Loading from '~/components/Loading'
import { useClasses } from '~/themes'

import PageFooter from './PageFooter'
import PageHeader from './PageHeader'
import style from './style'

const Page = ({
  onBack,
  title,
  breadcrumbs,
  children,
  loading,
  sx,
  fitScreen,
  freeSolo,
}) => {
  const classes = useClasses(style)
  const { pageTopRef, pageBottomRef, isBottomActionBarExisted } = useApp()
  const theme = useTheme()
  const pageRootRef = useRef()
  const pageContentRef = useRef()

  try {
    if (
      pageRootRef.current?.scrollHeight > pageRootRef.current?.clientHeight ||
      pageContentRef.current?.scrollHeight >
        pageContentRef.current?.clientHeight
    ) {
      pageRootRef.current.classList.add('page-width-scrollbar')
    }
  } catch (error) {}

  const largeScreenWidth = useMediaQuery('(min-height:800px)')
  const largeScreenHeight = useMediaQuery(theme.breakpoints.up('md'))
  const allowFitScreen = fitScreen && largeScreenWidth && largeScreenHeight

  const renderContent = () => {
    if (!freeSolo && allowFitScreen)
      return (
        <Paper
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flex: 1,
          }}
        >
          {children}
        </Paper>
      )

    if (!freeSolo && !allowFitScreen)
      return (
        <Paper
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          {children}
        </Paper>
      )

    if (freeSolo && allowFitScreen)
      return (
        <Box
          ref={pageContentRef}
          sx={{
            px: 2,
            mx: -2,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flex: 1,
          }}
        >
          {children}
        </Box>
      )

    return children
  }

  return (
    <Box
      ref={pageRootRef}
      className={classes.root}
      sx={{
        ...(isBottomActionBarExisted ? { pb: '72px' } : {}),
        ...sx,
      }}
    >
      <Box ref={pageTopRef} />

      <PageHeader onBack={onBack} title={title} breadcrumbs={breadcrumbs} />

      {renderContent()}

      {!isBottomActionBarExisted && <PageFooter />}

      <Loading open={loading}></Loading>
      <Box ref={pageBottomRef} />
    </Box>
  )
}

Page.defaultProps = {
  title: '',
  loading: false,
  sx: {},
  fitScreen: true,
  freeSolo: false,
}

Page.propTypes = {
  onBack: PropTypes.func,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  loading: PropTypes.bool,
  sx: PropTypes.shape(),
  fitScreen: PropTypes.bool,
  freeSolo: PropTypes.bool,
}

export default Page
