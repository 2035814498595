import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListAttendance = (params) => {
  const uri = `/human-resource/attendance`

  return api.get(uri, params)
}

/**
 * @returns {Promise}
 */
export const apiSyncAttendance = (body = {}) => {
  const uri = `/sync/attendance-plan`

  return api.post(uri, body)
}

/**
 * @params {id: string, body: object}
 * @returns {Promise}
 */
export const apiRegisterVehicleOt = ({ id, body = {} }) => {
  const uri = `/human-resource/overtime-plan/register-vehicle/${id}`

  return api.patch(uri, body)
}

/**
 * @params {id: string, body: object}
 * @returns {Promise}
 */
export const apiGetRegisterVehicleOt = (id) => {
  const uri = `/human-resource/overtime-plan/register-vehicle/${id}`

  return api.get(uri)
}

/**
 * @params {id: string, body: object}
 * @returns {Promise}
 */
export const apiRegisterVehicleBuTravel = ({ id, body = {} }) => {
  const uri = `/human-resource/bussiness-travel/register-vehicle/${id}`

  return api.patch(uri, body)
}

/**
 * @params {id: string, body: object}
 * @returns {Promise}
 */
export const apiGetRegisterVehicleBuTravel = (id) => {
  const uri = `/human-resource/bussiness-travel/register-vehicle/${id}`

  return api.get(uri)
}
