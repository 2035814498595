export const TAB_VALUE = {
  all: '',
  active: 1,
  inActive: 0,
}

export const tabList = (t, totals) => {
  const { all, totalActive, totalInActive } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
    {
      label: `${t('common.active')} (${totalActive})`,
      value: TAB_VALUE.active,
    },
    {
      label: `${t('common.pending')} (${totalInActive})`,

      value: TAB_VALUE.inActive,
    },
  ]
}
