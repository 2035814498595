import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import {
  apiGetListCatShiftList,
} from '~/modules/master/redux/apis/cat-shift'
import {
  apiGetListOrgStructureList,
} from '~/modules/master/redux/apis/org-structure'
import {
  apiGetListPickupPointList,
} from '~/modules/master/redux/apis/pickup-point'
import {
  apiGetListRouteList,
} from '~/modules/master/redux/apis/route'
import { convertFilterParams } from '~/utils'

import { CAT_SHIFT_LTF_CODE } from '../../../report-all/constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field.TextField
              name="employee"
              placeholder={t('reportBr11.filter.codeEmp')}
              inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX }}
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon name="search" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="orgStructures"
              placeholder={t('reportBr11.filter.codeOrgStructure')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListOrgStructureList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => `[${option.code}] ${option.name}`}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
              multiple
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="codeShift"
              placeholder={t('reportBr11.filter.codeShift')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListCatShiftList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                  filter: convertFilterParams({
                    codes: CAT_SHIFT_LTF_CODE.join(','),
                  }),
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="codeRoutes"
              placeholder={t('reportBr11.filter.codeRoute')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListRouteList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
              multiple
            />
          </Grid>
          <Grid item xs={4}>
            <Field.Autocomplete
              name="pickupPointCodes"
              placeholder={t('reportBr11.filter.pickupPointCode')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListPickupPointList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
              multiple
            />
          </Grid>
          <Grid item xs={4}>
            <Field.DateRangePicker
              name="rangeDate"
              placeholder={t('reportBr11.filter.rangeDate')}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterForm
