import React, { useState } from 'react'

import { Box, FormHelperText, Paper, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'

import logo from '~/assets/images/aj-logo.png'
import breadcrumb from '~/assets/images/breadcrumb.png'
import busRed from '~/assets/images/bus-red.png'
import { TEXTFIELD_ALLOW, TypeEnumLogin } from '~/common/constants'
import AzureLogin from '~/components/AzureLogin'
import Button from '~/components/Button'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { useAuth } from '~/modules/auth/redux/hooks/useAuth'
import { ROUTE } from '~/modules/auth/routes/config'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { useClasses } from '~/themes'
import { isAuth } from '~/utils'
import qs from '~/utils/qs'

import { loginSchema } from './schema'
import style from './style'

const Login = () => {
  const [visible, setVisible] = useState(false)
  const [error, setError] = useState('')
  const classes = useClasses(style)
  const { t } = useTranslation('buseye')
  const { actions, isLoading } = useAuth()
  const history = useHistory()
  const location = useLocation()
  const { callbackUrl } = qs.parse(location.search)
  const { actions: userActions } = useUserInfo()

  const initialValues = {
    username: '',
    password: '',
    rememberPassword: false,
  }

  const handleSubmit = (values) => {
    const params = {
      ...values,
      rememberPassword: +values.rememberPassword,
      type: TypeEnumLogin.SYSTEM,
    }
    actions.login(
      params,
      () => {
        history.push(callbackUrl || '/')
        userActions.getUserInfo()
      },
      (e) => {
        setError(e)
      },
    )
  }

  if (isAuth()) {
    return <Redirect to="/" />
  }

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          position: 'relative',
          zIndex: 999,
        }}
      >
        {t('login.pageTitle')}
      </Typography>
      <Box className={classes.logoBox}>
        <img src={logo} alt="logo" style={{ width: '5vw' }} />
        <Box
          sx={{
            position: 'absolute',

            width: '45vw',
            // height: '500px',
            color: 'red',
            fontSize: '4.5vw',
            lineHeight: 1,
            fontWeight: 'bold',
          }}
        >
          <Box sx={{ background: '#e4d5bf' }}>SMART BUSEYE</Box>
          <Box
            sx={{
              background: `url(${breadcrumb}) no-repeat 60% 10%`,
              backgroundSize: '30%',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              lineHeight: 1,
              marginTop: '10px',
              height: '10vh',
            }}
          ></Box>
        </Box>
      </Box>

      <Paper className={classes.paper}>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema(t)}
          onSubmit={handleSubmit}
        >
          {({ handleChange }) => (
            <Form>
              <Field.TextField
                vertical
                label={t('login.username')}
                name="username"
                onChange={(e) => {
                  handleChange(e)
                  setError('')
                }}
              />
              <Field.TextField
                vertical
                label={t('login.password')}
                type={visible ? 'text' : 'password'}
                name="password"
                onChange={(e) => {
                  handleChange(e)
                  setError('')
                }}
                endAdornment={
                  <IconButton
                    onClick={() => setVisible(!visible)}
                    size="small"
                    sx={{ mx: 0.5 }}
                  >
                    {visible ? (
                      <Icon name="visible" />
                    ) : (
                      <Icon name="invisible" />
                    )}
                  </IconButton>
                }
                allow={TEXTFIELD_ALLOW.EXCEPT_SPACES}
                sx={{ mt: 4 / 3 }}
              />
              {!!error && (
                <FormHelperText error sx={{ mt: 4 / 3 }}>
                  {error}
                </FormHelperText>
              )}
              <Box className={classes.extraBox}>
                <FormControlLabel
                  control={<Field.Checkbox name="rememberPassword" />}
                  label={t('login.savePassword')}
                />
                <Link
                  to={ROUTE.FORGOT_PASSWORD.PATH}
                  className={classes.linkForgotPassword}
                >
                  <Typography color="primary" component="span">
                    {t('login.forgotPassword')}
                  </Typography>
                </Link>
              </Box>

              <Button type="submit" fullWidth loading={isLoading}>
                {t('login.loginButton')}
              </Button>
              <AzureLogin sx={{ marginTop: '10px' }} />
            </Form>
          )}
        </Formik>
      </Paper>
      <Box className={classes.carRed}>
        <img src={busRed} alt="logo" width={'60%'} />
      </Box>
    </Box>
  )
}

export default Login
