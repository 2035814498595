import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    date: Yup.date().nullable().required(t('form.required')),
    flexibledNum: Yup.number()
      .nullable()
      .required(t('form.required'))
      .min(
        1,
        t('form.minNumber', {
          min: 1,
        }),
      ),
    fixedNum: Yup.number()
      .nullable()
      .required(t('form.required'))
      .min(
        1,
        t('form.minNumber', {
          min: 1,
        }),
      ),
    tax: Yup.number()
      .nullable()
      .required(t('form.required'))
      .min(
        1,
        t('form.minNumber', {
          min: 1,
        }),
      ),
    shiftRoutes: Yup.array().of(
      Yup.object().shape({
        routeCode: Yup.string()
          .nullable()
          .test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            return true
          }),
        shiftCode: Yup.string()
          .nullable()
          .test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            return true
          }),
      }),
    ),
  })
