/*eslint no-param-reassign: "error"*/
import { MODAL_MODE } from '~/common/constants'

import { SHIFT_TYPE, STATUS_VALUE } from '../../constants'

const TAB_VALUE = {
  all: 0,
  working: 1,
  unregistered: 2,
  maternityLeave: 3,
  quit: 4,
  synError: 5,
}

export const FIXED_VEHICLE = {
  min: 1,
  max: 99,
}

export const tabList = (t, totals) => {
  const { all } = totals
  return [
    {
      label: `${t('common.all')} (${all})`,
      value: TAB_VALUE.all,
    },
  ]
}

export const TIME_RUN_CHECKBOXS = [
  {
    name: 'runMon',
    text: 'route.form.runMon',
  },
  {
    name: 'runTue',
    text: 'route.form.runTue',
  },
  {
    name: 'runWed',
    text: 'route.form.runWed',
  },
  {
    name: 'runThu',
    text: 'route.form.runThu',
  },
  {
    name: 'runFri',
    text: 'route.form.runFri',
  },
  {
    name: 'runSat',
    text: 'route.form.runSat',
  },
  {
    name: 'runSun',
    text: 'route.form.runSun',
  },
]

export const TAB_FORM_VALUE = {
  pickupPoints: 0,
  vehicleCategories: 1,
  catShifts: 2,
}

export const TAB_FORM_VALUE_MAPPING = {
  [TAB_FORM_VALUE.pickupPoints]: 'pickupPoints',
  [TAB_FORM_VALUE.vehicleCategories]: 'vehicleCategories',
  [TAB_FORM_VALUE.catShifts]: 'catShifts',
}

export const tabFormList = (t, mode, { pickupPoints, catShifts }) => {
  return [
    {
      label: t('route.pickupPoints.title'),
      value: TAB_FORM_VALUE.pickupPoints,
      required: mode !== MODAL_MODE.DETAIL,
      error: pickupPoints,
    },
    {
      label: t('route.catShifts.title'),
      value: TAB_FORM_VALUE.catShifts,
      required: mode !== MODAL_MODE.DETAIL,
      error: catShifts,
    },
  ]
}

export const DEFAULT_TABLE = {
  pickupPoints: {
    pickupPoint: undefined,
    location: '',
    timeDeparture: '--:--',
    timeArrival: '--:--',
  },
  vehicleCategories: {
    vehicleCategory: undefined,
    totalSeat: 0,
    description: '',
  },
  catShifts: {
    catShift: undefined,
    timeDeparture: '--:--',
    timeArrival: '--:--',
    description: '',
    fixedVehicle: undefined,
    totalVehicle: undefined,
    vehicleType: undefined,
  },
}

export const DEFAULT_DATA = {
  isActive: STATUS_VALUE.active,
  name: '',
  code: '',
  timeDeparture: new Date(),
  timeArrival: new Date(),
  description: '',
  vehicleCategories: [DEFAULT_TABLE.vehicleCategories],
  catShifts: [DEFAULT_TABLE.catShifts],
  shiftType: SHIFT_TYPE.BUSSINESS_TIME,
  pickupPoints: [DEFAULT_TABLE.pickupPoints],
  timeRun: {
    runMon: true,
    runTue: true,
    runWed: true,
    runThu: true,
    runFri: true,
    runSat: true,
    runSun: false,
  },
}

export const getDayInWeekValue = (val = {}, checkData, uncheckData) => {
  return TIME_RUN_CHECKBOXS.reduce((acc, current) => {
    acc[current.name] = val[current.name] ? checkData : uncheckData
    return acc
  }, {})
}
