import moment from 'moment'
import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    dateStart: Yup.date()
      .nullable()
      .test(_, (value, context) => {
        if (!value) {
          return context.createError({
            path: context.path,
            message: t('form.required'),
          })
        }

        if (!validateTime(value, context.parent?.dateEnd)) {
          return context.createError({
            path: context.path,
            message: t('form.invalidTimeRange'),
          })
        }

        return true
      }),
    dateEnd: Yup.date()
      .nullable()
      .test(_, (value, context) => {
        if (!value) {
          return context.createError({
            path: context.path,
            message: t('form.required'),
          })
        }

        if (!validateTime(context.parent?.dateStart, value)) {
          return context.createError({
            path: context.path,
            message: t('form.invalidTimeRange'),
          })
        }

        return true
      }),
  })

const validateTime = (dateStart, dateEnd) => {
  if (moment(dateStart).diff(dateEnd) > 0) {
    return false
  }
  return true
}
