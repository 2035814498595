import { combineReducers } from 'redux'

import {
  MASTER_GET_REPORT_ALL,
  MASTER_GET_REPORT_ALL_FAILED,
  MASTER_GET_REPORT_ALL_RESET,
  MASTER_GET_REPORT_ALL_SUCCESS,
} from '../actions/report-all'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportAll(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_REPORT_ALL:
      return { ...state, isLoading: true }
    case MASTER_GET_REPORT_ALL_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_REPORT_ALL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_REPORT_ALL_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportAll,
})
