import React from 'react'

import { Box, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useSidebar } from '~/common/hooks'
import Button from '~/components/Button'
import { useClasses } from '~/themes'

import style from './style'

const QuickFilter = ({
  form,
  values,
  defaultValues,
  onApply,
  validationSchema,
  onCancel,
  searchBox,
  customWidth,
  gridSpace = 1,
}) => {
  const { isMinimal } = useSidebar()
  const classes = useClasses(style({ isMinimal, customWidth, gridSpace }))
  const { t } = useTranslation(['buseye'])

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onApply}
      enableReinitialize
    >
      {({ resetForm }) => (
        <Form className={classes.form}>
          <Grid container spacing={gridSpace} alignItems="flex-end">
            {searchBox}
            {form}
            {/* <Grid item xs={10} sx={{ background: 'red' }}></Grid> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {onCancel && (
                <Button
                  color="grayF4"
                  onClick={() => {
                    resetForm(defaultValues)
                    onCancel({ ...values, ...defaultValues })
                  }}
                >
                  {t('common.cancel')}
                </Button>
              )}

              <Button type="submit">{t('common.search')}</Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

QuickFilter.defaultProps = {
  onApply: () => {},
  values: {},
  defaultValues: {},
  searchBox: null,
}

QuickFilter.propTypes = {
  form: PropTypes.node,
  values: PropTypes.shape(),
  onApply: PropTypes.func,
  defaultValues: PropTypes.shape(),
  validationSchema: PropTypes.shape(),
  searchBox: PropTypes.node,
}

export default QuickFilter
