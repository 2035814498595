import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiSendMailBu = (body) => {
  const uri = `/mail/bussiness-travel`
  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiSendMailOt = (params) => {
  const uri = `/mail/overtime`
  return api.post(uri, params)
}
