import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useApp } from '~/common/hooks/useApp'

import { LGA_VEHICLE_VALUE } from '../../constants'

export const TAB_VALUE = {
  rosterCalendar: 0,
  overtime: 1,
  businessTravel: 2,
}

export const tabList = (t, totals) => {
  const { canAccess } = useApp()
  const { totalRosterCalendar, totalOvertimePlan, totalBussinessTravel } =
    totals
  const tabs = []

  if (canAccess(FUNCTION_CODE.USER_LIST_ROSTER_CALENDAR)) {
    tabs.push({
      label: `${t('menu.rosterCalendar')} (${totalRosterCalendar})`,
      value: TAB_VALUE.rosterCalendar,
    })
  }

  if (canAccess(FUNCTION_CODE.USER_LIST_OVERTIME_PLAN)) {
    tabs.push({
      label: `${t('menu.overtime')} (${totalOvertimePlan})`,
      value: TAB_VALUE.overtime,
    })
  }

  if (canAccess(FUNCTION_CODE.USER_LIST_BUSSINESS_TRAVEL)) {
    tabs.push({
      label: `${t('menu.businessTravel')} (${totalBussinessTravel})`,
      value: TAB_VALUE.businessTravel,
    })
  }

  return tabs
}

export const ATTENDANCE_SEARCH_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: FUNCTION_CODE.USER_LIST_ROSTER_CALENDAR,
  [TAB_VALUE.overtime]: FUNCTION_CODE.USER_LIST_OVERTIME_PLAN,
  [TAB_VALUE.businessTravel]: FUNCTION_CODE.USER_LIST_BUSSINESS_TRAVEL,
}

export const ATTENDANCE_SYNC_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: FUNCTION_CODE.USER_SYNCHRONIZE_ROSTER_CALENDAR,
  [TAB_VALUE.overtime]: FUNCTION_CODE.USER_SYNCHRONIZE_OVERTIME_PLAN,
  [TAB_VALUE.businessTravel]: FUNCTION_CODE.USER_SYNCHRONIZE_BUSSINESS_TRAVEL,
}

export const ATTENDANCE_REGISTER_VEHICLE_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: '_',
  [TAB_VALUE.overtime]: FUNCTION_CODE.USER_REGISTER_VEHICLE_OVERTIME_PLAN,
  [TAB_VALUE.businessTravel]:
    FUNCTION_CODE.USER_REGISTER_VEHICLE_BUSSINESS_TRAVEL,
}

export const ATTENDANCE_EXPORT_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: FUNCTION_CODE.USER_EXPORT_ROSTER_CALENDAR,
  [TAB_VALUE.overtime]: FUNCTION_CODE.USER_EXPORT_OVERTIME_PLAN,
  [TAB_VALUE.businessTravel]: FUNCTION_CODE.USER_EXPORT_BUSSINESS_TRAVEL,
}

export const ATTENDANCE_IMPORT_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: FUNCTION_CODE.USER_IMPORT_ROSTER_CALENDAR,
  [TAB_VALUE.overtime]: '_',
  [TAB_VALUE.businessTravel]: '_',
}

export const ATTENDANCE_SEND_EMAIL_PERMISSION = {
  [TAB_VALUE.rosterCalendar]: '_',
  [TAB_VALUE.overtime]:
    FUNCTION_CODE.USER_SEND_MAIL_REGISTER_VEHICLE_OVERTIME_PLAN,
  [TAB_VALUE.businessTravel]:
    FUNCTION_CODE.USER_SEND_MAIL_REGISTER_VEHICLE_BUSSINESS_TRAVEL,
}

export const LGA_DISTRIBUTE_VEHICLE_OPTIONS = [
  {
    id: LGA_VEHICLE_VALUE.NO,
    text: 'common.notDistributeVehicle',
  },
  {
    id: LGA_VEHICLE_VALUE.YES,
    text: 'common.distributeVehicle',
  },
]
