import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_POSITION,
  MASTER_GET_DETAIL_POSITION_FAILED,
  MASTER_GET_DETAIL_POSITION_RESET,
  MASTER_GET_DETAIL_POSITION_SUCCESS,
  MASTER_GET_LIST_POSITION,
  MASTER_GET_LIST_POSITION_FAILED,
  MASTER_GET_LIST_POSITION_RESET,
  MASTER_GET_LIST_POSITION_SUCCESS,
  MASTER_SYNC_POSITION,
  MASTER_SYNC_POSITION_FAILED,
  MASTER_SYNC_POSITION_RESET,
  MASTER_SYNC_POSITION_SUCCESS,
} from '../actions/position'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listPosition(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_POSITION:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_POSITION_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_POSITION_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_POSITION_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailPosition(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_POSITION:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_POSITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_POSITION_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_POSITION_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncPosition(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_POSITION:
      return { ...state, isLoading: true }
    case MASTER_SYNC_POSITION_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_POSITION_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_POSITION_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listPosition,
  detailPosition,
  syncPosition,
})
