import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
  useRef,
} from 'react'

import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
// import { useNotification } from '~/modules/shared/redux/hooks/useNotification'
import { isAuth } from '~/utils'

export const AppContext = createContext({})

export const AppProvider = ({ children }) => {
  const [refreshKey, setRefreshKey] = useState('')
  const [isBottomActionBarExisted, setIsBottomActionBarExisted] =
    useState(false)
  const pageTopRef = useRef()
  const pageBottomRef = useRef()

  // const {
  //     actions: notiActions
  // } = useNotification()

  const {
    data: { userInfo },
    actions,
  } = useUserInfo()

  const isAuthenticated = isAuth()

  useEffect(() => {
    if (isAuthenticated) {
      actions.getUserInfo()
    }
  }, [isAuthenticated])

  // useEffect(() => {
  //   if (userInfo?.statusNotification) {
  //     notiActions.getNotifications({ limit: ROWS_PER_PAGE_OPTIONS[0] })
  //   }
  // }, [userInfo?.statusNotification])

  const canAccess = useCallback(
    (code) => {
      const userPermissions = userInfo?.userPermissions || []

      if (!code) return true

      return userPermissions.some((item) => item?.code === code)
    },
    [userInfo?.userPermissions],
  )

  const scrollToTop = useCallback(
    (options) => {
      pageTopRef?.current?.scrollIntoView(options)
    },
    [pageTopRef],
  )

  const scrollToBottom = useCallback(
    (options) => {
      pageBottomRef?.current?.scrollIntoView(options)
    },
    [pageBottomRef],
  )

  const value = {
    refreshKey,
    setRefreshKey,
    clearRefreshKey: () => setRefreshKey(''),
    canAccess,
    pageTopRef,
    pageBottomRef,
    scrollToTop,
    scrollToBottom,
    isBottomActionBarExisted,
    setIsBottomActionBarExisted,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContext
