import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListFactory = (params) => {
  const uri = `/factories`
  return api.get(uri, params)
}

export const apiGetListFactoryList = (params) => {
  const uri = `/factories/list`
  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailFactory = (id) => {
  const uri = `/factories/${id}`
  return api.get(uri)
}

/**
 * @returns {Promise}
 */
export const apiSyncFactory = (body = {}) => {
  const uri = `/sync/org-structure`
  return api.post(uri, body)
}

/**
 * @returns {Promise}
 */
export const apiUpdateFactory = ({ id, body }) => {
  const uri = `/factories/${id}`
  return api.patch(uri, body)
}

export const apiUpdateChooseOrg = (body) => {
  const uri = `/factories/change-department`
  return api.post(uri, body)
}

/**
 * @returns {Promise}
 */
export const apiCreateFactory = (body = {}) => {
  const uri = `/factories`
  return api.post(uri, body)
}

/**
 * @param {code: []} params Params will be sent to server
 * @returns {Promise}
 */
export const apiDeleteFactory = (params) => {
  const uri = `/factories`
  return api.delete(uri, params)
}

/** status
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveFactory = (id) => {
  const uri = `/factories/${id}/active`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveFactorys = (body) => {
  const uri = `/factories/active/multiple`

  return api.post(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveFactory = (id) => {
  const uri = `/factories/${id}/inactive`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveFactorys = (body) => {
  const uri = `/factories/inactive/multiple`

  return api.post(uri, body)
}
