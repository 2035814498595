import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import HotKeys from '~/components/HotKeys'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import {
  EXPORT_TYPE,
  IMPORT_TYPE,
  STATUS_OPTIONS,
  STATUS_VALUE,
} from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import { apiGetDetailVehicleCategories } from '~/modules/master/redux/apis/vehicle-categories'
import useVehicleCategories from '~/modules/master/redux/hooks/useVehicleCategories'
import { ROUTE } from '~/modules/master/routes/config'
import { convertFilterParams, convertSortParams } from '~/utils'

import { tabList } from '../constants'
import CreateUpdate from '../form'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    route: ROUTE.VEHICLE_CATEGORIES.LIST.PATH,
    title: ROUTE.VEHICLE_CATEGORIES.LIST.TITLE,
  },
]
const DEFAULT_FILTERS = {
  code: '',
  isActive: STATUS_VALUE.active,
}

const ListVehicleCategories = () => {
  const { t } = useTranslation(['buseye'])

  const history = useHistory()

  const [selectedRows, setSelectedRows] = useState([])
  // const [showDetailId, setShowDetailId] = useState(null)
  const [createUpdate, setCreateUpdate] = useState(null)
  const [deleteItems, setDeleteItems] = useState(null)
  const [changeStatus, setChangeStatus] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listVehicleCategories: { data, total, isLoading },
    },
    actions: {
      actGetListVehicleCategories,
      actDeleteVehicleCategories,
      actChangeStatusVehicleCategories,
    },
  } = useVehicleCategories()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('vehicleCategories.code'),
      width: 80,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { code } = params?.row
        return code
        // return <TextLink text={code} onClick={() => handleShowDetail(id)} />
      },
    },
    {
      field: 'name',
      headerName: t('vehicleCategories.name'),
      width: 150,
      sortable: true,
    },
    {
      field: 'totalSeat',
      headerName: t('vehicleCategories.totalSeat'),
      width: 150,
    },
    {
      field: 'description',
      headerName: t('vehicleCategories.description'),
      width: 350,
      sortable: true,
    },
    {
      field: 'isActive',
      headerName: t('vehicleCategories.isActive'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 150,
      renderCell: (params) => {
        const { isActive, id } = params.row

        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton
              icon="edit"
              onClick={() => handleClickUpdate(params.row)}
              tooltipText={t('common.update')}
            />
            <IconButton
              icon="clone"
              onClick={() => handleClickClone(params.row)}
              tooltipText={t('common.clone')}
            />
            <IconButton
              icon={isActive ? 'locked' : 'unlock'}
              tooltipText={t('common.activePending')}
              onClick={() => openChangeStatus({ isActive, id })}
            />
            {isActive === STATUS_VALUE.pending && (
              <IconButton
                icon="delete"
                tooltipText={t('common.delete')}
                onClick={() => openConfirmDelete([params.row])}
              />
            )}
          </Box>
        )
      },
    },
  ])

  const handleDelete = () => {
    if (!deleteItems) return
    actDeleteVehicleCategories(
      { codes: deleteItems.map((item) => item.code) },
      refreshData,
    )
  }

  // const handleShowDetail = (id) => {
  //   setShowDetailId(id)
  // }

  // const handleCloseDetail = () => {
  //   setShowDetailId(null)
  //   actGetDetailVehicleCategoriesReset()
  // }

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters

    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(dataFilter, columns),
      sort: convertSortParams(sort),
    }
    actGetListVehicleCategories(params)
    closeConfirmDelete()
    closeChangeStatus()
    setSelectedRows([])
  }

  const handleClickCreateNew = () => {
    setCreateUpdate({})
  }

  const handleClickUpdate = async (data) => {
    if (!data?.id) return
    const res = await apiGetDetailVehicleCategories(data?.id)
    setCreateUpdate(res.data)
  }

  const handleClickClone = (data) => {
    const formData = { ...data, id: undefined, code: '' }
    setCreateUpdate(formData)
  }

  const handleSubmitSuccess = () => {
    setCreateUpdate(null)
    refreshData()
  }

  const handleCloseCreateUpdate = () => {
    setCreateUpdate(null)
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const openChangeStatus = (val) => {
    if (Array.isArray(val) && val.length > 0) {
      const status = val[0].isActive
      const checkUpdate = val.some((item) => item.isActive !== status)
      if (checkUpdate) return
    }
    setChangeStatus(val)
  }

  const closeChangeStatus = () => {
    setChangeStatus(null)
  }

  const handlChangeStatus = () => {
    if (Array.isArray(changeStatus) && changeStatus.length > 0) {
      const status = changeStatus[0].isActive
      const codes = changeStatus.map((item) => item.code)
      actChangeStatusVehicleCategories(
        { params: { codes }, isActive: status },
        refreshData,
      )
    } else {
      actChangeStatusVehicleCategories(
        { params: changeStatus.id, isActive: changeStatus.isActive },
        refreshData,
      )
    }
  }

  let actionHeader = [
    <IconButton
      icon="add"
      title={t('common.create')}
      onClick={handleClickCreateNew}
      sx={{ marginLeft: '10px', marginRight: '10px' }}
    />,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <IconButton
        icon="locked"
        title={`${t('common.active')}/ ${t('common.pending')}`}
        sx={{ marginLeft: '10px', marginRight: '10px' }}
        onClick={() => openChangeStatus(selectedRows)}
      />,
      <IconButton
        icon="delete"
        title={t('common.delete')}
        sx={{ marginLeft: '10px', marginRight: '10px' }}
        onClick={() => openConfirmDelete(selectedRows)}
      />,
    ]
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.vehicleCategories')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <TaskBar
        left={actionHeader}
        right={[
          <ImportExport
            name={t('vehicleCategories.fileNameExport')}
            onImport={(body) =>
              apiImport({ body, type: IMPORT_TYPE.VEHICLE_CATEGORY })
            }
            importMessage={{
              success: t('vehicleCategories.notification.importSuccess'),
            }}
            onExport={() => {
              const params = {
                filter: convertFilterParams(filters, [
                  { field: 'createdAt', filterFormat: 'date' },
                ]),
                sort: convertSortParams(sort),
                type: EXPORT_TYPE.VEHICLE_CATEGORY,
              }
              if (!isEmpty(selectedRows)) {
                params.ids = JSON.stringify(
                  selectedRows?.map((x) => ({ id: x?.id })),
                )
              }

              return apiExport(params)
            }}
            onDownloadTemplate={() =>
              apiGetTemplate(EXPORT_TYPE.VEHICLE_CATEGORY)
            }
            onRefresh={refreshData}
          />,
        ]}
      />
      <FilterArea
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: <FilterForm />,
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        tabs={tabElement}
      />
      {/* <DetailProfile
        open={showDetailId !== null}
        showDetailId={showDetailId}
        t={t}
        onClose={handleCloseDetail}
      /> */}
      <CreateUpdate
        open={!!createUpdate}
        data={createUpdate}
        t={t}
        onClose={handleCloseCreateUpdate}
        // onSubmit={handleSubmit}
        handleSubmitSuccess={handleSubmitSuccess}
      />
      <PopupConfirm
        t={t}
        title={t('vehicleCategories.modalChangeStatus.title')}
        content={t('vehicleCategories.modalChangeStatus.content')}
        open={!!changeStatus}
        onConfirm={handlChangeStatus}
        onCancel={closeChangeStatus}
      />
      <PopupConfirm
        t={t}
        title={t('vehicleCategories.modalDelete.title')}
        content={t('vehicleCategories.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default ListVehicleCategories
