/*eslint no-param-reassign: "error"*/

import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
} from '@mui/material'
import { Box } from '@mui/system'
import { isEmpty } from 'lodash'

import { TEXTFIELD_ALLOW } from '~/common/constants'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'

import { FREQUENCY, TIME_RUN_CHECKBOXS } from '../constants'

const LABEL_WIDTH = '200px'

const SettingForm = ({ t, name, errors, values, setFieldValue }) => {
  const handleChangeFrequency = (val) => {
    if (val === FREQUENCY.HOUR) {
      setFieldValue(`${name}.${FREQUENCY.MINUTE}`, null)
    } else {
      setFieldValue(`${name}.${FREQUENCY.HOUR}`, null)
    }
  }
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        // columnSpacing={{ xl: 1, xs: 1 }}
        sx={{
          backgroundColor: '#EFF5FB',
          padding: '24px',
          borderRadius: '8px',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={5.5} xs={12}>
          <Box
            sx={{
              display: 'flex',
              // width: '40%',
              justifyContent: 'space-between',
            }}
          >
            <Box mr={1}>
              <FormLabel sx={{ color: '#222' }} required={true}>
                {t(`setting.${name}`)}
              </FormLabel>
            </Box>
            <Field.Switch color="success" name={`${name}.isActive`} />
          </Box>
        </Grid>
        <Grid item lg={3} xs={12}>
          <Field.DatePicker
            label={t('setting.initDay')}
            name={`${name}.initDay`}
            placeholder={'24h'}
            disabled={!values[name].isActive}
            required
          />
        </Grid>
        <Grid item lg={3} xs={12}>
          <Field.TextField
            label={t('setting.initHour')}
            name={`${name}.initHour`}
            type="number"
            allow={TEXTFIELD_ALLOW.NUMERIC}
            disabled={!values[name].isActive}
            placeholder={'12'}
          />
        </Grid>
        <Grid item lg={1} xs={0}></Grid>
        <Grid item lg={11} xs={12}>
          <LV
            labelColor={'#222'}
            label={t('setting.repeat')}
            sx={{ display: 'flex', alignItems: 'center' }}
            value={
              <Box>
                {TIME_RUN_CHECKBOXS.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.name}
                      name={`${name}.${item.name}`}
                      control={<Field.Checkbox />}
                      label={t(item.text)}
                      sx={{ marginRight: '15px' }}
                    />
                  )
                })}

                {errors && !isEmpty(errors) ? (
                  <FormHelperText error>
                    {errors[`${name}.timeRun`]}
                  </FormHelperText>
                ) : (
                  ''
                )}
              </Box>
            }
          />
        </Grid>
        <Grid item lg={0.5} xs={0}></Grid>
        <Grid item lg={5.5} xs={12}>
          <Field.TextField
            label={t('setting.beginHour')}
            name={`${name}.beginHour`}
            type="number"
            labelWidth={LABEL_WIDTH}
            allow={TEXTFIELD_ALLOW.NUMERIC}
            InputProps={{ width: '100px' }}
            placeholder={'12'}
            required
          />
        </Grid>
        <Grid item lg={5.5} xs={12}>
          <Field.TextField
            label={t('setting.endHour')}
            name={`${name}.endHour`}
            type="number"
            labelWidth={LABEL_WIDTH}
            allow={TEXTFIELD_ALLOW.NUMERIC}
            InputProps={{ width: '100px' }}
            placeholder={'12'}
          />
        </Grid>
        <Grid item lg={1} xs={0}></Grid>
        <Grid
          item
          lg={11}
          xs={12}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box mr={5}>{t('setting.frequency')}</Box>
          <Field.RadioGroup
            row
            name={`${name}.frequency`}
            onChange={handleChangeFrequency}
            sx={{
              width: '55%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              mr={5}
            >
              <FormControlLabel
                value={FREQUENCY.HOUR}
                control={<Radio sx={{ p: '6px' }} />}
                label={''}
              />
              <Field.TextField
                name={`${name}.hour`}
                type="number"
                labelWidth={LABEL_WIDTH}
                allow={TEXTFIELD_ALLOW.NUMERIC}
                inputSx={{ width: '115px', flex: 'unset' }}
                placeholder={'12'}
                disabled={values[name].frequency !== FREQUENCY.HOUR}
              />

              <Box ml={1}>{t('hours')}</Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                value={FREQUENCY.MINUTE}
                control={<Radio sx={{ p: '6px' }} />}
                label={''}
              />
              <Field.TextField
                name={`${name}.minute`}
                type="number"
                inputSx={{ width: '115px', flex: 'unset' }}
                allow={TEXTFIELD_ALLOW.NUMERIC}
                placeholder={'12'}
                disabled={values[name].frequency !== FREQUENCY.MINUTE}
              />
              <Box ml={1}>{t('minutes')}</Box>
            </Box>
          </Field.RadioGroup>
        </Grid>
      </Grid>
    </>
  )
}

export default SettingForm
