export const isAuth = () => {
  const sessionToken = sessionStorage.getItem('token')
  const localToken = localStorage.getItem('token')
  const localRefreshToken = localStorage.getItem('refreshToken')

  let isAuth = false
  if (sessionToken && !localToken) {
    isAuth = true
  }
  if (!sessionToken && localToken) {
    sessionStorage.setItem('token', localToken)
    sessionStorage.setItem('refreshToken', localRefreshToken)
    isAuth = true
  }
  if (sessionToken || localToken) {
    isAuth = true
  }
  return isAuth
}
