import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import TextLink from '~/components/TextLink'
import { EXPORT_TYPE, STATUS_OPTIONS } from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import usePickupPoint from '~/modules/master/redux/hooks/usePickupPoint'
import { ROUTE } from '~/modules/master/routes/config'
import { convertFilterParams, convertSortParams } from '~/utils'

import { tabList } from '../constants'
import DetailProfile from '../detail'
import CreateUpdate from '../form'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    route: ROUTE.PICKUP_POINT.LIST.PATH,
    title: ROUTE.PICKUP_POINT.LIST.TITLE,
  },
]

const ListPickupPoint = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { canAccess } = useApp()

  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    costCenterIds: [],
    status: null,
  }

  const [selectedRows, setSelectedRows] = useState([])
  const [showDetailId, setShowDetailId] = useState(null)
  const [createUpdate, setCreateUpdate] = useState(null)
  const [deleteItems, setDeleteItems] = useState(null)
  const [changeStatus, setChangeStatus] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState()

  const {
    data: {
      listPickupPoint: { data, total, isLoading },
    },
    actions: {
      actGetListPickupPoint,
      actGetDetailPickupPointReset,
      actDeletePickupPoint,
      actChangeStatusPickupPoint,
    },
  } = usePickupPoint()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('pickupPoint.code'),
      width: 80,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { code, id } = params?.row
        return <TextLink text={code} onClick={() => handleShowDetail(id)} />
      },
    },
    {
      field: 'name',
      headerName: t('pickupPoint.name'),
      width: 150,
      sortable: true,
    },
    {
      field: 'timeDeparture',
      headerName: t('pickupPoint.timeDeparture'),
      width: 150,
    },
    {
      field: 'timeArrival',
      headerName: t('pickupPoint.timeArrival'),
      width: 150,
    },
    {
      field: 'location',
      headerName: t('pickupPoint.location'),
      width: 150,
      sortable: true,
    },
    {
      field: 'isActive',
      headerName: t('pickupPoint.isActive'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 150,
      renderCell: (params) => {
        const { isActive, id } = params.row

        return (
          <Box sx={{ display: 'flex' }}>
            <Guard code={FUNCTION_CODE.USER_UPDATE_PICKUP_POINT}>
              <IconButton
                icon="edit"
                onClick={() => handleClickUpdate(params.row)}
                tooltipText={t('common.update')}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CREATE_PICKUP_POINT}>
              <IconButton
                icon="clone"
                onClick={() => handleClickClone(params.row)}
                tooltipText={t('common.clone')}
              />
            </Guard>
            <Guard
              code={FUNCTION_CODE.USER_CHANGE_STATUS_PICKUP_POINT_PERMISSION}
            >
              <IconButton
                icon={isActive ? 'locked' : 'unlock'}
                tooltipText={t('common.activePending')}
                onClick={() => openChangeStatus({ isActive, id })}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_DELETE_PICKUP_POINT}>
              <IconButton
                icon="delete"
                tooltipText={t('common.delete')}
                onClick={() => openConfirmDelete([params.row])}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const handleDelete = () => {
    if (!deleteItems) return

    actDeletePickupPoint(
      { codes: deleteItems.map((item) => item.code) },
      refreshData,
    )
  }

  const handleShowDetail = (id) => {
    setShowDetailId(id)
  }

  const handleCloseDetail = () => {
    setShowDetailId(null)
    actGetDetailPickupPointReset()
  }

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(dataFilter, columns),
      sort: convertSortParams(sort),
    }
    actGetListPickupPoint(params)
    setCreateUpdate(null)
    setShowDetailId(null)
    closeConfirmDelete()
    closeChangeStatus()
    setSelectedRows([])
  }

  const handleClickCreateNew = () => {
    setCreateUpdate({})
  }

  const handleClickUpdate = (data) => {
    setCreateUpdate(data)
    setShowDetailId(null)
  }

  const handleClickClone = (data) => {
    const formData = { ...data, id: undefined, code: '' }
    setCreateUpdate(formData)
  }

  const handleSubmitSuccess = () => {
    setCreateUpdate(null)
    refreshData()
  }

  const handleCloseCreateUpdate = () => {
    setCreateUpdate(null)
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_CREATE_PICKUP_POINT}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={handleClickCreateNew}
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <Guard code={FUNCTION_CODE.USER_ACTIVE_PICKUP_POINT}>
        <IconButton
          icon="locked"
          title={`${t('common.active')}/ ${t('common.pending')}`}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openChangeStatus(selectedRows)}
        />
      </Guard>,
      <Guard code={FUNCTION_CODE.USER_DELETE_PICKUP_POINT}>
        <IconButton
          icon="delete"
          title={t('common.delete')}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openConfirmDelete(selectedRows)}
        />
      </Guard>,
    ]
  }

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const openChangeStatus = (val) => {
    if (Array.isArray(val) && val.length > 0) {
      const status = val[0].isActive
      const checkUpdate = val.some((item) => item.isActive !== status)
      if (checkUpdate) return
    }
    setChangeStatus(val)
  }

  const closeChangeStatus = () => {
    setChangeStatus(null)
  }

  const handlChangeStatus = () => {
    if (Array.isArray(changeStatus) && changeStatus.length > 0) {
      const status = changeStatus[0].isActive
      const codes = changeStatus.map((item) => item.code)
      actChangeStatusPickupPoint(
        { params: { codes }, isActive: status },
        refreshData,
      )
    } else {
      actChangeStatusPickupPoint(
        { params: changeStatus.id, isActive: changeStatus.isActive },
        refreshData,
      )
    }
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.pickupPoint')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <TaskBar
        left={actionHeader}
        right={[
          <ImportExport
            name={t('pickupPoint.fileNameExport')}
            onImport={
              canAccess(FUNCTION_CODE.USER_IMPORT_PICKUP_POINT)
                ? (body) => apiImport({ body, type: EXPORT_TYPE.PICKUP_POINT })
                : null
            }
            importMessage={{
              success: t('pickupPoint.notification.importSuccess'),
            }}
            onExport={
              canAccess(FUNCTION_CODE.USER_EXPORT_PICKUP_POINT)
                ? () => {
                    const params = {
                      filter: convertFilterParams(filters, [
                        { field: 'createdAt', filterFormat: 'date' },
                      ]),
                      sort: convertSortParams(sort),
                      type: EXPORT_TYPE.PICKUP_POINT,
                    }
                    if (!isEmpty(selectedRows)) {
                      params.ids = JSON.stringify(
                        selectedRows?.map((x) => ({ id: x?.id })),
                      )
                    }

                    return apiExport(params)
                  }
                : null
            }
            onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.PICKUP_POINT)}
            onRefresh={refreshData}
          />,
        ]}
      />
      <Guard code={FUNCTION_CODE.USER_SEARCH_PICKUP_POINT}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        tabs={tabElement}
      />
      <DetailProfile
        open={showDetailId !== null}
        showDetailId={showDetailId}
        t={t}
        openConfirmDelete={openConfirmDelete}
        onClose={handleCloseDetail}
        onUpdate={handleClickUpdate}
      />
      <CreateUpdate
        open={!!createUpdate}
        data={createUpdate}
        t={t}
        onClose={handleCloseCreateUpdate}
        handleSubmitSuccess={handleSubmitSuccess}
        openConfirmDelete={openConfirmDelete}
      />
      <PopupConfirm
        t={t}
        title={t('pickupPoint.modalChangeStatus.title')}
        content={t('pickupPoint.modalChangeStatus.content')}
        open={!!changeStatus}
        onConfirm={handlChangeStatus}
        onCancel={closeChangeStatus}
      />
      <PopupConfirm
        t={t}
        title={t('pickupPoint.modalDelete.title')}
        content={t('pickupPoint.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default ListPickupPoint
