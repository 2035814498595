import { useEffect, useState } from 'react'

import { Box, Button, Grid } from '@mui/material'

import {
  NOTIFICATION_TYPE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import Guard from '~/components/Guard'
import LV from '~/components/LabelValue'
import Status from '~/components/Status'
import TextField from '~/components/TextField'
import { STATUS_OPTIONS } from '~/modules/master/constants'
import { apiUpdateOrgStructure } from '~/modules/master/redux/apis/org-structure'
import addNotification from '~/utils/toast'

const FormData = ({ t, data, setLoading, refreshData }) => {
  const [abbreviation, setAbbreviation] = useState('')
  // apiUpdateOrgStructure

  useEffect(() => {
    if (data) {
      setAbbreviation(data.abbreviation || '')
    }
  }, [data])

  const handleChangeName = (_, val) => {
    setAbbreviation(val)
  }

  const handleReset = () => {
    setAbbreviation(data.abbreviation || '')
  }

  const handleSubmit = () => {
    if (!data?.id) return
    setLoading(true)
    apiUpdateOrgStructure({ id: data.id, abbreviation })
      .then((res) => {
        if (res?.statusCode === 200) {
          addNotification(
            'orgStructure.notification.changeNameSuccess',
            NOTIFICATION_TYPE.SUCCESS,
          )
        } else {
          addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
        }
        setLoading(false)
        refreshData()
      })
      .catch((err) => {
        addNotification(err?.message, NOTIFICATION_TYPE.ERROR)
        setLoading(false)
      })
  }

  return (
    <Box>
      <Box
        sx={{
          padding: 2,
          backgroundColor: '#DAE7F3',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '49%' }}>
          <LV
            label={t('orgStructure.code')}
            labelColor="#222222"
            valueSx={{ fontWeight: 'bold' }}
            value={data.code}
          />
        </Box>
        <Box sx={{ width: '49%' }}>
          <LV
            label={t('orgStructure.parentCode')}
            labelColor="#222222"
            valueSx={{ fontWeight: 'bold' }}
            value={data.parentCode}
          />
        </Box>
        <Box sx={{ width: '49%', mt: 1 }}>
          <LV
            label={t('orgStructure.name')}
            labelColor="#222222"
            valueSx={{ fontWeight: 'bold' }}
            value={data.name}
          />
        </Box>
        <Box sx={{ width: '49%', mt: 1 }}>
          <LV
            label={t('orgStructure.parentName')}
            labelColor="#222222"
            valueSx={{ fontWeight: 'bold' }}
            value={data.parentName}
          />
        </Box>
        <Box sx={{ width: '49%', mt: 1 }}>
          <TextField
            name="abbreviation"
            placeholder={t('orgStructure.abbreviation')}
            label={t('orgStructure.abbreviation')}
            value={abbreviation}
            onChange={handleChangeName}
            inputProps={{
              maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
            }}
            allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
            disabled={!data?.id}
          />
        </Box>
        <Box sx={{ width: '49%', mt: 1 }} />
        <Box sx={{ width: '49%', mt: 1 }}>
          <LV
            label={t('orgStructure.status')}
            labelColor="#222222"
            valueSx={{ fontWeight: 'bold' }}
            value={
              data.isActive !== null ? (
                <Status options={STATUS_OPTIONS} value={data.isActive} />
              ) : (
                data.isActive
              )
            }
          />
        </Box>
      </Box>
      {data?.id && (
        <Box>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                sx={{ marginRight: '10px' }}
                color="grayF4"
                onClick={handleReset}
              >
                {t('actionBar.cancel')}
              </Button>
              <Guard code={FUNCTION_CODE.USER_UPDATE_ORGANIZATION_STRUCTURE}>
                <Button
                  type="button"
                  sx={{ marginRight: '10px' }}
                  onClick={() => handleSubmit()}
                >
                  {t('actionBar.save')}
                </Button>
              </Guard>
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default FormData
