import { combineReducers } from 'redux'

import {
  MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL,
  MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_FAILED,
  MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_RESET,
  MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS,
  MASTER_DETAIL_REGISTER_VEHICLE_OT,
  MASTER_DETAIL_REGISTER_VEHICLE_OT_FAILED,
  MASTER_DETAIL_REGISTER_VEHICLE_OT_RESET,
  MASTER_DETAIL_REGISTER_VEHICLE_OT_SUCCESS,
  MASTER_GET_LIST_ATTENDANCE,
  MASTER_GET_LIST_ATTENDANCE_FAILED,
  MASTER_GET_LIST_ATTENDANCE_RESET,
  MASTER_GET_LIST_ATTENDANCE_SUCCESS,
  MASTER_REGISTER_VEHICLE_BU_TRAVEL,
  MASTER_REGISTER_VEHICLE_BU_TRAVEL_FAILED,
  MASTER_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS,
  MASTER_REGISTER_VEHICLE_OT,
  MASTER_REGISTER_VEHICLE_OT_FAILED,
  MASTER_REGISTER_VEHICLE_OT_SUCCESS,
  MASTER_SYNC_ATTENDANCE,
  MASTER_SYNC_ATTENDANCE_FAILED,
  MASTER_SYNC_ATTENDANCE_RESET,
  MASTER_SYNC_ATTENDANCE_SUCCESS,
} from '../actions/attendance'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listAttendance(
  state = {
    isLoading: false,
    data: [],
    totalBussinessTravel: 0,
    totalOvertimePlan: 0,
    totalRosterCalendar: 0,
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_ATTENDANCE:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_ATTENDANCE_SUCCESS:
      const {
        data,
        totalBussinessTravel,
        totalOvertimePlan,
        totalRosterCalendar,
      } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        totalBussinessTravel,
        totalOvertimePlan,
        totalRosterCalendar,
      }
    case MASTER_GET_LIST_ATTENDANCE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_ATTENDANCE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function syncAttendance(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_ATTENDANCE:
      return { ...state, isLoading: true }
    case MASTER_SYNC_ATTENDANCE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_ATTENDANCE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_ATTENDANCE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailRegisterVehicleOt(
  state = {
    isLoading: false,
    data: null,
  },
  action,
) {
  switch (action.type) {
    case MASTER_DETAIL_REGISTER_VEHICLE_OT:
      return { ...state, isLoading: true }
    case MASTER_DETAIL_REGISTER_VEHICLE_OT_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_DETAIL_REGISTER_VEHICLE_OT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DETAIL_REGISTER_VEHICLE_OT_RESET:
      return {
        isLoading: false,
        data: null,
      }
    default:
      return state
  }
}

function registerVehicleOt(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_REGISTER_VEHICLE_OT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_REGISTER_VEHICLE_OT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_REGISTER_VEHICLE_OT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function detailRegisterVehicleBuTravel(
  state = {
    isLoading: false,
    data: {},
  },
  action,
) {
  switch (action.type) {
    case MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL:
      return { ...state, isLoading: true }
    case MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_RESET:
      return {
        isLoading: false,
        data: null,
      }
    default:
      return state
  }
}

function registerVehicleBuTravel(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_REGISTER_VEHICLE_BU_TRAVEL:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_REGISTER_VEHICLE_BU_TRAVEL_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listAttendance,
  syncAttendance,
  detailRegisterVehicleOt,
  registerVehicleOt,
  detailRegisterVehicleBuTravel,
  registerVehicleBuTravel,
})
