import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { STATUS_OPTIONS } from '~/modules/master/constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={3}>
        <Field.TextField
          name="keyword"
          placeholder={t('common.inputKeyWord')}
          inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Field.Autocomplete
          label={t('common.status')}
          placeholder={t('common.status')}
          name="isActive"
          options={STATUS_OPTIONS}
          getOptionLabel={(opt) => t(opt?.text) || ''}
          getOptionValue={(opt) => opt?.id}
          labelWidth={100}
          isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
        />
      </Grid>
    </>
  )
}

export default FilterForm
