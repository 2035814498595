import { combineReducers } from 'redux'

import attendance from './attendance'
import businessTravel from './business-travel'
import catShift from './cat-shift'
import email from './email'
import factory from './factory'
import orgStructure from './org-structure'
import overtime from './overtime'
import pickupPoint from './pickup-point'
import position from './position'
import profileCustom from './profile-custom'
import reportAll from './report-all'
import reportBr11 from './report-br11'
import reportFee from './report-fee'
import reportOt from './report-ot'
import rosterCalendar from './roster-calendar'
import route from './route'
import setting from './setting'
import vehicleCategories from './vehicle-categories'
import workSchedule from './work-schedule'

export default combineReducers({
  pickupPoint,
  route,
  businessTravel,
  profileCustom,
  overtime,
  workSchedule,
  catShift,
  orgStructure,
  factory,
  attendance,
  vehicleCategories,
  position,
  rosterCalendar,
  reportBr11,
  reportAll,
  reportOt,
  setting,
  email,
  reportFee,
})
