import React, { useMemo } from 'react'

import { FormControlLabel, Grid, Radio } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'
import IconButton from '~/components/IconButton'
import LV from '~/components/LabelValue'
import { VEHICLE_TYPE_VALUE } from '~/modules/master/constants'
import palette from '~/themes/palette'

const ReceiptTable = ({ arrayHelpers, values, TABLE_ROW_DEFAULT }) => {
  const { t } = useTranslation(['buseye'])

  const columns = useMemo(() => {
    return [
      {
        field: 'Stt',
        headerName: 'Stt',
        width: 40,
        renderCell: (_, idx) => {
          return idx + 1
        },
      },
      {
        field: 'vehicleType',
        headerName: t('attendance.registerVehicle.description'),
        width: 200,
        renderCell: (_, idx) => {
          return (
            <Grid
              item
              sx={{
                '& > div > div:first-child': {
                  display: 'none !important',
                },
              }}
            >
              <LV
                labelColor={palette.text.main}
                value={
                  <Field.RadioGroup
                    row
                    name={`jobDetails[${idx}].vehicleType`}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      value={VEHICLE_TYPE_VALUE.taxi}
                      control={<Radio sx={{ p: '6px' }} />}
                      label={t('attendance.registerVehicle.taxi')}
                      sx={{ ml: '-6px', mr: 2 }}
                    />
                    <FormControlLabel
                      value={VEHICLE_TYPE_VALUE.grab}
                      control={<Radio sx={{ p: '6px' }} />}
                      label={t('attendance.registerVehicle.grab')}
                      sx={{ mr: 2 }}
                    />
                    <FormControlLabel
                      value={VEHICLE_TYPE_VALUE.route}
                      control={<Radio sx={{ p: '6px' }} />}
                      label={t('attendance.registerVehicle.routeVehicle')}
                    />
                  </Field.RadioGroup>
                }
              />
            </Grid>
          )
        },
      },
      {
        field: 'code',
        headerName: t('attendance.registerVehicle.code'),
        width: 200,
        visible: 'always',
        renderCell: (params, idx) => {
          return (
            <Field.TextField
              name={`jobDetails[${idx}].code`}
              placeholder={t('attendance.registerVehicle.code')}
              inputProps={{
                maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
              }}
            />
          )
        },
      },
      {
        field: 'createdBy',
        headerName: t('attendance.registerVehicle.createdBy'),
        width: 100,
        renderCell: (params) => {
          return params.row.createdBy?.fullName
        },
      },
      {
        field: 'createdAt',
        headerName: t('attendance.registerVehicle.dateCreate'),
        width: 100,
        renderCell: (params) => {
          const { createdAt } = params?.row
          return moment(createdAt).format('YYYY-MM-DD')
        },
      },

      {
        field: 'action',
        headerName: t('common.action'),
        width: 80,
        hide: values.jobDetails?.length <= 1,
        renderCell: (_, idx) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                icon="remove"
                onClick={() => arrayHelpers.remove(idx)}
                tooltipText={t('common.delete')}
              />
            </Box>
          )
        },
      },
    ]
  }, [t, values])

  const handleAddNew = () => {
    arrayHelpers.push(TABLE_ROW_DEFAULT)
  }

  const beforeTopbar = (
    <Button type="button" sx={{ marginRight: '10px' }} onClick={handleAddNew}>
      {t('attendance.registerVehicle.btnAdd')}
    </Button>
  )

  return (
    <DataTable
      rows={values.jobDetails || []}
      columns={columns}
      title={t('attendance.registerVehicle.tableDetail')}
      beforeTopbar={beforeTopbar}
      hideSetting
      hideFooter
    />
  )
}

export default ReceiptTable
