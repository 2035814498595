// Action: Get list
export const MASTER_GET_LIST_WORK_SCHEDULE = 'MASTER_GET_LIST_WORK_SCHEDULE'
export const MASTER_GET_LIST_WORK_SCHEDULE_SUCCESS =
  'MASTER_GET_LIST_WORK_SCHEDULE_SUCCESS'
export const MASTER_GET_LIST_WORK_SCHEDULE_FAILED =
  'MASTER_GET_LIST_WORK_SCHEDULE_FAILED'
export const MASTER_GET_LIST_WORK_SCHEDULE_RESET =
  'MASTER_GET_LIST_WORK_SCHEDULE_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_WORK_SCHEDULE = 'MASTER_GET_DETAIL_WORK_SCHEDULE'
export const MASTER_GET_DETAIL_WORK_SCHEDULE_SUCCESS =
  'MASTER_GET_DETAIL_WORK_SCHEDULE_SUCCESS'
export const MASTER_GET_DETAIL_WORK_SCHEDULE_FAILED =
  'MASTER_GET_DETAIL_WORK_SCHEDULE_FAILED'
export const MASTER_GET_DETAIL_WORK_SCHEDULE_RESET =
  'MASTER_GET_DETAIL_WORK_SCHEDULE_RESET'

// Action: update
export const MASTER_UPDATE_WORK_SCHEDULE = 'MASTER_UPDATE_WORK_SCHEDULE'
export const MASTER_UPDATE_WORK_SCHEDULE_SUCCESS =
  'MASTER_UPDATE_WORK_SCHEDULE_SUCCESS'
export const MASTER_UPDATE_WORK_SCHEDULE_FAILED =
  'MASTER_UPDATE_WORK_SCHEDULE_FAILED'

// Action: sync
export const MASTER_SYNC_WORK_SCHEDULE = 'MASTER_SYNC_WORK_SCHEDULE'
export const MASTER_SYNC_WORK_SCHEDULE_SUCCESS =
  'MASTER_SYNC_WORK_SCHEDULE_SUCCESS'
export const MASTER_SYNC_WORK_SCHEDULE_FAILED =
  'MASTER_SYNC_WORK_SCHEDULE_FAILED'
export const MASTER_SYNC_WORK_SCHEDULE_RESET = 'MASTER_SYNC_WORK_SCHEDULE_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListWorkSchedule(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_WORK_SCHEDULE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListWorkScheduleSuccess(payload) {
  return {
    type: MASTER_GET_LIST_WORK_SCHEDULE_SUCCESS,
    payload: payload,
  }
}

export function actGetListWorkScheduleFailed() {
  return {
    type: MASTER_GET_LIST_WORK_SCHEDULE_FAILED,
  }
}

export function actGetListWorkScheduleReset() {
  return {
    type: MASTER_GET_LIST_WORK_SCHEDULE_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailWorkSchedule(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_WORK_SCHEDULE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailWorkScheduleSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_WORK_SCHEDULE_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailWorkScheduleFailed() {
  return {
    type: MASTER_GET_DETAIL_WORK_SCHEDULE_FAILED,
  }
}

export function actGetDetailWorkScheduleReset() {
  return {
    type: MASTER_GET_DETAIL_WORK_SCHEDULE_RESET,
  }
}

/** update
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateWorkSchedule(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_WORK_SCHEDULE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateWorkScheduleSuccess(payload) {
  return {
    type: MASTER_UPDATE_WORK_SCHEDULE_SUCCESS,
    payload: payload,
  }
}

export function actUpdateWorkScheduleFailed() {
  return {
    type: MASTER_UPDATE_WORK_SCHEDULE_FAILED,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncWorkSchedule(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_WORK_SCHEDULE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncWorkScheduleSuccess(payload) {
  return {
    type: MASTER_SYNC_WORK_SCHEDULE_SUCCESS,
    payload: payload,
  }
}

export function actSyncWorkScheduleFailed() {
  return {
    type: MASTER_SYNC_WORK_SCHEDULE_FAILED,
  }
}

export function actSyncWorkScheduleReset() {
  return {
    type: MASTER_SYNC_WORK_SCHEDULE_RESET,
  }
}

export default {
  actGetListWorkSchedule,
  actGetListWorkScheduleSuccess,
  actGetListWorkScheduleFailed,
  actGetListWorkScheduleReset,
  actGetDetailWorkSchedule,
  actGetDetailWorkScheduleSuccess,
  actGetDetailWorkScheduleFailed,
  actGetDetailWorkScheduleReset,
  actUpdateWorkSchedule,
  actUpdateWorkScheduleSuccess,
  actUpdateWorkScheduleFailed,
  actSyncWorkSchedule,
  actSyncWorkScheduleSuccess,
  actSyncWorkScheduleFailed,
  actSyncWorkScheduleReset,
}
