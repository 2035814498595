import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actChangeStatusFactoryFailed,
  actChangeStatusFactorySuccess,
  MASTER_CHANGE_STATUS_FACTORY,
} from '../../actions/factory'
import {
  apiActiveFactory,
  apiActiveFactorys,
  apiInActiveFactory,
  apiInActiveFactorys,
} from '../../apis/factory'

const getApi = ({ params, isActive }) => {
  if (params?.codes) {
    return isActive ? apiInActiveFactorys : apiActiveFactorys
  } else {
    return isActive ? apiInActiveFactory : apiActiveFactory
  }
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doChangeStatus(action) {
  try {
    const response = yield call(
      getApi(action?.payload),
      action?.payload?.params,
    )

    if (response?.statusCode === 200) {
      yield put(actChangeStatusFactorySuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification('toast.changeStatusSuccess', NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actChangeStatusFactoryFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchChangeStatus() {
  yield takeLatest(MASTER_CHANGE_STATUS_FACTORY, doChangeStatus)
}
