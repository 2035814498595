/*eslint no-param-reassign: "error"*/
import { useEffect, useLayoutEffect, useMemo } from 'react'

import { FormControlLabel, FormHelperText, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { FieldArray, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  MODAL_MODE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { useQueryState } from '~/common/hooks'
import Button from '~/components/Button'
import { Field } from '~/components/Formik'
import HotKeys from '~/components/HotKeys'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import TaskBar from '~/components/TaskBar'
import { STATUS_OPTIONS, YES_NO_VALUE } from '~/modules/master/constants'
import useRoute from '~/modules/master/redux/hooks/useRoute'
import { ROUTE } from '~/modules/master/routes/config'
import qs from '~/utils/qs'

import {
  DEFAULT_DATA,
  getDayInWeekValue,
  TAB_FORM_VALUE,
  TAB_FORM_VALUE_MAPPING,
  TIME_RUN_CHECKBOXS,
} from '../constants'
import FormTable from './form-table/list'
import { validationSchema } from './schema'

const RouteForm = () => {
  const { t } = useTranslation(['buseye'])
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { cloneId } = qs.parse(location.search)
  const { id } = useParams()

  const formQuery = useQueryState()

  if (!formQuery.tab) {
    formQuery.tab = TAB_FORM_VALUE.pickupPoints
  }

  const MODE_MAP = {
    [ROUTE.ROUTE.CREATE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.ROUTE.UPDATE.PATH]: MODAL_MODE.UPDATE,
  }
  const mode = MODE_MAP[routeMatch.path]

  const breadcrumbs = [
    {
      route: ROUTE.ROUTE.LIST.PATH,
      title: ROUTE.ROUTE.LIST.TITLE,
    },
    {
      route:
        mode === MODAL_MODE.CREATE
          ? ROUTE.ROUTE.CREATE.PATH
          : ROUTE.ROUTE.UPDATE.PATH,
      title:
        mode === MODAL_MODE.CREATE
          ? ROUTE.ROUTE.CREATE.TITLE
          : ROUTE.ROUTE.UPDATE.TITLE,
    },
  ]

  const {
    data: {
      detailRoute: { data, isLoading },
      updateRoute: { isLoading: isLoadingUpdate },
    },
    actions: {
      actUpdateRoute,
      actCreateRoute,
      actGetDetailRoute,
      actGetDetailRouteReset,
      // actDeleteRoute,
    },
  } = useRoute()

  const initialValues = useMemo(() => {
    if (!isEmpty(data)) {
      const { ...detail } = data
      return {
        ...DEFAULT_DATA,
        ...detail,
        id: cloneId ? undefined : detail.id,
        code: cloneId ? undefined : detail.code,
        timeRun: getDayInWeekValue(data, true, false),
      }
    }
    return DEFAULT_DATA
  }, [id, data, getDayInWeekValue])

  useEffect(() => {
    if (id || cloneId) {
      actGetDetailRoute(id || cloneId)
    }
  }, [id, cloneId])

  useLayoutEffect(() => {
    return () => {
      actGetDetailRouteReset()
    }
  }, [])

  // const handleDelete = () => {
  //   actDeleteRoute({ codes: [data.code] }, goToList)
  // }

  const actionHeader = [
    // <Button
    //   type="button"
    //   sx={{ marginRight: '10px' }}
    //   color="success"
    //   variant="outlined"
    //   icon="edit"
    //   iconColor="success"
    //   onClick={() => {
    //     history.push(ROUTE.ROUTE.UPDATE.PATH.replace(':id', `${id}`))
    //   }}
    // >
    //   {t('actionBar.editInfo')}
    // </Button>,
    // <Button
    //   type="button"
    //   sx={{ marginRight: '10px' }}
    //   color="error"
    //   variant="outlined"
    //   icon={'deleteOutline'}
    //   onClick={handleDelete}
    // >
    //   {t('actionBar.delete')}
    // </Button>,
  ]

  const onSubmit = (val) => {
    if (!val) return
    const body = {
      code: val.code,
      name: val.name,
      description: val.description,
      isActive: val.isActive,
      timeDeparture: val?.timeDeparture,
      timeArrival: val?.timeArrival,
      ...getDayInWeekValue(val?.timeRun, YES_NO_VALUE.yes, YES_NO_VALUE.no),
      pickupPoints: val.pickupPoints?.map((item, idx) => ({
        order: idx,
        pickupPointCode: item.pickupPoint.code,
      })),
      // vehicleCategories: val.vehicleCategories?.map((item) => ({
      //   number: item.vehicleCategory?.totalSeat,
      //   vehicleCategoryCode: item.vehicleCategory?.code,
      // })),
      catShifts: val.catShifts?.map((item) => ({
        catShiftCode: item.catShift?.code,
        fixedVehicle:
          (item.vehicleType?.totalSeat || 0) * (item.totalVehicle || 0),
        vehicleTypeCode: item.vehicleType?.code,
        totalVehicle: item.totalVehicle,
      })),
    }

    if (mode === MODAL_MODE.CREATE) {
      actCreateRoute({ body }, backToList)
    } else {
      actUpdateRoute({ id, body }, backToList)
    }
  }

  const backToList = () => {
    history.push(ROUTE.ROUTE.LIST.PATH)
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t(
        `menu.${mode === MODAL_MODE.CREATE ? 'routeCreate' : 'routeUpdate'}`,
      )}
      loading={isLoading || isLoadingUpdate}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <Box sx={{ padding: '20px 24px' }}>
        <TaskBar sx={{ background: 'white' }} left={[]} right={actionHeader} />
        {/* <Divider sx={{ borderColor: '#CBCBCB', margin: '0 0 20px 0' }} /> */}
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema(t)}
          enableReinitialize
        >
          {({ values, errors, handleReset, setFieldValue }) => (
            <Form>
              <Grid
                container
                rowSpacing={2}
                // columnSpacing={{ xl: 1, xs: 1 }}
                sx={{
                  backgroundColor: '#EFF5FB',
                  padding: '24px',
                  borderRadius: '8px',
                }}
                justifyContent="space-between"
              >
                <Grid item lg={5.9} xs={12}>
                  <Field.TextField
                    name="code"
                    placeholder={t('route.code')}
                    label={t('route.code')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                    disabled={
                      mode === MODAL_MODE.UPDATE &&
                      initialValues.isActive === YES_NO_VALUE.yes
                    }
                    allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
                    required
                  />
                </Grid>
                <Grid item lg={5.9} xs={12}>
                  <Field.TextField
                    name="name"
                    placeholder={t('route.name')}
                    label={t('route.name')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LV
                    label={t('route.form.time')}
                    labelColor="#222222"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    required
                    value={
                      <Box>
                        {TIME_RUN_CHECKBOXS.map((item) => (
                          <FormControlLabel
                            key={item.name}
                            name={`timeRun.${item.name}`}
                            control={<Field.Checkbox />}
                            label={t(item.text)}
                            sx={{ marginRight: '15px' }}
                          />
                        ))}
                        {errors && !isEmpty(errors) ? (
                          <FormHelperText error>
                            {errors['timeRun']}
                          </FormHelperText>
                        ) : (
                          ''
                        )}
                      </Box>
                    }
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Field.Autocomplete
                    label={t('route.isActive')}
                    name="isActive"
                    options={STATUS_OPTIONS}
                    required={true}
                    getOptionLabel={(opt) => t(opt?.text) || ''}
                    getOptionValue={(opt) => opt?.id}
                    isOptionEqualToValue={(opt, val) =>
                      opt?.id === (val?.id || val)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field.TextField
                    name="description"
                    placeholder={t('route.form.description')}
                    label={t('route.form.description')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 3 }}>
                <FieldArray
                  name={
                    TAB_FORM_VALUE_MAPPING[
                      formQuery.tab || TAB_FORM_VALUE.pickupPoints
                    ]
                  }
                  render={(arrayHelpers) => (
                    <>
                      <FormTable
                        arrayHelpers={arrayHelpers}
                        values={values}
                        formQuery={formQuery}
                        mode={mode}
                        name={arrayHelpers.name}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </>
                  )}
                />
              </Box>
              <Box sx={{ padding: 3 }}>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="button"
                      sx={{ marginRight: '10px' }}
                      color="grayF4"
                      onClick={handleReset}
                    >
                      {t('actionBar.cancel')}
                    </Button>
                    <Button type="submit" sx={{ marginRight: '10px' }}>
                      {t(
                        mode === MODAL_MODE.CREATE
                          ? 'actionBar.create'
                          : 'actionBar.save',
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Page>
  )
}

export default RouteForm
