// Action: Get list
export const MASTER_GET_REPORT_BR11 = 'MASTER_GET_REPORT_BR11'
export const MASTER_GET_REPORT_BR11_SUCCESS = 'MASTER_GET_REPORT_BR11_SUCCESS'
export const MASTER_GET_REPORT_BR11_FAILED = 'MASTER_GET_REPORT_BR11_FAILED'
export const MASTER_GET_REPORT_BR11_RESET = 'MASTER_GET_REPORT_BR11_RESET'

// Action: Get summary
export const MASTER_GET_REPORT_BR11_SUMMARY = 'MASTER_GET_REPORT_BR11_SUMMARY'
export const MASTER_GET_REPORT_BR11_SUMMARY_SUCCESS =
  'MASTER_GET_REPORT_BR11_SUMMARY_SUCCESS'
export const MASTER_GET_REPORT_BR11_SUMMARY_FAILED =
  'MASTER_GET_REPORT_BR11_SUMMARY_FAILED'
export const MASTER_GET_REPORT_BR11_SUMMARY_RESET =
  'MASTER_GET_REPORT_BR11_SUMMARY_RESET'

// Action: sync
export const MASTER_SYNC_REPORT_BR11 = 'MASTER_SYNC_REPORT_BR11'
export const MASTER_SYNC_REPORT_BR11_SUCCESS = 'MASTER_SYNC_REPORT_BR11_SUCCESS'
export const MASTER_SYNC_REPORT_BR11_FAILED = 'MASTER_SYNC_REPORT_BR11_FAILED'
export const MASTER_SYNC_REPORT_BR11_RESET = 'MASTER_SYNC_REPORT_BR11_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetReportBr11(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_REPORT_BR11,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetReportBr11Success(payload) {
  return {
    type: MASTER_GET_REPORT_BR11_SUCCESS,
    payload: payload,
  }
}

export function actGetReportBr11Failed() {
  return {
    type: MASTER_GET_REPORT_BR11_FAILED,
  }
}

export function actGetReportBr11Reset() {
  return {
    type: MASTER_GET_REPORT_BR11_RESET,
  }
}

/** get list summary
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetReportBr11Summary(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_REPORT_BR11_SUMMARY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetReportBr11SummarySuccess(payload) {
  return {
    type: MASTER_GET_REPORT_BR11_SUMMARY_SUCCESS,
    payload: payload,
  }
}

export function actGetReportBr11SummaryFailed() {
  return {
    type: MASTER_GET_REPORT_BR11_SUMMARY_FAILED,
  }
}

export function actGetReportBr11SummaryReset() {
  return {
    type: MASTER_GET_REPORT_BR11_SUMMARY_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncReportBr11(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_REPORT_BR11,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncReportBr11Success(payload) {
  return {
    type: MASTER_SYNC_REPORT_BR11_SUCCESS,
    payload: payload,
  }
}

export function actSyncReportBr11Failed() {
  return {
    type: MASTER_SYNC_REPORT_BR11_FAILED,
  }
}

export function actSyncReportBr11Reset() {
  return {
    type: MASTER_SYNC_REPORT_BR11_RESET,
  }
}

export default {
  actGetReportBr11,
  actGetReportBr11Success,
  actGetReportBr11Failed,
  actGetReportBr11Reset,
  actGetReportBr11Summary,
  actGetReportBr11SummarySuccess,
  actGetReportBr11SummaryFailed,
  actGetReportBr11SummaryReset,
  actSyncReportBr11,
  actSyncReportBr11Success,
  actSyncReportBr11Failed,
  actSyncReportBr11Reset,
}
