import { combineReducers } from 'redux'

import {
  MASTER_UPDATE_SETTING,
  MASTER_UPDATE_SETTING_FAILED,
  MASTER_UPDATE_SETTING_SUCCESS,
  MASTER_GET_SETTING,
  MASTER_GET_SETTING_FAILED,
  MASTER_GET_SETTING_RESET,
  MASTER_GET_SETTING_SUCCESS,
} from '../actions/setting'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function updateSetting(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_UPDATE_SETTING:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function getSetting(state = { isLoading: false, data: [] }, action) {
  switch (action.type) {
    case MASTER_GET_SETTING:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data,
      }
    case MASTER_GET_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_SETTING_RESET:
      return { isLoading: false, data: [] }
    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  updateSetting,
  getSetting,
})
