import { List } from '@mui/material'
import { styled } from '@mui/system'

const ListMenuStyled = styled(List)(({ theme }) => {
  return {
    padding: '0 10px 10px 10px',
    flex: 1,
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ccc',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.a6,
    },

    '.MuiListItemButton-root': {
      borderRadius: 8,

      '&:hover': {
        background: theme.palette.sidebar.hover,
        '&>.MuiListItemIcon-root': { opacity: 1 },
      },

      '&.active': {
        background: theme.palette.sidebar.active,
      },

      '&.rootActive': {
        background: theme.palette.sidebar.active,

        '&>.MuiListItemIcon-root': { opacity: 1 },
        '&>.MuiListItemText-root>.MuiTypography-root': {
          fontWeight: 700,
        },
      },
      '&.rootActiveByChild': {
        '&>.MuiListItemIcon-root': { opacity: 1 },
        '&>.MuiListItemText-root>.MuiTypography-root': {
          fontWeight: 700,
        },
      },
    },

    a: {
      textDecoration: 'none',
    },
  }
})

export default ListMenuStyled
