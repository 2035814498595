import Big from 'big.js'
import BigNumber from 'bignumber.js'
import { isNil } from 'lodash'

export const convertNumberWithSISymbol = (num, digits = 2) => {
  if (!num) return 0

  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break
    }
  }

  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const convertNumberWithThousandSeparator = (num, digits = 2) => {
  if (isNil(num)) return ''

  return (
    BigNumber(num)
      .toFixed(digits)
      .toString()
      // .replace(/\.?0+$/, '')
      .replace(/\d(?=(\d{3})+(?!\d))/g, '$& ')
      .replace('.', ',')
  )
}

export const minus = (first, second) => {
  return Number(new Big(first).minus(new Big(second)))
}

export const plus = (first, second) => {
  return Number(new Big(first).plus(new Big(second)))
}

export const mul = (first, second) => {
  return Number(new Big(first).mul(new Big(second)))
}

export const div = (first, second) => {
  return Number(new Big(first).div(new Big(second)))
}

export function convertWithCommas(num, places = 2) {
  if (isNil(num)) return ''
  return round(num, places)
    .toString()
    .replace(/^[+-]?\d+/, function (int) {
      return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    })
}

export function round(number, places) {
  const multiplier = Math.pow(10, places)
  return Math.round(number * multiplier) / multiplier
}
export const convertNumberThousandComma = (num) => {
  if (isNil(num)) return ''

  return BigNumber(num)
    .toString()
    .replace(/\d(?=(\d{3})+(?!\d))/g, '$&,')
}
