import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetDetailFactoryFailed,
  actGetDetailFactorySuccess,
  MASTER_GET_DETAIL_FACTORY,
} from '../../actions/factory'
import { apiGetDetailFactory } from '../../apis/factory'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDetail(action) {
  try {
    const response = yield call(apiGetDetailFactory, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actGetDetailFactorySuccess(response.data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetDetailFactoryFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetDetail() {
  yield takeLatest(MASTER_GET_DETAIL_FACTORY, doGetDetail)
}
