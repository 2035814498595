import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import { MODAL_MODE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import LV from '~/components/LabelValue'
import { apiGetFeeSetting } from '~/modules/master/redux/apis/setting-fee'

import FormTable from '../form/form-table'

const LABEL_WIDTH = '200px'

const DetailFee = ({ t, openDetailData, onClose = () => {}, open }) => {
  const [detail, setDetail] = useState({})

  useEffect(() => {
    if (openDetailData) {
      refreshData()
    }
  }, [openDetailData])

  const refreshData = async () => {
    const res = await apiGetFeeSetting({
      month: openDetailData.month,
      year: openDetailData.year,
    })

    setDetail({
      shiftRoutes: res?.data?.shiftRoutes,
      fixedNum: res?.data?.fixedNum,
      flexibledNum: res?.data?.flexibledNum,
      tax: res?.data?.tax,
      month: res?.data?.month,
      year: res?.data?.year,
    })
  }

  return (
    <Dialog
      open={open}
      title={t('settingFee.popupDetailTitle')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
      >
        <Grid item lg={6} xs={12}>
          <LV
            label={t('settingFee.dateFee')}
            labelWidth={LABEL_WIDTH}
            value={`${detail.month}/${detail.year}`}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('settingFee.caNumber')}
            labelWidth={LABEL_WIDTH}
            value={detail.flexibledNum}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('settingFee.hcNumber')}
            labelWidth={LABEL_WIDTH}
            value={detail.fixedNum}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LV
            label={t('settingFee.tax')}
            labelWidth={LABEL_WIDTH}
            value={detail.tax}
          />
        </Grid>
        <Grid item xs={12} mt={3}>
          <FormTable values={detail} mode={MODAL_MODE.DETAIL} />
        </Grid>
      </Grid>
      <Box sx={{ padding: 3 }}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={onClose}
            >
              {t('actionBar.close')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DetailFee
