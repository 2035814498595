import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  MASTER_GET_SETTING,
  MASTER_UPDATE_SETTING,
  actGetSettingFailed,
  actGetSettingSuccess,
  actUpdateSettingFailed,
  actUpdateSettingSuccess,
} from '../../actions/setting'
import { apiUpdateSetting, apiGetSetting } from '../../apis/setting'

/**
 * Handle get data request and response
 * @param {object} action
 */

function* doUpdateSetting(action) {
  try {
    const response = yield call(apiUpdateSetting, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actUpdateSettingSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actUpdateSettingFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

function* doGetSetting(action) {
  try {
    const response = yield call(apiGetSetting, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actGetSettingSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetSettingFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchRegisterVehicle() {
  yield all([
    takeLatest(MASTER_GET_SETTING, doGetSetting),
    takeLatest(MASTER_UPDATE_SETTING, doUpdateSetting),
  ])
}
