import { useTranslation } from 'react-i18next'

import Dialog from '../Dialog'

const PopupConfirm = ({
  title,
  open = false,
  content,
  onCancel = () => {},
  onConfirm = () => {},
  type = 'primary',
}) => {
  const { t } = useTranslation(['buseye'])
  return (
    <Dialog
      open={open}
      title={title}
      onCancel={onCancel}
      cancelLabel={t('common.no')}
      onSubmit={onConfirm}
      submitLabel={t('common.yes')}
      submitProps={{
        color: type,
      }}
      hideHeader={!title}
      noBorderBottom
    >
      {content}
    </Dialog>
  )
}

export default PopupConfirm
