// Action: Get list
export const MASTER_GET_LIST_PICKUP_POINT = 'MASTER_GET_LIST_PICKUP_POINT'
export const MASTER_GET_LIST_PICKUP_POINT_SUCCESS =
  'MASTER_GET_LIST_PICKUP_POINT_SUCCESS'
export const MASTER_GET_LIST_PICKUP_POINT_FAILED =
  'MASTER_GET_LIST_PICKUP_POINT_FAILED'
export const MASTER_GET_LIST_PICKUP_POINT_RESET =
  'MASTER_GET_LIST_PICKUP_POINT_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_PICKUP_POINT = 'MASTER_GET_DETAIL_PICKUP_POINT'
export const MASTER_GET_DETAIL_PICKUP_POINT_SUCCESS =
  'MASTER_GET_DETAIL_PICKUP_POINT_SUCCESS'
export const MASTER_GET_DETAIL_PICKUP_POINT_FAILED =
  'MASTER_GET_DETAIL_PICKUP_POINT_FAILED'
export const MASTER_GET_DETAIL_PICKUP_POINT_RESET =
  'MASTER_GET_DETAIL_PICKUP_POINT_RESET'

// Action: create
export const MASTER_CREATE_PICKUP_POINT = 'MASTER_CREATE_PICKUP_POINT'
export const MASTER_CREATE_PICKUP_POINT_SUCCESS =
  'MASTER_CREATE_PICKUP_POINT_SUCCESS'
export const MASTER_CREATE_PICKUP_POINT_FAILED =
  'MASTER_CREATE_PICKUP_POINT_FAILED'

// Action: update
export const MASTER_UPDATE_PICKUP_POINT = 'MASTER_UPDATE_PICKUP_POINT'
export const MASTER_UPDATE_PICKUP_POINT_SUCCESS =
  'MASTER_UPDATE_PICKUP_POINT_SUCCESS'
export const MASTER_UPDATE_PICKUP_POINT_FAILED =
  'MASTER_UPDATE_PICKUP_POINT_FAILED'

// Action: delete
export const MASTER_DELETE_PICKUP_POINT = 'MASTER_DELETE_PICKUP_POINT'
export const MASTER_DELETE_PICKUP_POINT_SUCCESS =
  'MASTER_DELETE_PICKUP_POINT_SUCCESS'
export const MASTER_DELETE_PICKUP_POINT_FAILED =
  'MASTER_DELETE_PICKUP_POINT_FAILED'

// Action: change status
export const MASTER_CHANGE_STATUS_PICKUP_POINT =
  'MASTER_CHANGE_STATUS_PICKUP_POINT'
export const MASTER_CHANGE_STATUS_PICKUP_POINT_SUCCESS =
  'MASTER_CHANGE_STATUS_PICKUP_POINT_SUCCESS'
export const MASTER_CHANGE_STATUS_PICKUP_POINT_FAILED =
  'MASTER_CHANGE_STATUS_PICKUP_POINT_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListPickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListPickupPointSuccess(payload) {
  return {
    type: MASTER_GET_LIST_PICKUP_POINT_SUCCESS,
    payload: payload,
  }
}

export function actGetListPickupPointFailed() {
  return {
    type: MASTER_GET_LIST_PICKUP_POINT_FAILED,
  }
}

export function actGetListPickupPointReset() {
  return {
    type: MASTER_GET_LIST_PICKUP_POINT_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailPickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailPickupPointSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_PICKUP_POINT_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailPickupPointFailed() {
  return {
    type: MASTER_GET_DETAIL_PICKUP_POINT_FAILED,
  }
}

export function actGetDetailPickupPointReset() {
  return {
    type: MASTER_GET_DETAIL_PICKUP_POINT_RESET,
  }
}

/** create
 * @param {
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actCreatePickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_CREATE_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actCreatePickupPointSuccess(payload) {
  return {
    type: MASTER_CREATE_PICKUP_POINT_SUCCESS,
    payload: payload,
  }
}

export function actCreatePickupPointFailed() {
  return {
    type: MASTER_CREATE_PICKUP_POINT_FAILED,
  }
}

/** update
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdatePickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdatePickupPointSuccess(payload) {
  return {
    type: MASTER_UPDATE_PICKUP_POINT_SUCCESS,
    payload: payload,
  }
}

export function actUpdatePickupPointFailed() {
  return {
    type: MASTER_UPDATE_PICKUP_POINT_FAILED,
  }
}

/** delete
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDeletePickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_DELETE_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDeletePickupPointSuccess() {
  return {
    type: MASTER_DELETE_PICKUP_POINT_SUCCESS,
  }
}

export function actDeletePickupPointFailed() {
  return {
    type: MASTER_DELETE_PICKUP_POINT_FAILED,
  }
}

/** change status
 * @param {
 *  id: any,
 *  status: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actChangeStatusPickupPoint(payload, onSuccess, onError) {
  return {
    type: MASTER_CHANGE_STATUS_PICKUP_POINT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actChangeStatusPickupPointSuccess() {
  return {
    type: MASTER_CHANGE_STATUS_PICKUP_POINT_SUCCESS,
  }
}

export function actChangeStatusPickupPointFailed() {
  return {
    type: MASTER_CHANGE_STATUS_PICKUP_POINT_FAILED,
  }
}

export default {
  actGetListPickupPoint,
  actGetListPickupPointSuccess,
  actGetListPickupPointFailed,
  actGetListPickupPointReset,
  actGetDetailPickupPoint,
  actGetDetailPickupPointSuccess,
  actGetDetailPickupPointFailed,
  actGetDetailPickupPointReset,
  actUpdatePickupPoint,
  actUpdatePickupPointSuccess,
  actUpdatePickupPointFailed,
  actDeletePickupPoint,
  actDeletePickupPointSuccess,
  actDeletePickupPointFailed,
  actChangeStatusPickupPoint,
  actChangeStatusPickupPointSuccess,
  actChangeStatusPickupPointFailed,
  actCreatePickupPoint,
  actCreatePickupPointSuccess,
  actCreatePickupPointFailed,
}
