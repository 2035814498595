import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_OVERTIME,
  MASTER_GET_DETAIL_OVERTIME_FAILED,
  MASTER_GET_DETAIL_OVERTIME_RESET,
  MASTER_GET_DETAIL_OVERTIME_SUCCESS,
  MASTER_GET_LIST_OVERTIME,
  MASTER_GET_LIST_OVERTIME_FAILED,
  MASTER_GET_LIST_OVERTIME_RESET,
  MASTER_GET_LIST_OVERTIME_SUCCESS,
  MASTER_SYNC_OVERTIME,
  MASTER_SYNC_OVERTIME_FAILED,
  MASTER_SYNC_OVERTIME_RESET,
  MASTER_SYNC_OVERTIME_SUCCESS,
} from '../actions/overtime'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listOvertime(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_OVERTIME:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_OVERTIME_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_OVERTIME_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_OVERTIME_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailOvertime(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_OVERTIME:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_OVERTIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_OVERTIME_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_OVERTIME_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncOvertime(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_OVERTIME:
      return { ...state, isLoading: true }
    case MASTER_SYNC_OVERTIME_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_OVERTIME_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_OVERTIME_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listOvertime,
  detailOvertime,
  syncOvertime,
})
