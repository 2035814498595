// Action: Get list
export const MASTER_GET_LIST_BUSINESS_TRAVEL = 'MASTER_GET_LIST_BUSINESS_TRAVEL'
export const MASTER_GET_LIST_BUSINESS_TRAVEL_SUCCESS =
  'MASTER_GET_LIST_BUSINESS_TRAVEL_SUCCESS'
export const MASTER_GET_LIST_BUSINESS_TRAVEL_FAILED =
  'MASTER_GET_LIST_BUSINESS_TRAVEL_FAILED'
export const MASTER_GET_LIST_BUSINESS_TRAVEL_RESET =
  'MASTER_GET_LIST_BUSINESS_TRAVEL_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_BUSINESS_TRAVEL =
  'MASTER_GET_DETAIL_BUSINESS_TRAVEL'
export const MASTER_GET_DETAIL_BUSINESS_TRAVEL_SUCCESS =
  'MASTER_GET_DETAIL_BUSINESS_TRAVEL_SUCCESS'
export const MASTER_GET_DETAIL_BUSINESS_TRAVEL_FAILED =
  'MASTER_GET_DETAIL_BUSINESS_TRAVEL_FAILED'
export const MASTER_GET_DETAIL_BUSINESS_TRAVEL_RESET =
  'MASTER_GET_DETAIL_BUSINESS_TRAVEL_RESET'

// Action: sync
export const MASTER_SYNC_BUSINESS_TRAVEL = 'MASTER_SYNC_BUSINESS_TRAVEL'
export const MASTER_SYNC_BUSINESS_TRAVEL_SUCCESS =
  'MASTER_SYNC_BUSINESS_TRAVEL_SUCCESS'
export const MASTER_SYNC_BUSINESS_TRAVEL_FAILED =
  'MASTER_SYNC_BUSINESS_TRAVEL_FAILED'
export const MASTER_SYNC_BUSINESS_TRAVEL_RESET =
  'MASTER_SYNC_BUSINESS_TRAVEL_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListBusinessTravel(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_BUSINESS_TRAVEL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListBusinessTravelSuccess(payload) {
  return {
    type: MASTER_GET_LIST_BUSINESS_TRAVEL_SUCCESS,
    payload: payload,
  }
}

export function actGetListBusinessTravelFailed() {
  return {
    type: MASTER_GET_LIST_BUSINESS_TRAVEL_FAILED,
  }
}

export function actGetListBusinessTravelReset() {
  return {
    type: MASTER_GET_LIST_BUSINESS_TRAVEL_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailBusinessTravel(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_BUSINESS_TRAVEL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailBusinessTravelSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_BUSINESS_TRAVEL_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailBusinessTravelFailed() {
  return {
    type: MASTER_GET_DETAIL_BUSINESS_TRAVEL_FAILED,
  }
}

export function actGetDetailBusinessTravelReset() {
  return {
    type: MASTER_GET_DETAIL_BUSINESS_TRAVEL_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncBusinessTravel(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_BUSINESS_TRAVEL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncBusinessTravelSuccess(payload) {
  return {
    type: MASTER_SYNC_BUSINESS_TRAVEL_SUCCESS,
    payload: payload,
  }
}

export function actSyncBusinessTravelFailed() {
  return {
    type: MASTER_SYNC_BUSINESS_TRAVEL_FAILED,
  }
}

export function actSyncBusinessTravelReset() {
  return {
    type: MASTER_SYNC_BUSINESS_TRAVEL_RESET,
  }
}

export default {
  actGetListBusinessTravel,
  actGetListBusinessTravelSuccess,
  actGetListBusinessTravelFailed,
  actGetListBusinessTravelReset,
  actGetDetailBusinessTravel,
  actGetDetailBusinessTravelSuccess,
  actGetDetailBusinessTravelFailed,
  actGetDetailBusinessTravelReset,
  actSyncBusinessTravel,
  actSyncBusinessTravelSuccess,
  actSyncBusinessTravelFailed,
  actSyncBusinessTravelReset,
}
