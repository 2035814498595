import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    code: Yup.string().required(t('form.required')),
    name: Yup.string().required(t('form.required')),
    totalSeat: Yup.number()
      .min(
        1,
        t('form.minNumber', {
          min: 1,
        }),
      )
      .max(
        100,
        t('form.maxNumber', {
          max: 100,
        }),
      )
      .required(t('form.required')),
    isActive: Yup.number().nullable().required(t('form.required')),
  })
