import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_WORK_SCHEDULE,
  MASTER_GET_DETAIL_WORK_SCHEDULE_FAILED,
  MASTER_GET_DETAIL_WORK_SCHEDULE_RESET,
  MASTER_GET_DETAIL_WORK_SCHEDULE_SUCCESS,
  MASTER_GET_LIST_WORK_SCHEDULE,
  MASTER_GET_LIST_WORK_SCHEDULE_FAILED,
  MASTER_GET_LIST_WORK_SCHEDULE_RESET,
  MASTER_GET_LIST_WORK_SCHEDULE_SUCCESS,
  MASTER_SYNC_WORK_SCHEDULE,
  MASTER_SYNC_WORK_SCHEDULE_FAILED,
  MASTER_SYNC_WORK_SCHEDULE_RESET,
  MASTER_SYNC_WORK_SCHEDULE_SUCCESS,
  MASTER_UPDATE_WORK_SCHEDULE,
  MASTER_UPDATE_WORK_SCHEDULE_FAILED,
  MASTER_UPDATE_WORK_SCHEDULE_SUCCESS,
} from '../actions/work-schedule'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listWorkSchedule(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_WORK_SCHEDULE:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_WORK_SCHEDULE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_WORK_SCHEDULE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_WORK_SCHEDULE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailWorkSchedule(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_WORK_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_WORK_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_WORK_SCHEDULE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_WORK_SCHEDULE_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncWorkSchedule(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_WORK_SCHEDULE:
      return { ...state, isLoading: true }
    case MASTER_SYNC_WORK_SCHEDULE_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_WORK_SCHEDULE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_WORK_SCHEDULE_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function updateWorkSchedule(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_UPDATE_WORK_SCHEDULE:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_WORK_SCHEDULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_WORK_SCHEDULE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listWorkSchedule,
  detailWorkSchedule,
  syncWorkSchedule,
  updateWorkSchedule,
})
