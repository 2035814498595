import { all } from 'redux-saga/effects'

import watchActiveDepartment from './department-list/active-department'
import watchCreateDepartment from './department-list/create-department'
import watchDeactiveDepartment from './department-list/deactive-department'
import watchGetDepartmentAssignDetails from './department-list/get-department-assign'
import watchGetDepartmentDetails from './department-list/get-department-details'
import watchSearchDepartmentList from './department-list/search-department-list'
import watchUpdateDepartment from './department-list/update-department'
import watchUpdateDepartmentAssign from './department-list/update-department-assign'
import watchChangeStatus from './role-list/confirm-role-assign'
import watchCreateRoleAssign from './role-list/create-role-assign'
import watchDeleteRoleAssign from './role-list/delete-role-assign'
import watchGetRoleAssignDetails from './role-list/get-role-assign'
import watchSearchRoleList from './role-list/search-role-list'
import watchUpdateRoleAssign from './role-list/update-role-assign'
import watchChangePassword from './user-info/change-password'
import watchGetUserInfo from './user-info/get-user-info'
import watchUpdateUserInfo from './user-info/update-user-info'
import watchUserManagement from './user-management'
import watchGetUserPermissionDetails from './user-permission/get-user-permission'
import watchUpdateUserPermission from './user-permission/update-user-permission'

/**
 * Root saga
 */
export default function* sagas() {
  yield all([
    //company-chart
    watchSearchDepartmentList(),
    watchGetDepartmentAssignDetails(),
    watchUpdateDepartmentAssign(),
    watchCreateDepartment(),
    watchUpdateDepartment(),
    watchGetDepartmentDetails(),
    watchActiveDepartment(),
    watchDeactiveDepartment(),

    //role-list
    watchGetRoleAssignDetails(),
    watchSearchRoleList(),
    watchUpdateRoleAssign(),
    watchChangeStatus(),
    watchCreateRoleAssign(),
    watchDeleteRoleAssign(),

    //user-permission
    watchGetUserPermissionDetails(),
    watchUpdateUserPermission(),

    //user-info
    watchUpdateUserInfo(),
    watchChangePassword(),
    watchGetUserInfo(),

    //user-management
    watchUserManagement(),
  ])
}
