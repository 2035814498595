import { combineReducers } from 'redux'

import {
  MASTER_GET_REPORT_OT,
  MASTER_GET_REPORT_OT_FAILED,
  MASTER_GET_REPORT_OT_RESET,
  MASTER_GET_REPORT_OT_SUCCESS,
  MASTER_SYNC_REPORT_OT_BU,
  MASTER_SYNC_REPORT_OT_BU_FAILED,
  MASTER_SYNC_REPORT_OT_BU_RESET,
  MASTER_SYNC_REPORT_OT_BU_SUCCESS,
} from '../actions/report-ot'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportOt(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_REPORT_OT:
      return { ...state, isLoading: true }
    case MASTER_GET_REPORT_OT_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_REPORT_OT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_REPORT_OT_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function syncReportOtBu(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_REPORT_OT_BU:
      return { ...state, isLoading: true }
    case MASTER_SYNC_REPORT_OT_BU_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_REPORT_OT_BU_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_REPORT_OT_BU_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportOt,
  syncReportOtBu,
})
