import React, { useEffect, useMemo, useState } from 'react'

import { Box, FormControlLabel, Grid } from '@mui/material'
import { flatten, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { ASYNC_SEARCH_LIMIT, MODAL_MODE } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import ActionBar from '~/components/ActionBar'
import Autocomplete from '~/components/Autocomplete'
import Checkbox from '~/components/Checkbox'
import DataTableCollapse from '~/components/DataTableCollapse'
import Guard from '~/components/Guard'
import Page from '~/components/Page'
import useUserPermission from '~/modules/configuration/redux/hooks/useUserPermission'
import { ROUTE } from '~/modules/configuration/routes/config'
import { convertFilterParams } from '~/utils'

import { searchRoleListApi } from '../../redux/sagas/role-list/search-role-list'

const breadcrumbs = [
  {
    title: 'decentralization',
  },
  {
    route: ROUTE.USER_PERMISSION.PATH,
    title: ROUTE.USER_PERMISSION.TITLE,
  },
]

function UserPermission() {
  const { t } = useTranslation(['buseye'])

  const [userRole, setUserRole] = useState(null)
  const [permissionList, setPermissionList] = useState([])
  const history = useHistory()

  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const {
    data: { permissionDetails, isLoading, total },
    actions: {
      getUserPermissionDetails,
      resetUserPermissionDetailsState,
      updateUserPermission,
    },
  } = useUserPermission()

  useEffect(async () => {
    const listRoleRes = await searchRoleListApi({
      limit: ASYNC_SEARCH_LIMIT,
      filter: convertFilterParams({
        status: 1,
      }),
    })

    if (listRoleRes?.data?.items) {
      setUserRole(listRoleRes?.data?.items[0])
    }
  }, [])

  useEffect(() => {
    if (userRole?.id) {
      getUserPermissionDetails({
        roleId: userRole.id,
        page,
        limit: pageSize,
      })
    }
    return () => {
      resetUserPermissionDetailsState()
    }
  }, [userRole, page, pageSize])

  const pageData = useMemo(() => {
    const group = permissionDetails?.groupPermissions || []
    // const total = group.length || 0
    // const start = total ? pageSize * (page - 1) : 0
    // const end = total ? Math.min(pageSize * page, total) : 0

    // return group.slice(start, end)
    return group
  }, [permissionDetails?.groupPermissions, page, pageSize])

  const flattenPermissions = useMemo(
    () =>
      flatten(
        map(
          permissionDetails?.groupPermissions,
          (per) => per.permissionSettings,
        ),
      ),
    [permissionDetails?.groupPermissions],
  )

  useEffect(() => {
    setPermissionList(flattenPermissions)
  }, [flattenPermissions])

  const handleChangeCheckbox = (e, code) => {
    const newPermission = permissionList.map((permission) => {
      if (permission.code === code) {
        return {
          ...permission,
          status: e.target.checked ? 1 : 0,
        }
      }
      return { ...permission }
    })
    setPermissionList(newPermission)
  }

  const handleChangeAll = (e, permissionSettings) => {
    const permissionIdList = permissionSettings.map((item) => item.code)
    const newPermissionAll = permissionList.map((permission) => {
      if (permissionIdList.includes(permission.code)) {
        return {
          ...permission,
          status: e.target.checked ? 1 : 0,
        }
      }
      return { ...permission }
    })
    setPermissionList(newPermissionAll)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const params = {
      permissionSettings: permissionList.map((item) => ({
        ...item,
        userRoleId: userRole?.id,
        // departmentId: 0,
      })),
    }

    updateUserPermission(params, () => {
      getUserPermissionDetails({
        role: userRole?.id,
      })
    })
  }

  const renderActionBar = () => {
    return (
      <Guard code={FUNCTION_CODE.USER_SET_PERMISSION_USER}>
        <ActionBar
          onCancel={() => setPermissionList(flattenPermissions)}
          mode={MODAL_MODE.UPDATE}
        />
      </Guard>
    )
  }

  const columns = [
    {
      field: 'feature',
      headerName: t('userPermission.feature'),
      align: 'left',
      renderCell: (params) => {
        return params?.row?.name
      },
    },
    {
      field: 'assign',
      headerName: t('userPermission.assign'),
      width: 150,
      renderCell: (params) => {
        const { permissionSettings } = params.row
        const checkedPermissions = (permissionList || [])
          .filter((item) => item.status === 1)
          .map((per) => per.code)

        const isCheckAll = permissionSettings.every((item) => {
          return checkedPermissions.includes(item.code)
        })
        return (
          <Guard code={FUNCTION_CODE.USER_SET_PERMISSION_USER}>
            <FormControlLabel
              label=""
              control={
                <Checkbox
                  checked={isCheckAll}
                  onChange={(e) => handleChangeAll(e, permissionSettings)}
                  name="statusAll"
                />
              }
            />
          </Guard>
        )
      },
    },
  ]

  const subColumns = [
    {
      field: 'permissionSetting',
      renderCell: (params) => {
        return params?.row?.name
      },
    },
    {
      field: 'status',
      width: 150,
      renderCell: (params) => {
        const { code } = params.row
        const isChecked = permissionList?.some(
          (item) => item?.code === code && !!item?.status,
        )

        return (
          <Guard code={FUNCTION_CODE.USER_SET_PERMISSION_USER}>
            <FormControlLabel
              label=""
              control={
                <Checkbox
                  onChange={(e) => handleChangeCheckbox(e, code)}
                  checked={isChecked}
                  name="status"
                />
              }
            />
          </Guard>
        )
      },
    },
  ]

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.userPermission')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Grid container rowSpacing={4 / 3} columnSpacing={{ xl: 4, xs: 4 }}>
            <Grid item lg={6} xs={12}>
              {/* <Guard code={FUNCTION_CODE.USER_SEARCH_PERMISSION_USER}> */}
              <Autocomplete
                name="userRole.id"
                label={t('userPermission.role')}
                placeholder={t('userPermission.role')}
                value={userRole}
                asyncRequest={(s) =>
                  searchRoleListApi({
                    keyword: s,
                    limit: ASYNC_SEARCH_LIMIT,
                    filter: convertFilterParams({
                      status: 1,
                    }),
                  })
                }
                asyncRequestHelper={(res) => res?.data?.items}
                getOptionLabel={(opt) => opt?.name}
                onChange={(val) => {
                  setUserRole(val)
                }}
              />
              {/* </Guard> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {userRole?.id && (
        <form
          onSubmit={onSubmit}
          key={`${userRole?.id}`}
          style={{ marginTop: 40 }}
        >
          <Box>
            <DataTableCollapse
              rows={pageData}
              columns={columns}
              subColumns={subColumns}
              subDataKey="permissionSettings"
              total={total}
              page={page}
              pageSize={pageSize}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              hideSetting
            />
          </Box>

          {renderActionBar()}
        </form>
      )}
    </Page>
  )
}

export default UserPermission
