import { Box, Grid } from '@mui/material'
import { Form } from 'formik'

import {
  MODAL_MODE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'
import { STATUS_OPTIONS, STATUS_VALUE } from '~/modules/master/constants'

const FormInput = ({ t, isLoading, handleReset, mode, data }) => {
  return (
    <Form>
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
        justifyContent="space-between"
      >
        <Grid item lg={5.5} xs={12}>
          <Field.TextField
            name="code"
            placeholder={t('vehicleCategories.code')}
            label={t('vehicleCategories.code')}
            inputProps={{
              maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
            }}
            disabled={
              mode === MODAL_MODE.UPDATE &&
              data.isActive === STATUS_VALUE.active
            }
            allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
            required
          />
        </Grid>
        <Grid item lg={5.5} xs={12}>
          <Field.TextField
            name="name"
            placeholder={t('vehicleCategories.name')}
            label={t('vehicleCategories.name')}
            inputProps={{
              maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
            }}
            required
          />
        </Grid>
        <Grid item lg={5.5} xs={12}>
          <Field.TextField
            name="totalSeat"
            placeholder={t('vehicleCategories.totalSeat')}
            label={t('vehicleCategories.totalSeat')}
            type="number"
            allow={TEXTFIELD_ALLOW.NUMERIC}
            required
          />
        </Grid>

        <Grid item lg={5.5} xs={12}>
          <Field.Autocomplete
            label={t('vehicleCategories.isActive')}
            name="isActive"
            options={STATUS_OPTIONS}
            getOptionLabel={(opt) => t(opt?.text) || ''}
            getOptionValue={(opt) => opt?.id}
            isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Field.TextField
            name="description"
            label={t('vehicleCategories.description')}
            placeholder={t('vehicleCategories.description')}
            inputProps={{
              maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
            }}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <Box sx={{ padding: 3 }}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={handleReset}
            >
              {t('actionBar.cancel')}
            </Button>
            <Button type="submit" sx={{ marginRight: '10px' }}>
              {t(`actionBar.${mode === MODAL_MODE.UPDATE ? 'save' : 'create'}`)}
            </Button>
          </Box>
        </Grid>
      </Box>
      <Loading open={isLoading}></Loading>
    </Form>
  )
}

export default FormInput
