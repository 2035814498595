import { fork } from 'redux-saga/effects'

import authSagas from '~/modules/auth/redux/sagas'
import configurationSagas from '~/modules/configuration/redux/sagas'
import masterSagas from '~/modules/master/redux/sagas'
import sharedSagas from '~/modules/shared/redux/sagas'

export default function* rootSagas() {
  yield fork(authSagas)
  yield fork(sharedSagas)
  yield fork(configurationSagas)
  yield fork(masterSagas)
}
