import { TreeView } from '@mui/lab'
import { Box, Typography } from '@mui/material'

import theme from '~/themes'

import {
  MinusSquare,
  PlusSquare,
  StyledTreeBox,
  StyledTreeItem,
} from '../styled'

const nodeName = (data, onClick = () => {}, isRoot) => {
  const code = (
    <span
      style={{
        color: theme.palette.primary.main,
        cursor: 'pointer',
        ...(isRoot ? { fontWeight: 'bold' } : {}),
      }}
      onClick={() => {}}
    >
      {`[${data.code}]`}
    </span>
  )

  const fullName = (
    <span
      style={{
        cursor: 'pointer',
        fontWeight: isRoot ? 'bold' : '',
        ...(isRoot ? { fontWeight: 'bold' } : {}),
      }}
    >
      {data.name}
    </span>
  )

  const abbreviation = (
    <span
      style={{
        cursor: 'pointer',
        fontWeight: isRoot ? 'bold' : '',
        ...(isRoot ? { fontWeight: 'bold' } : {}),
      }}
    >
      &nbsp; (
      <span
        style={{
          color: '#0050AE',
        }}
      >
        {data.abbreviation}
      </span>
      )
    </span>
  )
  return (
    <div
      style={{ lineHeight: 1, padding: '5px 0' }}
      onClick={() => onClick(data)}
    >
      {code}
      &nbsp;
      {fullName}
      {data.abbreviation && abbreviation}
    </div>
  )
}

const genTreeItem = (data, id, onClick) => {
  let treeItems = []
  if (data?.children?.length > 0) {
    treeItems = data.children.map((item, idx) =>
      genTreeItem(item, `${id}.${idx}`, onClick),
    )
  }

  return (
    <StyledTreeItem
      key={id}
      nodeId={id}
      label={nodeName(data, onClick)}
      sx={{
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      {treeItems.map((item) => item)}
    </StyledTreeItem>
  )
}

const TreeNode = ({ data = [], onClick = () => {} }) => {
  return (
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<MinusSquare />}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <StyledTreeItem
        key={data?.id}
        nodeId={data?.id}
        label={
          <Box>
            <Typography variant="h4" component="span" marginBottom={100}>
              {nodeName(data, onClick, true)}
            </Typography>
          </Box>
        }
        sx={{
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <StyledTreeBox
          sx={(theme) => ({
            pl: 2,
            bottom: 0,
            zIndex: 100,
            background: '#fff',
            borderLeft: `1px solid ${theme.palette.grayF4.main} `,
          })}
        >
          <TreeView
            aria-label="customized"
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<MinusSquare />}
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {data?.children?.map((item, idx) =>
              genTreeItem(item, `${idx}`, onClick),
            )}
          </TreeView>
        </StyledTreeBox>
      </StyledTreeItem>
    </TreeView>
  )
}

export default TreeNode
