import { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'

import {
  MODAL_MODE,
  NOTIFICATION_TYPE,
  TEXTFIELD_ALLOW,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'
import {
  apiGetFeeSetting,
  apiUpsertFeeSetting,
} from '~/modules/master/redux/apis/setting-fee'
import addNotification from '~/utils/toast'

import { TAB_FORM_VALUE } from '../constants'
import FormTable from './form-table'
import { validationSchema } from './schema'

const DEFAULT_DATA = {
  date: moment(),
  tab: TAB_FORM_VALUE.FIXED_D,
  tax: 0,
  fixedNum: 0,
  flexibledNum: 0,
  shiftRoutes: [],
}

export const DEFAULT_TABLE = {
  shiftCode: undefined,
  routeCode: undefined,
}

const LABEL_WIDTH = '200px'

const CreateUpdate = ({
  t,
  open,
  dateUpdate = {},
  onClose = () => {},
  handleSubmitSuccess = () => {},
  // onSubmit = () => {},
}) => {
  const mode = isEmpty(dateUpdate) ? MODAL_MODE.CREATE : MODAL_MODE.UPDATE

  const [initialValues, setInitialValues] = useState(DEFAULT_DATA)

  useEffect(() => {
    if (open) {
      if (mode === MODAL_MODE.UPDATE) {
        refreshData(moment(`${dateUpdate.month}/${dateUpdate.year}`, 'MM/YYYY'))
      } else {
        refreshData(moment())
      }
    }
  }, [open])

  const refreshData = async (date, setFieldValue) => {
    const params = {
      month: moment(date).format('M'),
      year: moment(date).format('YYYY'),
    }

    if (setFieldValue) {
      setFieldValue('isLoading', true)
    }

    const resRoute = await apiGetListRouteList({
      isGetAll: 1,
    })
    const listRoute = resRoute?.data?.items || []

    const res = await apiGetFeeSetting(params)

    if (setFieldValue) {
      setFieldValue('isLoading', false)
    }

    setInitialValues((init) => ({
      listRouteAll: listRoute,
      ...init,
      date: moment(`${params.month}/${params.year}`, 'MM/YYYY'),
      fixedNum: res?.data?.fixedNum,
      flexibledNum: res?.data?.flexibledNum,
      tax: res?.data?.tax,
      shiftRoutes: res?.data?.shiftRoutes?.map((item) => {
        const currentRoute = listRoute.find((it) => it.code === item.routeCode)
        const filterDupcate = currentRoute?.catShifts?.reduce(
          (acc, current) => {
            acc[current.code] = current
            return acc
          },
          {},
        )
        return {
          catShiftOptions: Object.values(filterDupcate),
          ...item,
        }
      }),
    }))
  }

  const handleSubmit = async (val, { setFieldValue }) => {
    if (!val) return
    const body = {
      year: +moment(val.date).format('YYYY'),
      month: +moment(val.date).format('M'),
      type: val.tab,
      tax: val.tax,
      fixedNum: val.fixedNum,
      flexibledNum: val.flexibledNum,
    }

    body.shiftRoutes = val.shiftRoutes?.map((item) => ({
      year: body.year,
      month: body.month,
      shiftCode: item.shiftCode,
      routeCode: item.routeCode,
      id: item.id,
    }))

    setFieldValue('isLoading', true)
    let res = await apiUpsertFeeSetting(body)

    // setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `settingFee.notification.${
          mode === MODAL_MODE.UPDATE ? 'updateSuccess' : 'createSuccess'
        }`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      handleSubmitSuccess(val.date)
      refreshData(moment())
    } else {
      onClose()
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
    // setCreateUpdate(null)
  }

  const handleChangeDate = (date, setFieldValue) => {
    if (date) {
      refreshData(date, setFieldValue)
    } else {
      setInitialValues({
        ...DEFAULT_DATA,
        date: undefined,
      })
    }
  }

  return (
    <Dialog
      open={open}
      title={t('settingFee.popupUpSertTitle')}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ handleReset, values, setFieldValue }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              justifyContent="space-between"
            >
              <Grid item lg={5.5} xs={12}>
                <Field.DatePicker
                  name="date"
                  label={t('settingFee.dateFee')}
                  placeholder={t('settingFee.dateFee')}
                  labelWidth={LABEL_WIDTH}
                  views={['month', 'year']}
                  inputFormat="MM/yyyy"
                  disabled={mode === MODAL_MODE.UPDATE}
                  onChange={(val) => {
                    if (!val) {
                      handleChangeDate()
                    }
                  }}
                  onAccept={(val) => {
                    handleChangeDate(val, setFieldValue)
                  }}
                  required
                />
              </Grid>
              <Grid item lg={5.5} xs={12}>
                <Field.TextField
                  name="flexibledNum"
                  placeholder={t('settingFee.caNumber')}
                  label={t('settingFee.caNumber')}
                  type="number"
                  labelWidth={LABEL_WIDTH}
                  allow={TEXTFIELD_ALLOW.NUMERIC}
                  required
                />
              </Grid>
              <Grid item lg={5.5} xs={12}>
                <Field.TextField
                  name="fixedNum"
                  placeholder={t('settingFee.hcNumber')}
                  label={t('settingFee.hcNumber')}
                  type="number"
                  labelWidth={LABEL_WIDTH}
                  allow={TEXTFIELD_ALLOW.NUMERIC}
                  required
                />
              </Grid>
              <Grid item lg={5.5} xs={12}>
                <Field.TextField
                  name="tax"
                  placeholder={t('settingFee.tax')}
                  label={t('settingFee.tax')}
                  type="number"
                  labelWidth={LABEL_WIDTH}
                  allow={TEXTFIELD_ALLOW.NUMERIC}
                  required
                />
              </Grid>
              <Box sx={{ mt: 3 }}>
                <FieldArray
                  name="shiftRoutes"
                  render={(arrayHelpers) => (
                    <FormTable
                      t={t}
                      values={values}
                      mode={mode}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={handleReset}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t(
                      `actionBar.${
                        mode === MODAL_MODE.UPDATE ? 'save' : 'save'
                      }`,
                    )}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values?.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateUpdate
