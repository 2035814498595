import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import IconButton from '~/components/IconButton'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import useRoleManagement from '~/modules/configuration/redux/hooks/useRoleList'
import { ROUTE } from '~/modules/configuration/routes/config'
import { STATUS_OPTIONS } from '~/modules/master/constants'
import { convertFilterParams, convertSortParams } from '~/utils'

import { tabList } from '../constants'
import FilterForm from './filter-form'
const breadcrumbs = [
  {
    title: 'decentralization',
  },
  {
    route: ROUTE.ROLE_LIST.LIST.PATH,
    title: ROUTE.ROLE_LIST.LIST.TITLE,
  },
]
const RoleList = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()

  const {
    data: { roleList, isLoading, total, totalActive, totalInActive, count },
    actions: { searchRoleList, actChangeStatusRole, deleteRoleAssign },
  } = useRoleManagement()

  const [selectedRows, setSelectedRows] = useState([])
  const [changeStatus, setChangeStatus] = useState(null)
  const [deleteItems, setDeleteItems] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    keyword,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setKeyword,
    withSearch,
    setTab,
    tab,
  } = useQueryState()

  const refreshData = () => {
    const params = {
      keyword: keyword?.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...filters,
          status: tab,
        },
        columns,
      ),
      sort: convertSortParams(sort),
    }
    searchRoleList(params)
    closeChangeStatus()
    closeConfirmDelete()
    setSelectedRows([])
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, filters, sort, keyword])

  const columns = [
    {
      field: 'code',
      headerName: t('roleManagement.code'),
      width: 200,
      fixed: true,
    },
    {
      field: 'name',
      headerName: t('roleManagement.name'),
      width: 200,
      fixed: true,
    },
    {
      field: 'description',
      headerName: t('roleManagement.description'),
      width: 200,
      fixed: true,
    },
    {
      field: 'status',
      headerName: t('roleManagement.status'),
      width: 120,
      renderCell: (params) => {
        const status = Number(params?.row.status)
        return <Status options={STATUS_OPTIONS} value={status} variant="text" />
      },
    },
    {
      field: 'actions',
      headerName: t('common.action'),
      width: 150,
      fixed: true,
      align: 'center',
      renderCell: (params) => {
        const { id, status } = params?.row
        return (
          <Box sx={{ display: 'flex' }}>
            <Guard code={FUNCTION_CODE.USER_DETAIL_USER_ROLE_SETTING}>
              <IconButton
                icon="show"
                onClick={() =>
                  history.push(
                    withSearch(
                      ROUTE.ROLE_LIST.DETAIL.PATH.replace(':id', `${id}`),
                    ),
                  )
                }
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_UPDATE_USER_ROLE_SETTING}>
              <IconButton
                icon="edit"
                onClick={() =>
                  history.push(
                    withSearch(
                      ROUTE.ROLE_LIST.EDIT.PATH.replace(':id', `${id}`),
                    ),
                  )
                }
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_USER_ROLE_SETTING}>
              <IconButton
                icon={status ? 'locked' : 'unlock'}
                tooltipText={t('common.activePending')}
                onClick={() => openChangeStatus([params?.row])}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_DELETE_USER_ROLE_SETTING}>
              <IconButton
                icon="delete"
                tooltipText={t('common.delete')}
                onClick={() => openConfirmDelete(id)}
              />
            </Guard>
          </Box>
        )
      },
    },
  ]

  const openChangeStatus = (val) => {
    if (Array.isArray(val) && val.length > 0) {
      const status = val[0].status
      const checkUpdate = val.some((item) => item.status !== status)
      if (checkUpdate) return
    }
    setChangeStatus(val)
  }

  const closeChangeStatus = () => {
    setChangeStatus(null)
  }
  const handlChangeStatus = () => {
    if (Array.isArray(changeStatus) && changeStatus.length > 0) {
      const status = changeStatus[0].status
      const ids = changeStatus.map((item) => item.id)
      actChangeStatusRole({ params: { ids }, status }, refreshData)
    } else {
      actChangeStatusRole(
        { params: changeStatus.id, status: changeStatus.status },
        refreshData,
      )
    }
  }

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const handleDelete = () => {
    if (!deleteItems) return
    const params = Array.isArray(deleteItems)
      ? { codes: deleteItems.map((item) => item.code) }
      : deleteItems
    deleteRoleAssign(params, refreshData)
  }

  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_CREATE_USER_ROLE_SETTING}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={() => history.push(withSearch(ROUTE.ROLE_LIST.CREATE.PATH))}
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_USER_ROLE_SETTING}>
        <IconButton
          icon="locked"
          title={`${t('common.active')}/ ${t('common.pending')}`}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openChangeStatus(selectedRows)}
        />
      </Guard>,
      <Guard code={FUNCTION_CODE.USER_DELETE_USER_ROLE_SETTING}>
        <IconButton
          icon="delete"
          title={t('common.delete')}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openConfirmDelete(selectedRows)}
        />
      </Guard>,
    ]
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        totalActive,
        totalInActive,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.defineRole')}
      onSearch={setKeyword}
      placeholder={t('roleManagement.searchPlaceholder')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <TaskBar left={actionHeader} right={[]} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_USER_ROLE_SETTING}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
          }}
        />
      </Guard>
      <DataTable
        title={t('roleManagement.title')}
        columns={columns}
        rows={roleList}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={count}
        sort={sort}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        title={t('roleManagement.modalChangeStatus.title')}
        content={t('roleManagement.modalChangeStatus.content')}
        open={!!changeStatus}
        onConfirm={handlChangeStatus}
        onCancel={closeChangeStatus}
      />
      <PopupConfirm
        t={t}
        title={t('roleManagement.modalDelete.title')}
        content={t('roleManagement.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default RoleList
