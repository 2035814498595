import { useMemo } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'

import {
  MODAL_MODE,
  NOTIFICATION_TYPE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'
import { STATUS_OPTIONS, STATUS_VALUE } from '~/modules/master/constants'
import {
  apiCreateFactory,
  apiUpdateFactory,
} from '~/modules/master/redux/apis/factory'
import addNotification from '~/utils/toast'

import { validationSchema } from './schema'

const DEFAULT_DATA = {
  isActive: STATUS_VALUE.active,
  name: '',
  code: '',
  manageCar: false,
  orgStructure: [],
}

const FormUpdate = ({
  t,
  refreshData = () => {},
  onClose = () => {},
  open,
  data = {},
}) => {
  const mode = isEmpty(data)
    ? MODAL_MODE.CREATE
    : data.id
    ? MODAL_MODE.UPDATE
    : MODAL_MODE.CLONE

  const initialValues = useMemo(() => {
    if (!isEmpty(data)) {
      return {
        id: data.id,
        name: data.name,
        code: data.code,
        location: data.location,
        manageCar: !!data.manageCar,
        isActive: data.isActive,
        orgStructure: data.orgStructures,
      }
    }
    return { ...DEFAULT_DATA }
  }, [data])

  const handleSubmit = async (val, { setFieldValue }) => {
    const body = {
      code: val.code,
      name: val.name,
      location: val.location,
      isActive: val.isActive,
    }
    setFieldValue('isLoading', true)
    let res = null
    if (mode !== MODAL_MODE.UPDATE) {
      res = await apiCreateFactory(body)
    } else {
      body.id = data?.id
      res = await apiUpdateFactory({ id: data?.id, body })
    }

    setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `factory.notification.${
          mode === MODAL_MODE.UPDATE ? 'updateSuccess' : 'createSuccess'
        }`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      refreshData()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
  }

  return (
    <Dialog
      open={open}
      title={t(
        `${
          mode === MODAL_MODE.UPDATE
            ? 'factory.updateTitle'
            : 'factory.createTitle'
        }`,
      )}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values, resetForm }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              justifyContent={'space-between'}
            >
              {/* {mode === MODAL_MODE.UPDATE && (
                <Grid item xs={12}>
                  <LV
                    label={t('route.isActive')}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    value={
                      <Status
                        options={STATUS_OPTIONS}
                        value={values.isActive}
                      />
                    }
                  />
                </Grid>
              )} */}
              <Grid item lg={5.9} xs={12}>
                <Field.TextField
                  name="code"
                  placeholder={t('factory.code')}
                  label={t('factory.code')}
                  required
                  disabled={mode === MODAL_MODE.UPDATE}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                  allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
                />
              </Grid>
              <Grid item xs={12}>
                <Field.TextField
                  name="name"
                  placeholder={t('factory.name')}
                  label={t('factory.name')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Field.TextField
                  name="location"
                  placeholder={t('factory.location')}
                  label={t('factory.location')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Field.Autocomplete
                  label={t('factory.status')}
                  name="isActive"
                  options={STATUS_OPTIONS}
                  getOptionLabel={(opt) => t(opt?.text) || ''}
                  getOptionValue={(opt) => opt?.id}
                  isOptionEqualToValue={(opt, val) =>
                    opt?.id === (val?.id || val)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} lg={6}></Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t(
                      mode === MODAL_MODE.UPDATE
                        ? 'actionBar.save'
                        : 'actionBar.create',
                    )}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default FormUpdate
