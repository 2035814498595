import { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { FieldArray, Form, Formik } from 'formik'

import { ACTIVE_STATUS, NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import Loading from '~/components/Loading'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'
import {
  apiGetFeeSettingRoute,
  apiUpsertFeeSettingRoute,
} from '~/modules/master/redux/apis/setting-fee'
import { convertFilterParams } from '~/utils'
import addNotification from '~/utils/toast'

import { formatCatshift } from '../constants'
import FormTable from './form-table'
import { validationSchema } from './schema'

export const DEFAULT_TABLE = {
  catShifts: undefined,
  route: undefined,
  vehicleType: undefined,
  fee: 1,
}

const DEFAULT_INIT = {
  items: [DEFAULT_TABLE],
}

const FormCost = ({ t, open, onClose }) => {
  const [initialValues, setInitialValues] = useState(DEFAULT_INIT)

  useEffect(() => {
    if (open) {
      refreshData()
    }
  }, [open])

  const refreshData = async (data, setFieldValue) => {
    if (setFieldValue) {
      setFieldValue('isLoading', true)
    }

    const resRoute = await apiGetListRouteList({
      isGetAll: 1,
      filter: convertFilterParams({
        isActive: ACTIVE_STATUS.ACTIVE,
      }),
    })

    const listRoute = resRoute?.data?.items || []

    const res = await apiGetFeeSettingRoute(data)

    if (setFieldValue) {
      setFieldValue('isLoading', false)
    }

    const listItems = res.data?.items || []

    setInitialValues({
      listRouteAll: listRoute,
      ...DEFAULT_INIT,
      items: listItems.map((item) => {
        const currentRoute = listRoute.find((it) => it.code === item.routeCode)
        return {
          catShiftOptions: formatCatshift(currentRoute.catShifts),
          catShifts: { code: item.shiftCode, name: item.name },
          route: { code: item.routeCode, name: item.routeName },
          vehicleType: {
            code: item.vehicleTypeCode,
            name: item.vehicleTypeName,
          },
          fee: item?.fee,
        }
      }),
    })
  }

  const handleSubmit = async (val, { setFieldValue }) => {
    if (!val && !setFieldValue) return
    const body = {
      feeSettingRoutes: val?.items?.map((item) => ({
        routeCode: item.route?.code,
        shiftCode: item.catShifts?.code,
        vehicleTypeCode: item.vehicleType?.code,
        fee: item.fee,
      })),
    }
    setFieldValue('isLoading', true)
    let res = await apiUpsertFeeSettingRoute(body)
    setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `settingFee.notification.costSuccess`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      onClose()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
  }

  return (
    <Dialog
      open={open}
      title={t(`settingFee.costSettingTitle`)}
      onCancel={onClose}
      maxWidth="md"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ handleReset, values, setFieldValue }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              justifyContent="space-between"
            >
              <Box>
                <FieldArray
                  name="items"
                  render={(arrayHelpers) => (
                    <FormTable
                      t={t}
                      values={values}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={handleReset}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t(`actionBar.save`)}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values?.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default FormCost
