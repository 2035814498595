import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListPosition = (params) => {
  const uri = `/human-resource/position`
  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailPosition = (id) => {
  const uri = `${id}`
  return api.get(uri)
}

/**
 * @returns {Promise}
 */
export const apiSyncPosition = (body = {}) => {
  const uri = `/sync/position`
  return api.post(uri, body)
}
