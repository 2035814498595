import { useContext } from 'react'


import AzureContext from '~/contexts/AzureContext'

import Button from '../Button'

const AzureLogin = ({ sx }) => {
  const azure = useContext(AzureContext);

  return (
    <Button
      type="button"
      fullWidth
      loading={azure.isLoading}
      onClick={azure.openAzureLogin}
      sx={{
        ...sx,
        backgroundColor: '#069AF3',
      }}
    >
      {/* <Icon
        name={'azureLogo'}
        fill={'#0050AE'}
        // sx={{ width: '70px' }}
        sx={{
          svg: {
            width: '200px',
            height: '200px',
            path: { fill: '#0050AE', stroke: '#0050AE' },
          },
        }}
      /> */}
      Azure Login
    </Button>
  )
}

export default AzureLogin
