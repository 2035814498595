import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiCreateRoute = ({ body }) => {
  const uri = `/routes`
  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListRoute = (params) => {
  const uri = `/routes`
  return api.get(uri, params)
}

export const apiGetListRouteList = (params) => {
  const uri = `/routes/list`
  return api.get(uri, params)
}

/**
 * @param {id} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetDetailRoute = (id) => {
  const uri = `/routes/${id}`
  return api.get(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateRoute = ({ id, body }) => {
  const uri = `/routes/${id}`
  return api.patch(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
/**
 * @param {
 * routeCode: string, shiftCode: string
 * }
 * will be sent to server
 * @returns {Promise}
 */
export const apiGetAvailableSeat = (params) => {
  const uri = `/routes/available-seat`
  return api.get(uri, params)
}

/// delete
export const apiDeleteRoute = (params) => {
  const uri = `/routes`
  return api.delete(uri, params)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveRoute = (id) => {
  const uri = `/routes/${id}/active`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiActiveRoutes = (body) => {
  const uri = `/routes/active/multiple`

  return api.post(uri, body)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveRoute = (id) => {
  const uri = `/routes/${id}/inactive`

  return api.post(uri)
}

/**
 * @param {id: any, body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiInActiveRoutes = (body) => {
  const uri = `/routes/inactive/multiple`

  return api.post(uri, body)
}
