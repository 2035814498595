import React, { useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FieldArray, Form, Formik } from 'formik'

import {
  NOTIFICATION_TYPE,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { SEND_MAIL_TYPE, VEHICLE_TYPE_VALUE } from '~/modules/master/constants'
import {
  apiRegisterVehicleBuTravel,
  apiRegisterVehicleOt,
} from '~/modules/master/redux/apis/attendance'
import addNotification from '~/utils/toast'

import { TAB_VALUE } from '../constants'
import ReceiptTable from './receipt-table'
import { validationSchema } from './schema'

const RegisterRoute = ({
  t,
  open,
  registerVehicle,
  registerData,
  isLoading,
  onClose = () => {},
  refreshData,
  tab,
  // onSubmit = () => { },
}) => {
  const {
    data: { userInfo },
  } = useUserInfo()

  const TABLE_ROW_DEFAULT = {
    code: '',
    vehicleType: VEHICLE_TYPE_VALUE.taxi,
    createdAt: new Date(),
    createdBy: userInfo,
  }

  const DEFAULT_VALUE = {
    descriptionRegisterVehicle: '',
    jobDetails: [TABLE_ROW_DEFAULT],
  }

  const initialValues = useMemo(() => {
    const initData = {
      ...DEFAULT_VALUE,
    }
    if (registerData) {
      initData.descriptionRegisterVehicle =
        registerData.descriptionRegisterVehicle
      initData.jobDetails = registerData?.registerVehicles
    }

    return initData
  }, [registerData, userInfo])

  const onSubmit = async (values, setFieldValue) => {
    const body = {
      descriptionRegisterVehicle: values.descriptionRegisterVehicle,
      jobDetails: values.jobDetails?.map((item) => ({
        id: item.id,
        code: item.code,
        vehicleType: item.vehicleType,
        createdBy: item.createdBy?.id,
      })),
      type: SEND_MAIL_TYPE.ATTENDANCE,
    }

    setFieldValue('isLoading', true)

    let res = null

    if (tab === TAB_VALUE.overtime) {
      res = await apiRegisterVehicleOt({ id: values.id, body })
    } else {
      res = await apiRegisterVehicleBuTravel({ id: values.id, body })
    }

    if (res?.statusCode === 200) {
      addNotification(
        `attendance.notification.registerVehicleSuccess`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      refreshData()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }

    setFieldValue('isLoading', false)
  }

  return (
    <Dialog
      open={open}
      title={t('attendance.registerVehicle.title')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
      loading={isLoading}
    >
      <Formik
        onSubmit={(val, { setFieldValue }) => {
          return onSubmit({ ...val, id: registerVehicle?.id }, setFieldValue)
        }}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
            >
              <Grid item xs={12}>
                <Typography sx={{ paddingBottom: '12px' }}>
                  {t('attendance.registerVehicle.description')}:
                </Typography>
                <Field.TextField
                  name="descriptionRegisterVehicle"
                  placeholder={t('attendance.registerVehicle.desPlaceHolder')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name="jobDetails"
                  render={(arrayHelpers) => (
                    <>
                      <ReceiptTable
                        arrayHelpers={arrayHelpers}
                        values={values}
                        TABLE_ROW_DEFAULT={TABLE_ROW_DEFAULT}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={onClose}
                  >
                    {t('actionBar.close')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t('actionBar.save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default RegisterRoute
