import React, { useEffect, useMemo } from 'react'

import { Divider } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import Button from '~/components/Button'
import HotKeys from '~/components/HotKeys'
import Page from '~/components/Page'
import { ROUTE } from '~/modules/master/routes/config'

import useSetting from '../../redux/hooks/useSetting'
import { FREQUENCY, SETTING_CODE } from './constants'
import SettingForm from './form'
import { validationSchema } from './schema'

const breadcrumbs = [
  {
    title: ROUTE.SETTING.TITLE,
  },
  {
    route: ROUTE.SETTING.SYNC.PATH,
    title: ROUTE.SETTING.SYNC.TITLE,
  },
]
const SettingSync = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()

  const {
    data: {
      getSetting: { data = [], isLoading },
      updateSetting: { isLoading: isLoadingUpdate },
    },
    actions: { actGetSetting, actUpdateSetting, actGetSettingReset },
  } = useSetting()

  const initialValues = useMemo(() => {
    if (data) {
      return data.reduce((acc, current) => {
        const initTime = moment(current.initTime)
        const {
          runFri,
          runMon,
          runSat,
          runSun,
          runThu,
          runTue,
          runWed,
          ...item
        } = current
        acc[SETTING_CODE[current.code]] = {
          ...item,
          isActive: item.isActive ? true : 0,
          initDay: initTime,
          initHour: initTime.hours(),
          runFri: !!runFri,
          runMon: !!runMon,
          runSat: !!runSat,
          runSun: !!runSun,
          runThu: !!runThu,
          runTue: !!runTue,
          runWed: !!runWed,
          frequency: current.hour !== null ? FREQUENCY.HOUR : FREQUENCY.MINUTE,
        }
        return acc
      }, {})
    }

    return {}
  }, [data])

  useEffect(() => {
    refreshData()
    return () => {
      actGetSettingReset()
    }
  }, [])

  const refreshData = () => {
    actGetSetting({})
  }

  const onSubmit = (values) => {
    const body = Object.values(values).map(
      ({ initDay, initHour, frequency, ...val }) => ({
        ...val,
        isActive: val.isActive ? 1 : 0,
        runFri: val.runFri ? 1 : 0,
        runMon: val.runMon ? 1 : 0,
        runSat: val.runSat ? 1 : 0,
        runSun: val.runSun ? 1 : 0,
        runThu: val.runThu ? 1 : 0,
        runTue: val.runTue ? 1 : 0,
        runWed: val.runWed ? 1 : 0,
        initTime: moment(initDay).set('hours', initHour),
        hour: frequency === FREQUENCY.HOUR ? `${val.hour}` : null,
        minute: frequency === FREQUENCY.MINUTE ? `${val.minute}` : null,
        beginHour: `${val.beginHour}`,
        endHour: `${val.endHour}`,
      }),
    )

    actUpdateSetting({ cronSettings: body }, refreshData)
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.settingSync')}
      loading={isLoading || isLoadingUpdate}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />

      {!isEmpty(data) && (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema(t, initialValues)}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Box></Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    type="submit"
                    sx={{ marginRight: '10px' }}
                    color="success"
                    variant="outlined"
                  >
                    {t('actionBar.saveInfo')}
                  </Button>
                </Box>
              </Box>
              <Divider />
              {data?.map((item, idx) => (
                <Box sx={{ marginTop: idx === 0 ? '20px' : '30px' }}>
                  <SettingForm
                    t={t}
                    data={item}
                    values={values}
                    name={SETTING_CODE[item.code]}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              ))}
            </Form>
          )}
        </Formik>
      )}
    </Page>
  )
}

export default SettingSync
