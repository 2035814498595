import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        route: Yup.object()
          .nullable()
          .test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            const currentDatas =
              context.from[context.from.length - 1]?.value?.items || []
            const formVal = context?.parent || {}

            if (
              checkDupicate({
                items: currentDatas,
                route: value,
                catShifts: formVal.catShifts,
                vehicleType: formVal.vehicleType,
              })
            ) {
              return context.createError({
                path: context.path,
                message: t('form.codeDupicate'),
              })
            }

            return true
          }),
        catShifts: Yup.object()
          .nullable()
          .test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            const currentDatas =
              context.from[context.from.length - 1]?.value?.items || []
            const formVal = context?.parent || {}

            if (
              checkDupicate({
                items: currentDatas,
                route: formVal.route,
                catShifts: value,
                vehicleType: formVal.vehicleType,
              })
            ) {
              return context.createError({
                path: context.path,
                message: t('form.codeDupicate'),
              })
            }

            return true
          }),
        vehicleType: Yup.object()
          .nullable()
          .test(_, (value, context) => {
            if (!value) {
              return context.createError({
                path: context.path,
                message: t('form.required'),
              })
            }

            const currentDatas =
              context.from[context.from.length - 1]?.value?.items || []
            const formVal = context?.parent || {}

            if (
              checkDupicate({
                items: currentDatas,
                route: formVal.route,
                catShifts: formVal.catShifts,
                vehicleType: value,
              })
            ) {
              return context.createError({
                path: context.path,
                message: t('form.codeDupicate'),
              })
            }

            return true
          }),
        fee: Yup.number()
          .nullable()
          .required(t('form.required'))
          .min(
            1,
            t('form.minNumber', {
              min: 1,
            }),
          ),
      }),
    ),
  })

const checkDupicate = ({ items, route, catShifts, vehicleType }) => {
  if (!route || !catShifts || !vehicleType) return
  const listDupicate = items?.filter(
    (item) =>
      item?.route?.code === route.code &&
      item?.catShifts?.code === catShifts.code &&
      item?.vehicleType?.code === vehicleType.code,
  )

  return listDupicate.length > 1 ? true : false
}
