// Action: Get list
export const MASTER_GET_LIST_OVERTIME = 'MASTER_GET_LIST_OVERTIME'
export const MASTER_GET_LIST_OVERTIME_SUCCESS =
  'MASTER_GET_LIST_OVERTIME_SUCCESS'
export const MASTER_GET_LIST_OVERTIME_FAILED = 'MASTER_GET_LIST_OVERTIME_FAILED'
export const MASTER_GET_LIST_OVERTIME_RESET = 'MASTER_GET_LIST_OVERTIME_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_OVERTIME = 'MASTER_GET_DETAIL_OVERTIME'
export const MASTER_GET_DETAIL_OVERTIME_SUCCESS =
  'MASTER_GET_DETAIL_OVERTIME_SUCCESS'
export const MASTER_GET_DETAIL_OVERTIME_FAILED =
  'MASTER_GET_DETAIL_OVERTIME_FAILED'
export const MASTER_GET_DETAIL_OVERTIME_RESET =
  'MASTER_GET_DETAIL_OVERTIME_RESET'

// Action: sync
export const MASTER_SYNC_OVERTIME = 'MASTER_SYNC_OVERTIME'
export const MASTER_SYNC_OVERTIME_SUCCESS = 'MASTER_SYNC_OVERTIME_SUCCESS'
export const MASTER_SYNC_OVERTIME_FAILED = 'MASTER_SYNC_OVERTIME_FAILED'
export const MASTER_SYNC_OVERTIME_RESET = 'MASTER_SYNC_OVERTIME_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListOvertime(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_OVERTIME,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListOvertimeSuccess(payload) {
  return {
    type: MASTER_GET_LIST_OVERTIME_SUCCESS,
    payload: payload,
  }
}

export function actGetListOvertimeFailed() {
  return {
    type: MASTER_GET_LIST_OVERTIME_FAILED,
  }
}

export function actGetListOvertimeReset() {
  return {
    type: MASTER_GET_LIST_OVERTIME_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailOvertime(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_OVERTIME,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailOvertimeSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_OVERTIME_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailOvertimeFailed() {
  return {
    type: MASTER_GET_DETAIL_OVERTIME_FAILED,
  }
}

export function actGetDetailOvertimeReset() {
  return {
    type: MASTER_GET_DETAIL_OVERTIME_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncOvertime(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_OVERTIME,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncOvertimeSuccess(payload) {
  return {
    type: MASTER_SYNC_OVERTIME_SUCCESS,
    payload: payload,
  }
}

export function actSyncOvertimeFailed() {
  return {
    type: MASTER_SYNC_OVERTIME_FAILED,
  }
}

export function actSyncOvertimeReset() {
  return {
    type: MASTER_SYNC_OVERTIME_RESET,
  }
}

export default {
  actGetListOvertime,
  actGetListOvertimeSuccess,
  actGetListOvertimeFailed,
  actGetListOvertimeReset,
  actGetDetailOvertime,
  actGetDetailOvertimeSuccess,
  actGetDetailOvertimeFailed,
  actGetDetailOvertimeReset,
  actSyncOvertime,
  actSyncOvertimeSuccess,
  actSyncOvertimeFailed,
  actSyncOvertimeReset,
}
