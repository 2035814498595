import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().test(_, (value, context) => {
          if (!value) {
            return context.createError({
              path: context.path,
              message: t('form.required'),
            })
          }

          return true
        }),
      }),
    ),
  })
