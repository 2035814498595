import { useEffect, useLayoutEffect, useState } from 'react'

import { Divider, FormControlLabel, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { MODAL_MODE } from '~/common/constants'
import { useQueryState } from '~/common/hooks'
import Button from '~/components/Button'
import Checkbox from '~/components/Checkbox'
import HotKeys from '~/components/HotKeys'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import TaskBar from '~/components/TaskBar'
import { STATUS_OPTIONS } from '~/modules/master/constants'
import useRoute from '~/modules/master/redux/hooks/useRoute'
import { ROUTE } from '~/modules/master/routes/config'

import { TAB_FORM_VALUE, TIME_RUN_CHECKBOXS } from '../constants'
import FormTable from '../form/form-table/list'

const DetailRoute = () => {
  const { t } = useTranslation(['buseye'])
  const { id } = useParams()
  const history = useHistory()
  const [isDelete, setIsDelete] = useState(false)

  const formQuery = useQueryState()

  if (!formQuery.tab) {
    formQuery.tab = TAB_FORM_VALUE.pickupPoints
  }

  const mode = MODAL_MODE.DETAIL

  const breadcrumbs = [
    {
      route: ROUTE.ROUTE.LIST.PATH,
      title: ROUTE.ROUTE.LIST.TITLE,
    },
    {
      route: ROUTE.ROUTE.DETAIL.PATH,
      title: ROUTE.ROUTE.DETAIL.TITLE,
    },
  ]

  const {
    data: {
      detailRoute: { data, isLoading },
    },
    actions: { actGetDetailRoute, actGetDetailRouteReset, actDeleteRoute },
  } = useRoute()

  useEffect(() => {
    actGetDetailRoute(id)
  }, [id])

  useLayoutEffect(() => {
    return () => {
      actGetDetailRouteReset()
    }
  }, [])

  const goToList = () => {
    history.push(ROUTE.ROUTE.LIST.PATH)
  }

  const handleDelete = () => {
    actDeleteRoute({ codes: [data.code] }, () => {
      goToList()
      closeConfirmDelete()
    })
  }

  const openConfirmDelete = () => {
    setIsDelete(true)
  }

  const closeConfirmDelete = () => {
    setIsDelete(false)
  }

  const actionHeader = [
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      color="success"
      variant="outlined"
      icon="edit"
      iconColor="success"
      onClick={() => {
        history.push(ROUTE.ROUTE.UPDATE.PATH.replace(':id', `${id}`))
      }}
    >
      {t('actionBar.editInfo')}
    </Button>,
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      color="error"
      variant="outlined"
      icon={'deleteOutline'}
      onClick={openConfirmDelete}
    >
      {t('actionBar.delete')}
    </Button>,
  ]

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.routeDetail')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <Box sx={{ padding: '5px 24px 5px 24px' }}>
        <TaskBar sx={{ background: 'white' }} left={[]} right={actionHeader} />
        <Divider sx={{ borderColor: '#CBCBCB', margin: '0 0 20px 0' }} />
        <Grid
          container
          rowSpacing={2}
          // columnSpacing={{ xl: 1, xs: 1 }}
          sx={{
            backgroundColor: '#EFF5FB',
            padding: '24px',
            borderRadius: '8px',
          }}
          justifyContent="space-between"
        >
          <Grid item lg={5.9} xs={12}>
            <LV
              label={t('route.code')}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={data.code}
            />
          </Grid>
          <Grid item lg={5.9} xs={12}>
            <LV
              label={t('route.name')}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={data.name}
            />
          </Grid>
          <Grid item xs={12}>
            <LV
              label={t('route.form.time')}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={
                <Box>
                  {TIME_RUN_CHECKBOXS.map((item) => (
                    <FormControlLabel
                      key={item.name}
                      name={item.name}
                      control={<Checkbox checked={!!data[item.name]} />}
                      label={t(item.text)}
                      sx={{ marginRight: '15px' }}
                    />
                  ))}
                </Box>
              }
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LV
              label={t('route.isActive')}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={<Status options={STATUS_OPTIONS} value={data.isActive} />}
            />
          </Grid>
          <Grid item xs={12}>
            <LV
              label={t('route.form.description')}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={data.description}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <FormTable
            values={data}
            formQuery={formQuery}
            mode={mode}
            errors={{}}
          />
        </Box>
        <Box sx={{ padding: 3 }}>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="button"
                sx={{ marginRight: '10px' }}
                color="grayF4"
                onClick={goToList}
              >
                {t('actionBar.close')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
      <PopupConfirm
        t={t}
        title={t('route.modalDelete.title')}
        content={t('route.modalDelete.content')}
        open={isDelete}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default DetailRoute
