import { combineReducers } from 'redux'

import departmentList from './department-list'
import roleList from './role-list'
import userInfo from './user-info'
import userManagement from './user-management'
import userPermission from './user-permission'


export default combineReducers({
  departmentList,
  roleList,
  userPermission,
  userInfo,
  userManagement,
})
