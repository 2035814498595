import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actUpdateProfileCustomFailed,
  actUpdateProfileCustomSuccess,
  MASTER_UPDATE_PROFILE_CUSTOM,
} from '../../actions/profile-custom'
import { apiUpdateProfileCustom } from '../../apis/profile-custom'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doUpdate(action) {
  try {
    const response = yield call(apiUpdateProfileCustom, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actUpdateProfileCustomSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(
        'profileCustom.notification.updateSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actUpdateProfileCustomFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchUpdate() {
  yield takeLatest(MASTER_UPDATE_PROFILE_CUSTOM, doUpdate)
}
