export const keyMap = {
  goToHomePage: 'home',
  toggleNotification: 'shift+t',
  toggleFilter: 'shift+f',
  toggleConfig: 'shift+k',
  onNextPage: 'shift+right',
  onPrevPage: 'shift+left',
  onNextTab: ['ctrl+tab', 'command+tab'],
  onPrevTab: ['ctrl+shift+tab'],
  onBack: 'shift+backspace',
  onReset: 'shift+z',
  onCreate: 'shift+n',
  onEdit: 'shift+e',
  onDelete: ['del', 'backspace'],
  onApprove: 'shift+c',
  onReject: 'shift+r',
  onImport: 'shift+i',
  onExport: 'shift+p',
  onAddRow: 'alt+n',
  onRemoveRow: ['alt+del', 'alt+backspace'],
}
