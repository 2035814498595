import React, { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import {
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { useQueryState } from '~/common/hooks'
import ActionBar from '~/components/ActionBar'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import { USER_MANAGEMENT_STATUS_OPTIONS } from '~/modules/configuration/constants'
import { USER_TYPE } from '~/modules/configuration/feature/user-management/constants'
import { searchFactoriesApi } from '~/modules/configuration/redux/apis'
import useRoleManagement from '~/modules/configuration/redux/hooks/useRoleList'
import useUserManagement from '~/modules/configuration/redux/hooks/useUserManagement'
import { searchAzureUsersApi } from '~/modules/configuration/redux/sagas/user-management/search-azure-users'
import { ROUTE } from '~/modules/configuration/routes/config'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListOrgStructure } from '~/modules/master/redux/apis/org-structure'
import { convertFilterParams } from '~/utils'
import qs from '~/utils/qs'

import { validationSchema } from './schema'

function UserAzureForm() {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const location = useLocation()
  const { cloneId } = qs.parse(location.search)
  const routeMatch = useRouteMatch()
  const { withSearch } = useQueryState()

  const MODE_MAP = {
    [ROUTE.USER_MANAGEMENT.CREATE_AZURE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.USER_MANAGEMENT.EDIT.PATH]: MODAL_MODE.UPDATE,
  }

  const mode = MODE_MAP[routeMatch.path]
  const isUpdate = mode === MODAL_MODE.UPDATE

  const {
    data: { userDetails, isLoading },
    actions,
  } = useUserManagement()

  const {
    data: { roleList },
    actions: commonActions,
  } = useRoleManagement()

  useEffect(() => {
    commonActions.searchRoleList({
      filter: convertFilterParams({
        status: 1,
      }),
    })
  }, [])

  const initialValues = useMemo(() => {
    if (userDetails) {
      const init = {
        email: null,
        code: '',
        fullName: '',
        userRoleSettings: null
      }

      return init
    }
    return {}
  }, [userDetails, userDetails])

  const onSubmit = (values) => {
    const convertValues = {
      ...values,
      userRoleSettings: values.userRoleSettings
        ? [{ id: values.userRoleSettings }]
        : [{ id: 1 }],
      factories: values.factories ? [{ id: values.factories?.id }] : [],
      orgStructures: values.orgStructures?.map((item) => ({ id: item.id })),
      password: values.email.id,
      username: values.email.mail,
      email: values.email.mail,
      status: '1',
      type: USER_TYPE.AZURE
    }

    if (mode === MODAL_MODE.CREATE) {
      actions.createUser(convertValues, () =>
        history.push(ROUTE.USER_MANAGEMENT.LIST.PATH),
      )
    } else if (mode === MODAL_MODE.UPDATE) {
      actions.updateUser(convertValues, backToList)
    }
  }

  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        title: 'decentralization',
      },
      {
        route: withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH),
        title: ROUTE.USER_MANAGEMENT.LIST.TITLE,
      },
    ]
    switch (mode) {
      case MODAL_MODE.CREATE:
        breadcrumb.push({
          route: ROUTE.USER_MANAGEMENT.CREATE_AZURE.PATH,
          title: ROUTE.USER_MANAGEMENT.CREATE_AZURE.TITLE,
        })
        break
      case MODAL_MODE.UPDATE:
        breadcrumb.push({
          route: ROUTE.USER_MANAGEMENT.EDIT.PATH,
          title: ROUTE.USER_MANAGEMENT.EDIT.TITLE,
        })
        break
      default:
        break
    }
    return breadcrumb
  }

  const backToList = () => {
    history.push(withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH))
  }

  const handleChangeEmai = (val, setFieldValue) => {
    if (val) {
      setFieldValue('fullName', val.displayName)
      setFieldValue('phone', val.mobilePhone || '')
    } else {
      setFieldValue('fullName', '')
      setFieldValue('phone', '')
    }
  }

  return (
    <Page
      breadcrumbs={getBreadcrumb()}
      title={t('menu.' + ROUTE.USER_MANAGEMENT.USER_AZURE_FORM.TITLE)}
      onBack={backToList}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t, mode)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleReset, values, setFieldValue }) => (
              <Form>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {t('userManagement.commonInfo')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  rowSpacing={2}
                  sx={{
                    backgroundColor: '#DAE7F3',
                    marginTop: '5px',
                    padding: '0 15px 15px 15px',
                  }}
                  justifyContent="space-between"
                >
                  {isUpdate && (
                    <Grid item xs={12}>
                      <LV
                        label={
                          <Typography>{t('userManagement.status')}</Typography>
                        }
                        value={
                          <Status
                            name="status"
                            options={USER_MANAGEMENT_STATUS_OPTIONS}
                            value={userDetails?.status}
                          />
                        }
                      />
                    </Grid>
                  )}
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      label={t('userManagement.code')}
                      name="code"
                      placeholder={t('userManagement.code')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX,
                      }}
                      allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
                      disabled={isUpdate}
                      required
                      {...(cloneId ? { autoFocus: true } : {})}
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.Autocomplete
                      name="email"
                      label={t('userManagement.email')}
                      placeholder={t('userManagement.email')}
                      asyncRequest={(s) =>
                        searchAzureUsersApi({
                          keyword: s,
                          columns: 'displayName,id,mail,mobilePhone',
                          limit: ASYNC_SEARCH_LIMIT,
                        })
                      }
                      onChange={(val) => handleChangeEmai(val, setFieldValue)}
                      asyncRequestHelper={(res) => res?.data?.items}
                      getOptionLabel={(option) =>
                        `${option?.mail}`
                      }
                      // isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                    />
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      name="fullName"
                      label={t('userManagement.fullName')}
                      placeholder={t('userManagement.fullName')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.NAME.MAX,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.DatePicker
                      name="dateOfBirth"
                      label={t('userManagement.dateOfBirth')}
                      placeholder={t('userManagement.dateOfBirth')}
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      name="phone"
                      label={t('userManagement.phone')}
                      placeholder={t('userManagement.phone')}
                      allow={TEXTFIELD_ALLOW.NUMERIC}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.PHONE.MAX,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" mt={3}>
                    {t('userManagement.workInfo')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  rowSpacing={2}
                  sx={{
                    backgroundColor: '#DAE7F3',
                    marginTop: '5px',
                    padding: '0 15px 15px 15px',
                  }}
                  justifyContent="space-between"
                >
                  <Grid item lg={5.5} xs={12}>
                    {values && (
                      <Field.Autocomplete
                        name="orgStructures"
                        label={t('profileCustom.orgStructure')}
                        placeholder={t('profileCustom.orgStructure')}
                        asyncRequest={(s) =>
                          apiGetListOrgStructure({
                            keyword: s,
                            limit: ASYNC_SEARCH_LIMIT,
                            filter: convertFilterParams({
                              isActive: STATUS_VALUE.active,
                            }),
                          })
                        }
                        asyncRequestHelper={(res) => res?.data?.items}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                        multiple
                      />
                    )}
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Field.Autocomplete
                      name="factories"
                      label={t('userManagement.factoryName')}
                      placeholder={t('userManagement.factoryName')}
                      asyncRequest={(s) =>
                        searchFactoriesApi({
                          keyword: s,
                          limit: ASYNC_SEARCH_LIMIT,
                          filter: convertFilterParams({
                            isActive: STATUS_VALUE.active,
                          }),
                        })
                      }
                      asyncRequestHelper={(res) => res?.data?.items}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                    />
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Field.Autocomplete
                      name="userRoleSettings"
                      label={t('userManagement.roleAssign')}
                      placeholder={t('userManagement.roleAssign')}
                      options={roleList}
                      getOptionLabel={(opt) => opt?.name}
                      getOptionValue={(opt) => opt?.id}
                      required
                    />
                  </Grid>
                </Grid>
                <ActionBar
                  onBack={backToList}
                  onCancel={handleReset}
                  mode={MODAL_MODE.CREATE}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  )
}

export default UserAzureForm
