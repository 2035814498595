import { all } from 'redux-saga/effects'

import watchGetUserPermissionDetails from '../user-permission/get-user-permission'
import watchCreateUser from './create-user'
import watchDeleteUser from './delete-user'
import watchGenerateOTP from './generate-otp'
import watchGetUserDetails from './get-user-details'
import watchResetPassword from './reset-password'
import watchSearchAzureUsers from './search-azure-users'
import watchSearchUsers from './search-users'
import watchUpdateUser from './update-user'
import watchVerifyOTP from './verify-otp-code'

/**
 * Root saga
 */
export default function* sagas() {
  yield all([
    watchCreateUser(),
    watchDeleteUser(),
    watchGenerateOTP(),
    watchGetUserPermissionDetails(),
    watchResetPassword(),
    watchSearchUsers(),
    watchUpdateUser(),
    watchVerifyOTP(),
    watchGetUserDetails(),
    watchSearchAzureUsers(),
  ])
}
