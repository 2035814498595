import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actSyncFactoryFailed,
  actSyncFactorySuccess,
  MASTER_SYNC_FACTORY,
} from '../../actions/factory'
import { apiSyncFactory } from '../../apis/factory'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doSync(action) {
  try {
    const response = yield call(apiSyncFactory, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.items,
        total: response.data?.meta?.total,
      }
      yield put(actSyncFactorySuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actSyncFactoryFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchSync() {
  yield takeLatest(MASTER_SYNC_FACTORY, doSync)
}
