import React from 'react'

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { Box, Typography } from '@mui/material'

import { useTable } from '~/common/hooks/useTable'
import theme, { useClasses } from '~/themes'

import TableKeyFilter from '../TableKeyFilter'
import TableSetting from '../TableSetting'
import style from './style'

const TopBar = () => {
  const classes = useClasses(style)
  const {
    beforeTopbar,
    afterTopbar,
    title,
    tabs,
    hideSetting,
    onFilterByKeys,
    hasError,
    variantTitle = 'h3',
  } = useTable()

  if (!title && hideSetting && !beforeTopbar && !afterTopbar && !tabs)
    return null

  return (
    <Box className={classes.root}>
      {hasError && (
        <WarningAmberRoundedIcon
          fontSize="small"
          sx={{
            mr: '4px !important',
            color: `${theme.palette.error.main}`,
          }}
        />
      )}
      {tabs && <Box sx={{ minWidth: 0 }}>{tabs}</Box>}
      {title && !tabs && (
        <Typography variant={variantTitle}>{title}</Typography>
      )}
      <Box sx={{ display: 'flex', marginLeft: 'auto', gap: 1 }}>
        {beforeTopbar}
        {typeof onFilterByKeys === 'function' && <TableKeyFilter />}
        {!hideSetting && <TableSetting />}
        {afterTopbar}
      </Box>
    </Box>
  )
}

export default TopBar
