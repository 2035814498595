import React, { useMemo } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { MODAL_MODE } from '~/common/constants'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import Tabs from '~/components/Tabs'

import {
  DEFAULT_TABLE,
  tabFormList,
  TAB_FORM_VALUE,
  TAB_FORM_VALUE_MAPPING,
} from '../../../constants'
import { columnCatShiftTable, columnPickupPointTable } from '../columns'

const FormTable = ({
  arrayHelpers,
  values,
  formQuery,
  name,
  mode,
  setFieldValue,
  errors = {},
}) => {
  const { t } = useTranslation(['buseye'])

  // const [selectedRows, setSelectedRows] = useState([])

  const { page, pageSize, sort, tab, setPage, setPageSize, setSort, setTab } =
    formQuery

  // useEffect(() => {
  //   setSelectedRows([])
  // }, [selectedRowsDeps, tab])

  const tableConfig = useMemo(() => {
    switch (tab) {
      // case TAB_FORM_VALUE.vehicleCategories:
      //   return {
      //     columns: columnVehicleTable({
      //       t,
      //       arrayHelpers,
      //       tableData: values?.vehicleCategories,
      //       name,
      //       mode,
      //     }),
      //   }
      case TAB_FORM_VALUE.catShifts:
        return {
          columns: columnCatShiftTable({
            t,
            arrayHelpers,
            tableData: values?.catShifts,
            name,
            mode,
            setFieldValue,
          }),
        }
      default:
        return {
          columns: columnPickupPointTable({
            t,
            arrayHelpers,
            tableData: values.pickupPoints,
            name,
            mode,
          }),
        }
    }
  }, [tab, t, arrayHelpers, values])

  const handleAddNew = () => {
    arrayHelpers.push(DEFAULT_TABLE[TAB_FORM_VALUE_MAPPING[tab]])
  }

  const beforeTopbar =
    mode !== MODAL_MODE.DETAIL ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="button"
          sx={{ marginRight: '10px' }}
          variant="outlined"
          iconColor="primary"
          icon="add"
          onClick={handleAddNew}
        >
          {t(
            `route.${
              TAB_FORM_VALUE_MAPPING[tab || TAB_FORM_VALUE.pickupPoints]
            }.addButton`,
          )}
        </Button>
      </Box>
    ) : (
      []
    )

  return (
    <DataTable
      rows={values[TAB_FORM_VALUE_MAPPING[tab]] || []}
      pageSize={pageSize}
      page={page}
      columns={tableConfig.columns}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onSortChange={setSort}
      // {...(mode !== MODAL_MODE.DETAIL
      //   ? {
      //       onSelectionChange: setSelectedRows,
      //       selected: selectedRows,
      //     }
      //   : {})}
      total={tableConfig.total}
      title={t('master.title')}
      sort={sort}
      beforeTopbar={beforeTopbar}
      tabs={
        <Tabs
          list={tabFormList(
            t,
            mode,
            (errors = {
              pickupPoints: !isEmpty(errors?.pickupPoints),
              catShifts: !isEmpty(errors?.catShifts),
            }),
          )}
          value={tab}
          onChange={setTab}
        />
      }
      hideFooter
    />
  )
}

export default FormTable
