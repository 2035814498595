import React from 'react'

import { Box } from '@mui/material'
import PropTypes from 'prop-types'

import { useClasses } from '~/themes'

import style from './style'

const AuthLayout = ({ children }) => {
  const classes = useClasses(style)

  return (
    <Box className={classes.root}>
      {/* <Hidden mdDown>
        <Box className={classes.leftPanel}>
          <Box className={classes.slider}>
            <IntroSlider />
          </Box>
        </Box>
      </Hidden> */}
      <Box className={classes.main}>
        <Box className={classes.box}>{children}</Box>
      </Box>
    </Box>
  )
}

AuthLayout.defaultProps = {
  children: null,
}

AuthLayout.propTypes = {
  children: PropTypes.node,
}

export default AuthLayout
