import React, { useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FieldArray, Form, Formik } from 'formik'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import Guard from '~/components/Guard'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { VEHICLE_TYPE_VALUE } from '~/modules/master/constants'

import { validationSchema } from '../../attendance/register-vehicle/schema'
import ReceiptTable from './receipt-table'
const RegisterRoute = ({
  t,
  open,
  registerVehicle,
  registerData,
  isLoading,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const {
    data: { userInfo },
  } = useUserInfo()

  const TABLE_ROW_DEFAULT = {
    code: '',
    vehicleType: VEHICLE_TYPE_VALUE.taxi,
    createdAt: new Date(),
    createdBy: userInfo,
  }

  const DEFAULT_VALUE = {
    descriptionRegisterVehicle: '',
    jobDetails: [TABLE_ROW_DEFAULT],
  }

  const initialValues = useMemo(() => {
    const initData = {
      ...DEFAULT_VALUE,
    }
    if (registerData) {
      initData.descriptionRegisterVehicle =
        registerData.descriptionRegisterVehicle
      initData.jobDetails = registerData?.registerVehicles
    }

    return initData
  }, [registerData, userInfo])

  return (
    <Dialog
      open={open}
      title={t('attendance.registerVehicle.title')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
      loading={isLoading}
    >
      <Formik
        onSubmit={(val) => onSubmit({ ...val, id: registerVehicle?.id })}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
            >
              <Guard
                code={
                  FUNCTION_CODE.USER_ADD_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL
                }
              >
                <Grid item xs={12}>
                  <Typography sx={{ paddingBottom: '12px' }}>
                    {t('attendance.registerVehicle.description')}:
                  </Typography>
                  <Field.TextField
                    name="descriptionRegisterVehicle"
                    placeholder={t('attendance.registerVehicle.desPlaceHolder')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Guard>

              {values.vehicleType !== VEHICLE_TYPE_VALUE.route && (
                <Grid item xs={12}>
                  <FieldArray
                    name="jobDetails"
                    render={(arrayHelpers) => (
                      <>
                        <ReceiptTable
                          arrayHelpers={arrayHelpers}
                          values={values}
                          TABLE_ROW_DEFAULT={TABLE_ROW_DEFAULT}
                        />
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={onClose}
                  >
                    {t('actionBar.close')}
                  </Button>
                  <Guard
                    code={
                      FUNCTION_CODE.USER_REGISTER_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL
                    }
                  >
                    <Button type="submit" sx={{ marginRight: '10px' }}>
                      {t('actionBar.save')}
                    </Button>
                  </Guard>
                </Box>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default RegisterRoute
