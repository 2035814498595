/* eslint-disable no-param-reassign */
/* tslint:disable-next-line: max-line-length */
import { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import TreeNode from './tree-node'

export default function CustomizedTreeView({ data = {}, onClick = () => {} }) {
  const [treeData, setTreeData] = useState([])

  useEffect(() => {
    if (data) {
      setTreeData(data)
    }
  }, [data])

  return (
    <Box
      sx={() => ({
        bottom: 0,
        zIndex: 100,
        background: '#fff',
      })}
    >
      {treeData?.map((data) => (
        <TreeNode data={data} onClick={onClick} />
      ))}
    </Box>
  )
}
