import { combineReducers } from 'redux'

import {
  MASTER_SYNC_ROSTER_CALENDAR,
  MASTER_SYNC_ROSTER_CALENDAR_FAILED,
  MASTER_SYNC_ROSTER_CALENDAR_RESET,
  MASTER_SYNC_ROSTER_CALENDAR_SUCCESS,
} from '../actions/roster-calendar'

function syncRosterCalendar(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_ROSTER_CALENDAR:
      return { ...state, isLoading: true }
    case MASTER_SYNC_ROSTER_CALENDAR_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_ROSTER_CALENDAR_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_ROSTER_CALENDAR_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  syncRosterCalendar,
})
