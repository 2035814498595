// Action: Get list
export const MASTER_GET_LIST_VEHICLE_CATEGORIES =
  'MASTER_GET_LIST_VEHICLE_CATEGORIES'
export const MASTER_GET_LIST_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_GET_LIST_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_GET_LIST_VEHICLE_CATEGORIES_FAILED =
  'MASTER_GET_LIST_VEHICLE_CATEGORIES_FAILED'
export const MASTER_GET_LIST_VEHICLE_CATEGORIES_RESET =
  'MASTER_GET_LIST_VEHICLE_CATEGORIES_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_VEHICLE_CATEGORIES =
  'MASTER_GET_DETAIL_VEHICLE_CATEGORIES'
export const MASTER_GET_DETAIL_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_GET_DETAIL_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_GET_DETAIL_VEHICLE_CATEGORIES_FAILED =
  'MASTER_GET_DETAIL_VEHICLE_CATEGORIES_FAILED'
export const MASTER_GET_DETAIL_VEHICLE_CATEGORIES_RESET =
  'MASTER_GET_DETAIL_VEHICLE_CATEGORIES_RESET'

// Action: create
export const MASTER_CREATE_VEHICLE_CATEGORIES =
  'MASTER_CREATE_VEHICLE_CATEGORIES'
export const MASTER_CREATE_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_CREATE_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_CREATE_VEHICLE_CATEGORIES_FAILED =
  'MASTER_CREATE_VEHICLE_CATEGORIES_FAILED'

// Action: update
export const MASTER_UPDATE_VEHICLE_CATEGORIES =
  'MASTER_UPDATE_VEHICLE_CATEGORIES'
export const MASTER_UPDATE_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_UPDATE_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_UPDATE_VEHICLE_CATEGORIES_FAILED =
  'MASTER_UPDATE_VEHICLE_CATEGORIES_FAILED'

// Action: delete
export const MASTER_DELETE_VEHICLE_CATEGORIES =
  'MASTER_DELETE_VEHICLE_CATEGORIES'
export const MASTER_DELETE_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_DELETE_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_DELETE_VEHICLE_CATEGORIES_FAILED =
  'MASTER_DELETE_VEHICLE_CATEGORIES_FAILED'

// Action: change status
export const MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES =
  'MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES'
export const MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_SUCCESS =
  'MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_SUCCESS'
export const MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_FAILED =
  'MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListVehicleCategoriesSuccess(payload) {
  return {
    type: MASTER_GET_LIST_VEHICLE_CATEGORIES_SUCCESS,
    payload: payload,
  }
}

export function actGetListVehicleCategoriesFailed() {
  return {
    type: MASTER_GET_LIST_VEHICLE_CATEGORIES_FAILED,
  }
}

export function actGetListVehicleCategoriesReset() {
  return {
    type: MASTER_GET_LIST_VEHICLE_CATEGORIES_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailVehicleCategoriesSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_VEHICLE_CATEGORIES_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailVehicleCategoriesFailed() {
  return {
    type: MASTER_GET_DETAIL_VEHICLE_CATEGORIES_FAILED,
  }
}

export function actGetDetailVehicleCategoriesReset() {
  return {
    type: MASTER_GET_DETAIL_VEHICLE_CATEGORIES_RESET,
  }
}

/** create
 * @param {
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actCreateVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_CREATE_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actCreateVehicleCategoriesSuccess(payload) {
  return {
    type: MASTER_CREATE_VEHICLE_CATEGORIES_SUCCESS,
    payload: payload,
  }
}

export function actCreateVehicleCategoriesFailed() {
  return {
    type: MASTER_CREATE_VEHICLE_CATEGORIES_FAILED,
  }
}

/** update
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateVehicleCategoriesSuccess(payload) {
  return {
    type: MASTER_UPDATE_VEHICLE_CATEGORIES_SUCCESS,
    payload: payload,
  }
}

export function actUpdateVehicleCategoriesFailed() {
  return {
    type: MASTER_UPDATE_VEHICLE_CATEGORIES_FAILED,
  }
}

/** delete
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDeleteVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_DELETE_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDeleteVehicleCategoriesSuccess() {
  return {
    type: MASTER_DELETE_VEHICLE_CATEGORIES_SUCCESS,
  }
}

export function actDeleteVehicleCategoriesFailed() {
  return {
    type: MASTER_DELETE_VEHICLE_CATEGORIES_FAILED,
  }
}

/** change status
 * @param {
 *  id: any,
 *  status: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actChangeStatusVehicleCategories(payload, onSuccess, onError) {
  return {
    type: MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actChangeStatusVehicleCategoriesSuccess() {
  return {
    type: MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_SUCCESS,
  }
}

export function actChangeStatusVehicleCategoriesFailed() {
  return {
    type: MASTER_CHANGE_STATUS_VEHICLE_CATEGORIES_FAILED,
  }
}

export default {
  actGetListVehicleCategories,
  actGetListVehicleCategoriesSuccess,
  actGetListVehicleCategoriesFailed,
  actGetListVehicleCategoriesReset,
  actGetDetailVehicleCategories,
  actGetDetailVehicleCategoriesSuccess,
  actGetDetailVehicleCategoriesFailed,
  actGetDetailVehicleCategoriesReset,
  actUpdateVehicleCategories,
  actUpdateVehicleCategoriesSuccess,
  actUpdateVehicleCategoriesFailed,
  actDeleteVehicleCategories,
  actDeleteVehicleCategoriesSuccess,
  actDeleteVehicleCategoriesFailed,
  actChangeStatusVehicleCategories,
  actChangeStatusVehicleCategoriesSuccess,
  actChangeStatusVehicleCategoriesFailed,
  actCreateVehicleCategories,
  actCreateVehicleCategoriesSuccess,
  actCreateVehicleCategoriesFailed,
}
