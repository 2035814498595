import { useEffect, useMemo } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { FieldArray, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'

import {
  NOTIFICATION_TYPE,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'
import { TYPE_OT_AND_BT } from '~/modules/master/constants'
import { apiSendMailBu, apiSendMailOt } from '~/modules/master/redux/apis/email'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'
import addNotification from '~/utils/toast'

import EmpTable from './emp-table'
import { validationSchema } from './schema'

const PopupEmail = ({
  t,
  onClose = () => {},
  open,
  showDetailId,
  type,
  data,
  screen,
}) => {
  const initialValues = useMemo(() => {
    if (!data) return []
    return {
      subject:
        'Thông tin cấp phương tiện cho DS đăng ký OT/ Đi công tác có đăng ký phương tiện đi lại/ Information on arranging vehicle for OT/ Business trip registration with Application for transportation.',
      cc: '',
      bcc: '',
      detail: data.map((item) => ({ type: type, ...item, email: '' })),
    }
  }, [data])

  const {
    actions: { actGetDetailProfileCustom },
  } = useProfileCustom()

  useEffect(() => {
    if (showDetailId) {
      actGetDetailProfileCustom(showDetailId)
    }
  }, [showDetailId])

  const submitEmail = async (val, setFieldValue) => {
    const body = {
      ...val,
      type: screen,
      detail: val.detail?.map((item) => ({ id: item.id, email: item.email })),
    }
    setFieldValue('isLoading', true)

    let res = null

    if (type) {
      if (type === TYPE_OT_AND_BT.Overtime) {
        res = await apiSendMailOt(body)
      } else {
        res = await apiSendMailBu(body)
      }
    } else {
      const collectType = data?.reduce(
        (acc, current) => {
          if (current.type === TYPE_OT_AND_BT.Overtime) {
            acc[TYPE_OT_AND_BT.Overtime].push({
              id: current.id,
              email: current.email,
            })
          } else {
            acc[TYPE_OT_AND_BT.BussinessTravel].push({
              id: current.id,
              email: current.email,
            })
          }
          return acc
        },
        { [TYPE_OT_AND_BT.Overtime]: [], [TYPE_OT_AND_BT.BussinessTravel]: [] },
      )

      if (!isEmpty(collectType[TYPE_OT_AND_BT.Overtime])) {
        res = await apiSendMailOt(body)
      }

      if (!isEmpty(collectType[TYPE_OT_AND_BT.BussinessTravel])) {
        res = await apiSendMailBu(body)
      }
    }

    if (res.statusCode === 200) {
      addNotification(
        'attendance.notification.sendEmailSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
      onClose()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }

    setFieldValue('isLoading', false)
  }

  return (
    <Dialog
      open={open}
      title={t('attendance.email.title')}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={(val, { setFieldValue }) => submitEmail(val, setFieldValue)}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <Grid
              container
              rowSpacing={2}
              // columnSpacing={{ xl: 1, xs: 1 }}
              sx={{
                // backgroundColor: '#EFF5FB',
                padding: '24px',
                borderRadius: '8px',
              }}
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Field.TextField
                  name="subject"
                  placeholder={t('attendance.email.subject')}
                  label={t('attendance.email.subject')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                  required
                />
              </Grid>
              <Grid item lg={5.9} xs={12}>
                <Field.TextField
                  name="cc"
                  placeholder={t('attendance.email.cc')}
                  label={t('attendance.email.cc')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                />
              </Grid>
              <Grid item lg={5.9} xs={12}>
                <Field.TextField
                  name="bcc"
                  placeholder={t('attendance.email.bcc')}
                  label={t('attendance.email.bcc')}
                  inputProps={{
                    maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name="detail"
                  render={(arrayHelpers) => (
                    <>
                      <EmpTable arrayHelpers={arrayHelpers} values={values} />
                    </>
                  )}
                />
              </Grid>
            </Grid>

            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={onClose}
                  >
                    {t('actionBar.close')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t('actionBar.send')}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default PopupEmail
