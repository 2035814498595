export const FUNCTION_CODE = {
  USER_CREATE_USER: 'USER_CREATE_USER', // Tạo người dùng
  USER_UPDATE_USER: 'USER_UPDATE_USER', // Sửa người dùng
  USER_DELETE_USER: 'USER_DELETE_USER', // Xóa người dùng
  USER_DETAIL_USER: 'USER_DETAIL_USER', // Chi tiết người dùng
  USER_LIST_USER: 'USER_LIST_USER', // Danh sách người dùng
  USER_CHANGE_PASSWORD_USER: 'USER_CHANGE_PASSWORD_USER', // Thay đổi mật khẩu tài khoản người dùng
  USER_CONFIRM_USER: 'USER_CONFIRM_USER', // Xác nhận người dùng
  USER_REJECT_USER: 'USER_REJECT_USER', // Từ chối người dùng
  USER_SET_PERMISSION_USER: 'USER_SET_PERMISSION_USER', // Phân quyền
  USER_CONFIG_SIGNATURE: 'USER_CONFIG_SIGNATURE', // Cấu hình chữ ký
  USER_LIST_PERMISSION_USER: 'USER_LIST_PERMISSION_USER', // Quản lý phân quyền người dùng
  USER_CREATE_USER_ROLE_SETTING: 'USER_CREATE_USER_ROLE_SETTING', // Tạo vai trò
  USER_UPDATE_USER_ROLE_SETTING: 'USER_UPDATE_USER_ROLE_SETTING', // Sửa vai trò
  USER_DELETE_USER_ROLE_SETTING: 'USER_DELETE_USER_ROLE_SETTING', // Xóa vai trò
  USER_DETAIL_USER_ROLE_SETTING: 'USER_DETAIL_USER_ROLE_SETTING', // Chi tiết vai trò
  USER_LIST_USER_ROLE_SETTING: 'USER_LIST_USER_ROLE_SETTING', // Danh sách vai trò
  USER_CHANGE_STATUS_USER_ROLE_SETTING: 'USER_CHANGE_STATUS_USER_ROLE_SETTING',
  USER_CREATE_FACTORY: 'USER_CREATE_FACTORY', // Tạo nhà máy
  USER_UPDATE_FACTORY: 'USER_UPDATE_FACTORY', // Sửa nhà máy
  USER_DETAIL_FACTORY: 'USER_DETAIL_FACTORY', // Chi tiết nhà máy
  USER_LIST_FACTORY: 'USER_LIST_FACTORY', // Danh sách nhà máy
  USER_DELETE_FACTORY: 'USER_DELETE_FACTORY', // Xóa nhà máy
  USER_CHANGE_STATUS_FACTORY: 'USER_CHANGE_STATUS_FACTORY', // Hoạt động nhà máy
  USER_INACTIVE_FACTORY: 'USER_INACTIVE_FACTORY', // Tạm dừng nhà máy
  USER_EXPORT_FACTORY: 'USER_EXPORT_FACTORY', // Xuất dữ liệu nhà máy
  USER_IMPORT_FACTORY: 'USER_IMPORT_FACTORY', // Nhập dữ liệu nhà máy
  USER_SYNCHRONIZE_POSITION: 'USER_SYNCHRONIZE_POSITION', // Đồng bộ chức vụ
  USER_LIST_POSITION: 'USER_LIST_POSITION', // Danh sách chức vụ
  USER_SYNCHRONIZE_ORGANIZATION_STRUCTURE:
    'USER_SYNCHRONIZE_ORGANIZATION_STRUCTURE', // Đồng bộ bộ phận
  USER_LIST_ORGANIZATION_STRUCTURE: 'USER_LIST_ORGANIZATION_STRUCTURE', // Danh sách bộ phận
  USER_UPDATE_ORGANIZATION_STRUCTURE: 'USER_UPDATE_ORGANIZATION_STRUCTURE', // Cập nhật bộ phận
  USER_DETAIL_ORGANIZATION_STRUCTURE: 'USER_DETAIL_ORGANIZATION_STRUCTURE', // Chi tiết bộ phận
  USER_SYNCHRONIZE_CATEGORY_SHIFT: 'USER_SYNCHRONIZE_CATEGORY_SHIFT', // Đồng bộ ca làm việc
  USER_LIST_CATEGORY_SHIFT: 'USER_LIST_CATEGORY_SHIFT', // Danh sách ca làm việc
  USER_SYNCHRONIZE_PROFILE_CUSTOM: 'USER_SYNCHRONIZE_PROFILE_CUSTOM', // Đồng bộ nhân viên
  USER_LIST_PROFILE_CUSTOM: 'USER_LIST_PROFILE_CUSTOM', // Danh sách nhân viên
  USER_DETAIL_PROFILE_CUSTOM: 'USER_DETAIL_PROFILE_CUSTOM', // Chi tiết nhân viên
  USER_UPDATE_PROFILE_CUSTOM: 'USER_UPDATE_PROFILE_CUSTOM', // Cập nhật nhân viên
  USER_IMPORT_PROFILE_CUSTOM: 'USER_IMPORT_PROFILE_CUSTOM', // Nhập dữ liệu nhân viên
  USER_EXPORT_PROFILE_CUSTOM: 'USER_EXPORT_PROFILE_CUSTOM', // Xuất dữ liệu nhân viên
  USER_CREATE_VEHICLE_CATEGORY: 'USER_CREATE_VEHICLE_CATEGORY', // Tạo loại xe
  USER_UPDATE_VEHICLE_CATEGORY: 'USER_UPDATE_VEHICLE_CATEGORY', // Cập nhật loại xe
  USER_LIST_VEHICLE_CATEGORY: 'USER_LIST_VEHICLE_CATEGORY', // Danh sách loại xe
  USER_DETAIL_VEHICLE_CATEGORY: 'USER_DETAIL_VEHICLE_CATEGORY', // Chi tiết loại xe
  USER_DELETE_VEHICLE_CATEGORY: 'USER_DELETE_VEHICLE_CATEGORY', // Xóa loại xe
  USER_IMPORT_VEHICLE_CATEGORY: 'USER_IMPORT_VEHICLE_CATEGORY', // Nhập dữ liệu loại xe
  USER_EXPORT_VEHICLE_CATEGORY: 'USER_EXPORT_VEHICLE_CATEGORY', // Xuất dữ liệu loại xe
  USER_ACTIVE_VEHICLE_CATEGORY: 'USER_ACTIVE_VEHICLE_CATEGORY', // Hoạt động loại xe
  USER_INACTIVE_VEHICLE_CATEGORY: 'USER_INACTIVE_VEHICLE_CATEGORY', // Tạm dừng loại xe
  USER_CREATE_ROUTE: 'USER_CREATE_ROUTE', // Tạo tuyến
  USER_UPDATE_ROUTE: 'USER_UPDATE_ROUTE', // Cập nhật tuyến
  USER_LIST_ROUTE: 'USER_LIST_ROUTE', // Danh sách tuyến
  USER_DETAIL_ROUTE: 'USER_DETAIL_ROUTE', // Chi tiết tuyến
  USER_DELETE_ROUTE: 'USER_DELETE_ROUTE', // Xóa tuyến
  USER_IMPORT_ROUTE: 'USER_IMPORT_ROUTE', // Nhập dữ liệu tuyến
  USER_EXPORT_ROUTE: 'USER_EXPORT_ROUTE', // Xuất dữ liệu tuyến
  USER_CHANGE_STATUS_ROUTE: 'USER_CHANGE_STATUS_ROUTE', // Tạm dừng tuyến
  USER_CREATE_PICKUP_POINT: 'USER_CREATE_PICKUP_POINT', // Tạo trạm đón/trả
  USER_UPDATE_PICKUP_POINT: 'USER_UPDATE_PICKUP_POINT', // Cập nhật trạm đón/trả
  USER_LIST_PICKUP_POINT: 'USER_LIST_PICKUP_POINT', // Danh sách trạm đón/trả
  USER_DETAIL_PICKUP_POINT: 'USER_DETAIL_PICKUP_POINT', // Chi tiết trạm đón/trả
  USER_DELETE_PICKUP_POINT: 'USER_DELETE_PICKUP_POINT', // Xóa trạm đón/trả
  USER_IMPORT_PICKUP_POINT: 'USER_IMPORT_PICKUP_POINT', // Nhập dữ liệu trạm đón/trả
  USER_EXPORT_PICKUP_POINT: 'USER_EXPORT_PICKUP_POINT', // Xuất dữ liệu trạm đón/trả
  USER_CHANGE_STATUS_PICKUP_POINT: 'USER_CHANGE_STATUS_PICKUP_POINT', // Hoạt động trạm đón/trả
  USER_SYNCHRONIZE_ROSTER_CALENDAR: 'USER_SYNCHRONIZE_ROSTER_CALENDAR', // Đồng bộ chấm công ca chính
  USER_LIST_ROSTER_CALENDAR: 'USER_LIST_ROSTER_CALENDAR', // Danh sách chấm công ca chính
  USER_EXPORT_ROSTER_CALENDAR: 'USER_EXPORT_ROSTER_CALENDAR', // Xuất dữ liệu chấm công ca chính
  USER_SYNCHRONIZE_OVERTIME_PLAN: 'USER_SYNCHRONIZE_OVERTIME_PLAN', // Đồng bộ chấm công ngoài giờ
  USER_LIST_OVERTIME_PLAN: 'USER_LIST_OVERTIME_PLAN', // Danh sách chấm công ngoài giờ
  USER_EXPORT_OVERTIME_PLAN: 'USER_EXPORT_OVERTIME_PLAN', // Xuất dữ liệu chấm công ngoài giờ
  USER_REGISTER_VEHICLE_OVERTIME_PLAN: 'USER_REGISTER_VEHICLE_OVERTIME_PLAN', // Đăng ký tuyến xe ngoài giờ
  USER_DETAIL_OVERTIME_PLAN: 'USER_DETAIL_OVERTIME_PLAN', // Chi tiết chấm công ngoài giờ
  USER_SEND_MAIL_REGISTER_VEHICLE_OVERTIME_PLAN:
    'USER_SEND_MAIL_REGISTER_VEHICLE_OVERTIME_PLAN', // Gửi mail đăng ký tuyến xe ngoài giờ
  USER_SYNCHRONIZE_BUSSINESS_TRAVEL: 'USER_SYNCHRONIZE_BUSSINESS_TRAVEL', // Đồng bộ công tác
  USER_LIST_BUSSINESS_TRAVEL: 'USER_LIST_BUSSINESS_TRAVEL', // Danh sách công tác
  USER_EXPORT_BUSSINESS_TRAVEL: 'USER_EXPORT_BUSSINESS_TRAVEL', // Xuất dữ liệu công tác
  USER_REGISTER_VEHICLE_BUSSINESS_TRAVEL:
    'USER_REGISTER_VEHICLE_BUSSINESS_TRAVEL', // Đăng ký tuyến xe công tác
  USER_DETAIL_BUSSINESS_TRAVEL: 'USER_DETAIL_BUSSINESS_TRAVEL', // Chi tiết công tác
  USER_SEND_MAIL_REGISTER_VEHICLE_BUSSINESS_TRAVEL:
    'USER_SEND_MAIL_REGISTER_VEHICLE_BUSSINESS_TRAVEL', // Gửi mail đăng ký tuyến xe công tác
  USER_EXPORT_REPORT_ROSTER_CALENDAR: 'USER_EXPORT_REPORT_ROSTER_CALENDAR', // Xuất dữ liệu báo cáo đăng ký xe ca chính
  USER_LIST_REPORT_ROSTER_CALENDAR: 'USER_LIST_REPORT_ROSTER_CALENDAR', // Danh sách báo cáo đăng ký xe ca chính
  USER_EXPORT_REPORT_OVERALL: 'USER_EXPORT_REPORT_OVERALL', // Xuất dữ liệu báo cáo tổng hợp
  USER_LIST_REPORT_OVERALL: 'USER_LIST_REPORT_OVERALL', // Danh sách báo cáo tổng hợp
  USER_EXPORT_REPORT_FEE_DISTRIBUTION: 'USER_EXPORT_REPORT_FEE_DISTRIBUTION', // Xuất dữ liệu báo cáo phân phí
  USER_LIST_REPORT_FEE_DISTRIBUTION: 'USER_LIST_REPORT_FEE_DISTRIBUTION', // Danh sách báo cáo phân phí
  USER_EXPORT_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_EXPORT_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // Xuất dữ liệu báo cáo ngoài giờ và công tác
  USER_LIST_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_LIST_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // Danh sách báo cáo ngoài giờ và công tác
  USER_SEND_MAIL_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_SEND_MAIL_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // Gửi mail báo cáo ngoài giờ và công tác
  USER_UPDATE_CRON_SETTING: 'USER_UPDATE_CRON_SETTING', // Cập nhật cấu hình đồng bộ
  USER_DETAIL_CRON_SETTING: 'USER_DETAIL_CRON_SETTING', // Chi tiết cấu hình đồng bộ
  USER_FEE_SETTING_GROUP: 'USER_FEE_SETTING_GROUP', // Danh sách cấu hình fee
  USER_UPDATE_FEE_SETTING: 'USER_UPDATE_FEE_SETTING', // cập nhật cấu hình fee
  USER_DETAIL_FEE_SETTING: 'USER_DETAIL_FEE_SETTING', // Chi tiết cấu hình fee
  USER_SEARCH_USER: 'USER_SEARCH_USER', // Tìm kiếm danh sách người dùng
  USER_IMPORT_USER: 'USER_IMPORT_USER', // Nhập dữ liệu người dùng
  USER_EXPORT_USER: 'USER_EXPORT_USER', // Xuất dữ liệu người dùng
  USER_SEARCH_USER_ROLE_SETTING: 'USER_SEARCH_USER_ROLE_SETTING', // Tìm kiếm danh sách vai trò
  USER_IMPORT_USER_ROLE_SETTING: 'USER_IMPORT_USER_ROLE_SETTING', // Nhập dữ liệu vai trò
  USER_EXPORT_USER_ROLE_SETTING: 'USER_EXPORT_USER_ROLE_SETTING', // Xuất dữ liệu vai trò
  USER_SEARCH_FACTORY: 'USER_SEARCH_FACTORY', // Tìm kiếm thông tin nhà máy
  USER_SEARCH_POSITION: 'USER_SEARCH_POSITION', // Tìm kiếm thông tin chức vụ
  USER_SEARCH_CATEGORY_SHIFT: 'USER_SEARCH_CATEGORY_SHIFT', // Tìm kiếm danh sách ca làm việc
  USER_SEARCH_PROFILE_CUSTOM: 'USER_SEARCH_PROFILE_CUSTOM', // Tìm kiếm danh sách nhân viên
  USER_REGISTER_ROUTE_PROFILE_CUSTOM: 'USER_REGISTER_ROUTE_PROFILE_CUSTOM', // Đăng ký tuyến xe ca chính nhân viên
  USER_SEARCH_VEHICLE_CATEGORY: 'USER_SEARCH_VEHICLE_CATEGORY', // Tìm kiếm thông tin loại xe
  USER_SEARCH_ROUTE: 'USER_SEARCH_ROUTE', // Tìm kiếm thông tin tuyến
  USER_SEARCH_PICKUP_POINT: 'USER_SEARCH_PICKUP_POINT', // Tìm kiếm thông tin trạm đón/trả
  USER_SEARCH_ROSTER_CALENDAR: 'USER_SEARCH_ROSTER_CALENDAR', // Tìm kiếm thông tin chấm công ca chính
  USER_IMPORT_ROSTER_CALENDAR: 'USER_IMPORT_ROSTER_CALENDAR', // Nhập dữ liệu chấm công ca chính
  USER_SEARCH_OVERTIME_PLAN: 'USER_SEARCH_OVERTIME_PLAN', // Tìm kiếm thông tin chấm công ngoài giờ
  USER_SEARCH_BUSSINESS_TRAVEL: 'USER_SEARCH_BUSSINESS_TRAVEL', // Tìm kiếm thông tin chấm công công tác
  USER_SEARCH_REPORT_ROSTER_CALENDAR: 'USER_SEARCH_REPORT_ROSTER_CALENDAR', // Tìm kiếm thông tin báo cáo đăng ký xe ca chính
  USER_SEARCH_REPORT_OVERALL: 'USER_SEARCH_REPORT_OVERALL', // Tìm kiếm thông tin báo cáo tổng hợp
  USER_MANEUVER_REINFORCEMENT_VEHICLES_REPORT_OVERALL:
    'USER_MANEUVER_REINFORCEMENT_VEHICLES_REPORT_OVERALL', // Điều động xe tăng cường
  USER_SEARCH_REPORT_FEE_DISTRIBUTION: 'USER_SEARCH_REPORT_FEE_DISTRIBUTION', // Tìm kiếm thông tin báo cáo phân phí
  USER_UPDATE_REPORT_FEE_DISTRIBUTION: 'USER_UPDATE_REPORT_FEE_DISTRIBUTION', // Điều chỉnh thông tin phân phí
  USER_SEARCH_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_SEARCH_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // Tìm kiếm thông tin báo cáo ngoài giờ và công tác
  USER_REGISTER_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_REGISTER_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // mở popup Đăng ký thông tin điều xe chấm công: Ngoài giờ & Công tác
  USER_ADD_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_ADD_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // thêm các thông tin Đăng ký thông tin điều xe chấm công: Ngoài giờ & Công tác
  USER_UPDATE_CODE_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_UPDATE_CODE_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL', // update Đăng ký thông tin điều xe chấm công: Ngoài giờ & Công tác
  USER_DETAIL_REGISTER_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL:
    'USER_DETAIL_REGISTER_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL',
  USER_SEARCH_PERMISSION_USER: 'USER_SEARCH_PERMISSION_USER',
  USER_VIEW_ALL_PROFILE_CUSTOM: 'USER_VIEW_ALL_PROFILE_CUSTOM', // Xem tất cả dữ liệu nhân viên (bao gồm nhân viên đơn vị khác)
}
