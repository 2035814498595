import React from 'react'

import { Box, Divider, Stack } from '@mui/material'
import { PropTypes } from 'prop-types'

const TaskBar = ({ left = [], right = [], sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        mx: -1,
        mt: -1,
        mb: 1,
        bgcolor: 'bg.taskbar',
        ...sx,
      }}
    >
      {left && (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          divider={
            <Divider
              orientation="vertical"
              sx={{
                borderColor: '#CBCBCB',
                height: 24,
                '&:first-child, &+.MuiDivider-root': { display: 'none' },
              }}
            />
          }
        >
          {left.map((Element, idx) => (
            <React.Fragment key={idx}>{Element}</React.Fragment>
          ))}
          {/* <span style={{ width: 0 }} /> */}
        </Stack>
      )}

      {right && (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          divider={
            <Divider
              orientation="vertical"
              sx={{
                borderColor: '#CBCBCB',
                height: 24,
                '&:last-child, &+.MuiDivider-root': { display: 'none' },
              }}
            />
          }
          sx={{ ml: 'auto' }}
        >
          {right.map((Element, idx) => (
            <React.Fragment key={idx}>{Element}</React.Fragment>
          ))}
        </Stack>
      )}
    </Box>
  )
}

TaskBar.defaultProps = {
  left: undefined,
  right: undefined,
  sx: {},
}

TaskBar.propTypes = {
  left: PropTypes.arrayOf(PropTypes.node),
  right: PropTypes.node,
  sx: PropTypes.shape(),
}

export default TaskBar
