import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actChangeStatusPickupPointFailed,
  actChangeStatusPickupPointSuccess,
  MASTER_CHANGE_STATUS_PICKUP_POINT,
} from '../../actions/pickup-point'
import {
  apiActivePickupPoint,
  apiActivePickupPoints,
  apiInActivePickupPoint,
  apiInActivePickupPoints,
} from '../../apis/pickup-point'

const getApi = ({ params, isActive }) => {
  if (params?.codes) {
    return isActive ? apiInActivePickupPoints : apiActivePickupPoints
  } else {
    return isActive ? apiInActivePickupPoint : apiActivePickupPoint
  }
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doChangeStatus(action) {
  try {
    const response = yield call(
      getApi(action?.payload),
      action?.payload?.params,
    )

    if (response?.statusCode === 200) {
      yield put(actChangeStatusPickupPointSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification('toast.changeStatusSuccess', NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(
        response?.message || 'toast.changeStatusError',
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actChangeStatusPickupPointFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchChangeStatus() {
  yield takeLatest(MASTER_CHANGE_STATUS_PICKUP_POINT, doChangeStatus)
}
