import React from 'react'

import { InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Field.TextField
        name="keyword"
        placeholder={t('common.inputKeyWord')}
        inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            <Icon name="search" />
          </InputAdornment>
        }
      />

      <Field.TextField
        name="description"
        placeholder={t('roleManagement.description')}
        inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
      />
    </>
  )
}

export default FilterForm
