import React, { useEffect, useMemo, useState } from 'react'

import { Box, Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

import {
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { useQueryState } from '~/common/hooks'
import ActionBar from '~/components/ActionBar'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import LV from '~/components/LabelValue'
import Page from '~/components/Page'
import Status from '~/components/Status'
import { USER_MANAGEMENT_STATUS_OPTIONS } from '~/modules/configuration/constants'
import { searchFactoriesApi } from '~/modules/configuration/redux/apis'
import useRoleManagement from '~/modules/configuration/redux/hooks/useRoleList'
import useUserManagement from '~/modules/configuration/redux/hooks/useUserManagement'
import { ROUTE } from '~/modules/configuration/routes/config'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListOrgStructure } from '~/modules/master/redux/apis/org-structure'
import { convertFilterParams } from '~/utils'
import qs from '~/utils/qs'

import { validationSchema } from './schema'

function UserManagementForm() {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { cloneId } = qs.parse(location.search)
  const routeMatch = useRouteMatch()
  const { withSearch } = useQueryState()

  const MODE_MAP = {
    [ROUTE.USER_MANAGEMENT.CREATE.PATH]: MODAL_MODE.CREATE,
    [ROUTE.USER_MANAGEMENT.EDIT.PATH]: MODAL_MODE.UPDATE,
  }

  const mode = MODE_MAP[routeMatch.path]
  const isUpdate = mode === MODAL_MODE.UPDATE
  const [visible, setVisible] = useState(false)

  const {
    data: { userDetails, isLoading },
    actions,
  } = useUserManagement()

  const {
    data: { roleList },
    actions: commonActions,
  } = useRoleManagement()

  useEffect(() => {
    commonActions.searchRoleList({
      filter: convertFilterParams({
        status: 1,
      }),
    })
  }, [])

  const initialValues = useMemo(() => {
    if (userDetails) {
      const init = {
        code: isUpdate ? userDetails?.code : '',
        username: userDetails?.username || '',
        password: userDetails?.password || '',
        showPassword: false,
        companyId: userDetails?.companyId || '',
        fullName: userDetails?.fullName || '',
        dateOfBirth: userDetails?.dateOfBirth || null,
        email: userDetails?.email || '',
        phone: userDetails?.phone || '',
        status:
          typeof userDetails?.status === 'number' ? userDetails?.status : 1,
        factories: userDetails.factory,
        userRoleSettings: userDetails.userRoles?.[0]?.userRoleId || null,
        orgStructures: userDetails.orgStructures
          ? userDetails?.orgStructures
          : [],
      }

      if (cloneId) {
        init.email = ''
        init.username = ''
      }

      return init
    }
    return {}
  }, [userDetails, userDetails])

  useEffect(() => {
    if (isUpdate) {
      const id = params?.id
      actions.getUserDetailsById(id)
    }
    if (cloneId) {
      actions.getUserDetailsById(cloneId)
    }
    return () => {
      actions.resetUserDetailsState()
    }
  }, [params?.id, cloneId])

  const onSubmit = (values) => {
    const id = Number(params?.id)

    const convertValues = {
      ...values,
      id,
      status: values?.status?.toString(),
      userRoleSettings: values.userRoleSettings
        ? [{ id: values.userRoleSettings }]
        : [{ id: 1 }],
      factories: values.factories ? [{ id: values.factories?.id }] : [],
      orgStructures: values.orgStructures?.map((item) => ({ id: item.id })),
    }

    if (mode === MODAL_MODE.CREATE) {
      actions.createUser(convertValues, () =>
        history.push(ROUTE.USER_MANAGEMENT.LIST.PATH),
      )
    } else if (mode === MODAL_MODE.UPDATE) {
      actions.updateUser(convertValues, backToList)
    }
  }

  const getBreadcrumb = () => {
    const breadcrumb = [
      {
        title: 'decentralization',
      },
      {
        route: withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH),
        title: ROUTE.USER_MANAGEMENT.LIST.TITLE,
      },
    ]
    switch (mode) {
      case MODAL_MODE.CREATE:
        breadcrumb.push({
          route: ROUTE.USER_MANAGEMENT.CREATE.PATH,
          title: ROUTE.USER_MANAGEMENT.CREATE.TITLE,
        })
        break
      case MODAL_MODE.UPDATE:
        breadcrumb.push({
          route: ROUTE.USER_MANAGEMENT.EDIT.PATH,
          title: ROUTE.USER_MANAGEMENT.EDIT.TITLE,
        })
        break
      default:
        break
    }
    return breadcrumb
  }

  const renderActionBar = (handleReset) => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.CREATE}
          />
        )
      case MODAL_MODE.UPDATE:
        return (
          <ActionBar
            onBack={backToList}
            onCancel={handleReset}
            mode={MODAL_MODE.UPDATE}
          />
        )
      default:
        break
    }
  }

  const getTitle = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return ROUTE.USER_MANAGEMENT.CREATE.TITLE
      case MODAL_MODE.UPDATE:
        return ROUTE.USER_MANAGEMENT.EDIT.TITLE
      default:
        break
    }
  }

  const backToList = () => {
    history.push(withSearch(ROUTE.USER_MANAGEMENT.LIST.PATH))
  }

  return (
    <Page
      breadcrumbs={getBreadcrumb()}
      title={t('menu.' + getTitle())}
      onBack={backToList}
      loading={isLoading}
    >
      <Grid container justifyContent="center">
        <Grid item xl={11} xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t, mode)}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleReset, values }) => (
              <Form>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {t('userManagement.commonInfo')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  rowSpacing={2}
                  sx={{
                    backgroundColor: '#DAE7F3',
                    marginTop: '5px',
                    padding: '0 15px 15px 15px',
                  }}
                  justifyContent="space-between"
                >
                  {isUpdate && (
                    <Grid item xs={12}>
                      <LV
                        label={
                          <Typography>{t('userManagement.status')}</Typography>
                        }
                        value={
                          <Status
                            name="status"
                            options={USER_MANAGEMENT_STATUS_OPTIONS}
                            value={userDetails?.status}
                          />
                        }
                      />
                    </Grid>
                  )}
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      label={t('userManagement.code')}
                      name="code"
                      placeholder={t('userManagement.code')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX,
                      }}
                      allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
                      disabled={isUpdate}
                      required
                      {...(cloneId ? { autoFocus: true } : {})}
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      name="email"
                      label={t('userManagement.email')}
                      placeholder={t('userManagement.email')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.EMAIL.MAX,
                      }}
                      allow={TEXTFIELD_ALLOW.EMAIL}
                      disabled={userDetails.type}
                      required
                    />
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Box>
                      <Field.TextField
                        label={t('userManagement.username')}
                        name="username"
                        placeholder={t('userManagement.username')}
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.NAME.MAX,
                        }}
                        allow={TEXTFIELD_ALLOW.ALPHANUMERIC_SHIFT}
                        disabled={isUpdate}
                        required
                      />
                    </Box>
                  </Grid>
                  {!isUpdate && (
                    <Grid item lg={5.5} xs={12}>
                      <Field.TextField
                        name="password"
                        type={visible ? 'text' : 'password'}
                        label={t('userManagement.password')}
                        placeholder={t('userManagement.password')}
                        inputProps={{
                          maxLength: TEXTFIELD_REQUIRED_LENGTH.PASSWORD.MAX,
                        }}
                        endAdornment={
                          <IconButton
                            onClick={() => setVisible(!visible)}
                            size="small"
                            sx={{ mx: 0.5 }}
                          >
                            {visible ? (
                              <Icon name="visible" />
                            ) : (
                              <Icon name="invisible" />
                            )}
                          </IconButton>
                        }
                        required
                        allow={TEXTFIELD_ALLOW.ALPHANUMERIC_SPECIALS}
                      />
                    </Grid>
                  )}
                  {isUpdate && (
                    <Grid item lg={5.5} xs={12}>
                      <Field.Autocomplete
                        name="status"
                        label={t('userManagement.status')}
                        placeholder={t('userManagement.status')}
                        options={USER_MANAGEMENT_STATUS_OPTIONS}
                        getOptionValue={(opt) => opt?.id}
                        getOptionLabel={(opt) => t(opt?.text)}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      name="fullName"
                      label={t('userManagement.fullName')}
                      placeholder={t('userManagement.fullName')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.NAME.MAX,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.DatePicker
                      name="dateOfBirth"
                      label={t('userManagement.dateOfBirth')}
                      placeholder={t('userManagement.dateOfBirth')}
                    />
                  </Grid>
                  <Grid item lg={5.5} xs={12}>
                    <Field.TextField
                      name="phone"
                      label={t('userManagement.phone')}
                      placeholder={t('userManagement.phone')}
                      allow={TEXTFIELD_ALLOW.NUMERIC}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.PHONE.MAX,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" mt={3}>
                    {t('userManagement.workInfo')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  rowSpacing={2}
                  sx={{
                    backgroundColor: '#DAE7F3',
                    marginTop: '5px',
                    padding: '0 15px 15px 15px',
                  }}
                  justifyContent="space-between"
                >
                  <Grid item lg={5.5} xs={12}>
                    {values && (
                      <Field.Autocomplete
                        name="orgStructures"
                        label={t('profileCustom.orgStructure')}
                        placeholder={t('profileCustom.orgStructure')}
                        asyncRequest={(s) =>
                          apiGetListOrgStructure({
                            keyword: s,
                            limit: ASYNC_SEARCH_LIMIT,
                            filter: convertFilterParams({
                              isActive: STATUS_VALUE.active,
                            }),
                          })
                        }
                        asyncRequestHelper={(res) => res?.data?.items}
                        getOptionLabel={(option) => option?.name}
                        isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                        multiple
                      />
                    )}
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Field.Autocomplete
                      name="factories"
                      label={t('userManagement.factoryName')}
                      placeholder={t('userManagement.factoryName')}
                      asyncRequest={(s) =>
                        searchFactoriesApi({
                          keyword: s,
                          limit: ASYNC_SEARCH_LIMIT,
                          filter: convertFilterParams({
                            isActive: STATUS_VALUE.active,
                          }),
                        })
                      }
                      asyncRequestHelper={(res) => res?.data?.items}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                    />
                  </Grid>

                  <Grid item lg={5.5} xs={12}>
                    <Field.Autocomplete
                      name="userRoleSettings"
                      label={t('userManagement.roleAssign')}
                      placeholder={t('userManagement.roleAssign')}
                      options={roleList}
                      getOptionLabel={(opt) => opt?.name}
                      getOptionValue={(opt) => opt?.id}
                      required
                    />
                  </Grid>
                </Grid>
                {renderActionBar(handleReset, values)}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Page>
  )
}

export default UserManagementForm
