// Action: Get list
export const MASTER_GET_LIST_FACTORY = 'MASTER_GET_LIST_FACTORY'
export const MASTER_GET_LIST_FACTORY_SUCCESS = 'MASTER_GET_LIST_FACTORY_SUCCESS'
export const MASTER_GET_LIST_FACTORY_FAILED = 'MASTER_GET_LIST_FACTORY_FAILED'
export const MASTER_GET_LIST_FACTORY_RESET = 'MASTER_GET_LIST_FACTORY_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_FACTORY = 'MASTER_GET_DETAIL_FACTORY'
export const MASTER_GET_DETAIL_FACTORY_SUCCESS =
  'MASTER_GET_DETAIL_FACTORY_SUCCESS'
export const MASTER_GET_DETAIL_FACTORY_FAILED =
  'MASTER_GET_DETAIL_FACTORY_FAILED'
export const MASTER_GET_DETAIL_FACTORY_RESET = 'MASTER_GET_DETAIL_FACTORY_RESET'

// Action: update
export const MASTER_CREATE_FACTORY = 'MASTER_CREATE_FACTORY'
export const MASTER_CREATE_FACTORY_SUCCESS = 'MASTER_CREATE_FACTORY_SUCCESS'
export const MASTER_CREATE_FACTORY_FAILED = 'MASTER_CREATE_FACTORY_FAILED'

// Action: update
export const MASTER_UPDATE_FACTORY = 'MASTER_UPDATE_FACTORY'
export const MASTER_UPDATE_FACTORY_SUCCESS = 'MASTER_UPDATE_FACTORY_SUCCESS'
export const MASTER_UPDATE_FACTORY_FAILED = 'MASTER_UPDATE_FACTORY_FAILED'

// Action: sync
export const MASTER_SYNC_FACTORY = 'MASTER_SYNC_FACTORY'
export const MASTER_SYNC_FACTORY_SUCCESS = 'MASTER_SYNC_FACTORY_SUCCESS'
export const MASTER_SYNC_FACTORY_FAILED = 'MASTER_SYNC_FACTORY_FAILED'
export const MASTER_SYNC_FACTORY_RESET = 'MASTER_SYNC_FACTORY_RESET'

// Action: delete
export const MASTER_DELETE_FACTORY = 'MASTER_DELETE_FACTORY'
export const MASTER_DELETE_FACTORY_SUCCESS = 'MASTER_DELETE_FACTORY_SUCCESS'
export const MASTER_DELETE_FACTORY_FAILED = 'MASTER_DELETE_FACTORY_FAILED'

// Action: change status
export const MASTER_CHANGE_STATUS_FACTORY = 'MASTER_CHANGE_STATUS_FACTORY'
export const MASTER_CHANGE_STATUS_FACTORY_SUCCESS =
  'MASTER_CHANGE_STATUS_FACTORY_SUCCESS'
export const MASTER_CHANGE_STATUS_FACTORY_FAILED =
  'MASTER_CHANGE_STATUS_FACTORY_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListFactorySuccess(payload) {
  return {
    type: MASTER_GET_LIST_FACTORY_SUCCESS,
    payload: payload,
  }
}

export function actGetListFactoryFailed() {
  return {
    type: MASTER_GET_LIST_FACTORY_FAILED,
  }
}

export function actGetListFactoryReset() {
  return {
    type: MASTER_GET_LIST_FACTORY_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailFactorySuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_FACTORY_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailFactoryFailed() {
  return {
    type: MASTER_GET_DETAIL_FACTORY_FAILED,
  }
}

export function actGetDetailFactoryReset() {
  return {
    type: MASTER_GET_DETAIL_FACTORY_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncFactorySuccess(payload) {
  return {
    type: MASTER_SYNC_FACTORY_SUCCESS,
    payload: payload,
  }
}

export function actSyncFactoryFailed() {
  return {
    type: MASTER_SYNC_FACTORY_FAILED,
  }
}

export function actSyncFactoryReset() {
  return {
    type: MASTER_SYNC_FACTORY_RESET,
  }
}

/** create
 * @param {} payload body
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actCreateFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_CREATE_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actCreateFactorySuccess(payload) {
  return {
    type: MASTER_CREATE_FACTORY_SUCCESS,
    payload: payload,
  }
}

export function actCreateFactoryFailed() {
  return {
    type: MASTER_CREATE_FACTORY_FAILED,
  }
}

/** update
 * @param {
 *  id: any
 *  orgStructureIds: Array
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateFactorySuccess(payload) {
  return {
    type: MASTER_UPDATE_FACTORY_SUCCESS,
    payload: payload,
  }
}

export function actUpdateFactoryFailed() {
  return {
    type: MASTER_UPDATE_FACTORY_FAILED,
  }
}

/** delete
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDeleteFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_DELETE_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDeleteFactorySuccess() {
  return {
    type: MASTER_DELETE_FACTORY_SUCCESS,
  }
}

export function actDeleteFactoryFailed() {
  return {
    type: MASTER_DELETE_FACTORY_FAILED,
  }
}

/** change status
 * @param {
 *  id: any,
 *  status: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actChangeStatusFactory(payload, onSuccess, onError) {
  return {
    type: MASTER_CHANGE_STATUS_FACTORY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actChangeStatusFactorySuccess() {
  return {
    type: MASTER_CHANGE_STATUS_FACTORY_SUCCESS,
  }
}

export function actChangeStatusFactoryFailed() {
  return {
    type: MASTER_CHANGE_STATUS_FACTORY_FAILED,
  }
}

export default {
  actGetListFactory,
  actGetListFactorySuccess,
  actGetListFactoryFailed,
  actGetListFactoryReset,
  actGetDetailFactory,
  actGetDetailFactorySuccess,
  actGetDetailFactoryFailed,
  actGetDetailFactoryReset,
  actSyncFactory,
  actSyncFactorySuccess,
  actSyncFactoryFailed,
  actSyncFactoryReset,
  actCreateFactory,
  actCreateFactorySuccess,
  actCreateFactoryFailed,
  actUpdateFactory,
  actUpdateFactorySuccess,
  actUpdateFactoryFailed,
  actDeleteFactory,
  actDeleteFactorySuccess,
  actDeleteFactoryFailed,
  actChangeStatusFactory,
  actChangeStatusFactorySuccess,
  actChangeStatusFactoryFailed,
}
