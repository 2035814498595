const style =
  ({ isMinimal, customWidth, gridSpace }) =>
  (theme) => ({
    root: {},
    form: {
      flex: 1,
      '&>.MuiGrid-container > div': {
        paddingLeft: theme.spacing(gridSpace),
        paddingTop: theme.spacing(gridSpace),
        // flexGrow: 0,
        boxSizing: 'border-box',
        margin: 0,

        ...(customWidth
          ? { ...customWidth }
          : { flexBasis: '25%', maxWidth: '25%' }),

        ...(customWidth
          ? {}
          : isMinimal
          ? {
              [theme.breakpoints.down('lg')]: {
                flexBasis: '33.33%',
                maxWidth: '33.33%',
              },
              [theme.breakpoints.down('md')]: {
                flexBasis: '50%',
                maxWidth: '50%',
              },
              [theme.breakpoints.down('sm')]: {
                flexBasis: '100%',
                maxWidth: '100%',
              },
            }
          : {
              [theme.breakpoints.down('xl')]: {
                flexBasis: '25%',
                maxWidth: '25%',
              },
              [theme.breakpoints.down('lg')]: {
                flexBasis: '50%',
                maxWidth: '50%',
              },
              [theme.breakpoints.down('md')]: {
                flexBasis: '100%',
                maxWidth: '100%',
              },
            }),
      },
    },
  })

export default style
