const style = (theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    padding: theme.spacing(1 / 2),
    marginTop: 'auto',
  },
})

export default style
