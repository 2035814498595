import { api } from '~/services/api'

/**
 *
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListReportOt = (params) => {
  const uri = `/reports/bus-overtime-and-bussiness-travel`

  return api.get(uri, params)
}

/**
 * @returns {Promise}
 */
export const apiSyncReportOtBu = (body = {}) => {
  const uri = `/sync/report-overtime-and-bussiness`

  return api.post(uri, body)
}
