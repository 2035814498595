import * as Yup from 'yup'

import { phoneSchema } from '~/common/schemas'

export const validationSchema = (t) =>
  Yup.object().shape({
    code: Yup.string().required(t('form.required')),
    fullName: Yup.string().required(t('form.required')),
    email: Yup.object().nullable().required(t('form.required')),
    phone: phoneSchema(t),
    dateOfBirth: Yup.date().nullable().max(new Date(), t('date.maxToday')),
    userRoleSettings: Yup.number().nullable().required(t('form.required')),
  })
