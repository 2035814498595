import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MODAL_MODE } from '~/common/constants'
import { useSidebar } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import Button from '~/components/Button'

const BottomActionBar = ({
  onBack,
  onCancel,
  mode,
  sx,
  children,
  elBefore,
  elAfter,
  disableSaveButton,
  loading,
  titleButton,
  fixedPosition,
}) => {
  const { t } = useTranslation(['buseye'])

  const { setIsBottomActionBarExisted } = useApp()
  const { isMinimal } = useSidebar()
  const hasBtns = Boolean(mode || onCancel || children || elBefore || elAfter)

  useEffect(() => {
    if (fixedPosition && hasBtns) {
      setIsBottomActionBarExisted(true)

      return () => setIsBottomActionBarExisted(false)
    }
  }, [fixedPosition, hasBtns])

  if (!hasBtns) return null

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        p: 1,
        gap: 1,
        ...(fixedPosition
          ? {
              position: 'fixed',
              bottom: 0,
              right: theme.spacing(2),
              left: isMinimal ? 80 : 266,
              zIndex: 100,
              background: '#fff',
              transition: 'all .2s ease',
              boxShadow: '0px -5px 30px 2px rgba(0, 0, 0, 0.10)',
              borderRadius: '4px',
              '.page-width-scrollbar &': {
                right: theme.spacing(8 / 3),
              },
            }
          : {}),
        ...sx,

        '&:empty': {
          display: 'none',
        },
      })}
    >
      {children ? (
        children
      ) : (
        <>
          {typeof elBefore === 'function' ? elBefore() : elBefore}

          {typeof onBack === 'function' && (
            <Button color="grayF4" onClick={onBack}>
              {t('actionBar.back')}
            </Button>
          )}

          {typeof onCancel === 'function' && (
            <Button variant="outlined" color="subText" onClick={onCancel}>
              {t('actionBar.cancel')}
            </Button>
          )}

          {mode === MODAL_MODE.CREATE && (
            <Button
              type="submit"
              icon="save"
              disabled={disableSaveButton}
              loading={loading}
            >
              {titleButton || t('actionBar.create')}
            </Button>
          )}

          {mode === MODAL_MODE.UPDATE && (
            <Button
              type="submit"
              icon="save"
              disabled={disableSaveButton}
              loading={loading}
            >
              {titleButton || t('actionBar.save')}
            </Button>
          )}

          {typeof elAfter === 'function' ? elAfter() : elAfter}
        </>
      )}
    </Box>
  )
}

BottomActionBar.defaultProps = {
  children: null,
  elBefore: null,
  elAfter: null,
  sx: {},
  disableSaveButton: false,
  titleButton: '',
  fixedPosition: true,
}

BottomActionBar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  mode: PropTypes.oneOf([MODAL_MODE.CREATE, MODAL_MODE.UPDATE]),
  elBefore: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  elAfter: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  disableSaveButton: PropTypes.bool,
  loading: PropTypes.bool,
  titleButton: PropTypes.string,
  fixedPosition: PropTypes.bool,
}

export default BottomActionBar
