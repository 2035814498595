import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetDetailWorkScheduleFailed,
  actGetDetailWorkScheduleSuccess,
  MASTER_GET_DETAIL_WORK_SCHEDULE,
} from '../../actions/work-schedule'
import { apiGetDetailWorkSchedule } from '../../apis/work-schedule'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetDetail(action) {
  try {
    const response = yield call(apiGetDetailWorkSchedule, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actGetDetailWorkScheduleSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetDetailWorkScheduleFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetDetail() {
  yield takeLatest(MASTER_GET_DETAIL_WORK_SCHEDULE, doGetDetail)
}
