import { combineReducers } from 'redux'

import {
  MASTER_CHANGE_STATUS_FACTORY,
  MASTER_CHANGE_STATUS_FACTORY_FAILED,
  MASTER_CHANGE_STATUS_FACTORY_SUCCESS,
  MASTER_CREATE_FACTORY,
  MASTER_CREATE_FACTORY_FAILED,
  MASTER_CREATE_FACTORY_SUCCESS,
  MASTER_DELETE_FACTORY,
  MASTER_DELETE_FACTORY_FAILED,
  MASTER_DELETE_FACTORY_SUCCESS,
  MASTER_GET_DETAIL_FACTORY,
  MASTER_GET_DETAIL_FACTORY_FAILED,
  MASTER_GET_DETAIL_FACTORY_RESET,
  MASTER_GET_DETAIL_FACTORY_SUCCESS,
  MASTER_GET_LIST_FACTORY,
  MASTER_GET_LIST_FACTORY_FAILED,
  MASTER_GET_LIST_FACTORY_RESET,
  MASTER_GET_LIST_FACTORY_SUCCESS,
  MASTER_SYNC_FACTORY,
  MASTER_SYNC_FACTORY_FAILED,
  MASTER_SYNC_FACTORY_RESET,
  MASTER_SYNC_FACTORY_SUCCESS,
  MASTER_UPDATE_FACTORY,
  MASTER_UPDATE_FACTORY_FAILED,
  MASTER_UPDATE_FACTORY_SUCCESS,
} from '../actions/factory'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listFactory(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_FACTORY:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_FACTORY_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_FACTORY_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailFactory(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_FACTORY:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_FACTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_FACTORY_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncFactory(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_FACTORY:
      return { ...state, isLoading: true }
    case MASTER_SYNC_FACTORY_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_FACTORY_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function updateFactory(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_UPDATE_FACTORY:
      return { ...state, isLoading: true }
    case MASTER_UPDATE_FACTORY_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
      }
    case MASTER_UPDATE_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

function createFactory(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_CREATE_FACTORY:
      return { ...state, isLoading: true }
    case MASTER_CREATE_FACTORY_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
      }
    case MASTER_CREATE_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

function changeStatusFactory(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_CHANGE_STATUS_FACTORY:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CHANGE_STATUS_FACTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_CHANGE_STATUS_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function deleteFactory(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_DELETE_FACTORY:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_DELETE_FACTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DELETE_FACTORY_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listFactory,
  detailFactory,
  syncFactory,
  updateFactory,
  createFactory,
  changeStatusFactory,
  deleteFactory,
})
