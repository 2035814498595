import { useMemo } from 'react'

import { Formik } from 'formik'
import { isEmpty } from 'lodash'

import { MODAL_MODE, NOTIFICATION_TYPE } from '~/common/constants'
import Dialog from '~/components/Dialog'
import { STATUS_VALUE } from '~/modules/master/constants'
import {
  apiCreateVehicleCategories,
  apiUpdateVehicleCategories,
} from '~/modules/master/redux/apis/vehicle-categories'
import addNotification from '~/utils/toast'

import FormInput from './form-input'
import { validationSchema } from './schema'

const DEFAULT_DATA = {
  isActive: STATUS_VALUE.active,
  code: '',
  name: '',
  totalSeat: undefined,
}

const CreateUpdate = ({
  t,
  open,
  data = {},
  onClose = () => {},
  handleSubmitSuccess = () => {},
  // onSubmit = () => {},
}) => {
  const mode = isEmpty(data)
    ? MODAL_MODE.CREATE
    : data.id
    ? MODAL_MODE.UPDATE
    : MODAL_MODE.CLONE

  const initialValues = useMemo(() => {
    if (data) {
      const timeDeparture = data.timeDeparture?.split(':')
      const timeArrival = data.timeArrival?.split(':')

      return {
        ...DEFAULT_DATA,
        ...data,
        timeDeparture:
          timeDeparture && Number(timeDeparture[0]) < 10
            ? `0${Number(timeDeparture[0])}:${timeDeparture[1]}`
            : data.timeDeparture,
        timeArrival:
          timeDeparture && Number(timeArrival[0]) < 10
            ? `0${Number(timeArrival[0])}:${timeArrival[1]}`
            : data.timeArrival,
      }
    }
    return DEFAULT_DATA
  }, [data])

  const handleSubmit = async (val, { setFieldValue }) => {
    const body = {
      ...val,
    }
    setFieldValue('isLoading', true)
    let res = null
    if (body?.id) {
      res = await apiUpdateVehicleCategories({ body })
    } else {
      res = await apiCreateVehicleCategories({ body })
    }

    setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `vehicleCategories.notification.${
          body?.id ? 'updateSuccess' : 'createSuccess'
        }`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      handleSubmitSuccess()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
    // setCreateUpdate(null)
  }

  return (
    <Dialog
      open={open}
      title={t(
        `vehicleCategories.${
          mode === MODAL_MODE.UPDATE ? 'modalUpdateTitle' : 'modalAddTitle'
        }`,
      )}
      onCancel={onClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ handleReset, values }) => (
          <FormInput
            t={t}
            mode={mode}
            data={data}
            isLoading={values.isLoading}
            handleReset={handleReset}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default CreateUpdate
