import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import { STATUS_OPTIONS, STATUS_SYN_OPTION } from '~/modules/master/constants'
import usePosition from '~/modules/master/redux/hooks/usePosition'
import { ROUTE } from '~/modules/master/routes/config'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateToLocalTz,
} from '~/utils'

import { tabList } from '../constants'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.EMPLOYEE.TITLE,
  },
  {
    route: ROUTE.EMPLOYEE.POSITION.LIST.PATH,
    title: ROUTE.EMPLOYEE.POSITION.LIST.TITLE,
  },
]
const ListPosition = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()

  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    costCenterIds: [],
    status: null,
  }

  const [selectedRows, setSelectedRows] = useState([])
  const [confirmSync, setConfirmSync] = useState(false)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState()

  const {
    data: {
      listPosition: { data, total, isLoading },
      syncPosition: { isLoading: isLoadingSync },
    },
    actions: { actGetListPosition, actSyncPosition },
  } = usePosition()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const columns = useMemo(() => [
    {
      field: '#',
      headerName: 'STT',
      width: 40,
      minWidth: 40,
      align: 'center',
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'code',
      headerName: t('position.code'),
      width: 80,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'name',
      headerName: t('position.viName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'engName',
      headerName: t('position.enName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'status',
      headerName: t('position.status'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'dateCreate',
      headerName: t('position.dateCreate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateCreate } = params?.row
        return convertUtcDateToLocalTz(dateCreate)
      },
    },
    {
      field: 'dateUpdate',
      headerName: t('position.dateUpdate'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { dateUpdate } = params?.row
        return convertUtcDateToLocalTz(dateUpdate)
      },
    },
    {
      field: 'isSyncSuccess',
      headerName: t('common.sync'),
      width: 150,
      renderCell: (params) => {
        const { isSyncSuccess } = params.row
        return (
          <Status
            options={STATUS_SYN_OPTION}
            value={isSyncSuccess}
            variant="text"
          />
        )
      },
    },
  ])

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(dataFilter, columns),
      sort: convertSortParams(sort),
    }
    actGetListPosition(params)
    closeConfirmSync()
  }

  const handleSyncData = () => {
    actSyncPosition({}, refreshData)
  }

  const openConfirm = () => {
    setConfirmSync(true)
  }

  const closeConfirmSync = () => {
    setConfirmSync(false)
  }

  const syncButton = (
    <Guard code={FUNCTION_CODE.USER_SYNCHRONIZE_POSITION}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={openConfirm}
      >
        <Icon
          name="assign"
          fill="#FF9054"
          stroke="#FF9054"
          sx={{ marginRight: '5px' }}
        />
        {t('common.sync')}
      </Box>
    </Guard>
  )

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        working: 1,
        unregistered: 22,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.position')}
      onBack={() => history.goBack()}
      loading={isLoading || isLoadingSync}
    >
      <HotKeys handlers={{}} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_POSITION}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            customWidth: {},
            gridSpace: 4,
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        beforeTopbar={syncButton}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!confirmSync}
        onConfirm={handleSyncData}
        onCancel={closeConfirmSync}
      />
    </Page>
  )
}

export default ListPosition
