import React, { useMemo } from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useApp } from '~/common/hooks/useApp'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { apiGetListOrgStructureList } from '~/modules/master/redux/apis/org-structure'

import { LGA_DISTRIBUTE_VEHICLE_OPTIONS, TAB_VALUE } from '../../constants'

const FilterForm = ({ tab }) => {
  const { t } = useTranslation(['buseye'])
  const { canAccess } = useApp()

  const filterElm = useMemo(() => {
    switch (tab) {
      case TAB_VALUE.overtime:
        return (
          <>
            <Grid item xs={2.5}>
              <Field.TextField
                name="keyword"
                placeholder={t('common.inputKeyWord')}
                inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <Icon name="search" />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.DateRangePicker
                name="otDate"
                // label={t('attendance.overtime.otDate')}
                placeholder={t('attendance.overtime.otDate')}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.Autocomplete
                name="orgStructure"
                // label={t('attendance.overtime.orgStructure')}
                placeholder={t('attendance.overtime.orgStructure')}
                asyncRequest={(s) =>
                  apiGetListOrgStructureList({
                    keyword: s,
                    limit: ASYNC_SEARCH_LIMIT,
                  })
                }
                asyncRequestHelper={(res) => res?.data?.items}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(opt, val) => opt?.id === val}
                multiple
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.Autocomplete
                // label={t('attendance.overtime.lgaVehicle')}
                placeholder={t('attendance.overtime.lgaVehicle')}
                name="lgaVehicle"
                options={LGA_DISTRIBUTE_VEHICLE_OPTIONS}
                getOptionLabel={(opt) => t(opt?.text) || ''}
                getOptionValue={(opt) => opt?.id}
                isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
              />
            </Grid>
          </>
        )
      case TAB_VALUE.businessTravel:
        return (
          <>
            <Grid item xs={2.5}>
              <Field.TextField
                name="keyword"
                placeholder={t('common.inputKeyWord')}
                inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <Icon name="search" />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.DateRangePicker
                name="businessTravelDay"
                // label={t('attendance.businessTravel.businessTravelDay')}
                placeholder={t('attendance.businessTravel.businessTravelDay')}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.Autocomplete
                name="orgStructure"
                // label={t('attendance.overtime.orgStructure')}
                placeholder={t('attendance.overtime.orgStructure')}
                labelWidth={70}
                fullWidth
                asyncRequest={(s) =>
                  apiGetListOrgStructureList({
                    keyword: s,
                    limit: ASYNC_SEARCH_LIMIT,
                  })
                }
                asyncRequestHelper={(res) => res?.data?.items}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                multiple
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.Autocomplete
                // label={t('attendance.overtime.lgaVehicle')}
                placeholder={t('attendance.overtime.lgaVehicle')}
                name="lgaVehicle"
                options={LGA_DISTRIBUTE_VEHICLE_OPTIONS}
                getOptionLabel={(opt) => t(opt?.text) || ''}
                getOptionValue={(opt) => opt?.id}
                isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
              />
            </Grid>
          </>
        )
      default:
        return (
          <>
            <Grid item xs={2.5}>
              <Field.TextField
                name="keyword"
                placeholder={t('common.inputKeyWord')}
                inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <Icon name="search" />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.TextField
                name="route"
                // label={t('attendance.rosterCalendar.routeName')}
                placeholder={t('attendance.rosterCalendar.routeName')}
                inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
                labelWidth={70}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Field.DateRangePicker
                name="dateWork"
                // label={t('attendance.rosterCalendar.dateWork')}
                placeholder={t('attendance.rosterCalendar.dateWork')}
              />
            </Grid>
            {canAccess(FUNCTION_CODE.USER_VIEW_ALL_PROFILE_CUSTOM) ? <Grid item xs={2.5}>
              <Field.Autocomplete
                name="orgStructure"
                label={t('attendance.rosterCalendar.orgStructure')}
                placeholder={t('attendance.rosterCalendar.orgStructure')}
                asyncRequest={(s) =>
                  apiGetListOrgStructureList({
                    keyword: s,
                    limit: ASYNC_SEARCH_LIMIT,
                  })
                }
                labelWidth={70}
                asyncRequestHelper={(res) => res?.data?.items}
                getOptionLabel={(option) => `[${option.code}] ${option.name}`}
                isOptionEqualToValue={(opt, val) => opt?.id === val}
                multiple
              />
            </Grid> : null }
          </>
        )
    }
  }, [tab])

  return filterElm
}

export default FilterForm
