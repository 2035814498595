import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import { TAB_VALUE } from '~/modules/master/features/attendance/constants'
import addNotification from '~/utils/toast'

import {
  MASTER_SEND_EMAIL,
  actSendEmailFailed,
  actSendEmailSuccess,
} from '../../actions/email'
import { apiSendMailBu, apiSendMailOt } from '../../apis/email'

/**
 * Handle get data request and response
 * @param {object} action
 */

function* doSendEmail(action) {
  try {
    const { type, body } = action?.payload
    const response = yield call(
      type === TAB_VALUE.overtime ? apiSendMailOt : apiSendMailBu,
      body,
    )

    if (response?.statusCode === 200) {
      yield put(actSendEmailSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actSendEmailFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchEmail() {
  yield all([takeLatest(MASTER_SEND_EMAIL, doSendEmail)])
}
