// Action: Get list
export const MASTER_GET_LIST_ROUTE = 'MASTER_GET_LIST_ROUTE'
export const MASTER_GET_LIST_ROUTE_SUCCESS = 'MASTER_GET_LIST_ROUTE_SUCCESS'
export const MASTER_GET_LIST_ROUTE_FAILED = 'MASTER_GET_LIST_ROUTE_FAILED'
export const MASTER_GET_LIST_ROUTE_RESET = 'MASTER_GET_LIST_ROUTE_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_ROUTE = 'MASTER_GET_DETAIL_ROUTE'
export const MASTER_GET_DETAIL_ROUTE_SUCCESS = 'MASTER_GET_DETAIL_ROUTE_SUCCESS'
export const MASTER_GET_DETAIL_ROUTE_FAILED = 'MASTER_GET_DETAIL_ROUTE_FAILED'
export const MASTER_GET_DETAIL_ROUTE_RESET = 'MASTER_GET_DETAIL_ROUTE_RESET'

// Action: create
export const MASTER_CREATE_ROUTE = 'MASTER_CREATE_ROUTE'
export const MASTER_CREATE_ROUTE_SUCCESS = 'MASTER_CREATE_ROUTE_SUCCESS'
export const MASTER_CREATE_ROUTE_FAILED = 'MASTER_CREATE_ROUTE_FAILED'

// Action: update
export const MASTER_UPDATE_ROUTE = 'MASTER_UPDATE_ROUTE'
export const MASTER_UPDATE_ROUTE_SUCCESS = 'MASTER_UPDATE_ROUTE_SUCCESS'
export const MASTER_UPDATE_ROUTE_FAILED = 'MASTER_UPDATE_ROUTE_FAILED'

// Action: delete
export const MASTER_DELETE_ROUTE = 'MASTER_DELETE_ROUTE'
export const MASTER_DELETE_ROUTE_SUCCESS = 'MASTER_DELETE_ROUTE_SUCCESS'
export const MASTER_DELETE_ROUTE_FAILED = 'MASTER_DELETE_ROUTE_FAILED'

// Action: change status
export const MASTER_CHANGE_STATUS_ROUTE = 'MASTER_CHANGE_STATUS_ROUTE'
export const MASTER_CHANGE_STATUS_ROUTE_SUCCESS =
  'MASTER_CHANGE_STATUS_ROUTE_SUCCESS'
export const MASTER_CHANGE_STATUS_ROUTE_FAILED =
  'MASTER_CHANGE_STATUS_ROUTE_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListRouteSuccess(payload) {
  return {
    type: MASTER_GET_LIST_ROUTE_SUCCESS,
    payload: payload,
  }
}

export function actGetListRouteFailed() {
  return {
    type: MASTER_GET_LIST_ROUTE_FAILED,
  }
}

export function actGetListRouteReset() {
  return {
    type: MASTER_GET_LIST_ROUTE_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailRouteSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_ROUTE_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailRouteFailed() {
  return {
    type: MASTER_GET_DETAIL_ROUTE_FAILED,
  }
}

export function actGetDetailRouteReset() {
  return {
    type: MASTER_GET_DETAIL_ROUTE_RESET,
  }
}

/** create
 * @param {
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actCreateRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_CREATE_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actCreateRouteSuccess(payload) {
  return {
    type: MASTER_CREATE_ROUTE_SUCCESS,
    payload: payload,
  }
}

export function actCreateRouteFailed() {
  return {
    type: MASTER_CREATE_ROUTE_FAILED,
  }
}

/** update
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateRouteSuccess(payload) {
  return {
    type: MASTER_UPDATE_ROUTE_SUCCESS,
    payload: payload,
  }
}

export function actUpdateRouteFailed() {
  return {
    type: MASTER_UPDATE_ROUTE_FAILED,
  }
}

/** delete
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDeleteRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_DELETE_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDeleteRouteSuccess() {
  return {
    type: MASTER_DELETE_ROUTE_SUCCESS,
  }
}

export function actDeleteRouteFailed() {
  return {
    type: MASTER_DELETE_ROUTE_FAILED,
  }
}

/** change status
 * @param {
 *  id: any,
 *  status: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actChangeStatusRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_CHANGE_STATUS_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actChangeStatusRouteSuccess() {
  return {
    type: MASTER_CHANGE_STATUS_ROUTE_SUCCESS,
  }
}

export function actChangeStatusRouteFailed() {
  return {
    type: MASTER_CHANGE_STATUS_ROUTE_FAILED,
  }
}

export default {
  actGetListRoute,
  actGetListRouteSuccess,
  actGetListRouteFailed,
  actGetListRouteReset,
  actGetDetailRoute,
  actGetDetailRouteSuccess,
  actGetDetailRouteFailed,
  actGetDetailRouteReset,
  actUpdateRoute,
  actUpdateRouteSuccess,
  actUpdateRouteFailed,
  actDeleteRoute,
  actDeleteRouteSuccess,
  actDeleteRouteFailed,
  actChangeStatusRoute,
  actChangeStatusRouteSuccess,
  actChangeStatusRouteFailed,
  actCreateRoute,
  actCreateRouteSuccess,
  actCreateRouteFailed,
}
