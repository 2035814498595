import React, { useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { MODAL_MODE } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import Button from '~/components/Button'
import DataTable from '~/components/DataTable'
import { Field } from '~/components/Formik'
import Guard from '~/components/Guard'
import IconButton from '~/components/IconButton'

import { DEFAULT_TABLE } from '../'
import FormManual from '../../form-manual'

const FormTable = ({ mode, values = {}, arrayHelpers, setFieldValue }) => {
  const { t } = useTranslation(['buseye'])
  const [editManual, setEditManual] = useState(null)
  const [detailManual, setDetailManual] = useState(null)
  const modeManual = editManual ? MODAL_MODE.UPDATE : MODAL_MODE.DETAIL

  const openEditManual = (val) => {
    setEditManual(val)
  }

  const closeEditManual = () => {
    setEditManual(null)
  }

  const openDetailManual = (val) => {
    setDetailManual(val)
  }

  const closeDetailManual = () => {
    setDetailManual(null)
  }

  const handleSubmit = (val) => {
    if (!val) return
  }

  const handleChangeRoute = (val, idx, routes) => {
    if (!val) return
    const route = routes?.find((item) => item.code === val)
    const filterDupcate = route?.catShifts?.reduce((acc, current) => {
      acc[current.code] = current
      return acc
    }, {})

    setFieldValue(
      `shiftRoutes[${idx}].catShiftOptions`,
      Object.values(filterDupcate),
    )
  }

  const columns = useMemo(
    () => [
      {
        field: '#',
        headerName: '#',
        width: 40,
        align: 'center',
        renderCell: (_, idx) => {
          return idx + 1
        },
      },
      {
        field: 'routeCode',
        headerName: t('settingFee.route'),
        minWidth: 150,
        required: true,
        renderCell: (params, idx) => {
          const { routeCode } = params?.row
          const id = `setting-route-${idx}`;
          return mode === MODAL_MODE.DETAIL ? (
            routeCode
          ) : (
            <Field.Autocomplete
              id={id}
              name={`shiftRoutes[${idx}].routeCode`}
              placeholder={t('settingFee.route')}
              options={values?.listRouteAll || []}
              getOptionLabel={(opt) => opt?.name}
              getOptionValue={(opt) => opt?.code}
              labelWidth={100}
              isAutoFocus={idx === 0}
              isAutoFocusIdx={idx}
              isOptionEqualToValue={(opt, val) =>
                opt?.code === (val?.code || val)
              }
              onChange={(val) => {
                handleChangeRoute(val, idx, values?.listRouteAll)
              }}
            />
          )
        },
      },
      {
        field: 'shiftCode',
        headerName: t('settingFee.catShift'),
        minWidth: 150,
        required: true,
        renderCell: (params, idx) => {
          const { catShiftOptions = [], shiftCode } = params?.row
          return mode === MODAL_MODE.DETAIL ? (
            shiftCode
          ) : (
            <Field.Autocomplete
              name={`shiftRoutes[${idx}].shiftCode`}
              placeholder={t('settingFee.catShift')}
              options={catShiftOptions}
              getOptionLabel={(opt) => opt?.name}
              getOptionValue={(opt) => opt?.code}
              labelWidth={100}
              isOptionEqualToValue={(opt, val) => opt?.code === val}
            />
          )
        },
      },
      {
        field: 'action',
        headerName: t('common.action'),
        width: 80,
        align: 'center',
        renderCell: (params, idx) => {
          const { year, month, shiftCode, routeCode, id } = params.row

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {mode !== MODAL_MODE.DETAIL && id && (
                <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
                  <IconButton
                    icon="edit"
                    tooltipText={t('common.update')}
                    onClick={() => {
                      openEditManual({ year, month, shiftCode, routeCode, id })
                    }}
                  />
                </Guard>
              )}
              {mode !== MODAL_MODE.DETAIL && id && (
                <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
                  <IconButton
                    icon="delete"
                    tooltipText={t('common.update')}
                    onClick={() => {
                      arrayHelpers?.remove(idx)
                    }}
                  />
                </Guard>
              )}
              {mode === MODAL_MODE.DETAIL && (
                <Guard code={FUNCTION_CODE.USER_UPDATE_FEE_SETTING}>
                  <IconButton
                    icon="show"
                    tooltipText={t('common.detail')}
                    onClick={() => {
                      openDetailManual({
                        year,
                        month,
                        shiftCode,
                        routeCode,
                        id,
                      })
                    }}
                  />
                </Guard>
              )}
            </Box>
          )
        },
      },
    ],
    [t, values, openEditManual, openDetailManual],
  )

  const focusNewInput = () => {
    setTimeout(() => {
      const inputs = document.querySelectorAll(
        'input[id^="setting-route-"]',
      );

      const firstInput = inputs[0];
      if (firstInput) {
        firstInput.focus();
      }
    }, 0);
  };

  const handleAddNew = () => {
    arrayHelpers.unshift({
      ...DEFAULT_TABLE,
      // id: new Date().getTime(),
    })

    focusNewInput()
  }

  const beforeTopbar = (
    <Button
      type="button"
      sx={{ marginRight: '10px' }}
      variant="outlined"
      iconColor="primary"
      icon="add"
      onClick={handleAddNew}
    >
      {t('common.create')}
    </Button>
  )

  return (
    <>
      <DataTable
        title={t('settingFee.flexibleManual')}
        rows={values?.shiftRoutes || []}
        columns={columns}
        maxHeight="310px"
        total={0}
        // tabs={
        //   <Tabs list={tabFormList(t)} value={tab} onChange={handleTabChange} />
        // }
        hideFooter
        hideSetting
        // uniqKey={tab}
        beforeTopbar={mode !== MODAL_MODE.DETAIL ? beforeTopbar : null}
      />
      <FormManual
        open={modeManual === MODAL_MODE.UPDATE ? !!editManual : !!detailManual}
        dataEdit={modeManual === MODAL_MODE.UPDATE ? editManual : detailManual}
        t={t}
        mode={modeManual}
        onClose={
          modeManual === MODAL_MODE.UPDATE ? closeEditManual : closeDetailManual
        }
        onSubmit={handleSubmit}
        handleSubmitSuccess={() => { }}
      />
    </>
  )
}

export default FormTable
