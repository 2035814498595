// Action: Get list
export const MASTER_GET_LIST_ATTENDANCE = 'MASTER_GET_LIST_ATTENDANCE'
export const MASTER_GET_LIST_ATTENDANCE_SUCCESS =
  'MASTER_GET_LIST_ATTENDANCE_SUCCESS'
export const MASTER_GET_LIST_ATTENDANCE_FAILED =
  'MASTER_GET_LIST_ATTENDANCE_FAILED'
export const MASTER_GET_LIST_ATTENDANCE_RESET =
  'MASTER_GET_LIST_ATTENDANCE_RESET'

// Action: sync
export const MASTER_SYNC_ATTENDANCE = 'MASTER_SYNC_ATTENDANCE'
export const MASTER_SYNC_ATTENDANCE_SUCCESS = 'MASTER_SYNC_ATTENDANCE_SUCCESS'
export const MASTER_SYNC_ATTENDANCE_FAILED = 'MASTER_SYNC_ATTENDANCE_FAILED'
export const MASTER_SYNC_ATTENDANCE_RESET = 'MASTER_SYNC_ATTENDANCE_RESET'

// Action: register OT
export const MASTER_REGISTER_VEHICLE_OT = 'MASTER_REGISTER_VEHICLE_OT'
export const MASTER_REGISTER_VEHICLE_OT_SUCCESS =
  'MASTER_REGISTER_VEHICLE_OT_SUCCESS'
export const MASTER_REGISTER_VEHICLE_OT_FAILED =
  'MASTER_REGISTER_VEHICLE_OT_FAILED'

// Action: detail OT
export const MASTER_DETAIL_REGISTER_VEHICLE_OT =
  'MASTER_DETAIL_REGISTER_VEHICLE_OT'
export const MASTER_DETAIL_REGISTER_VEHICLE_OT_SUCCESS =
  'MASTER_DETAIL_REGISTER_VEHICLE_OT_SUCCESS'
export const MASTER_DETAIL_REGISTER_VEHICLE_OT_FAILED =
  'MASTER_DETAIL_REGISTER_VEHICLE_OT_FAILED'
export const MASTER_DETAIL_REGISTER_VEHICLE_OT_RESET =
  'MASTER_DETAIL_REGISTER_VEHICLE_OT_RESET'

// Action: register Business travel
export const MASTER_REGISTER_VEHICLE_BU_TRAVEL =
  'MASTER_REGISTER_VEHICLE_BU_TRAVEL'
export const MASTER_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS =
  'MASTER_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS'
export const MASTER_REGISTER_VEHICLE_BU_TRAVEL_FAILED =
  'MASTER_REGISTER_VEHICLE_BU_TRAVEL_FAILED'

// Action: detail Business travel
export const MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL =
  'MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL'
export const MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS =
  'MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS'
export const MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_FAILED =
  'MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_FAILED'
export const MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_RESET =
  'MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListAttendance(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_ATTENDANCE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListAttendanceSuccess(payload) {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_SUCCESS,
    payload: payload,
  }
}

export function actGetListAttendanceFailed() {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_FAILED,
  }
}

export function actGetListAttendanceReset() {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_RESET,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncAttendance(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_ATTENDANCE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncAttendanceSuccess(payload) {
  return {
    type: MASTER_SYNC_ATTENDANCE_SUCCESS,
    payload: payload,
  }
}

export function actSyncAttendanceFailed() {
  return {
    type: MASTER_SYNC_ATTENDANCE_FAILED,
  }
}

export function actSyncAttendanceReset() {
  return {
    type: MASTER_SYNC_ATTENDANCE_RESET,
  }
}

/** register OT
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actRegisterVehicleOt(payload, onSuccess, onError) {
  return {
    type: MASTER_REGISTER_VEHICLE_OT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actRegisterVehicleOtSuccess(payload) {
  return {
    type: MASTER_REGISTER_VEHICLE_OT_SUCCESS,
    payload: payload,
  }
}

export function actRegisterVehicleOtFailed() {
  return {
    type: MASTER_REGISTER_VEHICLE_OT_FAILED,
  }
}

/** get detail register OT
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDetailRegisterVehicleOt(payload, onSuccess, onError) {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_OT,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDetailRegisterVehicleOtSuccess(payload) {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_OT_SUCCESS,
    payload: payload,
  }
}

export function actDetailRegisterVehicleOtFailed() {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_OT_FAILED,
  }
}

export function actDetailRegisterVehicleOtReset() {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_OT_RESET,
  }
}

/** register Business travel
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actRegisterVehicleBuTravel(payload, onSuccess, onError) {
  return {
    type: MASTER_REGISTER_VEHICLE_BU_TRAVEL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actRegisterVehicleBuTravelSuccess(payload) {
  return {
    type: MASTER_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS,
    payload: payload,
  }
}

export function actRegisterVehicleBuTravelFailed() {
  return {
    type: MASTER_REGISTER_VEHICLE_BU_TRAVEL_FAILED,
  }
}

/** get detail Business travel
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actDetailRegisterVehicleBuTravel(payload, onSuccess, onError) {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actDetailRegisterVehicleBuTravelSuccess(payload) {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_SUCCESS,
    payload: payload,
  }
}

export function actDetailRegisterVehicleBuTravelFailed() {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_FAILED,
  }
}

export function actDetailRegisterVehicleBuTravelReset() {
  return {
    type: MASTER_DETAIL_REGISTER_VEHICLE_BU_TRAVEL_RESET,
  }
}

export default {
  actGetListAttendance,
  actGetListAttendanceSuccess,
  actGetListAttendanceFailed,
  actGetListAttendanceReset,

  actSyncAttendance,
  actSyncAttendanceSuccess,
  actSyncAttendanceFailed,
  actSyncAttendanceReset,

  actRegisterVehicleOt,
  actRegisterVehicleOtSuccess,
  actRegisterVehicleOtFailed,
  actDetailRegisterVehicleOt,
  actDetailRegisterVehicleOtSuccess,
  actDetailRegisterVehicleOtFailed,
  actDetailRegisterVehicleOtReset,

  actRegisterVehicleBuTravel,
  actRegisterVehicleBuTravelSuccess,
  actRegisterVehicleBuTravelFailed,
  actDetailRegisterVehicleBuTravel,
  actDetailRegisterVehicleBuTravelSuccess,
  actDetailRegisterVehicleBuTravelFailed,
  actDetailRegisterVehicleBuTravelReset,
}
