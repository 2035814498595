import { combineReducers } from 'redux'

import {
  MASTER_CHANGE_STATUS_PICKUP_POINT,
  MASTER_CHANGE_STATUS_PICKUP_POINT_FAILED,
  MASTER_CHANGE_STATUS_PICKUP_POINT_SUCCESS,
  MASTER_CREATE_PICKUP_POINT,
  MASTER_CREATE_PICKUP_POINT_FAILED,
  MASTER_CREATE_PICKUP_POINT_SUCCESS,
  MASTER_DELETE_PICKUP_POINT,
  MASTER_DELETE_PICKUP_POINT_FAILED,
  MASTER_DELETE_PICKUP_POINT_SUCCESS,
  MASTER_GET_DETAIL_PICKUP_POINT,
  MASTER_GET_DETAIL_PICKUP_POINT_FAILED,
  MASTER_GET_DETAIL_PICKUP_POINT_RESET,
  MASTER_GET_DETAIL_PICKUP_POINT_SUCCESS,
  MASTER_GET_LIST_PICKUP_POINT,
  MASTER_GET_LIST_PICKUP_POINT_FAILED,
  MASTER_GET_LIST_PICKUP_POINT_RESET,
  MASTER_GET_LIST_PICKUP_POINT_SUCCESS,
  MASTER_UPDATE_PICKUP_POINT,
  MASTER_UPDATE_PICKUP_POINT_FAILED,
  MASTER_UPDATE_PICKUP_POINT_SUCCESS,
} from '../actions/pickup-point'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listPickupPoint(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_PICKUP_POINT:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_PICKUP_POINT_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_GET_LIST_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_PICKUP_POINT_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function createPickupPoint(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_CREATE_PICKUP_POINT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CREATE_PICKUP_POINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_CREATE_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function detailPickupPoint(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_PICKUP_POINT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_PICKUP_POINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_GET_DETAIL_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_PICKUP_POINT_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function updatePickupPoint(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_UPDATE_PICKUP_POINT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_PICKUP_POINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function deletePickupPoint(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_DELETE_PICKUP_POINT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_DELETE_PICKUP_POINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_DELETE_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function changeStatusPickupPoint(
  state = { isLoading: false, data: null },
  action,
) {
  switch (action.type) {
    case MASTER_CHANGE_STATUS_PICKUP_POINT:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_CHANGE_STATUS_PICKUP_POINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_CHANGE_STATUS_PICKUP_POINT_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  listPickupPoint,
  createPickupPoint,
  detailPickupPoint,
  updatePickupPoint,
  deletePickupPoint,
  changeStatusPickupPoint,
})
