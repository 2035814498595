export const TAB_FORM_VALUE = {
  FIXED_D: 0,
  FIXED_OTHER: 1,
  FLEXIBLE_AUTO: 2,
  FLEXIBLE_MANUAL: 3,
}

export const tabFormList = (t) => {
  return [
    {
      label: t('settingFee.fixedD'),
      value: TAB_FORM_VALUE.FIXED_D,
    },
    {
      label: t('settingFee.fixedOther'),
      value: TAB_FORM_VALUE.FIXED_OTHER,
    },
    {
      label: t('settingFee.flexibleAuto'),
      value: TAB_FORM_VALUE.FLEXIBLE_AUTO,
    },
    {
      label: t('settingFee.flexibleManual'),
      value: TAB_FORM_VALUE.FLEXIBLE_MANUAL,
    },
  ]
}

export const formatCatshift = (catShifts = []) => {
  const dataFormat = catShifts.reduce((acc, current) => {
    if (['1', '2', '3'].includes(current.code)) {
      acc['OTHER'] = {
        name: 'SHIFT 1,2,3',
        code: 'OTHER',
      }
    } else {
      acc[current.code] = current
    }

    return acc
  }, {})

  return Object.values(dataFormat)
}
